import React, { useState } from 'react';
import { StayPrimaryPortrait } from '@mui/icons-material';
import { OneBrandPromoUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import Localized from '../../../../shared/Localized';
import { OneBrandPromoPreview } from '../../ContentPreview/OneBrandPromoPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { oneBrandPromoListItemTestIds } from '../../../../shared/TestsIds';

export type OneBrandPromoListItemProps = IBaseUIModuleListItemProps<OneBrandPromoUiModuleBody>;

export function OneBrandPromoListItem({ uiModule, ...props }: OneBrandPromoListItemProps): JSX.Element {
  const { t } = useLocales();
  const [isPreviewShowing, setIsPreviewShowing] = useState(false);
  const { formControlColor } = useTheme();

  const onPreviewButtonClick = () => {
    setIsPreviewShowing(true);
  };

  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} data-testid={oneBrandPromoListItemTestIds.title} />}
        collectionLine={[
          <Button
            key="preview"
            variant="text"
            size="small"
            startIcon={<StayPrimaryPortrait />}
            color={formControlColor}
            onClick={onPreviewButtonClick}
            data-testid={oneBrandPromoListItemTestIds.button}
          >
            {t('layouts.preview_contents')}
          </Button>
        ]}
        {...props}
      />
      <OneBrandPromoPreview module={uiModule} isOpen={isPreviewShowing} setIsOpen={setIsPreviewShowing} />
    </>
  );
}
