import { Stack } from '@mui/material';
import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextValidator } from 'react-material-ui-form-validator';
import { InlinePromoUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { FormBody, FormField } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PagesAutocomplete } from '../PagesAutocomplete';
import { isPromoPagePath } from '../../../../../utils/uiModuleUtils';
import { inlinePromoFormTestIds } from '../../../../shared/TestsIds';

const InlinePromoForm = forwardRef<UIModuleTypeForm<InlinePromoUiModuleBody>>(() => {
  const { control } = useFormContext<InlinePromoUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const InlinePromoAssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: {
    name: keyof InlinePromoUiModuleBody;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FormField>
              <AssetBrowser
                label={isRequired(label)}
                assetId={assetType}
                assetType={assetType}
                value={value as string}
                data-testid={inlinePromoFormTestIds.assetField(name)}
                hasError={!!error}
                onChange={onChange}
              />
            </FormField>
          );
        }}
      />
    );
  };

  return (
    <FormBody data-testid={inlinePromoFormTestIds.formBody}>
      <Stack direction="row" mt={6}>
        <InlinePromoAssetPicker
          name="landscapeFillImage"
          label={t('image_labels.landscape')}
          assetType={AssetTypes.inlinePromo.landscapeFill}
          required
        />
        <InlinePromoAssetPicker
          name="portraitFillImage"
          label={t('image_labels.portrait')}
          assetType={AssetTypes.inlinePromo.portraitFill}
          required
        />
      </Stack>
      <Stack direction="row">
        <InlinePromoAssetPicker
          name="mobileFillImage"
          label={t('image_labels.mobile')}
          assetType={AssetTypes.inlinePromo.mobileFill}
        />
        <InlinePromoAssetPicker
          name="ctvFillImage"
          label={t('image_labels.ctv')}
          assetType={AssetTypes.inlinePromo.ctvFill}
        />
      </Stack>
      <Stack direction="row">
        <FormField>
          <Controller
            control={control}
            name="ctaName"
            render={({ field: { value, onChange } }) => (
              <LokaliseAutocomplete
                label={t('layouts.cta_text')}
                name="ctaName"
                value={value as string}
                onChange={onChange}
                required
                data-testid={inlinePromoFormTestIds.ctaNameField}
              />
            )}
          />
        </FormField>
        <FormField>
          <Controller
            control={control}
            name="ctaUrlPath"
            render={({ field: { value, onChange } }) => (
              <PagesAutocomplete
                value={value}
                onChange={onChange}
                filter={isPromoPagePath}
                renderInput={(params) => (
                  <TextValidator
                    value={value}
                    {...params}
                    name="targetId"
                    label={markAsRequired(t('layouts.cta_page_path'))}
                    color={formControlColor}
                    validators={['required']}
                    errorMessages={[t('general.field_is_required')]}
                  />
                )}
                data-testid={inlinePromoFormTestIds.ctaPathField}
              />
            )}
          />
        </FormField>
      </Stack>

      <FormField>
        <Controller
          control={control}
          name="priceText"
          render={({ field: { value, onChange } }) => (
            <LokaliseAutocomplete
              label={t('layouts.price_text')}
              name="priceText"
              value={value as string}
              onChange={onChange}
              data-testid={inlinePromoFormTestIds.priceTextField}
              required={false}
            />
          )}
        />
      </FormField>
    </FormBody>
  );
});

InlinePromoForm.displayName = 'InlinePromoForm';

export default InlinePromoForm;
