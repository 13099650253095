import React from 'react';
import { Avatar, Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';
import { themeColors } from '../../../theme';
import { countryBadgeTestIds } from '../TestsIds';

export interface ICountryBadgeProps extends ChipProps {
  countryCode: string;
}

const useStyles = makeStyles()((theme) => ({
  chip: {
    backgroundColor: themeColors[theme.palette.mode].countryBadgeColor
  }
}));

export default function CountryBadge({ countryCode, ...props }: ICountryBadgeProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <Chip
      className={classes.chip}
      avatar={
        <Avatar src={`/assets/flags/${countryCode.toLowerCase()}.png`} data-testid={countryBadgeTestIds.avatar} />
      }
      size="small"
      label={countryCode}
      data-testid={countryBadgeTestIds.chip}
      {...props}
    />
  );
}
