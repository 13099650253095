import React from 'react';
import { Chip } from '@mui/material';
import { capitalize } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import { ContentCollectionBody, ContentCollectionResponse } from '../../../API';

const useStyles = makeStyles()((theme) => ({
  message: {
    marginTop: '20px'
  },
  deleteGenreWarning: {
    fontSize: '14px',
    marginTop: '20px'
  },
  deleteWarningLabel: {
    marginRight: theme.spacing(1)
  }
}));

export const testIds = {
  componentRoot: 'collection-delete-confirm-component-root'
};

export function CollectionDeleteConfirm({
  collection
}: {
  collection: ContentCollectionResponse | ContentCollectionBody;
}): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <div data-testid={testIds.componentRoot}>
      <div className={classes.message}>{t('confirm.delete_collection')}</div>
      {collection.genre?.key && (
        <div className={classes.deleteGenreWarning}>
          <Chip color="warning" label={t('filters.warning')} className={classes.deleteWarningLabel} size="small" />
          {t('confirm.delete_genre_collection', { genre: capitalize(collection.genre.key) })}
        </div>
      )}
    </div>
  );
}
