import React, { useEffect, useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../data-layer';
import Drawer from '../../Drawer';
import Button from '../../Button';
import { useConfirm, useLocales, useNotifications } from '../../../../hooks';
import { Delete } from '@mui/icons-material';
import {
  DataPresetResponse,
  DataPresetType,
  HeroItemChannelDataPresetBody,
  HeroItemEventsDataPresetBody,
  HeroItemPromotionDataPresetBody
} from '../../../../API';
import TextField from '../../TextField';
import { fieldErrorsToArray, markAsRequired } from '../../../../utils/formHelpers';
import { Controller, FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { HeroItemFormFields } from '../../HeroItemFormFields';
import { NewHeroPresetTypes } from '../../../../utils/uiModuleUtils';
import { convertDate } from '../../../../utils/dateHelpers';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { presetFormTestIds } from '../../TestsIds';

const useStyles = makeStyles()(() => ({
  footerButton: {
    minWidth: 160
  }
}));

export function PresetForm(): React.ReactElement {
  const { canSave, canDelete } = usePermissionsGuard({
    homepageOption: HomepageOptions.HPC
  });
  const {
    presets: {
      state: { withFormMetadata, withIsSaving },
      hook: { closeForm, removePreset, updatePreset, createPreset }
    }
  } = useData();
  const { classes } = useStyles();

  const { t } = useLocales();
  const formRef = useRef<ValidatorForm>(null);

  const { confirm } = useConfirm();
  const { notifyError } = useNotifications();
  const formMetadata = useRecoilValue(withFormMetadata);
  const isSaving = useRecoilValue(withIsSaving);
  const preset = formMetadata.record;
  const isNew = formMetadata.isNew;

  const form = useForm<DataPresetResponse>();

  const { control, handleSubmit, reset } = form;

  useEffect(() => {
    reset(preset);
  }, [preset]);

  const transformScheduleTimes = <T extends NewHeroPresetTypes>(preset: DataPresetResponse): DataPresetResponse => {
    return {
      ...preset,
      data: {
        ...preset.data,
        scheduleTimes: (preset.data as T).scheduleTimes?.map((item) => ({
          ...item,
          startTime: convertDate(item.startTime),
          endTime: convertDate(item.endTime)
        }))
      }
    };
  };

  const transformDateTimes = (preset: DataPresetResponse): DataPresetResponse => {
    if (!preset) return preset;

    switch (preset.type) {
      case DataPresetType.HERO_ITEM_CHANNEL:
        return transformScheduleTimes<HeroItemChannelDataPresetBody>(preset);
      case DataPresetType.HERO_ITEM_EVENTS:
        return transformScheduleTimes<HeroItemEventsDataPresetBody>(preset);
      case DataPresetType.HERO_ITEM_PROMOTION:
        return transformScheduleTimes<HeroItemPromotionDataPresetBody>(preset);
      default:
        return preset;
    }
  };

  const onDelete = async () => {
    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: t('confirm.delete_preset')
    });

    if (result && preset) {
      const successful = await removePreset((preset as DataPresetResponse).id as string);
      if (successful) {
        onClose();
      }
    }
  };

  const onClose = () => {
    if (!isSaving) {
      closeForm();
    }
  };

  const onSubmit: SubmitHandler<DataPresetResponse> = async (formData, event) => {
    event?.preventDefault();
    event?.stopPropagation();

    if (isNew) {
      if (await createPreset(transformDateTimes(formData))) {
        onClose();
      }
    } else if (await updatePreset(formData.id as string, transformDateTimes(formData))) {
      onClose();
    }
  };

  const onError: SubmitErrorHandler<DataPresetResponse> = (errors, event) => {
    event?.preventDefault();
    event?.stopPropagation();
    notifyError(t('general.form_error'), fieldErrorsToArray(errors));
  };

  const headerLeft = (
    <Typography variant="h6">{formMetadata.isNew ? t('presets.new_preset') : t('presets.edit_preset')}</Typography>
  );

  const headerRight = !isNew && (
    <Button
      color="error"
      onClick={onDelete}
      endIcon={<Delete />}
      data-testid={presetFormTestIds.deleteButton}
      disabled={!canDelete}
    >
      {t('general.delete')}
    </Button>
  );

  const footerLeft = (
    <Button
      type="submit"
      color="primary"
      onClick={handleSubmit(onSubmit, onError)}
      disabled={!canSave || isSaving}
      loading={isSaving}
      className={classes.footerButton}
      data-testid={presetFormTestIds.saveButton}
    >
      {t('presets.save_preset')}
    </Button>
  );

  const footerRight = (
    <Button
      color="grey"
      disabled={isSaving}
      onClick={onClose}
      className={classes.footerButton}
      data-testid={presetFormTestIds.cancelButton}
    >
      {t('general.cancel')}
    </Button>
  );

  return (
    <Drawer
      open={formMetadata.isShowingForm}
      onSubmit={handleSubmit(onSubmit, onError)}
      onClose={onClose}
      headerLeft={headerLeft}
      headerRight={headerRight}
      footerLeft={footerLeft}
      footerRight={footerRight}
      formRef={formRef}
    >
      <FormProvider {...form}>
        {preset && (
          <Stack gap={6} padding={4}>
            <Controller
              control={control}
              name="name"
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  value={value}
                  onChange={onChange}
                  label={markAsRequired(t('general.name'))}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <HeroItemFormFields isPreset />
          </Stack>
        )}
      </FormProvider>
    </Drawer>
  );
}
