import { InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from 'lodash-es';
import React, { ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import { VllChannelType } from '../../../../API';
import { useLocales } from '../../../../hooks';
import { withChannelLiveFilter, withChannelPremiumFilter, withChannelTypeFilter } from '../../../../state/Layouts';
import { ALL, TRUE, FALSE, IncludeAll, TrinaryBoolean } from '../../../../utils/types/genericTypes';
import FormControl from '../../../shared/FormControl';
import { EPGChannelFiltersTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()(() => ({
  filterDropdown: {
    minWidth: 120
  },
  filterDropdownContainer: {
    flexBasis: 1,
    flexGrow: 1
  }
}));

export function EPGChannelFilters(): ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const [channelType, setChannelType] = useRecoilState(withChannelTypeFilter);
  const [isLive, setIsLive] = useRecoilState(withChannelLiveFilter);
  const [isPremium, setIsPremium] = useRecoilState(withChannelPremiumFilter);

  return (
    <>
      <FormControl className={classes.filterDropdownContainer}>
        <InputLabel>{t('epg.channels.channel_type')}</InputLabel>
        <Select
          className={classes.filterDropdown}
          value={channelType}
          onChange={({ target: { value } }) => setChannelType(value as IncludeAll<VllChannelType>)}
          data-testid={EPGChannelFiltersTestIds.channelTypeSelector}
        >
          <MenuItem value={ALL} data-testid={EPGChannelFiltersTestIds.channelTypeMenuItem}>
            {t('filters.all')}
          </MenuItem>
          {Object.values(VllChannelType).map((value) => (
            <MenuItem
              key={value}
              value={value}
              data-testid={EPGChannelFiltersTestIds.channelTypeMenuItem}
              data-value={value}
            >
              {value === VllChannelType.VLL ? value : capitalize(value.toLowerCase())}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.filterDropdownContainer}>
        <InputLabel>{t('epg.channels.live_status')}</InputLabel>
        <Select
          className={classes.filterDropdown}
          value={isLive}
          onChange={({ target: { value } }) => setIsLive(value as TrinaryBoolean)}
          data-testid={EPGChannelFiltersTestIds.isLiveSelector}
        >
          <MenuItem value={ALL} data-testid={EPGChannelFiltersTestIds.isLiveMenuItem} data-value={ALL}>
            {t('filters.all')}
          </MenuItem>
          <MenuItem value={TRUE} data-testid={EPGChannelFiltersTestIds.isLiveMenuItem} data-value={TRUE}>
            {t('epg.channels.live')}
          </MenuItem>
          <MenuItem value={FALSE} data-testid={EPGChannelFiltersTestIds.isLiveMenuItem} data-value={FALSE}>
            {t('epg.channels.not_live')}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.filterDropdownContainer}>
        <InputLabel>{t('epg.channels.premium_status')}</InputLabel>
        <Select
          className={classes.filterDropdown}
          value={isPremium}
          onChange={({ target: { value } }) => setIsPremium(value as TrinaryBoolean)}
          data-testid={EPGChannelFiltersTestIds.isPremiumSelector}
        >
          <MenuItem value={ALL} data-testid={EPGChannelFiltersTestIds.isPremiumMenuItem} data-value={ALL}>
            {t('filters.all')}
          </MenuItem>
          <MenuItem value={TRUE} data-testid={EPGChannelFiltersTestIds.isPremiumMenuItem} data-value={TRUE}>
            {t('epg.channels.options.premium')}
          </MenuItem>
          <MenuItem value={FALSE} data-testid={EPGChannelFiltersTestIds.isPremiumMenuItem} data-value={FALSE}>
            {t('epg.channels.options.non_premium')}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
