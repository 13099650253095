import React, { useRef, useState } from 'react';
import { Button, MenuItem, ListItemText, Popover, ButtonProps } from '@mui/material';
import { dropDownTestIds } from '../TestsIds';

export interface DropdownButtonProps {
  buttonCaption: string;
  buttonProps?: Partial<ButtonProps>;
  actions: Record<string, string>;
  onSelectAction?: (action: string) => void;
  disableAction?: (action: string) => boolean;
}

const DropdownButton = ({
  buttonCaption,
  buttonProps,
  actions,
  onSelectAction,
  disableAction
}: DropdownButtonProps): JSX.Element => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = (action: string) => {
    if (disableAction?.(action)) return;
    handleClose();
    onSelectAction?.(action);
  };

  return (
    <>
      <Button {...buttonProps} ref={anchorRef} onClick={handleOpen} data-testid={dropDownTestIds.menuButton}>
        {buttonCaption}
      </Button>

      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        data-testid={dropDownTestIds.popover}
      >
        {Object.keys(actions).map((action) => (
          <MenuItem
            key={action}
            onClick={() => handleAction(action)}
            data-testid={dropDownTestIds.menuItem(action)}
            disabled={disableAction?.(action)}
          >
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{actions[action]}</ListItemText>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default DropdownButton;
