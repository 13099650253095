import React, { useEffect, useRef } from 'react';
import { Chip, InputLabel, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { HeroPresetCollectionResponse, PermissionsGroupId } from '../../../API';
import { useData } from '../../../data-layer';
import { useConfirm, useLocales } from '../../../hooks';
import Button from '../../shared/Button';
import DraftBadge from '../../shared/DraftBadge';
import Drawer from '../../shared/Drawer';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import InputController from '../../shared/InputController';
import { makeStyles } from 'tss-react/mui';
import TextField from '../../shared/TextField';
import { AppRoutes } from '../../../Routes';
import { useNavigate } from 'react-router-dom';
import FormControl from '../../shared/FormControl';
import PermissionsGroupSelector from '../../shared/PermissionsGroupSelector';
import { Delete } from '@mui/icons-material';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import Localized from '../../shared/Localized';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { handleSaveDraft, handleSavePublish, isFormValid } from '../../../utils/formHelpers';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { HPCFormTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  select: {
    width: 500
  },
  button: {
    minWidth: 190
  }
}));

export function HPCForm(): React.ReactElement {
  const {
    canDelete: canDeleteGuard,
    canPublish: canPublishGuard,
    canSave: canSaveGuard
  } = usePermissionsGuard({
    homepageOption: HomepageOptions.HPC
  });
  const formRef = useRef<ValidatorForm>(null);
  const {
    heroPresetCollections: {
      state: { withFormMetadata, withIsSaving, withIsPublishing },
      hook: { closeForm, save, saveAndPublish, remove }
    }
  } = useData();

  const { confirm } = useConfirm();
  const { hasPermissions } = usePermissions();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useLocales();
  const formMetadata = useRecoilValue(withFormMetadata);
  const isSaving = useRecoilValue(withIsSaving);
  const isPublishing = useRecoilValue(withIsPublishing);
  const collection = formMetadata.record;
  const { control, getValues, handleSubmit, reset } = useForm<HeroPresetCollectionResponse>({
    defaultValues: collection
  });

  const { UPSERT: canSave, PUBLISH: canPublish, DELETE: canDelete } = hasPermissions(collection?.ownerPermissionsGroup);

  useEffect(() => {
    reset(collection);
  }, [collection]);

  const handleOnClose = () => {
    if (!isSaving && !isPublishing) {
      closeForm();
    }
  };

  const handleOnDelete = async () => {
    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: t('confirm.delete_collection'),
      'data-testid': HPCFormTestIds.confirmDialog
    });

    if (result && collection) {
      const successful = await remove((collection as HeroPresetCollectionResponse).entityId);
      if (successful) {
        closeForm();
        navigate(AppRoutes.heroPresetCollections());
      }
    }
  };

  const onSubmit = async (data: HeroPresetCollectionResponse, shouldPublish = false) => {
    if (!(await isFormValid(formRef))) return;
    const saveData: HeroPresetCollectionResponse = { ...data, editorNotes: data.editorNotes || '' };
    const savedCollection = await (shouldPublish ? saveAndPublish(saveData) : save(saveData));
    if (!savedCollection) return;
    if (formMetadata.isNew) {
      navigate(AppRoutes.heroPresetCollections(savedCollection.entityId));
    }
    closeForm();
  };

  const headerLeft = (
    <Stack spacing={4} direction="row" alignItems="center">
      <Typography variant="h6" data-testid={HPCFormTestIds.name}>
        {t(`collections.${formMetadata.isNew ? 'new_collection' : 'edit_collection_metadata'}`)}
      </Typography>
      {(collection as HeroPresetCollectionResponse)?.status && !formMetadata.isNew && (
        <DraftBadge data-testid={HPCFormTestIds.draftBadge} />
      )}
      {formMetadata.isCloning && formMetadata.cloningRecord?.name && (
        <Chip
          size="small"
          color="secondary"
          label={
            <>
              {t('general.clone_of')} <Localized prop={formMetadata.cloningRecord?.name} />
            </>
          }
          data-testid={HPCFormTestIds.cloneBadge}
        />
      )}
    </Stack>
  );

  const headerRight = (
    <Button
      color="error"
      disabled={formMetadata.isNew || !canDelete || !canDeleteGuard}
      onClick={handleOnDelete}
      endIcon={<Delete />}
      data-testid={HPCFormTestIds.deleteButton}
    >
      {t('general.delete')}
    </Button>
  );

  const footerLeft = (
    <Stack spacing={4} direction="row">
      <Button
        className={classes.button}
        color="secondary"
        loading={isSaving}
        onClick={handleSaveDraft(handleSubmit, onSubmit)}
        disabled={!canSave || !canSaveGuard}
        data-testid={HPCFormTestIds.saveButton}
      >
        {t('general.saveAsDraft')}
      </Button>
      <Button
        className={classes.button}
        color="primary"
        loading={isPublishing}
        onClick={handleSavePublish(handleSubmit, onSubmit)}
        disabled={!canPublish || !canPublishGuard || !formMetadata.record?.presets.length}
        data-testid={HPCFormTestIds.publishButton}
      >
        {t('general.saveAndPublish')}
      </Button>
    </Stack>
  );

  const footerRight = (
    <Button
      color="grey"
      disabled={isSaving || isPublishing}
      onClick={handleOnClose}
      data-testid={HPCFormTestIds.cancelButton}
    >
      {t('general.cancel')}
    </Button>
  );

  return (
    <Drawer
      data-testid={HPCFormTestIds.root}
      open={formMetadata.isShowingForm}
      onClose={handleOnClose}
      headerLeft={headerLeft}
      headerRight={headerRight}
      footerLeft={footerLeft}
      footerRight={footerRight}
      formRef={formRef}
    >
      {collection && (
        <div className={classes.container}>
          <LocalizedInputCollection
            reset={reset}
            getValues={getValues}
            fields={[
              {
                component: (
                  <InputController
                    name="name"
                    control={control}
                    rules={{ required: t('general.field_is_required') }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        value={value}
                        onChange={onChange}
                        label={t('general.title')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )
              }
            ]}
          />
          <FormControl>
            <Controller
              name="editorNotes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  multiline
                  name="editorNotes"
                  label={t('general.editor_notes')}
                  value={value}
                  onChange={onChange}
                  data-testid={HPCFormTestIds.editorNotes}
                />
              )}
            />
          </FormControl>
          <Controller
            name="ownerPermissionsGroup"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl required className={classes.select} error={!!error}>
                <InputLabel>{t('permissions.permissions_group')}</InputLabel>
                <PermissionsGroupSelector
                  value={value}
                  onChange={(groupId) => onChange(groupId as PermissionsGroupId)}
                  required
                  error={!!error}
                />
              </FormControl>
            )}
          />
        </div>
      )}
    </Drawer>
  );
}
