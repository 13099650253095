import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import TextField from '../../shared/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocales, useTheme } from '../../../hooks';
import { TournamentResponse } from '../../../API';
import { tournamentsMultiSelectTestIds } from '../../shared/TestsIds';

export interface ITournamentsMultiSelectProps {
  className?: string;
  label: string;
  value: TournamentResponse[];
  Options: TournamentResponse[];
  onChange: (changedValues: TournamentResponse[]) => void;
  required: false;
}

const useStyles = makeStyles()((theme) => ({
  optionCheckbox: {
    marginRight: theme.spacing(2)
  }
}));

export function TournamentsMultiSelect({
  className,
  label,
  value,
  onChange,
  Options,
  required
}: ITournamentsMultiSelectProps): React.ReactElement {
  const { classes } = useStyles();
  const { t, localize } = useLocales();
  const [tournaments, setTournaments] = useState<TournamentResponse[]>(Options ? [...Options] : []);
  const { formControlColor } = useTheme();

  useEffect(() => {
    setTournaments(Options ? [...Options] : []);
  }, [value]);

  function handleSelectedSports(event: TournamentResponse[]) {
    if (onChange) {
      onChange(event);
    }
  }

  return (
    <>
      {tournaments && (
        <Autocomplete
          multiple={true}
          options={tournaments}
          getOptionLabel={(option) => localize(option?.name)}
          disableClearable
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <li {...props} data-testid={tournamentsMultiSelectTestIds.option} data-option={option.id}>
              <Checkbox color={formControlColor} className={classes.optionCheckbox} checked={selected} />
              {localize(option.name)}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={required && !value.length}
              required={required}
              helperText={required && !value.length && t('general.field_is_required')}
              label={label}
            />
          )}
          value={value}
          onChange={(_, changedValues) => handleSelectedSports(changedValues as TournamentResponse[])}
          className={className}
          data-testid={tournamentsMultiSelectTestIds.autocomplete}
        />
      )}
    </>
  );
}
