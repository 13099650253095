import React, { useEffect, useRef, useState, useMemo } from 'react';
import { InputAdornment, InputBaseComponentProps, InputProps } from '@mui/material';
import { debounce } from 'lodash-es';
import MaterialTextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { Close } from '@mui/icons-material';
import IconButton from '../IconButton';
import { useLocales, useTheme } from '../../../hooks';
import { FieldError } from 'react-hook-form';
import { textFieldTestIds } from '../TestsIds';

export interface ITextFieldProps extends Omit<StandardTextFieldProps, 'variant'> {
  clearable?: boolean;
  debounced?: boolean;
  debounceDelay?: number;
  className?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  onClear?: () => void;
  'data-testid'?: string;
  fieldError?: FieldError;
  isNumber?: boolean;
  min?: number;
  max?: number;
}

export function TextField({
  className = '',
  clearable,
  debounced = false,
  debounceDelay = 300,
  onClear,
  variant = 'standard',
  value,
  onChange,
  InputProps,
  fieldError,
  isNumber = false,
  min,
  max,
  ...props
}: ITextFieldProps): React.ReactElement {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const textFieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const [localValue, setLocalValue] = useState<string | number>((value as string | number) || '');

  useEffect(() => {
    setLocalValue((value as string | number) || '');
  }, [value]);

  useEffect(() => {
    if (!textFieldRef.current) return;
    inputRef.current = textFieldRef.current.querySelector('input') as HTMLInputElement;
  }, [textFieldRef]);

  const onClearClicked = () => {
    setLocalValue('');
    fireOnChangeWithText('');
    onClear?.();
  };

  const fireOnChangeWithText = (inputValue: string | number) => {
    onChange?.({ target: { value: inputValue } } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleChangeDebounced = useMemo(() => debounce(fireOnChangeWithText, debounceDelay), [debounceDelay]);

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let enteredText: string | number = evt.target.value;
    if (isNumber) {
      let parsedValue = parseFloat(enteredText);
      if (isNaN(parsedValue)) {
        enteredText = localValue;
      } else {
        if (max !== undefined && parsedValue > max) parsedValue = max;
        if (min !== undefined && parsedValue < min) parsedValue = min;
        enteredText = parsedValue;
      }
    }
    setLocalValue(enteredText);
    if (debounced) {
      handleChangeDebounced(enteredText);
    } else {
      fireOnChangeWithText(enteredText);
    }
  };

  const inputProps: Partial<InputProps> = {
    ...InputProps,
    onChange: onInputChange
  };
  if (clearable && !!localValue) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton
          data-testid={textFieldTestIds.closeButton}
          onClick={onClearClicked}
          title={t('tooltips.clear')}
          size="small"
        >
          <Close fontSize="small" />
        </IconButton>
      </InputAdornment>
    );
  }
  if (isNumber) {
    const htmlInputProps: InputBaseComponentProps = {
      type: 'number'
    };
    if (min !== undefined) {
      htmlInputProps.min = min;
    }
    if (max !== undefined) {
      htmlInputProps.max = max;
    }
    inputProps.inputProps = htmlInputProps;
  }

  return (
    <MaterialTextField
      value={localValue}
      variant={variant}
      ref={textFieldRef}
      className={className}
      color={formControlColor}
      data-testid={textFieldTestIds.textField}
      InputProps={inputProps}
      error={!!fieldError}
      helperText={fieldError?.message}
      {...props}
    />
  );
}
