import React from 'react';
import { Chip, Typography } from '@mui/material';
import { LiveNewsUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { ChannelsPreview } from '../../ContentPreview/ChannelsPreview';
import { getChannelsTreatmentIcon } from '../../../../../utils/uiModuleUtils';
import { channelsListItemTestIds } from '../../../../shared/TestsIds';

export type ChannelsListItemProps = IBaseUIModuleListItemProps<LiveNewsUiModuleBody>;

const ChannelsListItem = ({ uiModule, ...props }: ChannelsListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')}:
          </Typography>
          <Chip
            icon={getChannelsTreatmentIcon(uiModule.contentTreatment)}
            label={toTitleCase(uiModule.contentTreatment)}
            size="small"
            data-testid={channelsListItemTestIds.treatment}
          />
        </>
      ]}
      renderPreview={(showPreview) => (
        <div data-testid={channelsListItemTestIds.preview}>
          <ChannelsPreview channelsContents={uiModule.liveNewsContents} showing={showPreview} />
        </div>
      )}
      {...props}
    />
  );
};

export default ChannelsListItem;
