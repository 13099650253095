import React, { useEffect, useState } from 'react';
import { ContentPreview } from '..';
import { TournamentResponse } from '../../../../../API';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { LEAGUE_ID_PARAM } from '../../../../../utils/consts/uiModules';
import { withPreviewLeagueId } from '../../../../../state/Layouts';
import { useData } from '../../../../../data-layer';
import { tournamentSplashHeroPreviewTestIds } from '../../../../shared/TestsIds';

export interface TournamentSplashHeroPreviewProps {
  tournamentId: string;
  showing: boolean;
}

const useStyles = makeStyles()((theme) => ({
  landscapeThumbnail: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%'
  },
  landscapeBackgroundImg: {
    width: '100%'
  },
  landscapeLogoImgContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  landscapeLogoImg: {
    width: '25%'
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7), transparent)'
  }
}));

type TournamentSplashHeroPreviewModel = {
  contentId: string;
  backgroundImage?: string;
  logoImage?: string;
};

export function TournamentSplashHeroPreview({
  tournamentId,
  showing
}: TournamentSplashHeroPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const {
    tournaments: {
      state: { withAllRecords: withAllTournaments },
      hook: { getAll: getAllTournaments }
    }
  } = useData();

  const leagues = useRecoilValue(withAllTournaments);
  const previewLeagueId = useRecoilValue(withPreviewLeagueId);
  const [previewItem, setPreviewItem] = useState<TournamentSplashHeroPreviewModel>();

  const actualTournamentId = tournamentId === LEAGUE_ID_PARAM ? previewLeagueId : tournamentId;

  useEffect(() => {
    if (showing && !leagues) getAllTournaments();
  }, [showing]);

  useEffect(() => {
    if (leagues) populatePreviewItem();
  }, [leagues, actualTournamentId]);

  const getBackgroundImage = (tournament?: TournamentResponse) => {
    if (tournament?.splashBackgroundImage) {
      return getAssetUrl(tournament?.splashBackgroundImage);
    }
    return '/vix-landscape-fallback.jpeg';
  };

  const getLogoImage = (tournament?: TournamentResponse) => {
    if (tournament?.logoImage) {
      return getAssetUrl(tournament?.logoImage);
    }
  };

  const populatePreviewItem = () => {
    if (!actualTournamentId) return;
    const tournament = leagues?.find((league) => league.tournamentId === actualTournamentId);
    setPreviewItem({
      contentId: tournamentId,
      backgroundImage: getBackgroundImage(tournament),
      logoImage: getLogoImage(tournament)
    });
  };

  const TournamentSplashHeroThumbnail = (contentItem: TournamentSplashHeroPreviewModel) => {
    return (
      <div className={classes.landscapeThumbnail} data-testid={tournamentSplashHeroPreviewTestIds.heroContentItem}>
        <div className={classes.mask} />
        {contentItem.backgroundImage && (
          <img className={classes.landscapeBackgroundImg} src={contentItem.backgroundImage} />
        )}
        <div className={classes.landscapeLogoImgContainer}>
          {contentItem.logoImage && <img className={classes.landscapeLogoImg} src={contentItem.logoImage} />}
        </div>
      </div>
    );
  };

  return (
    <ContentPreview
      showing={showing}
      displayAs="heroLandscape"
      model={{
        contentList: [previewItem].filter(Boolean),
        thumbnailComponent: TournamentSplashHeroThumbnail,
        idField: 'contentId'
      }}
      loaderCount={1}
    />
  );
}
