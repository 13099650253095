/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum Permission {
    ADMIN = 'ADMIN',
    AVOD_ADMIN = 'AVOD_ADMIN',
    AVOD_READ = 'AVOD_READ',
    AVOD_UPSERT = 'AVOD_UPSERT',
    AVOD_PUBLISH = 'AVOD_PUBLISH',
    AVOD_DELETE = 'AVOD_DELETE',
    SVOD_ADMIN = 'SVOD_ADMIN',
    SVOD_READ = 'SVOD_READ',
    SVOD_UPSERT = 'SVOD_UPSERT',
    SVOD_PUBLISH = 'SVOD_PUBLISH',
    SVOD_DELETE = 'SVOD_DELETE',
    SPORTS_ADMIN = 'SPORTS_ADMIN',
    SPORTS_READ = 'SPORTS_READ',
    SPORTS_UPSERT = 'SPORTS_UPSERT',
    SPORTS_PUBLISH = 'SPORTS_PUBLISH',
    SPORTS_DELETE = 'SPORTS_DELETE',
    NEWS_ADMIN = 'NEWS_ADMIN',
    NEWS_READ = 'NEWS_READ',
    NEWS_UPSERT = 'NEWS_UPSERT',
    NEWS_PUBLISH = 'NEWS_PUBLISH',
    NEWS_DELETE = 'NEWS_DELETE',
    MARKETING_ADMIN = 'MARKETING_ADMIN',
    MARKETING_READ = 'MARKETING_READ',
    MARKETING_UPSERT = 'MARKETING_UPSERT',
    MARKETING_PUBLISH = 'MARKETING_PUBLISH',
    MARKETING_DELETE = 'MARKETING_DELETE',
    MONETIZATION_ADMIN = 'MONETIZATION_ADMIN',
    MONETIZATION_READ = 'MONETIZATION_READ',
    MONETIZATION_UPSERT = 'MONETIZATION_UPSERT',
    MONETIZATION_PUBLISH = 'MONETIZATION_PUBLISH',
    MONETIZATION_DELETE = 'MONETIZATION_DELETE',
    CLIP_ADMIN = 'CLIP_ADMIN',
    CLIP_READ = 'CLIP_READ',
    CLIP_UPSERT = 'CLIP_UPSERT',
    CLIP_PUBLISH = 'CLIP_PUBLISH',
    CLIP_DELETE = 'CLIP_DELETE'
}



