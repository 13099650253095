import { EventAvailable, EventBusy, EventNote } from '@mui/icons-material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PromotionResponse } from '../../../../API';
import { useLocales } from '../../../../hooks';
import { getPromotionStatus } from '../../../../utils/paymentsHelpers';
import { PromotionStatusEnum } from '../../../../utils/types/paymentsTypes';
import { promoStatusTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  }
}));

export interface IPromotionStatusProps {
  promotion: PromotionResponse;
}

export default function PromotionStatus({ promotion }: IPromotionStatusProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  const status = getPromotionStatus(promotion);

  return (
    <div className={classes.root} data-testid={promoStatusTestIds.componentRoot}>
      {status === PromotionStatusEnum.DRAFT && (
        <>
          <EventNote fontSize="small" color="info" />
          <span data-testid={promoStatusTestIds.draftStatus}>{t('payments.promo_status.draft')}</span>
        </>
      )}
      {status === PromotionStatusEnum.ACTIVE && (
        <>
          <EventAvailable fontSize="small" color="success" />
          <span data-testid={promoStatusTestIds.activeStatus}>{t('payments.promo_status.active')}</span>
        </>
      )}
      {status === PromotionStatusEnum.ENDED && (
        <>
          <EventBusy fontSize="small" color="error" />
          <span data-testid={promoStatusTestIds.endedStatus}>{t('payments.promo_status.ended')}</span>
        </>
      )}
    </div>
  );
}
