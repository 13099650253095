import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
import { ScheduleTime } from '../../../../API';
import { useLocales } from '../../../../hooks';
import { getActiveEndTime, isInScheduledTime } from '../scheduleTimeHelpers';
import { scheduleTimePickerTestIds } from '../../TestsIds';

const useStyles = makeStyles()((theme) => ({
  scheduleTextPreview: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}));

export function ScheduleTimePreviewUntilTime({ value }: { value?: ScheduleTime[] }): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <div className={classes.scheduleTextPreview} data-testid={scheduleTimePickerTestIds.root}>
      <Typography variant="caption">
        {t('scheduled_time.label')}:{' '}
        {isInScheduledTime(value)
          ? t('scheduled_time.active_until_time', { time: getActiveEndTime(value) })
          : t('scheduled_time.no_active_schedule')}
      </Typography>
    </div>
  );
}
