import React, { useEffect, useState } from 'react';
import { Chip, Tab, Tabs } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useLayouts, useLocales, useTheme } from '../../../hooks';
import {
  DRAFT_LAYOUTS,
  LAYOUT_VIEW_TYPE,
  withAllLayouts,
  withLayoutsType,
  withLayoutsView
} from '../../../state/Layouts';
import LayoutsTimeline from '../LayoutsTimeline';
import LayoutsDrafts from '../LayoutsDrafts';
import { layoutsViewTabsTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  draftTab: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  timelinePanel: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

export function LayoutsViewTabs({ className }: { className?: string }): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { onViewTypeChange } = useLayouts();
  const { formControlColor } = useTheme();
  const allLayouts = useRecoilValue(withAllLayouts);
  const layoutsType = useRecoilValue(withLayoutsType);
  const whichView = useRecoilValue(withLayoutsView);
  const [fallbackView, setFallbackView] = useState<LAYOUT_VIEW_TYPE>(whichView || LAYOUT_VIEW_TYPE.VIEW_SCHEDULE);

  // Persist the last used view tab as a fallback to prevent console errors
  // during the intermediate loading state when switching page paths.
  useEffect(() => {
    if (whichView) {
      setFallbackView(whichView);
    }
  }, [whichView]);

  const draftsCount =
    allLayouts &&
    layoutsType &&
    allLayouts[DRAFT_LAYOUTS].reduce((m, layout) => m + (layout.layoutType === layoutsType ? 1 : 0), 0);

  return (
    <>
      <Tabs
        className={className}
        value={whichView || fallbackView}
        indicatorColor={formControlColor}
        textColor={formControlColor}
        variant="fullWidth"
        onChange={(_, viewType) => onViewTypeChange(viewType)}
      >
        <Tab
          disabled={!allLayouts}
          label={t('layouts.schedule')}
          value={LAYOUT_VIEW_TYPE.VIEW_SCHEDULE}
          data-testid={layoutsViewTabsTestIds.scheduleTab}
        />
        <Tab
          disabled={!allLayouts || !allLayouts[DRAFT_LAYOUTS].length}
          value={LAYOUT_VIEW_TYPE.VIEW_DRAFTS}
          label={
            <div className={classes.draftTab}>
              {t('general.drafts')}{' '}
              {!!draftsCount && (
                <Chip
                  label={draftsCount}
                  color="secondary"
                  size="small"
                  data-testid={layoutsViewTabsTestIds.draftsCounter}
                />
              )}
            </div>
          }
          data-testid={layoutsViewTabsTestIds.draftsTab}
        />
      </Tabs>

      <div className={classes.timelinePanel}>
        {whichView === LAYOUT_VIEW_TYPE.VIEW_SCHEDULE && <LayoutsTimeline />}
        {whichView === LAYOUT_VIEW_TYPE.VIEW_DRAFTS && <LayoutsDrafts />}
      </div>
    </>
  );
}
