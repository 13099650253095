import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PublishedWithChanges, SyncDisabled } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useLocales, useTimezones } from '../../../../../hooks';
import { AutoRotateBody, ContentCollectionResponse } from '../../../../../API';
import {
  autoRotateGetCurrentIndexHuman,
  autoRotateGetNextRotation,
  defaultAutoRotateParams
} from '../../../../../utils/autoRotate';
import { DateTime } from 'luxon';
import { autoRotateBadgeTestIds } from '../../../../shared/TestsIds';

export interface IAutoRotateBadgeProps {
  collection: ContentCollectionResponse;
  onCollectionChange?: (collection: ContentCollectionResponse) => void;
}

const useStyles = makeStyles()((theme) => ({
  badge: {
    display: 'flex'
  },
  header: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold'
  }
}));

export function AutoRotateBadge({ collection, onCollectionChange }: IAutoRotateBadgeProps): React.ReactElement {
  const { t } = useLocales();
  const { classes } = useStyles();
  const { timestamp } = useTimezones();
  const [autoRotateParams, setAutoRotateParams] = useState<AutoRotateBody>();

  const nextRotationTime = autoRotateGetNextRotation(collection);

  const toggleAutoRotate = () => {
    if (!onCollectionChange) return;
    if (collection.autoRotate) {
      setAutoRotateParams(collection.autoRotate);
      onCollectionChange({
        ...collection,
        autoRotate: undefined
      });
    } else {
      onCollectionChange({
        ...collection,
        autoRotate: autoRotateParams
          ? {
              ...autoRotateParams,
              startDate: DateTime.now().toISO() as string
            }
          : defaultAutoRotateParams()
      });
    }
  };

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <>
          {collection.autoRotate && (
            <>
              <Typography className={classes.header} variant="body2">
                {t('collections.auto_rotate.enabled')}
              </Typography>
              <div>{t('collections.auto_rotate.shifts', { count: collection.autoRotate.numberOfItems })}</div>
              <div>{t('collections.auto_rotate.days', { count: collection.autoRotate.numberOfRotationDays })}</div>
              <div>
                {t('collections.auto_rotate.current_position')} {autoRotateGetCurrentIndexHuman(collection)}
              </div>
              <div>
                {t('collections.auto_rotate.next_rotation')} {nextRotationTime && timestamp(nextRotationTime)}
              </div>
            </>
          )}
          {!collection.autoRotate && (
            <div>
              <Typography className={classes.header} variant="body2">
                {t('collections.auto_rotate.disabled')}
              </Typography>
              <div>{t('collections.auto_rotate.disabled_help_text')}</div>
            </div>
          )}
        </>
      }
    >
      <div className={classes.badge} onClick={toggleAutoRotate}>
        {collection.autoRotate && <PublishedWithChanges data-testid={autoRotateBadgeTestIds.enabledBadge} />}
        {!collection.autoRotate && <SyncDisabled color="disabled" data-testid={autoRotateBadgeTestIds.disabledBadge} />}
      </div>
    </Tooltip>
  );
}
