import React from 'react';
import { useRecoilValue } from 'recoil';
import { DRAFT_LAYOUTS, withAllLayouts, withLayoutsType } from '../../../state/Layouts';
import LayoutListItem from '../LayoutListItem';
import ShadowScroller from '../../shared/ShadowScroller';
import { layoutDraftsTestIds } from '../../shared/TestsIds';

function LayoutsDrafts(): JSX.Element {
  const allLayouts = useRecoilValue(withAllLayouts);
  const layoutsType = useRecoilValue(withLayoutsType);
  const filteredLayouts =
    allLayouts && layoutsType && allLayouts[DRAFT_LAYOUTS].filter((layout) => layout.layoutType === layoutsType);

  return (
    <ShadowScroller paper={true} loading={!allLayouts} data-testid={layoutDraftsTestIds.componentRoot}>
      {filteredLayouts &&
        filteredLayouts.map((layout, index) => {
          return <LayoutListItem key={layout.id} layout={layout} index={index} which={DRAFT_LAYOUTS} />;
        })}
    </ShadowScroller>
  );
}

export default LayoutsDrafts;
