import { Typography } from '@mui/material';
import React from 'react';

export interface IEditorNotesProps {
  object?: WithEditorNotes;
  'data-testid'?: string;
}

type WithEditorNotes = {
  editorNotes?: string;
};

export function EditorNotes({ object, ...props }: IEditorNotesProps): React.ReactElement {
  return (
    <>
      {!!object?.editorNotes && (
        <Typography component="span" variant="body2" color="textSecondary" data-testid={props['data-testid']}>
          “{object.editorNotes}”
        </Typography>
      )}
    </>
  );
}
