import Button from '../../../../shared/Button';
import { Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSetRecoilState } from 'recoil';
import { useData } from '../../../../../data-layer';
import { addAllToCuratedTestIds } from '../../../../shared/TestsIds';

export default function AddAllToCuratedButton({
  filteredMediaList,
  filteredQueryMediaList,
  hasUpsertPermission
}: {
  filteredMediaList: string[];
  filteredQueryMediaList: string[];
  hasUpsertPermission: boolean;
}): React.ReactElement {
  const {
    collections: {
      state: { withSelectedCollectionContent }
    }
  } = useData();
  const setSelectedCollectionContents = useSetRecoilState(withSelectedCollectionContent);
  const [isDisabled, setIsDisabled] = React.useState(false);

  useEffect(() => {
    const somethingToAdd = filteredQueryMediaList.filter((mediaId) => !filteredMediaList.includes(mediaId)).length > 0;
    setIsDisabled(!somethingToAdd || !hasUpsertPermission || !filteredQueryMediaList.length);
  }, [filteredMediaList, filteredQueryMediaList]);

  const onAdd = () => {
    setSelectedCollectionContents((contents) => {
      const smartQueryFilteredMedias = filteredQueryMediaList.filter((mediaId) => !contents?.includes(mediaId));
      return contents ? [...contents, ...smartQueryFilteredMedias] : [...smartQueryFilteredMedias];
    });
  };

  return (
    <Tooltip title={t('general.add_all_curated_tooltip')}>
      <Button
        style={{ flexGrow: 1 }}
        color="primary"
        size="small"
        disabled={isDisabled}
        data-testid={addAllToCuratedTestIds.addAllButton}
        onClick={onAdd}
      >
        {t('general.add_all_curated')} ⇧
      </Button>
    </Tooltip>
  );
}
