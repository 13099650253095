import { useSetRecoilState } from 'recoil';
import {
  GetAllMediaQuery,
  MediaExportQuery,
  MediaResponse,
  SmartQuerySearchIndex,
  SmartQueryBody,
  OperationIds,
  ContentPatchBody
} from '../../../API';
import { Paginated, useLocales } from '../../../hooks';
import { MediaAPI } from '../../../hooks/API/Media/MediaAPI';
import { useNotificationSubscription } from '../../../hooks/API/NotificationSubscription/useNotificationSubscription';
import {
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  MAX_GET_BY_IDS,
  useGetRecoilState
} from '../../DataManager';
import { CacheManager } from '../../DataManager/CacheManager';
import { DataManagerHelpers } from '../../DataManager/DataManagerHelpers';
import { saveAsFile } from '../../../utils/saveAsFile';
import { chunk, cloneDeep, debounce, flatten } from 'lodash-es';
import { isChannel, isMatch } from '../../../utils/mediaUtilities';
import { useContentServiceAPI } from '../../../hooks/API/ContentService/useContentServiceAPI';
import { LIMIT_OPTIONS } from '../../../components/shared/Pagination';

export interface MediaHookReturnType extends DataManagerHookReturnType<MediaResponse> {
  searchMedia: (params: GetAllMediaQuery) => Promise<Paginated<MediaResponse> | undefined>;
  exportMediaToCSV: (query: MediaExportQuery) => Promise<void>;
  getMediaByCollectionId: (collectionId: string) => Promise<MediaResponse[] | undefined>;
  getMediaBySmartQuery: (query: SmartQueryBody) => Promise<MediaResponse[] | undefined>;
  getContentSubscriptionStatus: (contentId: string) => Promise<boolean | undefined>;
  setContentSubscriptionStatus: (contentId: string, status: boolean) => Promise<boolean>;
  queueIdToFetch: (id: string, includeDeleted?: boolean) => void;
  getChannelsByIds: (ids: string[], includeDeleted?: boolean) => Promise<MediaResponse[] | undefined>;
  getTransmissionsByIds: (ids: string[], includeDeleted?: boolean) => Promise<MediaResponse[] | undefined>;
  patchContent: (contentId: string, body: ContentPatchBody) => Promise<MediaResponse | null | undefined>;
}

export function MediaHook(params: IDataManagerHookProps<MediaResponse>): MediaHookReturnType {
  const dataManagerHook = DataManagerHook<MediaResponse>(params);
  const api = params.useApiHook() as MediaAPI;
  const notificationSubscriptionApi = useNotificationSubscription();
  const contentServiceApi = useContentServiceAPI();

  const setCache = useSetRecoilState(params.state.withDataCache);
  const { addRecordsToCache, addRecordToCache, flattenCache } = CacheManager(params.idField, setCache);

  const { t } = useLocales();
  const { handleApiError, missingActionError } = DataManagerHelpers({ name: params.name, toString });

  const setIsSaving = useSetRecoilState(params.state.withIsSaving);
  const getCache = useGetRecoilState(params.state.withDataCache);

  const transmissionIdsToFetch: Set<string> = new Set();
  const channelIdsToFetch: Set<string> = new Set();

  const queueIdToFetch = (id: string, includeDeleted = false) => {
    if (isMatch(id)) {
      transmissionIdsToFetch.add(id);
      fetchAllTransmissionsFromQueue(includeDeleted);
    } else if (isChannel(id)) {
      channelIdsToFetch.add(id);
      fetchAllChannelsFromQueue(includeDeleted);
    } else {
      dataManagerHook.queueIdToFetch(id, includeDeleted);
    }
  };

  const fetchAllChannelsFromQueue = debounce((includeDeleted: boolean) => {
    const idsCopy = [...channelIdsToFetch];
    channelIdsToFetch.clear();
    fetchChannelsByIds(idsCopy, includeDeleted);
  }, 50);

  const fetchAllTransmissionsFromQueue = debounce((includeDeleted: boolean) => {
    const idsCopy = [...transmissionIdsToFetch];
    transmissionIdsToFetch.clear();
    fetchTransmissionsByIds(idsCopy, includeDeleted);
  }, 50);

  const searchMedia = async (params: GetAllMediaQuery) => {
    const action = 'fetch_paginated';
    try {
      if (!dataManagerHook.fetchPaginatedPost) throw missingActionError(action);
      const { limit: limitParam } = params;
      const [minLimit, maxLimit] = [Math.min(...LIMIT_OPTIONS), Math.max(...LIMIT_OPTIONS)];
      if (!limitParam || limitParam > maxLimit) {
        params.limit = maxLimit;
      } else if (limitParam < minLimit) {
        params.limit = minLimit;
      }
      return await dataManagerHook.fetchPaginatedPost(params);
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_paginated', JSON.stringify(params));
    }
  };

  const exportMediaToCSV = async (query: MediaExportQuery) => {
    try {
      const response = await api.exportCsv(query);
      saveAsFile(response);
    } catch (err) {
      console.error(err);
      handleApiError(err, 'export_media_to_csv', `${t('query_builder.query')}: ${query}`);
    }
  };

  const getMediaByCollectionId = async (collectionId: string) => {
    try {
      const response = await api.getByCollectionId(collectionId);
      addRecordsToCache(response.data.body);
      return response.data.body;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_media_by_collection_id', collectionId);
    }
  };

  const getMediaBySmartQuery = async (query: SmartQueryBody) => {
    try {
      const fetchedMedia = query
        ? (await api.getBySmartQuery({ ...query, searchIndex: SmartQuerySearchIndex.CONTENT })).data.body
        : [];
      addRecordsToCache(fetchedMedia);
      return fetchedMedia;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_by_smart_query', `${t('query_builder.query')}: ${query}`);
    }
  };

  const getContentSubscriptionStatus = async (contentId: string) => {
    try {
      const { data } = await notificationSubscriptionApi.getById(contentId);
      return data.body.isEnabled;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'get_content_subscription_status', contentId);
    }
  };

  const setContentSubscriptionStatus = async (contentId: string, isEnabled: boolean) => {
    try {
      if (isEnabled) {
        await notificationSubscriptionApi.create(contentId);
      } else {
        await notificationSubscriptionApi.remove(contentId);
      }
      return true;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'set_content_subscription_status', contentId);
      return false;
    }
  };

  const getChannelsByIds = async (ids: string[], includeDeleted = false) => {
    if (!ids.length) return;
    const cachedRecords = await dataManagerHook.getCachedRecordsToFetch(ids);
    if (cachedRecords.length > 0) {
      return flattenCache(cachedRecords);
    }
    const fetchedRecords = await fetchChannelsByIds(ids, includeDeleted);
    if (!fetchedRecords) {
      return flattenCache(cachedRecords);
    }
    return ids.map((id, i) => cachedRecords[i]?.object ?? fetchedRecords[id]?.object);
  };

  const getTransmissionsByIds = async (ids: string[], includeDeleted = false) => {
    if (!ids.length) return;
    const cachedRecords = await dataManagerHook.getCachedRecordsToFetch(ids);
    if (cachedRecords.length > 0) {
      return flattenCache(cachedRecords);
    }
    const fetchedRecords = await fetchTransmissionsByIds(ids, includeDeleted);
    if (!fetchedRecords) {
      return flattenCache(cachedRecords);
    }
    return ids.map((id, i) => cachedRecords[i]?.object ?? fetchedRecords[id]?.object);
  };

  const fetchIdsByAction = async (
    ids: string[],
    includeDeleted = false,
    action: OperationIds.GET_CHANNELS_BY_IDS | OperationIds.GET_TRANSMISSIONS_BY_IDS
  ) => {
    try {
      if (!api[action]) {
        throw missingActionError(action);
      }
      const chunkedIds = chunk(ids, MAX_GET_BY_IDS);
      const promises = chunkedIds.map((cIds) => api[action](cIds, includeDeleted));
      const responses = await Promise.all(promises);
      const records = flatten(responses.map((response) => response.data.body));
      return addRecordsToCache(records);
    } catch (err) {
      handleApiError(err, action, ids);
    }
  };

  const fetchChannelsByIds = async (ids: string[], includeDeleted = false) => {
    const action = OperationIds.GET_CHANNELS_BY_IDS;
    return fetchIdsByAction(ids, includeDeleted, action);
  };

  const fetchTransmissionsByIds = async (ids: string[], includeDeleted = false) => {
    const action = OperationIds.GET_TRANSMISSIONS_BY_IDS;
    return fetchIdsByAction(ids, includeDeleted, action);
  };

  const patchContent = async (contentId: string, body: ContentPatchBody) => {
    const action = OperationIds.PATCH;
    const cache = await getCache();
    const cachedRecord = cache[contentId];
    if (!cachedRecord) return;

    try {
      if (!contentServiceApi.patch) throw missingActionError(action);
      setIsSaving(true);
      const content = await contentServiceApi.patch(contentId, body);
      const clonedRecord: MediaResponse = cloneDeep(cachedRecord.object);
      clonedRecord.reverseOrder = content.data.body.reverseOrder;
      addRecordToCache(clonedRecord);
      return clonedRecord;
    } catch (err) {
      console.error(err);
      handleApiError(err, 'patch_content', contentId);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    ...dataManagerHook,
    searchMedia,
    exportMediaToCSV,
    getMediaByCollectionId,
    getMediaBySmartQuery,
    getContentSubscriptionStatus,
    setContentSubscriptionStatus,
    queueIdToFetch,
    getChannelsByIds,
    getTransmissionsByIds,
    patchContent
  };
}
