import React from 'react';
import { Autocomplete } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../data-layer';
import { useLocales } from '../../../../hooks';
import TextField from '../../../shared/TextField';
import { FieldError } from 'react-hook-form';
import { markAsRequired } from '../../../../utils/formHelpers';
import { promoGroupPickerTestIds } from '../../../shared/TestsIds';

export interface IPromoGroupPickerProps {
  value: string;
  onChange: (planCode: string) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  fieldError?: FieldError;
}

export default function PromoGroupPicker({
  value,
  onChange,
  className,
  disabled,
  required = false,
  fieldError
}: IPromoGroupPickerProps): React.ReactElement {
  const { t } = useLocales();
  const { plans } = useData();

  const planGroups = useRecoilValue(plans.state.withPlanGroups);

  return (
    <>
      {planGroups && (
        <Autocomplete
          options={planGroups}
          blurOnSelect
          fullWidth
          renderOption={(props, planGroup) => (
            <li data-testid={promoGroupPickerTestIds.option} data-value={planGroup} {...props}>
              {planGroup}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              className={className}
              {...params}
              label={markAsRequired(t('payments.promo_group'), required)}
              fieldError={fieldError}
            />
          )}
          value={value}
          onChange={(_, changedValue) => {
            onChange(changedValue || '');
          }}
          freeSolo
          disabled={disabled}
          data-testid={promoGroupPickerTestIds.autocomplete}
        />
      )}
    </>
  );
}
