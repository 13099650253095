import React, { useState, useMemo, useEffect } from 'react';
import {
  Grid,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Chip,
  Avatar
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import ShadowScroller from '../ShadowScroller';
import MultiSelect from '../MultiSelect';
import { useLocales, useTheme } from '../../../hooks';
import {
  COUNTRIES,
  COUNTRIES_BY_ID,
  COUNTRY_GROUPINGS,
  ICountry,
  sortByCountryName
} from '../../../utils/countryCodes';
import { DocumentLocale } from '../../../API';
import TextField from '../TextField';
import { countryPickerTestIds } from '../TestsIds';

export interface ICountryPickerProps {
  onChange: (countries: string[]) => void;
  value: string[];
  disabledCountries?: string[];
}

const useStyles = makeStyles()((theme) => ({
  countryPicker: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  filterContainer: {
    padding: theme.spacing(4, 4, 1)
  },
  filterTextContainer: {
    paddingRight: theme.spacing(4),
    position: 'relative',
    top: -1
  },
  countryListContainer: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',

    '& > div': {
      height: 'initial'
    }
  },
  checkIcon: {
    minWidth: 40
  },
  countryCode: {
    minWidth: 60
  },
  countriesList: {
    margin: theme.spacing(2, 4)
  },
  noCountries: {
    marginTop: theme.spacing(1)
  },
  countryChip: {
    margin: theme.spacing(1, 1, 0, 0)
  }
}));

export const CountryPicker = ({ value, onChange, disabledCountries }: ICountryPickerProps): JSX.Element => {
  const { classes } = useStyles();
  const { currentLang, t } = useLocales();
  const { formControlColor } = useTheme();
  const [selectedGroupings, setSelectedGroupings] = useState<string[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [countries, setCountries] = useState<string[]>(value ? [...value] : []);

  // Use English name unless currentLang is Spanish (no Portuguese country names atm)
  const langForCountryName = currentLang === DocumentLocale.ES ? DocumentLocale.ES : DocumentLocale.EN;

  useEffect(() => {
    setCountries(value ? [...value] : []);
  }, [value]);

  const handleChangeFilterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const handleClickClearFilterText = () => {
    setFilterText('');
  };

  const handleChangeGroupings = (newGroupings: string[]) => {
    setSelectedGroupings(newGroupings);
  };

  const handleClickCountryItem = (countryCode: string) => {
    let newCountries;
    if (countries.includes(countryCode)) {
      newCountries = countries.filter((country) => country !== countryCode);
    } else {
      newCountries = [...countries, countryCode];
    }
    setCountries(newCountries);
    onChange(newCountries);
  };

  const deleteCountry = (index: number): void => {
    onChange(value.slice(0, index).concat(value.slice(index + 1)));
  };

  const filteredCountries = useMemo<ICountry[]>(() => {
    return COUNTRIES.filter((item: ICountry): boolean => {
      if (
        selectedGroupings &&
        selectedGroupings.length > 0 &&
        !selectedGroupings.includes(item.grouping[langForCountryName] as string)
      ) {
        return false;
      }
      if (filterText) {
        const regex = new RegExp(filterText, 'i');
        if (!regex.test(String(item.name[langForCountryName])) && !regex.test(String(item.code))) {
          return false;
        }
      }
      return true;
    }).sort(sortByCountryName(langForCountryName));
  }, [langForCountryName, filterText, selectedGroupings]);

  const countryGroupings = Object.keys(COUNTRY_GROUPINGS).map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key) => (COUNTRY_GROUPINGS as any)[key][langForCountryName]
  );

  return (
    <div className={classes.countryPicker}>
      <div className={classes.countriesList}>
        <Typography color="textSecondary" variant="body2">
          {t('region_countries')}
        </Typography>
        {countries &&
          countries.map((country: string, index: number) => (
            <Chip
              size="small"
              avatar={<Avatar src={`/assets/flags/${country.toLowerCase()}.png`} />}
              className={classes.countryChip}
              key={country}
              label={COUNTRIES_BY_ID[country].name[langForCountryName]}
              onDelete={() => deleteCountry(index)}
              data-testid={countryPickerTestIds.countryChip}
            />
          ))}
        {!countries ||
          (!countries.length && (
            <Typography className={classes.noCountries} variant="body1">
              {t('general.none')}
            </Typography>
          ))}
      </div>
      <Grid container className={classes.filterContainer}>
        <Grid item xs={12} md={6}>
          <div className={classes.filterTextContainer}>
            <TextField
              label={t('country_picker.filter_countries')}
              value={filterText}
              clearable={true}
              onClear={handleClickClearFilterText}
              onChange={handleChangeFilterText}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              data-testid={countryPickerTestIds.textFilter}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <MultiSelect
            label={t('regions')}
            options={countryGroupings}
            value={selectedGroupings}
            onChange={handleChangeGroupings}
          />
        </Grid>
      </Grid>
      <div className={classes.countryListContainer}>
        <ShadowScroller>
          <List>
            {filteredCountries.map((item) => (
              <ListItem
                key={item.code}
                dense
                button
                disabled={disabledCountries?.includes(item.code)}
                onClick={() => handleClickCountryItem(item.code)}
                data-testid={countryPickerTestIds.listItem}
              >
                <ListItemIcon className={classes.checkIcon}>
                  <Checkbox
                    color={formControlColor}
                    edge="start"
                    checked={countries.includes(item.code)}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Grid container spacing={3}>
                      <Grid item className={classes.countryCode}>
                        {item.code}
                      </Grid>
                      <Grid item>{item.name[langForCountryName]}</Grid>
                    </Grid>
                  }
                />
              </ListItem>
            ))}
          </List>
        </ShadowScroller>
      </div>
    </div>
  );
};
