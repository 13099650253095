import React from 'react';
import { Delete } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';
import { useLocales } from '../../../../../hooks';
import IconButton from '../../../../shared/IconButton';
import InputController from '../../../../shared/InputController';
import PlanPicker from '../../../Plans/PlanPicker/PlanPicker';
import CouponPicker from '../../../Coupons/CouponPicker/CouponPicker';
import PromoGroupPicker from '../../PromoGroupPicker/PromoGroupPicker';
import { CouponCodeState, PlanPromoPriceUnit, PromotionResponse } from '../../../../../API';
import { InputLabel, MenuItem, Select } from '@mui/material';
import FormControl from '../../../../shared/FormControl';
import TextField from '../../../../shared/TextField';
import { markAsRequired } from '../../../../../utils/formHelpers';
import { capitalize } from 'lodash-es';
import TemplateIdPicker from '../../TemplateIdPicker/TemplateIdPicker';
import { useData } from '../../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { promoPlanItemTestIds } from '../../../../shared/TestsIds';

interface IPromoPlanItemProps {
  index: number;
  onDelete: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex'
  },
  rowLeft: {
    flexGrow: 1,
    marginRight: theme.spacing(6)
  },
  rowRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  formRow: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  promoPriceLength: {
    width: 140
  },
  promoPriceUnit: {
    width: 140
  },
  grow: {
    flexGrow: 1
  }
}));

export function PromoPlanItem({ index, onDelete, disabled }: IPromoPlanItemProps): JSX.Element {
  const { t } = useLocales();
  const { classes } = useStyles();
  const { plans } = useData();
  const { control, watch, setValue } = useFormContext<PromotionResponse>();
  const planCode = watch(`plans.${index}.code`);

  const plansById = useRecoilValue(plans.state.withAllRecordsById);

  return (
    <div className={classes.container} data-testid={promoPlanItemTestIds.root}>
      <div className={classes.rowLeft}>
        <div className={classes.formRow}>
          <InputController
            control={control}
            name={`plans.${index}.code`}
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              const onChangeOverride = (planCode: string) => {
                // Clear the coupon codes whenever the user changes the plan
                setValue(`plans.${index}.changes.couponCodes`, []);
                const plan = plansById[planCode];
                if (plan) {
                  if (plan.promoPriceLength) setValue(`plans.${index}.changes.promoPriceLength`, plan.promoPriceLength);
                  if (plan.promoPriceUnit) setValue(`plans.${index}.changes.promoPriceUnit`, plan.promoPriceUnit);
                  if (plan.promoGroup) setValue(`plans.${index}.changes.promoGroup`, plan.promoGroup);
                  if (plan.localizationTemplateId)
                    setValue(`plans.${index}.changes.localizationTemplateId`, plan.localizationTemplateId);
                }
                onChange(planCode);
              };
              return (
                <PlanPicker value={value} onChange={onChangeOverride} disabled={disabled} required fieldError={error} />
              );
            }}
          />
          <InputController
            control={control}
            name={`plans.${index}.changes.couponCodes`}
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CouponPicker
                value={value}
                onChange={onChange}
                disabled={!planCode || disabled}
                allowMultiple
                planCode={planCode}
                required
                fieldError={error}
                filterByState={[CouponCodeState.REDEEMABLE]}
              />
            )}
          />
        </div>
        <div className={classes.formRow}>
          <InputController
            control={control}
            name={`plans.${index}.changes.promoPriceLength`}
            rules={{ required: t('general.field_is_required') }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={onChange}
                className={classes.promoPriceLength}
                label={markAsRequired(t('payments.promo_price_length'))}
                disabled={disabled}
                fieldError={error}
                isNumber
                min={1}
                data-testid={promoPlanItemTestIds.promoPriceLengthInput}
              />
            )}
          />
          <InputController
            control={control}
            name={`plans.${index}.changes.promoPriceUnit`}
            render={({ field: { value, onChange } }) => (
              <FormControl className={classes.promoPriceUnit}>
                <InputLabel>{markAsRequired(t('payments.promo_price_unit'))}</InputLabel>
                <Select
                  label={markAsRequired(t('payments.promo_price_unit'))}
                  value={value}
                  onChange={onChange}
                  data-testid={promoPlanItemTestIds.promoPriceUnitInput}
                  disabled={disabled}
                >
                  {Object.values(PlanPromoPriceUnit).map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {capitalize(unit)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <div className={classes.grow}>
            <InputController
              control={control}
              name={`plans.${index}.changes.promoGroup`}
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <PromoGroupPicker value={value} onChange={onChange} disabled={disabled} required fieldError={error} />
              )}
            />
          </div>
          <div className={classes.grow}>
            <InputController
              control={control}
              name={`plans.${index}.changes.localizationTemplateId`}
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <TemplateIdPicker value={value} onChange={onChange} disabled={disabled} required fieldError={error} />
              )}
            />
          </div>
        </div>
      </div>
      <div className={classes.rowRight}>
        <IconButton
          onClick={onDelete}
          title={t('payments.delete_promo_plan_item')}
          size="large"
          data-testid={promoPlanItemTestIds.deleteItemButton}
          disabled={disabled}
        >
          <Delete />
        </IconButton>
      </div>
    </div>
  );
}
