import React, { useEffect, useRef, useState } from 'react';
import { Box, InputAdornment, List, Skeleton } from '@mui/material';
import { PlaylistRemove, Search, FindInPage } from '@mui/icons-material';
import { useLocales, useTheme } from '../../../../hooks';
import Button from '../../Button';
import MuiButton from '@mui/material/Button';
import ShadowScroller from '../../ShadowScroller';
import { GetAllContentCollectionQuery, PaginationMeta } from '../../../../API';
import ContentCollectionListItem from '../ContentCollectionListItem';
import Pagination from '../../Pagination';
import { useData } from '../../../../data-layer';
import PaginationResults from '../../Pagination/PaginationResults';
import useConfirm from '../../../../hooks/General/useConfirm';
import Repeat from '../../Repeat';
import { Controller, useForm } from 'react-hook-form';
import TextField from '../../TextField';
import { useNavigate } from 'react-router-dom';
import { random } from 'lodash-es';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import NoMediaItems from '../../NoMediaItems';
import { AppRoutes } from '../../../../Routes';
import { makeStyles } from 'tss-react/mui';
import { associatedCollectionsTestIds } from '../../TestsIds';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    margin: theme.spacing(4)
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  searchGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexGrow: 1
  },
  shadowScroller: {
    borderTop: `1px solid ${theme.palette.divider}`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    ['& > div']: {
      flexGrow: 1
    }
  },
  footer: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
  }
}));

function SkeletonCollectionListItem(): JSX.Element {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: 4 }}>
      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', flexGrow: 1 }}>
        <Skeleton animation="wave" height={24} width={random(250, 400)} />
        <Skeleton animation="wave" height={20} width={random(80, 90)} />
      </Box>
      <Box>
        <Skeleton animation="wave" width={75} height={30} />
      </Box>
    </Box>
  );
}

function NoCollectionsFound(): JSX.Element {
  const { t } = useLocales();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <NoMediaItems
        icon={<FindInPage />}
        header={t('content.drawer.no_collections')}
        message={t('content.drawer.no_collections_text')}
      />
    </Box>
  );
}

export interface AssociatedCollectionsProps {
  contentId: string;
}

export default function AssociatedCollections({ contentId }: AssociatedCollectionsProps): JSX.Element {
  const {
    collections: {
      state: { withScrollTo, withMixedCollectionDisplay },
      hook: { getPaginated, removeContentFromCollections }
    },
    media: {
      hook: { closeForm }
    }
  } = useData();

  const { handleSubmit, control, reset, setValue, getValues } = useForm<GetAllContentCollectionQuery>({
    defaultValues: { limit: 20, contentId }
  });

  const { classes } = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { confirm } = useConfirm();
  const navigate = useNavigate();

  const setScrollTo = useSetRecoilState(withScrollTo);
  const [isLoading, setIsLoading] = useState(false);
  const [collectionList, setCollectionList] = useState<string[]>([]);
  const isMixedCollection = useRecoilValue(withMixedCollectionDisplay);

  useEffect(() => {
    reset();
    handleSubmit(handleSearch)();
  }, [contentId]);

  // handles search, used by pagination control to respect current page
  const handleSearch = async (data: GetAllContentCollectionQuery) => {
    setIsLoading(true);
    const response = await getPaginated({ ...data, contentId });
    setIsLoading(false);
    if (!response) {
      setCollectionList([]);
      return;
    }
    setCollectionList(response.results.map((collection) => collection.entityId));
    // call reset passing in the data and meta to reset the default values
    reset({ ...data, ...response.meta });
  };

  // resets the page to 1 when performing a new search
  const handleNewSearch = async (data: GetAllContentCollectionQuery) => handleSearch({ ...data, page: 1 });

  const handleRemoveAll = async () => {
    // redirect to collections to have the history manager catch any unsaved changes
    navigate(isMixedCollection ? AppRoutes.collections : AppRoutes.vodCollections);

    const removeAll = t('content.drawer.remove_all', { n: collectionList.length });

    const result = await confirm({
      confirmText: t('content.drawer.remove'),
      confirmColor: 'error',
      body: removeAll,
      'data-testid': associatedCollectionsTestIds.removeAllConfirm
    });

    if (result && (await removeContentFromCollections(contentId))) {
      setCollectionList([]);
    }
  };

  const handleView = (collectionId: string) => {
    navigate(isMixedCollection ? AppRoutes.collection(collectionId) : AppRoutes.vodCollection(collectionId));
    setScrollTo(contentId);
    closeForm();
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleNewSearch)}
          data-testid={associatedCollectionsTestIds.form}
        >
          <div className={classes.searchGroup}>
            <Controller
              name="search"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  data-testid={associatedCollectionsTestIds.searchField}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={t('content.drawer.search_collections')}
                  fullWidth
                  clearable
                  onClear={() => {
                    setValue('search', '');
                    setValue('page', 1);
                    handleSubmit(handleSearch)();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
            <Button type="submit" data-testid={associatedCollectionsTestIds.submitBtn}>
              {t('general.search')}
            </Button>
          </div>
          <MuiButton
            variant="text"
            color={formControlColor}
            startIcon={<PlaylistRemove />}
            onClick={handleRemoveAll}
            data-testid={associatedCollectionsTestIds.removeAllBtn}
            disabled={isLoading || collectionList.length === 0}
          >
            {t('content.drawer.remove_from_all_collections')}
          </MuiButton>
        </form>
        <PaginationResults list={collectionList} isLoading={isLoading} meta={getValues() as PaginationMeta} />
      </div>

      <div className={classes.shadowScroller}>
        <ShadowScroller ref={scrollRef} loading={isLoading}>
          {isLoading || collectionList.length ? (
            <List disablePadding data-testid={associatedCollectionsTestIds.collectionList}>
              {isLoading ? (
                <Repeat n={20}>
                  <SkeletonCollectionListItem />
                </Repeat>
              ) : (
                collectionList.map((collectionId) => (
                  <ContentCollectionListItem
                    key={collectionId}
                    collectionId={collectionId}
                    onView={handleView}
                    data-testid={associatedCollectionsTestIds.collectionItem}
                  />
                ))
              )}
            </List>
          ) : (
            <NoCollectionsFound />
          )}
        </ShadowScroller>
        <div className={classes.footer}>
          <Pagination
            queryMetaData={getValues() as PaginationMeta}
            onPageChange={(page: number) => {
              reset();
              setValue('page', page);
              handleSubmit(handleSearch)();
              scrollRef.current?.scrollTo(0, 0);
            }}
            onResultsChange={(limit: number) => {
              reset();
              setValue('limit', limit);
              handleSubmit(handleNewSearch)();
            }}
          />
        </div>
      </div>
    </div>
  );
}
