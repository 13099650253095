import React from 'react';
import { AppRegistration, FilterAlt } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ContentCollectionSortingType } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { sortingTypeTestIds } from '../../../../shared/TestsIds';

export interface SortingTypeProps {
  sortingType: string;
}

export const SortingType = ({ sortingType }: SortingTypeProps): JSX.Element => {
  const { t } = useLocales();
  return (
    <Tooltip arrow placement="top" title={t(`collections.sorting.${sortingType.toLowerCase()}`)}>
      {sortingType === ContentCollectionSortingType.CURATED_FIRST ? (
        <AppRegistration data-testid={sortingTypeTestIds.curatedFirst} />
      ) : (
        <FilterAlt data-testid={sortingTypeTestIds.queriedFirst} />
      )}
    </Tooltip>
  );
};
