import React from 'react';
import { CallToAction } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import { HeroUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import Localized from '../../../../shared/Localized';
import { HeroPreview } from '../../ContentPreview/HeroPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { HPCLink } from '../../../../HPC/HPCLink';
import { heroListItemTestIds } from '../../../../shared/TestsIds';

export type HeroListItemProps = IBaseUIModuleListItemProps<HeroUiModuleBody>;

const HeroListItem = ({ uiModule, ...props }: HeroListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')}:
          </Typography>
          <Chip
            icon={<CallToAction />}
            label={toTitleCase(uiModule.contentTreatment)}
            size="small"
            data-testid={heroListItemTestIds.treatment}
          />
        </>
      ]}
      collectionLine={[
        <>
          {uiModule.heroPresetCollection && (
            <>
              <Typography variant="body2" color="textSecondary">
                {t('hpc.hero_preset_collection')}:
              </Typography>
              <HPCLink data-testid={heroListItemTestIds.hpcLink} hpcId={uiModule.heroPresetCollection} />
            </>
          )}
        </>
      ]}
      renderPreview={(showPreview) => (
        <div data-testid={heroListItemTestIds.preview}>
          <HeroPreview
            heroContents={uiModule.heroContents}
            heroPresetCollection={uiModule.heroPresetCollection}
            showing={showPreview}
          />
        </div>
      )}
      {...props}
    />
  );
};

export default HeroListItem;
