import React, { useState } from 'react';
import Modal from '../../../../shared/Modal';
import { LinkingAccountUiModuleBody } from '../../../../../API';
import { useLocales, useLokalise, useTheme } from '../../../../../hooks';
import { Box, Stack, Tab, Typography } from '@mui/material';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { TextButton, VixOrangeButton } from '../shared/SharedButtons';
import Button from '../../../../shared/Button';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Close } from '@mui/icons-material';
import ShadowScroller from '../../../../shared/ShadowScroller';
import { previewDetailsDialog } from '../../../../../styles/previewDetails';
import { linkingAccountPreviewTestIds } from '../../../../shared/TestsIds';

type LinkingAccountPreviewProps = {
  module: LinkingAccountUiModuleBody;
  open: boolean;
  setOpen: (open: boolean) => unknown;
};

enum LinkingAccountTabs {
  RegisteredUsers = 'registered_users',
  LoggedIn = 'logged_in',
  SvodUsers = 'svod_users'
}

export function LinkingAccountPreview({ module, open, setOpen }: LinkingAccountPreviewProps): React.ReactElement {
  const { classes } = previewDetailsDialog({ width: 375 })();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { lt } = useLokalise();

  const [tab, setTab] = useState<LinkingAccountTabs>(LinkingAccountTabs.RegisteredUsers);

  const onClose = () => setOpen(false);
  const handleTabChange = (event: React.SyntheticEvent, newTab: LinkingAccountTabs) => setTab(newTab);

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerLeft={t('layouts.linking_account.preview')}
      footerLeft={
        <Button color="grey" onClick={onClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box data-testid={linkingAccountPreviewTestIds.root} className={classes.root}>
        <TabContext value={tab}>
          <TabList
            indicatorColor={formControlColor}
            textColor={formControlColor}
            onChange={handleTabChange}
            centered
            className={classes.tabs}
          >
            {Object.values(LinkingAccountTabs).map((tab) => (
              <Tab key={tab} label={t(`layouts.linking_account.headers.${tab}`)} value={tab} />
            ))}
          </TabList>
          <ShadowScroller>
            <div className={classes.scroller}>
              <TabPanel
                value={LinkingAccountTabs.RegisteredUsers}
                data-testid={linkingAccountPreviewTestIds.registeredUsersContainer}
              >
                <Box className={classes.container}>
                  {/* Background Image */}
                  <Box
                    component="img"
                    src={getAssetUrl(module.background)}
                    sx={{
                      width: '100%',
                      top: '60%',
                      position: 'absolute'
                    }}
                  />
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <Box
                      sx={{
                        background: 'linear-gradient(to top, transparent, rgba(0,0,0,0.92) 20%)',
                        padding: '16px',
                        height: '100%'
                      }}
                    >
                      <Stack gap="24px" width="100%">
                        <Stack direction="row" justifyContent="space-between" width="100%">
                          <Stack direction="row" gap="16px" alignItems="center">
                            <img src={getAssetUrl(module.vixLogo)} className={classes.logo} />
                            <div className={classes.separator} />
                            <img src={getAssetUrl(module.partnerLogo)} className={classes.logo} />
                          </Stack>
                        </Stack>

                        <Stack gap="16px" marginTop="8px">
                          <Typography variant="h5" alignSelf="start">
                            <span dangerouslySetInnerHTML={{ __html: lt(module.avodHeader) }} />
                          </Typography>
                          <Typography>
                            <span dangerouslySetInnerHTML={{ __html: lt(module.avodSubHeader) }} />
                          </Typography>
                        </Stack>

                        <Stack gap="8px" marginTop="48px">
                          <VixOrangeButton fullWidth>{lt(module.createAccountCta)}</VixOrangeButton>
                          <TextButton>{lt(module.signInCta)}</TextButton>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel
                value={LinkingAccountTabs.LoggedIn}
                data-testid={linkingAccountPreviewTestIds.svodUsersContainer}
              >
                <Box className={classes.container}>
                  {/* Background Image */}
                  <Box
                    component="img"
                    src={getAssetUrl(module.background)}
                    sx={{
                      width: '100%',
                      top: '70%',
                      position: 'absolute'
                    }}
                  />
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <Box
                      sx={{
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.92) 80%, transparent, rgba(0,0,0,0.92))',
                        padding: '16px',
                        height: '100%'
                      }}
                    >
                      <Stack gap="24px" width="100%">
                        <Stack direction="row" justifyContent="space-between" width="100%">
                          <Stack direction="row" gap="16px" alignItems="center">
                            <img src={getAssetUrl(module.vixLogo)} className={classes.logo} />
                            <div className={classes.separator} />
                            <img src={getAssetUrl(module.partnerLogo)} className={classes.logo} />
                          </Stack>
                        </Stack>

                        <Stack gap="16px" marginTop="8px">
                          <Typography variant="h5" alignSelf="start">
                            <span dangerouslySetInnerHTML={{ __html: lt(module.loggedInHeader) }} />
                          </Typography>
                          <Typography fontWeight="bold" color="grey">
                            quentin.tarantino@hotmail.com
                          </Typography>
                          <Typography>
                            <span dangerouslySetInnerHTML={{ __html: lt(module.loggedInSubHeader) }} />
                          </Typography>
                        </Stack>

                        <Stack gap="8px" marginTop="24px">
                          <VixOrangeButton fullWidth>{lt(module.loggedInLinkingCta)}</VixOrangeButton>
                          <TextButton>{lt(module.loggedInCreateNewAccountCta)}</TextButton>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>

              <TabPanel
                value={LinkingAccountTabs.SvodUsers}
                data-testid={linkingAccountPreviewTestIds.registeredUsersContainer}
              >
                <Box className={classes.container}>
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <Box
                      sx={{
                        padding: '16px'
                      }}
                    >
                      <Stack gap="16px" width="100%">
                        <Stack direction="row" justifyContent="flex-end" width="100%">
                          <Close />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" width="100%" marginTop="24px">
                          <Stack direction="row" gap="16px" alignItems="center">
                            <img src={getAssetUrl(module.vixLogo)} className={classes.logo} />
                          </Stack>
                        </Stack>

                        <Stack gap="16px" marginTop="12px">
                          <Typography variant="h5" alignSelf="start">
                            <span dangerouslySetInnerHTML={{ __html: lt(module.svodHeader) }} />
                          </Typography>
                          <Typography fontWeight="bold" color="grey">
                            quentin.tarantino@hotmail.com
                          </Typography>
                          <Typography>
                            <span dangerouslySetInnerHTML={{ __html: lt(module.svodSubHeader) }} />
                          </Typography>
                        </Stack>

                        <Stack gap="8px">
                          <VixOrangeButton fullWidth>{lt(module.svodCreateAccountCta)}</VixOrangeButton>
                          <TextButton>{lt(module.notNowCta)}</TextButton>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
            </div>
          </ShadowScroller>
        </TabContext>
      </Box>
    </Modal>
  );
}
