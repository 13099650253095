import React from 'react';
import { Chip, Typography } from '@mui/material';
import { ContinueWatchingUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import { getContentTreatmentIcon } from '../../../../../utils/uiModuleUtils';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { continueWatchingListItemTestIds } from '../../../../shared/TestsIds';

export type ContinueWatchingListItemProps = IBaseUIModuleListItemProps<ContinueWatchingUiModuleBody>;

const ContinueWatchingListItem = ({ uiModule, ...props }: ContinueWatchingListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')}:
          </Typography>
          <Chip
            icon={getContentTreatmentIcon(uiModule.contentTreatment)}
            label={toTitleCase(uiModule.contentTreatment)}
            size="small"
            data-testid={continueWatchingListItemTestIds.treatment}
          />
        </>,
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.content_type')}:
          </Typography>
          <Chip label={uiModule.contentType} size="small" data-testid={continueWatchingListItemTestIds.contentType} />
        </>
      ]}
      {...props}
    />
  );
};

export default ContinueWatchingListItem;
