import { ContentCollectionSortingType, ContentCollectionType } from '../../API';
import { LocaleKeys } from '../../locales/i18n';

export const sortingTypeCaptions: Record<ContentCollectionSortingType, LocaleKeys> = {
  [ContentCollectionSortingType.CURATED_FIRST]: 'collections.sorting.curated_first',
  [ContentCollectionSortingType.QUERIED_FIRST]: 'collections.sorting.queried_first',
  [ContentCollectionSortingType.FALLBACK_CURATED]: 'collections.sorting.fallback_curated'
};

export const sortingTypeDescriptions: Record<ContentCollectionSortingType, LocaleKeys> = {
  [ContentCollectionSortingType.CURATED_FIRST]: 'collections.sorting.curated_first_description',
  [ContentCollectionSortingType.QUERIED_FIRST]: 'collections.sorting.queried_first_description',
  [ContentCollectionSortingType.FALLBACK_CURATED]: 'collections.sorting.fallback_curated_description'
};

export const collectionTypeCaptions: Partial<Record<ContentCollectionType, LocaleKeys>> = {
  [ContentCollectionType.EPISODE]: 'collections.content_collection_types.episode',
  [ContentCollectionType.MOVIE]: 'collections.content_collection_types.movie',
  [ContentCollectionType.NOVELA]: 'collections.content_collection_types.novela',
  [ContentCollectionType.SERIES]: 'collections.content_collection_types.series',
  [ContentCollectionType.SPORTS]: 'collections.content_collection_types.sports',
  [ContentCollectionType.VIDEO_LIST]: 'collections.content_collection_types.videos_list',
  [ContentCollectionType.SPORTS_EVENTS]: 'collections.content_collection_types.sports',
  [ContentCollectionType.CLIP]: 'collections.content_collection_types.clip',
  [ContentCollectionType.TRAILER]: 'collections.content_collection_types.trailer',
  [ContentCollectionType.SHORT_NOVELA]: 'collections.content_collection_types.short_novela'
};
