import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Adjust, ArrowForward, Upload, Visibility } from '@mui/icons-material';
import { Box, InputLabel, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDropzone, Accept } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { AssetBody, AssetResponse, FocusMode } from '../../../API';
import { useData } from '../../../data-layer';
import { useConfirm, useLocales, useNotifications, useResponsive, useTheme } from '../../../hooks';
import { LocaleKeys } from '../../../locales/i18n';
import { AspectRatio, PreviewAspectRatios } from '../../../utils/aspectRatios';
import { checkAsset, getAssetUrl, shortenImageMimeType } from '../../../utils/assetHelpers';
import Button from '../../shared/Button';
import FormControl from '../../shared/FormControl';
import Modal from '../../shared/Modal';
import { ImageCropper } from './ImageCropper';
import { bigSize } from '../../shared/Modal/Modal';
import { isFormValid } from '../../../utils/formHelpers';
import { AssetGuidelines } from '../../../utils/assetGuidelines';
import { AssetGuideline, AssetTypesEnum } from '../../../utils/assetTypes';
import { AssetGuidelinesBreakdown } from '../AssetGuidelinesBreakdown';
import TextValidator from '../../shared/TextValidator';
import Select from '../../shared/Select';
import { assetUploaderTestIds } from '../../shared/TestsIds';

const nonPositionalFocusModes = [FocusMode.AUTO, FocusMode.FACE, FocusMode.CUSTOM];

const aspectRatioButtonSize = 69;

const useStyles = makeStyles()((theme) => ({
  form: {
    height: '100%'
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  filePicker: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed rgba(255 255 255 / 50%)',
    height: '100%',
    minHeight: 200,
    cursor: 'pointer',
    borderRadius: 10
  },
  imageContainer: {
    position: 'relative',
    height: '100%',
    flexGrow: 1
  },
  fileEditContainer: {
    height: '100%',
    display: 'flex'
  },
  imageFocusControls: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(2, 4, 2, 2)
  },
  alignmentGridContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: theme.spacing(0, 2, 2, 0)
  },
  alignmentGrid: {
    display: 'flex',
    flexDirection: 'column'
  },
  alignmentGridRow: {
    display: 'flex'
  },
  alignmentGridButton: {
    minWidth: 'unset',
    width: 30,
    height: 30,
    margin: '2px',
    [`&.${FocusMode.TOP_LEFT} svg`]: {
      transform: 'rotate(-135deg)'
    },
    [`&.${FocusMode.TOP} svg`]: {
      transform: 'rotate(-90deg)'
    },
    [`&.${FocusMode.TOP_RIGHT} svg`]: {
      transform: 'rotate(-45deg)'
    },
    [`&.${FocusMode.LEFT} svg`]: {
      transform: 'rotate(180deg)'
    },
    [`&.${FocusMode.BOTTOM_LEFT} svg`]: {
      transform: 'rotate(135deg)'
    },
    [`&.${FocusMode.BOTTOM} svg`]: {
      transform: 'rotate(90deg)'
    },
    [`&.${FocusMode.BOTTOM_RIGHT} svg`]: {
      transform: 'rotate(45deg)'
    }
  },
  focusTypeSelector: {
    width: 180,
    marginBottom: theme.spacing(2)
  },
  footerButton: {
    minWidth: 140
  },
  aspectRatioButtonsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  aspectRatioButtonContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  aspectRatioButton: {
    width: aspectRatioButtonSize,
    height: aspectRatioButtonSize,
    padding: 0,
    marginBottom: theme.spacing(2)
  },
  aspectRatioButtonInner: {
    background: '#fff',
    borderRadius: 3
  },
  aspectRatioButtonTooltip: {
    textAlign: 'center'
  },
  modal: bigSize,
  guidelines: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary
  },
  guidelinesHeader: {
    marginBottom: theme.spacing(2)
  },
  guidelinesLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2)
  },
  assetTypeLabel: {
    fontWeight: 'bold',
    fontFamily: 'monospace'
  }
}));

type FilePreview = File & {
  preview: string;
};

type AssetUploaderProps = {
  assetId?: string;
  assetType: AssetTypesEnum;
  open: boolean;
  onAssetUpload?: (asset: AssetResponse, isEditing: boolean) => void;
  onClose?: () => void;
  acceptedFileTypes?: Accept;
  aspectRatios?: AspectRatio[];
  editingAsset?: AssetResponse;
};

export function AssetUploader({
  assetId = '',
  assetType,
  open,
  onAssetUpload,
  onClose,
  acceptedFileTypes = { 'image/*': ['.jpeg', '.jpg', '.png'] },
  aspectRatios = PreviewAspectRatios,
  editingAsset
}: AssetUploaderProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const formRef = useRef<ValidatorForm>(null);
  const [file, setFile] = useState<FilePreview | undefined>();
  const [fileType, setFileType] = useState<string>();
  const [isOpen, setIsOpen] = useState(open);
  const { notifyError } = useNotifications();
  const [isUploading, setIsUploading] = useState(false);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState<AspectRatio>(aspectRatios[0]);
  const { ref: imageContainerRef, width: imageContainerWidth, height: imageContainerHeight } = useResponsive();
  const { handleSubmit, control, reset, setValue } = useForm<AssetResponse>();
  const [showingPreview, setShowingPreview] = useState(false);
  const { confirm } = useConfirm();

  const {
    assets: {
      hook: { create, update }
    }
  } = useData();

  useEffect(() => {
    if (isOpen) {
      setShowingPreview(false);
    }
  }, [isOpen]);

  // when file is selected
  const onDrop = useCallback((files: File[]) => {
    if (files && files.length) {
      const file = files[0] as FilePreview;
      file.preview = URL.createObjectURL(files[0]);
      setFile(file);
      setFileType(file.type);
      const asset: Partial<AssetResponse> = {
        assetId,
        assetType,
        assetName: stripExtension(file.name),
        focusOptions: { mode: FocusMode.AUTO }
      };
      reset(asset);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedFileTypes
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (editingAsset) {
      setIsOpen(true);

      reset({
        ...editingAsset,
        focusOptions: { ...editingAsset.focusOptions, mode: editingAsset.focusOptions?.mode ?? FocusMode.AUTO }
      });
    }
  }, [editingAsset]);

  const stripExtension = (fileName: string) => {
    const match = fileName.match(/^(.*)\.\w+$/);
    return match?.[1] ?? fileName;
  };

  const handleUploadClick = async () => {
    if (!formRef.current) return;
    if (!editingAsset && !file) {
      return notifyError(t('errors.assets.validate_no_file'));
    }
    const valid = await isFormValid(formRef);
    if (!valid) {
      notifyError(t('general.form_error'));
    }
    // Check asset guidelines
    if (!editingAsset && file && fileType) {
      const dimensions = await getImageDimensions(file);
      const guidelinesCheckResult = checkAsset(
        assetType,
        dimensions.width,
        dimensions.height,
        shortenImageMimeType(fileType)
      );
      if (!guidelinesCheckResult.adheres && AssetGuidelines[assetType]) {
        const confirmUpload = await confirm({
          confirmColor: 'primary',
          confirmText: t('layouts.save_with_warnings'),
          body: (
            <AssetGuidelinesBreakdown
              guidelines={AssetGuidelines[assetType] as AssetGuideline}
              results={guidelinesCheckResult}
              type={assetType}
            />
          )
        });
        if (!confirmUpload) {
          return;
        }
      }
    }
    (formRef.current as ValidatorForm & { submit: () => void }).submit();
  };

  const getImageDimensions = async (file: FilePreview): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = document.createElement('img');
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
      };
      img.src = file.preview;
    });
  };

  const doSubmit = async (asset: AssetResponse) => {
    setIsUploading(true);
    let savedAsset;
    if (editingAsset) {
      savedAsset = await update(asset.id, asset);
    } else if (file) {
      savedAsset = await create(
        {
          assetId: asset.assetId,
          assetName: asset.assetName,
          assetType,
          focusOptions: asset.focusOptions
        } as AssetBody,
        false,
        file
      );
    }
    setIsUploading(false);
    if (savedAsset) {
      onAssetUpload?.(savedAsset, !!editingAsset);
    }
    handleClose();
  };

  const onSubmit: SubmitHandler<AssetResponse> = (asset) => {
    doSubmit(asset);
  };

  const reinitialize = () => {
    setFile(undefined);
    setShowingPreview(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    reinitialize();
    onClose?.();
  };

  const onXPositionChange = (focusX: number | undefined) => {
    setValue('focusOptions.focusX', focusX);
    if (focusX !== undefined) setValue('focusOptions.mode', FocusMode.CUSTOM);
  };

  const onYPositionChange = (focusY: number | undefined) => {
    setValue('focusOptions.focusY', focusY);
    if (focusY !== undefined) setValue('focusOptions.mode', FocusMode.CUSTOM);
  };

  const clearFocalPoints = () => {
    setValue('focusOptions.focusX', undefined);
    setValue('focusOptions.focusY', undefined);
  };

  const DirectionButton = ({ direction }: { direction: FocusMode }) => {
    return (
      <Controller
        name="focusOptions.mode"
        control={control}
        render={({ field: { value } }) => (
          <Button
            className={`${classes.alignmentGridButton} ${direction}`}
            color={value === direction ? 'primary' : 'grey'}
            onClick={() => {
              setValue('focusOptions.mode', direction);
              clearFocalPoints();
            }}
          >
            {direction === FocusMode.CENTER ? <Adjust /> : <ArrowForward />}
          </Button>
        )}
      />
    );
  };

  const AspectRatioButton = ({ aspectRatio, index }: { aspectRatio: AspectRatio; index: number }) => {
    const maxDimension = aspectRatioButtonSize * 0.8;
    const width = aspectRatio.factor > 1 ? maxDimension : maxDimension * aspectRatio.factor;
    const height = aspectRatio.factor < 1 ? maxDimension : maxDimension / aspectRatio.factor;
    return (
      <Tooltip
        title={
          <div className={classes.aspectRatioButtonTooltip}>
            <Typography variant="body1">{aspectRatio.name}</Typography>
            <div>{aspectRatio.description}</div>
          </div>
        }
        arrow
        placement={index % 2 ? 'right' : 'left'}
      >
        <div className={classes.aspectRatioButtonContainer}>
          <Button
            className={classes.aspectRatioButton}
            color={selectedAspectRatio.factor === aspectRatio.factor ? 'primary' : 'grey'}
            onClick={() => setSelectedAspectRatio(aspectRatio)}
          >
            <Box className={classes.aspectRatioButtonInner} sx={{ height, width }}></Box>
          </Button>
        </div>
      </Tooltip>
    );
  };

  const guidelines = AssetGuidelines[assetType];

  return (
    <Modal
      open={isOpen}
      bodyClassName={classes.modal}
      onClose={handleClose}
      headerLeft={<Typography variant="h6">{t(editingAsset ? 'assets.edit_asset' : 'assets.new_asset')}</Typography>}
      footerLeft={
        <>
          <Button
            className={classes.footerButton}
            loading={isUploading}
            endIcon={<Upload />}
            variant="contained"
            onClick={handleUploadClick}
            data-testid={assetUploaderTestIds.formSubmit}
          >
            {t(editingAsset ? 'general.save_changes' : 'general.upload')}
          </Button>
        </>
      }
      footerRight={
        <Button className={classes.footerButton} color="grey" onClick={handleClose}>
          {t('general.cancel')}
        </Button>
      }
    >
      <ValidatorForm
        className={classes.form}
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        data-testid={assetUploaderTestIds.validatorForm}
      >
        <div className={classes.root}>
          {!file && !editingAsset && (
            <div className={classes.filePicker} {...getRootProps()}>
              <Stack direction="column" gap={6} alignItems="center">
                {isDragActive ? t('assets.drop_file') : t('assets.drag_and_drop')}
                {guidelines && (
                  <div className={classes.guidelines}>
                    <div className={classes.guidelinesHeader}>
                      {t('assets.guidelines.guidelines_for_type')}{' '}
                      <span className={classes.assetTypeLabel}>{assetType}</span>
                    </div>
                    {guidelines.preferredFileTypes && (
                      <div>
                        <span className={classes.guidelinesLabel}>{t('assets.guidelines.preferred_file_types')}:</span>
                        {guidelines.preferredFileTypes.join(', ')}
                      </div>
                    )}
                    {guidelines.preferredAspectRatio && (
                      <div>
                        <span className={classes.guidelinesLabel}>
                          {t('assets.guidelines.preferred_aspect_ratio')}:
                        </span>
                        {guidelines.preferredAspectRatio}
                      </div>
                    )}
                    {guidelines.minHeight && (
                      <div>
                        <span className={classes.guidelinesLabel}>{t('assets.guidelines.recommended_width')}:</span>
                        {guidelines.minWidth}px
                      </div>
                    )}
                    {guidelines.minHeight && (
                      <div>
                        <span className={classes.guidelinesLabel}>{t('assets.guidelines.recommended_height')}:</span>
                        {guidelines.minHeight}px
                      </div>
                    )}
                  </div>
                )}
              </Stack>
              <input data-testid={assetUploaderTestIds.fileInput} {...getInputProps()} />
            </div>
          )}
          {(file || editingAsset) && (
            <div className={classes.fileEditContainer}>
              <div className={classes.imageFocusControls}>
                <Controller
                  name="assetName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextValidator
                      name="assetName"
                      required
                      fullWidth
                      color={formControlColor}
                      value={value}
                      onChange={onChange}
                      label={t('assets.name')}
                      validators={['required']}
                      errorMessages={[t('assets.asset_name_required')]}
                      data-testid={assetUploaderTestIds.assetName}
                    />
                  )}
                />
                <Controller
                  name="focusOptions.mode"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const positional = 'positional';
                    return (
                      <FormControl>
                        <InputLabel id="focusTypeSelector">{t('assets.focus_mode')}</InputLabel>
                        <Select
                          label={t('assets.focus_mode')}
                          className={classes.focusTypeSelector}
                          labelId="focusTypeSelector"
                          value={nonPositionalFocusModes.indexOf(value) >= 0 ? String(value) : FocusMode.CENTER}
                          onChange={onChange}
                          data-testid={assetUploaderTestIds.focusModeSelector}
                          data-test-value={value}
                        >
                          {[...nonPositionalFocusModes, positional].map((value) => (
                            <MenuItem key={value} value={String(value === positional ? FocusMode.CENTER : value)}>
                              {t(`assets.focus_modes.${value}` as LocaleKeys)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    );
                  }}
                />

                <Controller
                  name="focusOptions.mode"
                  control={control}
                  render={({ field: { value } }) => {
                    if (nonPositionalFocusModes.indexOf(value) >= 0) return <></>;
                    return (
                      <div className={classes.alignmentGridContainer}>
                        <div className={classes.alignmentGrid}>
                          <div className={classes.alignmentGridRow}>
                            <DirectionButton direction={FocusMode.TOP_LEFT} />
                            <DirectionButton direction={FocusMode.TOP} />
                            <DirectionButton direction={FocusMode.TOP_RIGHT} />
                          </div>
                          <div className={classes.alignmentGridRow}>
                            <DirectionButton direction={FocusMode.LEFT} />
                            <DirectionButton direction={FocusMode.CENTER} />
                            <DirectionButton direction={FocusMode.RIGHT} />
                          </div>
                          <div className={classes.alignmentGridRow}>
                            <DirectionButton direction={FocusMode.BOTTOM_LEFT} />
                            <DirectionButton direction={FocusMode.BOTTOM} />
                            <DirectionButton direction={FocusMode.BOTTOM_RIGHT} />
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Controller
                  name="focusOptions.mode"
                  control={control}
                  render={({ field: { value } }) => {
                    if (value !== FocusMode.CUSTOM) return <></>;
                    return (
                      <>
                        <div>
                          <Typography variant="body2" color="textSecondary">
                            {t('assets.focus_point_x')}
                          </Typography>
                          <Controller
                            name="focusOptions.focusX"
                            control={control}
                            render={({ field: { value } }) => (
                              <div data-testid={assetUploaderTestIds.focusX}>{value || t('general.unset')}</div>
                            )}
                          />
                        </div>

                        <div>
                          <Typography variant="body2" color="textSecondary">
                            {t('assets.focus_point_y')}
                          </Typography>
                          <Controller
                            name="focusOptions.focusY"
                            control={control}
                            render={({ field: { value } }) => (
                              <div data-testid={assetUploaderTestIds.focusY}>{value || t('general.unset')}</div>
                            )}
                          />
                        </div>
                      </>
                    );
                  }}
                />

                {editingAsset && (
                  <Controller
                    name="focusOptions.mode"
                    control={control}
                    render={({ field: { value } }) => {
                      if (![FocusMode.AUTO, FocusMode.FACE].includes(value)) return <></>;
                      return (
                        <div>
                          <Button
                            color={formControlColor}
                            variant="text"
                            startIcon={<Visibility />}
                            onClick={() => setShowingPreview(!showingPreview)}
                          >
                            {!showingPreview ? 'Preview Cropped' : 'Show Original'}
                          </Button>
                        </div>
                      );
                    }}
                  />
                )}

                <Controller
                  name="focusOptions.mode"
                  control={control}
                  render={({ field: { value } }) => {
                    if (!editingAsset && [FocusMode.AUTO, FocusMode.FACE].includes(value)) return <></>;
                    return (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {t('assets.preview_aspect_ratio')}
                        </Typography>
                        <div className={classes.aspectRatioButtonsContainer}>
                          {aspectRatios.map((aspectRatio, i) => (
                            <AspectRatioButton key={aspectRatio.name} aspectRatio={aspectRatio} index={i} />
                          ))}
                        </div>
                      </>
                    );
                  }}
                />
              </div>

              <div className={classes.imageContainer} ref={imageContainerRef}>
                <Controller
                  name="focusOptions.mode"
                  control={control}
                  render={({ field: { value } }) => (
                    <ImageCropper
                      src={editingAsset ? getAssetUrl(editingAsset.filePath) : (file as FilePreview).preview}
                      fileType={editingAsset ? editingAsset.mediaType : fileType}
                      assetType={assetType}
                      focusMode={value}
                      aspectRatio={selectedAspectRatio?.factor}
                      maxHeight={imageContainerHeight}
                      maxWidth={imageContainerWidth}
                      initialX={editingAsset?.focusOptions?.focusX}
                      initialY={editingAsset?.focusOptions?.focusY}
                      onXPositionChange={onXPositionChange}
                      onYPositionChange={onYPositionChange}
                      showingPreview={showingPreview}
                      setShowingPreview={setShowingPreview}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </ValidatorForm>
    </Modal>
  );
}
