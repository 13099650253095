/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiPaginatedResponseOfLoggerResponse } from '../model';
// @ts-ignore
import { OperationIds } from '../model';
/**
 * LoggerApi - axios parameter creator
 * @export
 */
export const LoggerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [userId] User´s identifier
         * @param {OperationIds} [actionType] 
         * @param {string} [startDate] Search range start date
         * @param {string} [endDate] Search range end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated: async (limit?: number, page?: number, userId?: string, actionType?: OperationIds, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/logger/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (actionType !== undefined) {
                localVarQueryParameter['actionType'] = actionType;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoggerApi - functional programming interface
 * @export
 */
export const LoggerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoggerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [userId] User´s identifier
         * @param {OperationIds} [actionType] 
         * @param {string} [startDate] Search range start date
         * @param {string} [endDate] Search range end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaginated(limit?: number, page?: number, userId?: string, actionType?: OperationIds, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaginatedResponseOfLoggerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaginated(limit, page, userId, actionType, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoggerApi - factory interface
 * @export
 */
export const LoggerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoggerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [page] 
         * @param {string} [userId] User´s identifier
         * @param {OperationIds} [actionType] 
         * @param {string} [startDate] Search range start date
         * @param {string} [endDate] Search range end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaginated(limit?: number, page?: number, userId?: string, actionType?: OperationIds, startDate?: string, endDate?: string, options?: any): AxiosPromise<ApiPaginatedResponseOfLoggerResponse> {
            return localVarFp.getPaginated(limit, page, userId, actionType, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoggerApi - interface
 * @export
 * @interface LoggerApi
 */
export interface LoggerApiInterface {
    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [userId] User´s identifier
     * @param {OperationIds} [actionType] 
     * @param {string} [startDate] Search range start date
     * @param {string} [endDate] Search range end date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerApiInterface
     */
    getPaginated(limit?: number, page?: number, userId?: string, actionType?: OperationIds, startDate?: string, endDate?: string, options?: any): AxiosPromise<ApiPaginatedResponseOfLoggerResponse>;

}

/**
 * LoggerApi - object-oriented interface
 * @export
 * @class LoggerApi
 * @extends {BaseAPI}
 */
export class LoggerApi extends BaseAPI implements LoggerApiInterface {
    /**
     * 
     * @param {number} [limit] 
     * @param {number} [page] 
     * @param {string} [userId] User´s identifier
     * @param {OperationIds} [actionType] 
     * @param {string} [startDate] Search range start date
     * @param {string} [endDate] Search range end date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerApi
     */
    public getPaginated(limit?: number, page?: number, userId?: string, actionType?: OperationIds, startDate?: string, endDate?: string, options?: any) {
        return LoggerApiFp(this.configuration).getPaginated(limit, page, userId, actionType, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}
