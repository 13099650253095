import React, { useEffect, useRef } from 'react';
import { useLocales, useTheme } from '../../../hooks';
import { useRecoilValue } from 'recoil';
import { Typography, MenuItem, InputLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '../../shared/Button';
import Drawer from '../../shared/Drawer';
import FormControl from '../../shared/FormControl';
import AssetBrowser from '../../Assets/AssetBrowser';
import { AssetTypes } from '../../../utils/assetTypes';
import { DocumentLocale, TournamentBody, TournamentResponse } from '../../../API';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import { markAsRequired } from '../../../utils/formHelpers';
import { CustomValidators } from '../../../utils/customValidators';
import InputController from '../../shared/InputController';
import { useData } from '../../../data-layer';
import useConfirm from '../../../hooks/General/useConfirm';
import { AdTargeting } from '../../shared/AdTargeting';
import { Slug } from '../../shared/Slug';
import TextValidator from '../../shared/TextValidator';
import Select from '../../shared/Select';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { tournamentFormTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  sportsTypeSelect: {
    minWidth: 155,
    marginBottom: theme.spacing(4)
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4)
  },
  formField: {
    maxWidth: 500
  },
  actionButton: {
    minWidth: 120
  },
  textInput: {
    minWidth: 400,
    marginBottom: theme.spacing(4)
  },
  adTargeting: {
    marginTop: theme.spacing(2)
  }
}));

enum TournamentValidationCodes {
  TOURNAMENT_HAS_MATCHES = 'TOURNAMENT_HAS_MATCHES'
}

export function TournamentForm(): JSX.Element {
  const formRef = useRef<ValidatorForm>(null);
  const { formControlColor } = useTheme();
  const { classes } = useStyles();
  const { t, localize } = useLocales();
  const { canSave: hasUpsertPermission, canDelete: hasDeletePermission } = usePermissionsGuard({
    homepageOption: HomepageOptions.SPORTS
  });

  const {
    sports: {
      state: { withAllRecords: withAllSports }
    },
    tournaments: {
      state: { withFormMetadata, withIsSaving, withIsDeleting, withIsValidatingDeletion },
      hook: {
        create: createTournament,
        update: updateTournament,
        closeForm,
        validateDeletion,
        remove: deleteTournament
      }
    }
  } = useData();
  const formMetadata = useRecoilValue(withFormMetadata);
  const sportTypes = useRecoilValue(withAllSports);
  const tournament: TournamentResponse = formMetadata.record as TournamentResponse;

  const isSavingLeague = useRecoilValue(withIsSaving);
  const isDeleting = useRecoilValue(withIsDeleting);
  const isValidatingDeletion = useRecoilValue(withIsValidatingDeletion);

  const { handleSubmit, control, reset, getValues } = useForm<TournamentBody | TournamentResponse>();
  const name = useWatch({ control, name: 'name' });
  const { confirm } = useConfirm();

  useEffect(() => {
    reset(tournament);
  }, [tournament]);

  const saveTournament = async (tournamentToSave: TournamentBody | TournamentResponse) => {
    let savedTournament: TournamentResponse | undefined;
    if (formMetadata.isNew) {
      savedTournament = await createTournament(tournamentToSave);
    } else {
      savedTournament = await updateTournament(tournament.id, tournamentToSave as TournamentResponse);
    }
    if (savedTournament) closeForm();
  };

  const handleLeagueDeletionMessages = (code: string | undefined) => {
    if (code) {
      return TournamentValidationCodes.TOURNAMENT_HAS_MATCHES === code
        ? t('errors.leagues.league_has_matches')
        : t('errors.leagues.league_has_teams');
    }
    return t('leagues.delete_confirm');
  };

  const validateLeagueDeletion = async () => {
    const isDeletableResponse = await validateDeletion(tournament.id);
    if (isDeletableResponse) {
      const result = await confirm({
        confirmText: t('general.confirm_delete'),
        cancelText: isDeletableResponse.code ? t('general.ok') : t('general.cancel'),
        confirmColor: 'error',
        isShown: isDeletableResponse.deletable,
        body: handleLeagueDeletionMessages(isDeletableResponse.code),
        'data-testid': tournamentFormTestIds.confirmDialog
      });

      if (result) {
        const deleted = await deleteTournament(tournament.id);
        if (deleted) closeForm();
      }
    }
  };

  const onSubmit: SubmitHandler<TournamentBody | TournamentResponse> = (tournament) => {
    saveTournament(tournament);
  };

  const onClose = () => {
    if (!isSavingLeague && !isDeleting && !isValidatingDeletion) {
      closeForm();
    }
  };

  const normalizedValue = (value: string) => {
    return value.replace(/\s/g, '');
  };

  return (
    <>
      <Drawer
        open={formMetadata.isShowingForm}
        formRef={formRef}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
        headerLeft={
          <Typography variant="h6">{t(`leagues.${formMetadata.isNew ? 'create' : 'edit'}_league`)}</Typography>
        }
        headerRight={
          !formMetadata.isNew && (
            <Button
              className={classes.actionButton}
              color="error"
              endIcon={<Delete />}
              loading={isDeleting || isValidatingDeletion}
              onClick={validateLeagueDeletion}
              data-testid={tournamentFormTestIds.deleteButton}
              disabled={!hasDeletePermission}
            >
              {t('general.delete')}
            </Button>
          )
        }
        footerLeft={
          <>
            <Button
              type="submit"
              loading={isSavingLeague}
              disabled={isDeleting || isValidatingDeletion || !hasUpsertPermission}
              className={classes.actionButton}
              data-testid={tournamentFormTestIds.saveButton}
            >
              {t('general.save')}
            </Button>
          </>
        }
        footerRight={
          <Button
            color="grey"
            className={classes.actionButton}
            disabled={isSavingLeague || isDeleting || isValidatingDeletion}
            onClick={onClose}
            data-testid={tournamentFormTestIds.cancelButton}
          >
            {t('general.cancel')}
          </Button>
        }
      >
        {tournament && (
          <div className={classes.formBody} data-testid={tournamentFormTestIds.formBody}>
            <LocalizedInputCollection
              containerClassName={classes.formField}
              getValues={getValues}
              reset={reset}
              fields={[
                {
                  component: (
                    <InputController
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextValidator
                          className={classes.textInput}
                          fullWidth
                          name="name"
                          color={formControlColor}
                          value={value}
                          onChange={onChange}
                          label={markAsRequired(t('leagues.league_name'))}
                          validators={[CustomValidators.requiredIfDefined]}
                          errorMessages={[t('general.field_is_required')]}
                        />
                      )}
                    />
                  )
                },
                {
                  component: (
                    <InputController
                      name="description"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextValidator
                          className={classes.textInput}
                          fullWidth
                          name="description"
                          color={formControlColor}
                          value={value}
                          onChange={onChange}
                          required={false}
                          label={markAsRequired(t('leagues.league_description'))}
                        />
                      )}
                    />
                  )
                }
              ]}
            />
            <div className={classes.formField}>
              {sportTypes && (
                <Controller
                  name="sport"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl className={classes.sportsTypeSelect}>
                      <InputLabel>{t('sports.sport_type')}</InputLabel>
                      {sportTypes && (
                        <Select
                          disabled
                          required
                          value={value}
                          onChange={onChange}
                          data-testid={tournamentFormTestIds.sportSelector}
                        >
                          {sportTypes.map((sport) => (
                            <MenuItem key={sport.id} value={sport.id} data-testid={tournamentFormTestIds.sportSelector}>
                              {localize(sport.name)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  )}
                />
              )}
            </div>
            <div className={classes.formField}>
              <Controller
                name="tournamentId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextValidator
                    fullWidth
                    color={formControlColor}
                    name="tournamentId"
                    label={markAsRequired(t('leagues.league_id'))}
                    value={value}
                    validators={['required']}
                    errorMessages={[t('general.field_is_required')]}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      const normalized = normalizedValue(newValue);
                      onChange(normalized);
                    }}
                    data-testid={tournamentFormTestIds.leagueIdInput}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Controller
                name="slug"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Slug required value={value} onChange={onChange} slugify={name?.[DocumentLocale.ES]} />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Typography variant="body2" color="textSecondary">
                {t('leagues.league_image_logo')}
              </Typography>
              <Controller
                name="logoImage"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AssetBrowser
                    value={value}
                    assetId={tournament.id || AssetTypes.sports.leagueLogo}
                    assetType={AssetTypes.sports.leagueLogo}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Typography variant="body2" color="textSecondary">
                {t('leagues.league_image_card')}
              </Typography>
              <Controller
                name="cardBackgroundImage"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AssetBrowser
                    value={value}
                    assetId={tournament.id || AssetTypes.sports.leagueCard}
                    assetType={AssetTypes.sports.leagueCard}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Typography variant="body2" color="textSecondary">
                {t('leagues.league_image_splash')}
              </Typography>
              <Controller
                name="splashBackgroundImage"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AssetBrowser
                    value={value}
                    assetId={tournament.id || AssetTypes.sports.leagueSplash}
                    assetType={AssetTypes.sports.leagueSplash}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div>
              <Typography variant="body2" color="textSecondary">
                {t('ad_targeting.ad_targeting')}
              </Typography>
              <Controller
                name="adTargetingList"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AdTargeting className={classes.adTargeting} value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}
