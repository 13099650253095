import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  BecauseYouSource,
  BecauseYouType,
  BecauseYouUiModuleBody,
  BecauseYouUIModuleContentTreatment
} from '../../../../../API';
import { FormBody } from '../../styles';
import TreatmentPicker from '../../TreatmentPicker';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { InputLabel, MenuItem, Select } from '@mui/material';
import FormControl from '../../../../shared/FormControl';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../../hooks';
import TextField from '../../../../shared/TextField';
import { markAsRequired } from '../../../../../utils/formHelpers';
import { becauseYouFormTestIds } from '../../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  selector: {
    minWidth: 260
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(4)
  },
  textField: {
    '&.MuiTextField-root': {
      marginBottom: '0 !important'
    }
  }
}));

const BecauseYouForm = forwardRef<UIModuleTypeForm<BecauseYouUiModuleBody>>(() => {
  const { t } = useLocales();
  const { classes } = useStyles();
  const { control } = useFormContext<BecauseYouUiModuleBody>();

  return (
    <FormBody data-testid={becauseYouFormTestIds.formBody}>
      <div className={classes.formRow}>
        <Controller
          control={control}
          rules={{ required: t('general.field_is_required') }}
          name="textTitle"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              fullWidth
              label={markAsRequired(t('layouts.because_you.textTitle'), true)}
              value={value}
              className={classes.textField}
              onChange={onChange}
              clearable
              data-testid={becauseYouFormTestIds.textTitle}
              fieldError={error}
            />
          )}
        />
      </div>
      <div className={classes.formRow}>
        <Controller
          control={control}
          rules={{ required: t('general.field_is_required') }}
          name="type"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormControl>
              <InputLabel id="typeSelector">{markAsRequired(t('layouts.because_you.type'), true)}</InputLabel>
              <Select
                label={markAsRequired(t('layouts.because_you.type'), true)}
                className={classes.selector}
                required
                labelId="typeSelector"
                value={value}
                onChange={({ target: { value } }) => onChange(value as BecauseYouType)}
                data-testid={becauseYouFormTestIds.becauseYouType}
                error={!!error}
              >
                {Object.values(BecauseYouType).map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid={becauseYouFormTestIds.becauseYouTypeItem}
                    data-treatment-id={value}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="source"
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormControl>
              <InputLabel id="sourceSelector">{markAsRequired(t('layouts.because_you.source'), true)}</InputLabel>
              <Select
                label={markAsRequired(t('layouts.because_you.source'), true)}
                className={classes.selector}
                required
                labelId="sourceSelector"
                value={value}
                onChange={({ target: { value } }) => onChange(value as BecauseYouSource)}
                data-testid={becauseYouFormTestIds.becauseYouType}
                error={!!error}
              >
                {Object.values(BecauseYouSource).map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid={becauseYouFormTestIds.becauseYouSource}
                    data-treatment-id={value}
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="contentTreatment"
          render={({ field: { value, onChange } }) => (
            <TreatmentPicker
              options={BecauseYouUIModuleContentTreatment}
              value={value}
              onChange={(value) => onChange(value as BecauseYouUIModuleContentTreatment)}
            />
          )}
        />
      </div>
    </FormBody>
  );
});

BecauseYouForm.displayName = 'BecauseYouForm';

export default BecauseYouForm;
