import React from 'react';
import { DragHandle } from '@mui/icons-material';
import { capitalize, Chip, emphasize, ListItemText } from '@mui/material';
import { getMediaAspectRatio } from '../../../../../utils/styleUtils';
import { useLocales } from '../../../../../hooks';
import { useData } from '../../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { MediaComponentProps } from '..';
import License from '../License';
import Image from '../../../../shared/Image';
import PremiumBadge from '../../../../shared/PremiumBadge';
import { makeStyles } from 'tss-react/mui';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';
import InfoBadge from '../../../../shared/InfoBadge';
import { useRatings } from '../../../../../hooks/Media/useRatings';
import { isChannel, isMatch } from '../../../../../utils/mediaUtilities';
import { MatchListItem } from '../../../../Sports/MatchListItem';
import { VllChannelResponse } from '../../../../../API';
import EPGChannelItem from '../../../../EPG/EPGChannelItem';
import { mediaListTestIds } from '../../../../shared/TestsIds';

const useStyles = makeStyles<{ disabled: boolean | undefined }>()((theme, props) => ({
  container: {
    display: 'flex',
    position: 'relative',
    background: theme.palette.background.default,
    transition: 'box-shadow 0.5s ease',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    gap: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    ['& > *']: {
      opacity: props.disabled ? 0.5 : 1
    }
  },
  handle: {
    cursor: 'grab'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 0
  },
  badges: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  listItemText: {
    margin: 0,
    ['& > span']: {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      color: props.disabled ? theme.palette.text.disabled : 'auto'
    }
  },
  listImage: {
    aspectRatio: getMediaAspectRatio(DisplayAsOptions.LANDSCAPE),
    borderRadius: theme.shape.borderRadius,
    height: 50
  },
  fallback: {
    borderRadius: theme.shape.borderRadius,
    background: emphasize(theme.palette.background.paper, 0.1),
    height: 50,
    aspectRatio: getMediaAspectRatio(DisplayAsOptions.LANDSCAPE),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentBadge: {
    height: 24
  },
  premiumBadge: {
    borderRadius: theme.shape.borderRadius,
    ['& > svg']: {
      height: '100%'
    }
  },
  infoBadge: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1)
  }
}));

export function MediaList({
  mediaId,
  imageUrl,
  disabled,
  allowSort,
  previewMode,
  collectionForKids,
  collectionSelected,
  children
}: MediaComponentProps): JSX.Element {
  const { classes } = useStyles({ disabled });
  const {
    media: {
      state: { withRecordById }
    },
    channels: {
      state: { withAllRecords: withAllChannels }
    }
  } = useData();
  const { localize, t } = useLocales();
  const media = useRecoilValue(withRecordById(mediaId));
  const { isSuitableForKids } = useRatings(mediaId);
  const allChannels = useRecoilValue(withAllChannels);

  const renderThumbnail = () => {
    if (media?.contentId) {
      if (isMatch(media?.contentId)) {
        return <MatchListItem match={media} showContent={false} />;
      }
      if (isChannel(media.contentId)) {
        const channel = allChannels?.find((c) => c.entityId === media.contentId) as VllChannelResponse;
        return (
          <EPGChannelItem channel={channel} isChannelPicker={false} isContentServiceChannel={false} isStandalone />
        );
      }
    }

    if (imageUrl) {
      return (
        <Image
          className={classes.listImage}
          draggable={false}
          src={imageUrl}
          sx={{ height: 50 }}
          data-testid={mediaListTestIds.image}
          fallback={<div className={classes.fallback} />}
        />
      );
    }

    return null;
  };

  return (
    <div className={classes.container} data-testid={mediaListTestIds.container}>
      {allowSort && <DragHandle className={classes.handle} />}
      {imageUrl && renderThumbnail()}
      <div className={classes.details}>
        <ListItemText
          className={classes.listItemText}
          primary={localize(media?.title)}
          data-testid={mediaListTestIds.title}
        />
        <div className={classes.badges}>
          {media?.contentType && (
            <Chip
              label={capitalize(media.contentType.toLowerCase())}
              className={classes.contentBadge}
              data-testid={mediaListTestIds.contentBadge}
            />
          )}
          {media?.isPremium && (
            <PremiumBadge className={classes.premiumBadge} data-testid={mediaListTestIds.premiumBadge} />
          )}
          {media && !previewMode && (
            <License publishWindow={media.publishWindowsDefault} data-testid={mediaListTestIds.licenseLabel} />
          )}
          {collectionSelected && collectionForKids && !isSuitableForKids() && (
            <InfoBadge
              className={classes.infoBadge}
              title={t('collections.kids.infoTooltip')}
              data-testid={mediaListTestIds.infoBadge}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
}
