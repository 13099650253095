import React from 'react';
import { TdsGiftCardsUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { tdsGiftCardsListItemTestIds } from '../../../../shared/TestsIds';

export type TdsGiftCardsListItemProps = IBaseUIModuleListItemProps<TdsGiftCardsUiModuleBody>;

export function TdsGiftCardsListItem({ uiModule, ...props }: TdsGiftCardsListItemProps): JSX.Element {
  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} data-testid={tdsGiftCardsListItemTestIds.title} />}
        {...props}
      />
    </>
  );
}
