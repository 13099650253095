import React, { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../hooks';
import { Divider, Typography } from '@mui/material';
import { useConfig } from '../../../hooks/Config';
import { withAllSettings } from '../../../state/Config';
import { useRecoilValue } from 'recoil';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import { useForm } from 'react-hook-form';
import { ConfigResponse, SeoConfigBody } from '../../../API';
import { Button } from '../../shared/Button/Button';
import InputController from '../../shared/InputController';
import ShadowScroller from '../../shared/ShadowScroller';
import { Skeleton } from '@mui/material';
import Repeat from '../../shared/Repeat';
import { seoTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  frameMain: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  frameBottom: {
    padding: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper
  },
  mainContent: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  contentRow: {
    display: 'flex',
    '&:not(:last-of-type)': {
      borderBottom: `1px dotted ${theme.palette.divider}`
    }
  },
  leftSection: {
    margin: theme.spacing(5, 0),
    width: '250px'
  },
  rightSection: {
    margin: theme.spacing(3, 0),
    flexGrow: 1
  },
  localized: {
    display: 'flex',
    gap: theme.spacing(2),
    '& > div': {
      flexGrow: 1
    }
  },
  skeletonRow: {
    margin: theme.spacing(4),
    height: theme.spacing(6)
  },
  saveButton: {
    minWidth: 200
  }
}));

export default function Seo(): JSX.Element {
  const { classes } = useStyles();
  const formRef = useRef<ValidatorForm>(null);
  const { formControlColor } = useTheme();
  const { t } = useLocales();

  const { fetchCmsConfig, updateCmsConfig } = useConfig();

  const seoConfig = useRecoilValue(withAllSettings)?.seo;

  const config = useRecoilValue(withAllSettings);

  useEffect(() => {
    fetchCmsConfig();
  }, []);

  const { handleSubmit, control, reset, getValues } = useForm<SeoConfigBody>({
    defaultValues: seoConfig
  });

  useEffect(() => {
    reset(seoConfig);
  }, [seoConfig]);

  const onSubmit = (seoUpdated: SeoConfigBody) => {
    updateCmsConfig({
      ...(config as ConfigResponse),
      seo: seoUpdated
    });
  };

  const handleSave: React.MouseEventHandler = (evt) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (formRef.current as any).submit(evt);
  };

  return (
    <>
      <div data-testid={seoTestIds.root} className={classes.root}>
        <div className={classes.frameMain}>
          <ShadowScroller>
            <div className={classes.mainContent}>
              {!seoConfig && (
                <Repeat n={6}>
                  <Skeleton className={classes.skeletonRow} animation="wave" />
                </Repeat>
              )}
              {seoConfig && Object.keys(getValues()).length && (
                <ValidatorForm ref={formRef} onSubmit={handleSubmit(onSubmit)} data-testid={seoTestIds.validatorForm}>
                  {Object.keys(seoConfig).map((key, index) => {
                    const fields = [
                      {
                        component: (
                          <InputController
                            name={`${key}.title`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextValidator
                                fullWidth
                                name={`${key}.title`}
                                color={formControlColor}
                                value={value}
                                onChange={onChange}
                                label={t('general.title')}
                                validators={['requiredIfDefined']}
                                errorMessages={[t('general.field_is_required')]}
                              />
                            )}
                          />
                        )
                      },
                      {
                        component: (
                          <InputController
                            name={`${key}.description`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextValidator
                                fullWidth
                                className={classes.localized}
                                color={formControlColor}
                                name={`${key}.description`}
                                label={t('general.description')}
                                value={value}
                                validators={['requiredIfDefined']}
                                onChange={onChange}
                                errorMessages={[t('general.field_is_required')]}
                              />
                            )}
                          />
                        )
                      }
                    ];
                    if (key === 'sportsEvent') {
                      fields.push({
                        component: (
                          <InputController
                            name={`${key}.dateFormat`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextValidator
                                fullWidth
                                name={`${key}.dateFormat`}
                                color={formControlColor}
                                value={value}
                                onChange={onChange}
                                label={t('general.date_format')}
                                validators={['requiredIfDefined']}
                                errorMessages={[t('general.field_is_required')]}
                              />
                            )}
                          />
                        )
                      });
                    }
                    return (
                      <div className={classes.contentRow} key={index}>
                        <div className={classes.leftSection}>
                          <Typography variant="h6" gutterBottom component="div">
                            {key}
                          </Typography>
                        </div>
                        <div className={classes.rightSection}>
                          <LocalizedInputCollection
                            containerClassName={classes.localized}
                            reset={reset}
                            getValues={getValues}
                            fields={fields}
                          />
                        </div>
                        <Divider variant="middle"></Divider>
                      </div>
                    );
                  })}
                </ValidatorForm>
              )}
            </div>
          </ShadowScroller>
          <div className={classes.frameBottom}>
            <Button
              type="submit"
              className={classes.saveButton}
              onClick={handleSave}
              data-testid={seoTestIds.saveButton}
            >
              {t('general.save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
