import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, MenuItem, Stack, TextField } from '@mui/material';
import { debounce } from 'lodash-es';
import { ContentCollectionResponse, EntitlementType, GetAllContentCollectionQuery } from '../../../../API';
import { useData } from '../../../../data-layer';
import { useLocales, useTheme } from '../../../../hooks';
import CollectionLabel, { collectionLabelClasses } from '../../../shared/CollectionLabel';
import { makeStyles } from 'tss-react/mui';
import { useRecoilValue } from 'recoil';
import { EntitlementsFilter } from '../../../shared/Entitlements';
import { markAsRequired } from '../../../../utils/formHelpers';
import { collectionPickerTestIds } from '../../../shared/TestsIds';

export const collectionPickerClasses = {
  root: 'collection-picker-root',
  input: 'collection-picker-input'
};

type CollectionPickerProps = {
  className?: string;
  label: string;
  value: string | undefined;
  onChange: (changedValues: string) => void;
  entitlements?: EntitlementType[];
  required?: boolean;
  hideEntitlements?: boolean;
  error?: boolean;
  helperText?: string;
  filters?: Partial<GetAllContentCollectionQuery>;
};

const useStyles = makeStyles()((theme) => ({
  autocomplete: {
    flexGrow: 1
  },
  option: {
    padding: `${theme.spacing(4)} !important`,
    overflow: 'hidden',
    flexWrap: 'wrap'
  },
  label: {
    overflow: 'hidden',
    [`& .${collectionLabelClasses.title}`]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}));

export function CollectionPicker({
  label,
  value,
  onChange,
  required,
  hideEntitlements,
  entitlements: defaultEntitlements,
  filters,
  error,
  helperText
}: CollectionPickerProps): React.ReactElement {
  const {
    collections: {
      state: { withLatestPublishedRecordById },
      hook: { getPaginated, queueLatestPublishedIdToFetch }
    }
  } = useData();
  const { classes, cx } = useStyles();
  const { localize } = useLocales();
  const { formControlColor } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [entitlements, setEntitlements] = useState<EntitlementType[]>(defaultEntitlements ?? []);
  const [collections, setCollections] = useState<ContentCollectionResponse[]>([]);
  const [inputValue, setInputValue] = useState<string>();
  const collection = useRecoilValue(withLatestPublishedRecordById(value));

  useEffect(() => {
    if (!collection && value) {
      queueLatestPublishedIdToFetch(value);
    }
  }, [value]);

  useEffect(() => {
    debouncedGetCollections(inputValue);
  }, [inputValue]);

  const getCollections = async (search = '') => {
    setIsLoading(true);
    const response = await getPaginated({ search, isLatestPublishedRevision: true, ...filters });
    setIsLoading(false);
    if (response) {
      setCollections(response.results);
    }
  };

  const onValueChange = (event: SyntheticEvent, changedValue: ContentCollectionResponse | null) => {
    if (event && event.type === 'click') {
      onChange(changedValue?.entityId ?? '');
      setInputValue('');
    }
  };

  const onInputChange = (event: SyntheticEvent, newValue: string) => {
    if (!event) return;
    if (event.type === 'change' && newValue !== inputValue) {
      setInputValue(newValue);
    } else {
      setInputValue('');
    }
  };

  const debouncedGetCollections = useCallback(debounce(getCollections, 500), []);

  return (
    <Stack direction="row" gap={2} className={collectionPickerClasses.root}>
      <Autocomplete
        role="listbox"
        options={collections}
        clearOnBlur
        blurOnSelect
        loading={isLoading}
        className={classes.autocomplete}
        filterOptions={(options) => options}
        getOptionLabel={(option) => localize(option.title)}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            className={cx(props.className, classes.option)}
            key={option?.entityId || 'none'}
            data-option={option?.entityId || null}
            data-testid={collectionPickerTestIds.option}
          >
            <CollectionLabel collectionId={option.entityId} className={classes.label} />
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className={collectionPickerClasses.input}
            name="collection"
            label={markAsRequired(label, !!required)}
            color={formControlColor}
            error={error}
            helperText={helperText}
          />
        )}
        value={collection || null}
        onChange={onValueChange}
        onInputChange={onInputChange}
        isOptionEqualToValue={(option, value) => option.entityId === value.entityId}
        data-testid={collectionPickerTestIds.autocomplete}
      />
      {!hideEntitlements && (
        <EntitlementsFilter
          data-testid={collectionPickerTestIds.entitlements}
          entitlements={entitlements}
          setEntitlements={setEntitlements}
        />
      )}
    </Stack>
  );
}
