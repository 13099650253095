import React from 'react';
import { WatchListUiModuleBody } from '../../../../../API';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { useLocales } from '../../../../../hooks';
import Localized from '../../../../shared/Localized';
import { Chip, Typography } from '@mui/material';
import { getContentTreatmentIcon } from '../../../../../utils/uiModuleUtils';
import { toTitleCase } from '../../../../../utils/textUtils';
import { watchListListItemTestIds } from '../../../../shared/TestsIds';

export type WatchListListItemProps = IBaseUIModuleListItemProps<WatchListUiModuleBody>;

const WatchListListItem = ({ uiModule, ...props }: WatchListListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')};
          </Typography>
          <Chip
            icon={getContentTreatmentIcon(uiModule.contentTreatment)}
            label={toTitleCase(uiModule.contentTreatment)}
            size="small"
            data-testid={watchListListItemTestIds.treatment}
          />
        </>
      ]}
      {...props}
    />
  );
};

export default WatchListListItem;
