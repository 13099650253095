import React from 'react';
import { Chip, Typography } from '@mui/material';
import { SportsEventUiModuleBody, UiModuleSportsEventType } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import { getContentTreatmentIcon } from '../../../../../utils/uiModuleUtils';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { SportsPreview } from '../../ContentPreview/SportsPreview';
import { LeaguesBadge } from '../../../../Sports/LeaguesBadge';
import { SportsBadge } from '../../../../Sports/SportsBadge';
import { sportsEventListItemTestIds } from '../../../../shared/TestsIds';

export type SportsEventListItemProps = IBaseUIModuleListItemProps<SportsEventUiModuleBody>;

const SportsEventListItem = ({ uiModule, ...props }: SportsEventListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')}:
          </Typography>
          <Chip
            icon={getContentTreatmentIcon(uiModule.contentTreatment)}
            label={toTitleCase(uiModule.contentTreatment)}
            size="small"
            data-testid={sportsEventListItemTestIds.treatment}
          />
        </>
      ]}
      collectionLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('sports.events')}:
          </Typography>
          <Chip
            label={toTitleCase(uiModule.sportsEventType)}
            size="small"
            data-testid={sportsEventListItemTestIds.sportsEventType}
          />
        </>,
        uiModule.sportsEventType !== UiModuleSportsEventType.CURATED && (
          <>
            <Typography variant="body2" color="textSecondary">
              {t('sports.sport')}:
            </Typography>
            <SportsBadge sportIds={uiModule.sportIds} data-testid={sportsEventListItemTestIds.sportId} />
          </>
        ),
        uiModule.sportsEventType !== UiModuleSportsEventType.CURATED && (
          <>
            <Typography variant="body2" color="textSecondary">
              {t('sports.league')}:
            </Typography>
            <LeaguesBadge
              leagueIds={uiModule.leagueIds}
              data-testid={sportsEventListItemTestIds.leagueId}
              useTournamentId
            />
          </>
        )
      ]}
      renderPreview={(showPreview) => (
        <div data-testid={sportsEventListItemTestIds.preview}>
          <SportsPreview
            eventsType={uiModule.sportsEventType}
            sportIds={uiModule.sportIds}
            leagueIds={uiModule.leagueIds}
            matchIds={uiModule.contents}
            showing={showPreview}
          />
        </div>
      )}
      {...props}
    />
  );
};

export default SportsEventListItem;
