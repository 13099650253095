import { Sell } from '@mui/icons-material';
import { Chip, ChipProps, Tooltip } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { useLocales } from '../../../../hooks';
import { IdBadge } from '../../../shared/IdBadge';
import CouponDiscount from '../CouponDiscount/CouponDiscount';
import CouponDuration from '../CouponDuration/CouponDuration';
import { couponBadgeTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    fontSize: '1.2em',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center'
  }
}));

export interface ICouponBadgeProps extends ChipProps {
  couponCode: string;
}

export default function CouponBadge({ couponCode, ...props }: ICouponBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { coupons } = useData();
  const coupon = useRecoilValue(coupons.state.withRecordById(couponCode));

  return (
    <>
      {coupon && (
        <Tooltip
          title={
            <div className={classes.tooltip} data-testid={couponBadgeTestIds.tooltip}>
              {coupon.code && (
                <div className={classes.row}>
                  <b>{t('payments.code')}:</b>
                  <IdBadge id={coupon.code} forceWhiteForeground />
                </div>
              )}
              <div className={classes.row}>
                <b>{t('payments.state')}:</b>
                {t(`payments.coupon_status.${coupon.state}`)}
              </div>
              <div className={classes.row}>
                <b>{t('payments.coupon_type')}:</b>
                {t(`payments.coupon_types.${coupon.couponType}`)}
              </div>
              <div className={classes.row}>
                <b>{t('payments.discount')}:</b>
                <CouponDiscount coupon={coupon} />
              </div>
              <div className={classes.row}>
                <b>{t('payments.duration')}:</b>
                <CouponDuration coupon={coupon} />
              </div>
            </div>
          }
          placement="top"
          arrow
        >
          <Chip icon={<Sell fontSize="small" />} data-testid={couponBadgeTestIds.chip} label={coupon.name} {...props} />
        </Tooltip>
      )}
    </>
  );
}
