import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '../../shared/Button';
import { useAuth, useLocales, useNotifications, useTheme } from '../../../hooks';
import { initializeApp } from 'firebase/app';
import { getAuth, UserCredential, signInWithEmailAndPassword } from 'firebase/auth';
import firebaseConfig from '../../../common/config/firebaseConfig.json';
import { Email, Lock } from '@mui/icons-material';
import Login from '..';
import { fieldErrorsToArray, isFormValid as isFormValidHelper } from '../../../utils/formHelpers';
import TextValidator from '../../shared/TextValidator';
import { automationFormTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  }
}));

interface LoginCreds {
  Email: string;
  Password: string;
}

export default function AutomationForm(): JSX.Element {
  const { classes } = useStyles();
  const { login } = useAuth();
  const { formControlColor } = useTheme();
  const [isSubmitting, setSubmitting] = useState(false);
  const { notifyError } = useNotifications();
  const { t } = useLocales();

  const formRef = React.useRef<ValidatorForm>(null);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<LoginCreds>({
    defaultValues: { Email: '', Password: '' }
  });

  const onSubmit: SubmitHandler<LoginCreds> = async ({ Email, Password }) => {
    const valid = await isFormValid();
    if (valid) {
      setSubmitting(true);
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      try {
        const signInResult: UserCredential = await signInWithEmailAndPassword(auth, Email, Password);
        const idToken = await signInResult.user.getIdToken();
        await handleLoginSuccess(signInResult.user.email || '', idToken || '');
      } catch (error: unknown) {
        handleLoginFailure([(error as Error).message]);
      }
    }
  };

  const isFormValid = async () => {
    if (formRef && formRef.current) {
      const valid = await isFormValidHelper(formRef);
      if (!valid) notifyError(t('general.form_error'), fieldErrorsToArray(errors));
      return valid;
    }
    return false;
  };

  const handleLoginSuccess = async (email: string, token?: string) => {
    if (!token || !email) {
      handleLoginFailure();
      return;
    }

    await login({ email, token }, 'firebase');
    setSubmitting(false);
  };

  const handleLoginFailure = (errorDetails?: string | string[]) => {
    notifyError(t('auth.login_error'), errorDetails);
    setSubmitting(false);
  };

  return (
    <Login>
      <ValidatorForm
        className={classes.formBody}
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        data-testid={automationFormTestIds.validatorForm}
      >
        <Controller
          name="Email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextValidator
              fullWidth
              color={formControlColor}
              name="email"
              variant="outlined"
              label={t('users.email')}
              value={value}
              validators={['required', 'isEmail']}
              errorMessages={[t('general.field_is_required'), t('users.email_valid')]}
              onChange={onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                )
              }}
              data-testid={automationFormTestIds.emailInput}
            />
          )}
        />
        <Controller
          name="Password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextValidator
              color={formControlColor}
              fullWidth
              name="password"
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              onChange={onChange}
              value={value}
              validators={['required']}
              errorMessages={[t('general.field_is_required')]}
              data-testid={automationFormTestIds.passwordInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                )
              }}
            />
          )}
        />

        <Button
          name="Login"
          loading={isSubmitting}
          disabled={isSubmitting}
          data-testid={automationFormTestIds.loginButton}
          type="submit"
        >
          Login
        </Button>
      </ValidatorForm>
    </Login>
  );
}
