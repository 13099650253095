import React, { forwardRef } from 'react';
import { AccountLoadingUiModuleBody } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { useFormContext } from 'react-hook-form';
import { FormBody } from '../../styles';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { accountLoadingFormTestIds } from '../../../../shared/TestsIds';

const AccountLoadingForm = forwardRef<UIModuleTypeForm<AccountLoadingUiModuleBody>>(() => {
  const { control } = useFormContext<AccountLoadingUiModuleBody>();

  return (
    <FormBody data-testid={accountLoadingFormTestIds.formBody}>
      <div>
        <LokaliseField
          name="accountLoading"
          control={control}
          toTranslate="layouts.account_loading.fields"
          dataTestId={accountLoadingFormTestIds.formField('accountLoading')}
          required
        />
      </div>
    </FormBody>
  );
});

AccountLoadingForm.displayName = 'AccountLoadingForm';

export default AccountLoadingForm;
