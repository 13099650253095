import React from 'react';
import { CallToAction, CropLandscape, CropPortrait } from '@mui/icons-material';
import {
  AllowedTargetId,
  ContentListUIModuleContentTreatment,
  ContinueWatchingUIModuleContentTreatment,
  HeroDataPresetDataBody,
  HeroItemChannelDataPresetBody,
  HeroItemCollectionLinkDataPresetBody,
  HeroItemEventsDataPresetBody,
  HeroItemPageDataPresetBody,
  HeroItemPromotionDataPresetBody,
  HeroItemVodDataPresetBody,
  DataPresetType,
  HeroModuleItemType,
  HeroUiModuleChannelItem,
  HeroUiModuleCollectionLinkItem,
  HeroUiModuleEventsItem,
  HeroUiModulePageItem,
  HeroUiModulePromotionItem,
  HeroUiModuleVodItem,
  LiveNewsUIModuleContentTreatment,
  RecommendationsUIModuleContentTreatment,
  SportsEventUIModuleContentTreatment,
  UiModulePageListTreatment,
  WatchListUIModuleContentTreatment,
  MixedListUIModuleContentTreatment,
  HeroUiModuleClipsItem,
  BecauseYouUIModuleContentTreatment
} from '../API';
import { isChannel, isMatch } from './mediaUtilities';
import { HeroUiModuleItem } from '../state/Layouts';

type ContentTreatment =
  | SportsEventUIModuleContentTreatment
  | ContentListUIModuleContentTreatment
  | ContinueWatchingUIModuleContentTreatment
  | WatchListUIModuleContentTreatment
  | RecommendationsUIModuleContentTreatment;

export function getContentTreatmentIcon(
  treatment: ContentTreatment | MixedListUIModuleContentTreatment | BecauseYouUIModuleContentTreatment
): JSX.Element {
  switch (treatment) {
    case 'LANDSCAPE':
    case 'LANDSCAPE_WITH_SUBTITLE':
      return <CropLandscape />;
    case 'PORTRAIT':
    case 'PORTRAIT_LARGE':
      return <CropPortrait />;
    default:
      return <CallToAction />;
  }
}

export function getPageListTreatmentIcon(treatment: UiModulePageListTreatment): JSX.Element {
  switch (treatment) {
    case UiModulePageListTreatment.HORIZONTAL_BUTTONS:
    case UiModulePageListTreatment.HORIZONTAL_LOGO_LIST:
      return <CropLandscape />;
    case UiModulePageListTreatment.VERTICAL_LIST:
      return <CropPortrait />;
    default:
      return <CallToAction />;
  }
}

export function getChannelsTreatmentIcon(treatment: LiveNewsUIModuleContentTreatment): JSX.Element {
  switch (treatment) {
    case LiveNewsUIModuleContentTreatment.LANDSCAPE:
      return <CropLandscape />;
    default:
      return <CallToAction />;
  }
}

export function isPagePath(path: string): boolean {
  return path.startsWith('/');
}

export type NewHeroPresetTypes =
  | HeroItemChannelDataPresetBody
  | HeroItemCollectionLinkDataPresetBody
  | HeroItemVodDataPresetBody
  | HeroItemEventsDataPresetBody
  | HeroItemPageDataPresetBody
  | HeroItemPromotionDataPresetBody;

export type AllHeroPresetTypes = HeroDataPresetDataBody | NewHeroPresetTypes;

export const getContentTypeFromHeroPreset = (presetData: AllHeroPresetTypes): HeroModuleItemType =>
  (presetData as NewHeroPresetTypes).heroItemType ||
  getHeroModuleTypeFromContentId((presetData as HeroDataPresetDataBody).contentId);

export const getContentIdFromHeroPreset = (presetData: AllHeroPresetTypes): string =>
  (presetData as HeroDataPresetDataBody).contentId || getContentIdFromHeroItem(presetData as NewHeroPresetTypes);

export function isPromoPagePath(pagePath: string): boolean {
  return [AllowedTargetId.VIXPLUS, AllowedTargetId.SUSCRIPCION, AllowedTargetId.SUSCRIPCION_PLANES]
    .map((targetId) => String(targetId))
    .includes(pagePath);
}

export const getHeroModuleTypeFromItem = (heroModuleItem: HeroUiModuleItem): HeroModuleItemType => {
  return heroModuleItem.heroItemType;
};

export const getHeroModuleTypeFromContentId = (contentId: string | undefined): HeroModuleItemType => {
  if (!contentId) {
    return HeroModuleItemType.VOD;
  }

  if (isChannel(contentId)) {
    return HeroModuleItemType.CHANNEL;
  }
  if (isMatch(contentId)) {
    return HeroModuleItemType.EVENTS;
  }
  if (isPagePath(contentId)) {
    // No way to know from just the content ID if this is a PAGE, PROMOTION, or COLLECTION_LINK
    return HeroModuleItemType.PAGE;
  }
  return HeroModuleItemType.VOD;
};

export const getPresetTypeFromHeroModuleType = (heroModuleType: HeroModuleItemType): DataPresetType => {
  switch (heroModuleType) {
    case HeroModuleItemType.CHANNEL:
      return DataPresetType.HERO_ITEM_CHANNEL;
    case HeroModuleItemType.EVENTS:
      return DataPresetType.HERO_ITEM_EVENTS;
    case HeroModuleItemType.PAGE:
      return DataPresetType.HERO_ITEM_PAGE;
    case HeroModuleItemType.COLLECTION_LINK:
      return DataPresetType.HERO_ITEM_COLLECTION_LINK;
    case HeroModuleItemType.PROMOTION:
      return DataPresetType.HERO_ITEM_PROMOTION;
    case HeroModuleItemType.CLIPS:
      return DataPresetType.HERO_ITEM_CLIPS;
    case HeroModuleItemType.VOD:
    default:
      return DataPresetType.HERO_ITEM_VOD;
  }
};

export const getHeroContentTypeFromPresetType = (presetType: DataPresetType): HeroModuleItemType => {
  switch (presetType) {
    case DataPresetType.HERO_ITEM_CHANNEL:
      return HeroModuleItemType.CHANNEL;
    case DataPresetType.HERO_ITEM_EVENTS:
      return HeroModuleItemType.EVENTS;
    case DataPresetType.HERO_ITEM_PAGE:
      return HeroModuleItemType.PAGE;
    case DataPresetType.HERO_ITEM_COLLECTION_LINK:
      return HeroModuleItemType.COLLECTION_LINK;
    case DataPresetType.HERO_ITEM_PROMOTION:
      return HeroModuleItemType.PROMOTION;
    case DataPresetType.HERO_ITEM_CLIPS:
      return HeroModuleItemType.CLIPS;
    case DataPresetType.HERO_ITEM_VOD:
    default:
      return HeroModuleItemType.VOD;
  }
};

export function isVodHeroItem(heroItem: HeroUiModuleItem): heroItem is HeroUiModuleVodItem {
  return heroItem.heroItemType === HeroModuleItemType.VOD;
}

export function isChannelHeroItem(heroItem: HeroUiModuleItem): heroItem is HeroUiModuleChannelItem {
  return heroItem.heroItemType === HeroModuleItemType.CHANNEL;
}

export function isEventsHeroItem(heroItem: HeroUiModuleItem): heroItem is HeroUiModuleEventsItem {
  return heroItem.heroItemType === HeroModuleItemType.EVENTS;
}

export function isPageHeroItem(heroItem: HeroUiModuleItem): heroItem is HeroUiModulePageItem {
  return heroItem.heroItemType === HeroModuleItemType.PAGE;
}

export function isCollectionLinkHeroItem(heroItem: HeroUiModuleItem): heroItem is HeroUiModuleCollectionLinkItem {
  return heroItem.heroItemType === HeroModuleItemType.COLLECTION_LINK;
}

export function isPromotionHeroItem(heroItem: HeroUiModuleItem): heroItem is HeroUiModulePromotionItem {
  return heroItem.heroItemType === HeroModuleItemType.PROMOTION;
}

export function isClipsHeroItem(heroItem: HeroUiModuleItem): heroItem is HeroUiModuleClipsItem {
  return heroItem.heroItemType === HeroModuleItemType.CLIPS;
}

export function getContentIdFromHeroItem(heroItem: HeroUiModuleItem): string {
  if (isVodHeroItem(heroItem)) {
    return heroItem.contentId;
  }

  if (isChannelHeroItem(heroItem)) {
    return heroItem.channelId;
  }

  if (isEventsHeroItem(heroItem)) {
    return heroItem.sportsEventId;
  }

  if (isCollectionLinkHeroItem(heroItem)) {
    return heroItem.pagePath;
  }

  if (isPromotionHeroItem(heroItem)) {
    return heroItem.pagePath;
  }

  if (isPageHeroItem(heroItem)) {
    return heroItem.pagePath;
  }

  if (isClipsHeroItem(heroItem)) {
    return heroItem.pagePath;
  }

  return '';
}

export function setContentIdOnHeroItem(heroItem: HeroUiModuleItem, value: string): void {
  if (isVodHeroItem(heroItem)) {
    heroItem.contentId = value;
  }

  if (isChannelHeroItem(heroItem)) {
    heroItem.channelId = value;
  }

  if (isEventsHeroItem(heroItem)) {
    heroItem.sportsEventId = value;
  }

  if (isCollectionLinkHeroItem(heroItem)) {
    heroItem.pagePath = value;
  }

  if (isPageHeroItem(heroItem)) {
    heroItem.pagePath = value;
  }

  if (isPromotionHeroItem(heroItem)) {
    heroItem.pagePath = value;
  }

  if (isClipsHeroItem(heroItem)) {
    heroItem.pagePath = value;
  }
}
