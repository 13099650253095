import React, { useEffect } from 'react';
import { Allotment } from 'allotment';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../data-layer';
import { withEPGSplitPane } from '../../state/General';
import { useSplitPane } from '../../hooks/General/useSplitPane';
import EPGCategories from './EPGCategories';
import EPGCategoryForm from './EPGCategories/EPGCategoryForm';
import EPGRegionForm from './EPGCategories/EPGRegionForm';
import EPGCategoryChannels from './EPGCategoryChannels';
import EPGChannelForm from './EPGChannelForm/EPGChannelForm';
import EPGChannels from './EPGChannels';
import { epgTestIds } from '../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  leftColumn: {
    height: '100%',
    flexGrow: 2,
    flexBasis: 0,
    background: theme.palette.background.paper
  }
}));

function EPG(): JSX.Element {
  const { classes } = useStyles();
  const {
    categoryBundles: {
      hook: { getAll: getCategoryBundles }
    }
  } = useData();

  const { sizes, onChange: onSplitPaneChange } = useSplitPane(withEPGSplitPane);

  useEffect(() => {
    getCategoryBundles();
  }, []);

  return (
    <>
      <div data-testid={epgTestIds.componentRoot} className={classes.root}>
        <Allotment defaultSizes={sizes} onChange={onSplitPaneChange}>
          <Allotment.Pane minSize={320} maxSize={500} preferredSize="25%">
            <div className={classes.leftColumn}>
              <EPGCategories />
            </div>
          </Allotment.Pane>
          <Allotment.Pane minSize={450} preferredSize="37%">
            <EPGCategoryChannels />
          </Allotment.Pane>
          <Allotment.Pane minSize={470} preferredSize="37%">
            <EPGChannels />
          </Allotment.Pane>
        </Allotment>
      </div>
      <EPGChannelForm />
      <EPGCategoryForm />
      <EPGRegionForm />
    </>
  );
}

export default EPG;
