import { Permission, PermissionsGroupId } from '../API';
export const getPermissionsGroupsWithUpsertAccess = (permissionsGroup: PermissionsGroupId): Permission[] => {
  switch (permissionsGroup) {
    case PermissionsGroupId.AVOD:
      return [Permission.AVOD_ADMIN, Permission.AVOD_UPSERT];
    case PermissionsGroupId.SVOD:
      return [Permission.SVOD_ADMIN, Permission.SVOD_UPSERT];
    case PermissionsGroupId.SPORTS:
      return [Permission.SPORTS_ADMIN, Permission.SPORTS_UPSERT];
    case PermissionsGroupId.MONETIZATION:
      return [Permission.MONETIZATION_ADMIN, Permission.MONETIZATION_UPSERT];
    case PermissionsGroupId.NEWS:
      return [Permission.NEWS_ADMIN, Permission.NEWS_UPSERT];
    case PermissionsGroupId.MARKETING:
      return [Permission.MARKETING_ADMIN, Permission.MARKETING_UPSERT];
    case PermissionsGroupId.CLIP:
      return [Permission.CLIP_ADMIN, Permission.CLIP_UPSERT];
    default:
      return [];
  }
};

export const getPermissionsGroupsWithPublishAccess = (permissionsGroup: PermissionsGroupId): Permission[] => {
  switch (permissionsGroup) {
    case PermissionsGroupId.AVOD:
      return [Permission.AVOD_ADMIN, Permission.AVOD_PUBLISH];
    case PermissionsGroupId.SVOD:
      return [Permission.SVOD_ADMIN, Permission.SVOD_PUBLISH];
    case PermissionsGroupId.SPORTS:
      return [Permission.SPORTS_ADMIN, Permission.SPORTS_PUBLISH];
    case PermissionsGroupId.MONETIZATION:
      return [Permission.MONETIZATION_ADMIN, Permission.MONETIZATION_PUBLISH];
    case PermissionsGroupId.NEWS:
      return [Permission.NEWS_ADMIN, Permission.NEWS_PUBLISH];
    case PermissionsGroupId.MARKETING:
      return [Permission.MARKETING_ADMIN, Permission.MARKETING_PUBLISH];
    case PermissionsGroupId.CLIP:
      return [Permission.CLIP_ADMIN, Permission.CLIP_PUBLISH];
    default:
      return [];
  }
};

export const getPermissionsGroupsWithDeleteAccess = (permissionsGroup: PermissionsGroupId): Permission[] => {
  switch (permissionsGroup) {
    case PermissionsGroupId.AVOD:
      return [Permission.AVOD_ADMIN, Permission.AVOD_DELETE];
    case PermissionsGroupId.SVOD:
      return [Permission.SVOD_ADMIN, Permission.SVOD_DELETE];
    case PermissionsGroupId.SPORTS:
      return [Permission.SPORTS_ADMIN, Permission.SPORTS_DELETE];
    case PermissionsGroupId.MONETIZATION:
      return [Permission.MONETIZATION_ADMIN, Permission.MONETIZATION_DELETE];
    case PermissionsGroupId.NEWS:
      return [Permission.NEWS_ADMIN, Permission.NEWS_DELETE];
    case PermissionsGroupId.MARKETING:
      return [Permission.MARKETING_ADMIN, Permission.MARKETING_DELETE];
    case PermissionsGroupId.CLIP:
      return [Permission.CLIP_ADMIN, Permission.CLIP_DELETE];
    default:
      return [];
  }
};

export const getPermissionsGroupsWithReadAccess = (permissionsGroup: PermissionsGroupId): Permission[] => {
  switch (permissionsGroup) {
    case PermissionsGroupId.AVOD:
      return [Permission.AVOD_ADMIN, Permission.AVOD_READ];
    case PermissionsGroupId.SVOD:
      return [Permission.SVOD_ADMIN, Permission.SVOD_READ];
    case PermissionsGroupId.SPORTS:
      return [Permission.SPORTS_ADMIN, Permission.SPORTS_READ];
    case PermissionsGroupId.MONETIZATION:
      return [Permission.MONETIZATION_ADMIN, Permission.MONETIZATION_READ];
    case PermissionsGroupId.NEWS:
      return [Permission.NEWS_ADMIN, Permission.NEWS_READ];
    case PermissionsGroupId.MARKETING:
      return [Permission.MARKETING_ADMIN, Permission.MARKETING_READ];
    case PermissionsGroupId.CLIP:
      return [Permission.CLIP_ADMIN, Permission.CLIP_READ];
    default:
      return [];
  }
};
