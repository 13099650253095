import React from 'react';
import { Delete } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useFormContext } from 'react-hook-form';
import { useLocales } from '../../../../../hooks';
import IconButton from '../../../../shared/IconButton';
import InputController from '../../../../shared/InputController';
import { CouponResponse, PromotionResponse } from '../../../../../API';
import TextField from '../../../../shared/TextField';
import { markAsRequired } from '../../../../../utils/formHelpers';
import CountryPickerLite from '../../../../shared/CountryPickerLite';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../../data-layer';
import { Autocomplete, InputLabel } from '@mui/material';
import { IdBadge } from '../../../../shared/IdBadge';
import { subscriptionManagerCouponMappingItemTestIds } from '../../../../shared/TestsIds';

interface IOfferMappingSubManagerCoupinMappingItemProps {
  index: number;
  onDelete: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex'
  },
  rowLeft: {
    flexGrow: 1,
    marginRight: theme.spacing(6)
  },
  rowRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  formRow: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  countryInput: {
    width: 250
  },
  autocomplete: {
    display: 'flex'
  },
  codeInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  textField: {
    '&, & input': {
      cursor: 'pointer'
    },
    '& input': {
      minWidth: '0 !important'
    },
    '& .MuiInput-root': {
      paddingRight: '10px !important'
    }
  }
}));

export function SubscriptionManagerCouponMappingItem({
  index,
  onDelete,
  disabled
}: IOfferMappingSubManagerCoupinMappingItemProps): JSX.Element {
  const { t } = useLocales();
  const { coupons } = useData();
  const allCoupons = useRecoilValue(coupons.state.withAllRecords);
  const couponsById = useRecoilValue(coupons.state.withAllRecordsById);

  const [couponSelected, setCouponSelected] = React.useState<CouponResponse>();

  const { classes } = useStyles();
  const { control } = useFormContext<PromotionResponse>();

  return (
    <div className={classes.container} data-testid={subscriptionManagerCouponMappingItemTestIds.root}>
      <div className={classes.rowLeft}>
        <div className={classes.formRow}>
          {!!allCoupons && (
            <InputController
              name={`source.offer.subscriptionManagerCouponMappings.${index}.code`}
              rules={{ required: t('general.field_is_required') }}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                const coupon = couponsById[value];
                setCouponSelected(coupon);
                return (
                  <Autocomplete
                    className={classes.autocomplete}
                    multiple={false}
                    options={allCoupons}
                    getOptionLabel={(coupon) => coupon.name || coupon.code} // Used for searching
                    filterOptions={(options, params) => {
                      const searchTerm = params.inputValue.toLowerCase();
                      return options.filter(
                        (coupon) =>
                          coupon.name?.toLowerCase().includes(searchTerm) ||
                          coupon.code.toLowerCase().includes(searchTerm)
                      );
                    }}
                    disableClearable
                    blurOnSelect
                    fullWidth
                    renderOption={(props, coupon) => (
                      <li data-testid={subscriptionManagerCouponMappingItemTestIds.option} {...props}>
                        {coupon.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        label={markAsRequired(t('payments.coupons'), true)}
                        fieldError={error}
                      />
                    )}
                    value={coupon}
                    onChange={(_, changedValues) => {
                      const newSelectedCoupon = changedValues;
                      setCouponSelected(newSelectedCoupon);
                      onChange(newSelectedCoupon ? [newSelectedCoupon.code] : []);
                    }}
                    isOptionEqualToValue={(option, value) => option.code === value?.code}
                    disabled={disabled}
                    data-testid={subscriptionManagerCouponMappingItemTestIds.autocomplete}
                  />
                );
              }}
            />
          )}
          <InputController
            control={control}
            name={`source.offer.subscriptionManagerCouponMappings.${index}.codeReadOnly`}
            data-testid={subscriptionManagerCouponMappingItemTestIds.codeInput}
            render={({ field: { onChange }, fieldState: { error } }) =>
              couponSelected?.code ? (
                <div className={classes.codeInput}>
                  <InputLabel>{t('payments.offer_mapping.source_offer_mappings_code')}</InputLabel>
                  <IdBadge id={couponSelected?.code} />
                </div>
              ) : (
                <TextField
                  className={classes.codeInput}
                  value={couponSelected?.code}
                  disabled
                  fullWidth
                  onChange={onChange}
                  label={t('payments.offer_mapping.source_offer_mappings_code')}
                  fieldError={error}
                />
              )
            }
          />
        </div>
        <div className={classes.formRow}>
          <InputController
            name={`source.offer.subscriptionManagerCouponMappings.${index}.country`}
            rules={{ required: t('general.field_is_required') }}
            control={control}
            data-testid={subscriptionManagerCouponMappingItemTestIds.countryInput}
            render={({ field: { value, onChange } }) => {
              let initialValue: string[] = [];
              if (typeof value === 'string' && value.length > 0) {
                initialValue = [value];
              } else if (Array.isArray(value)) {
                initialValue = value;
              }

              return (
                <CountryPickerLite
                  className={classes.countryInput}
                  label={markAsRequired(t('payments.offer_mapping.source_offer_mappings_country'))}
                  value={initialValue}
                  onChange={onChange}
                  hidePresets
                  required
                  singleSelection
                />
              );
            }}
          />
        </div>
      </div>
      <div className={classes.rowRight}>
        {index !== 0 && (
          <IconButton
            onClick={onDelete}
            title={t('payments.offer_mapping.delete_item')}
            size="large"
            data-testid={subscriptionManagerCouponMappingItemTestIds.deleteItemButton}
            disabled={disabled}
          >
            <Delete />
          </IconButton>
        )}
      </div>
    </div>
  );
}
