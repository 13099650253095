import React, { forwardRef } from 'react';
import { WhoIsWatchingUiModuleBody, WhoIsWatchingUiModuleBodyCtaMain } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useLocales, useTheme } from '../../../../../hooks';
import { FormBody, FormField } from '../../styles';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import generateId from '../../../../../utils/generateId';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { Sortable } from '../../../../shared/Sortable';
import { Add, Delete } from '@mui/icons-material';
import { LocaleKeys } from '../../../../../locales/i18n';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { whoIsWatchingFormTestIds } from '../../../../shared/TestsIds';

const WhoIsWatchingForm = forwardRef<UIModuleTypeForm<WhoIsWatchingUiModuleBody>>(() => {
  const { control } = useFormContext<WhoIsWatchingUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const {
    append: appendCtaMain,
    remove: removeCtaMain,
    fields: fieldsCtaMain,
    replace: replaceCtaMain
  } = useFieldArray({
    control,
    name: 'ctaMain'
  });

  const LokaliseFieldCtaMain = (
    name: keyof WhoIsWatchingUiModuleBodyCtaMain,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`ctaMain.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.who_is_watching.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={whoIsWatchingFormTestIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const onAddCtaMain = () => {
    appendCtaMain({
      id: generateId(),
      cta: ''
    });
  };

  return (
    <FormBody data-testid={whoIsWatchingFormTestIds.formBody}>
      <div>
        <LokaliseField
          name="headerMain"
          control={control}
          toTranslate="layouts.who_is_watching.fields"
          dataTestId={whoIsWatchingFormTestIds.formField('headerMain')}
          required
        />
        <LokaliseField
          name="subHeaderMain"
          control={control}
          toTranslate="layouts.who_is_watching.fields"
          dataTestId={whoIsWatchingFormTestIds.formField('subHeaderMain')}
          required
        />
      </div>
      <div>
        <Box>
          <Sortable
            list={fieldsCtaMain}
            setList={replaceCtaMain}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsCtaMain.map((cta, i) => (
              <Stack
                key={cta.id}
                data-testid={whoIsWatchingFormTestIds.ctaMain}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldCtaMain('cta', i, true)}
                <IconButton
                  title={t('layouts.who_is_watching.actions.remove_cta_main')}
                  onClick={() => removeCtaMain(i)}
                  sx={{ mt: 4 }}
                  data-testid={whoIsWatchingFormTestIds.ctaMainRemoveButton}
                  disabled={fieldsCtaMain.length === 1}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddCtaMain}
            data-testid={whoIsWatchingFormTestIds.ctaMainAddButton}
          >
            {t('layouts.who_is_watching.actions.add_cta_main')}
          </Button>
        </Box>
      </div>
    </FormBody>
  );
});

WhoIsWatchingForm.displayName = 'WhoIsWatchingForm';

export default WhoIsWatchingForm;
