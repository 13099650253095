import React, { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocales, useNotifications } from '../../../hooks';
import { List, MenuItem, Popover, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button } from '../../shared/Button/Button';
import { AddOutlined, MoreVertOutlined } from '@mui/icons-material';
import ShadowScroller from '../../shared/ShadowScroller';
import IconButton from '../../shared/IconButton';
import { Sortable } from '../../shared/Sortable';
import RevisionManager from '../../shared/RevisionManager';
import Repeat from '../../shared/Repeat';
import HistoryManager from '../../shared/HistoryManager';
import EPGRegionSelector from './EPGRegionSelector';
import useConfirm from '../../../hooks/General/useConfirm';
import { useData } from '../../../data-layer';
import { EPGCategoryListItem } from './EPGCategoryListItem';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import { EPGCategoryOrderingSelector } from './EPGCategoryOrderings';
import { withHistoryManager } from '../../../state/General/withHistoryManager';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { EPGCategoriesTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(4)
  },
  regionPanel: {
    display: 'flex',
    marginLeft: theme.spacing(4),
    alignItems: 'center',
    height: 64
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(4),
    gap: theme.spacing(2)
  },
  categoriesListContainer: {
    flexGrow: 1
  },
  categoriesList: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: 0
  },
  skeletonCategory: {
    height: theme.spacing(6),
    margin: theme.spacing(2, 3)
  },
  footer: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}));

function EPGCategories(): JSX.Element {
  const { canDelete, canPublish, canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.EPG
  });
  const { classes } = useStyles();
  const { t } = useLocales();
  const navigate = useNavigate();
  const { notifyError } = useNotifications();
  const { confirm, confirmUnsavedChanges } = useConfirm();
  const {
    categoryBundles: {
      hook: {
        new: newCategoryBundle,
        clone: cloneCategoryBundle,
        edit: editCategoryBundle,
        remove: deleteCategoryBundle,
        getRevisions,
        update: updateCategoryBundle,
        publish: publishCategoryBundle
      },
      state: {
        withAllRecords: withAllCategoryBundles,
        withSelectedId: withSelectedCategoryBundleId,
        withSelected: withSelectedCategoryBundle,
        withIsSaving: withIsSavingCategoryBundle,
        withIsPublishing: withIsPublishingCategoryBundle,
        withSelectedCategoryBundleCategories
      }
    },
    categories: {
      hook: { new: newCategory }
    }
  } = useData();
  const [regionMenuAnchorEl, setRegionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const categoryBundles = useRecoilValue(withAllCategoryBundles);
  const selectedCategoryBundleId = useRecoilValue(withSelectedCategoryBundleId);
  const [selectedCategoryBundle, setSelectedCategoryBundle] = useRecoilState(withSelectedCategoryBundle);
  const isSaving = useRecoilValue(withIsSavingCategoryBundle);
  const isPublishing = useRecoilValue(withIsPublishingCategoryBundle);
  const [selectedBundleCategories, setSelectedBundleCategories] = useRecoilState(withSelectedCategoryBundleCategories);

  const { isClean: isHistoryManagerClean } = useRecoilValue(withHistoryManager);

  const AppRoutePath = AppRoutes.epg;

  const openRegionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRegionMenuAnchorEl(event.currentTarget);
  };

  const closeRegionMenu = () => {
    setRegionMenuAnchorEl(null);
  };

  const newRegion = async () => {
    const shallContinue = isHistoryManagerClean || (await confirmUnsavedChanges());
    if (shallContinue) {
      closeRegionMenu();
      newCategoryBundle();
    }
  };

  const editRegion = async () => {
    const shallContinue = isHistoryManagerClean || (await confirmUnsavedChanges());
    if (shallContinue) {
      closeRegionMenu();
      if (selectedCategoryBundleId) {
        editCategoryBundle(selectedCategoryBundleId);
      }
    }
  };

  const cloneRegion = async () => {
    const shallContinue = isHistoryManagerClean || (await confirmUnsavedChanges());
    if (shallContinue) {
      closeRegionMenu();
      if (selectedCategoryBundleId) {
        cloneCategoryBundle(selectedCategoryBundleId);
      }
    }
  };

  const deleteRegion = async () => {
    closeRegionMenu();
    if (!selectedCategoryBundle || !categoryBundles?.length) return;
    if (selectedCategoryBundle.countries.length) {
      return notifyError(`${t('delete_region_not_empty')} ${selectedCategoryBundle.countries.join(', ')}`);
    }

    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: t('delete_region_confirm'),
      'data-testid': EPGCategoriesTestIds.deleteRegionConfirmDialog
    });
    if (!result) return;

    await deleteCategoryBundle(selectedCategoryBundle.entityId);
    const newSelectedCategoryBundle = categoryBundles.filter(
      (item) => item.entityId !== selectedCategoryBundle.entityId
    )[0];
    navigate(AppRoutePath(newSelectedCategoryBundle?.entityId, newSelectedCategoryBundle?.categories[0]));
  };

  const saveSelectedCategoryBundle = async () => {
    if (!selectedCategoryBundle) return;
    await updateCategoryBundle(selectedCategoryBundleId, selectedCategoryBundle);
  };

  const publishSelectedCategoryBundle = async () => {
    if (!selectedCategoryBundle) return;
    await publishCategoryBundle(selectedCategoryBundleId);
  };

  const getSelectedCategoryBundleRevisions = useCallback(
    async (limit?: number, page?: number) => {
      return selectedCategoryBundle && getRevisions(selectedCategoryBundle.entityId, limit, page);
    },
    [selectedCategoryBundle, getRevisions]
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTop}>
          <Typography variant="h5">{t('categories')}</Typography>
          {selectedCategoryBundle && (
            <RevisionManager
              getRevisionsPromise={getSelectedCategoryBundleRevisions}
              state={selectedCategoryBundle}
              onChange={setSelectedCategoryBundle}
            />
          )}
        </div>
        <div className={classes.regionPanel}>
          <EPGRegionSelector />
          <div>
            <IconButton
              data-testid={EPGCategoriesTestIds.regionMenuButton}
              disabled={!categoryBundles}
              onClick={openRegionMenu}
              title={t('tooltips.options')}
              size="large"
            >
              <MoreVertOutlined />
            </IconButton>
            <Popover
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              anchorEl={regionMenuAnchorEl}
              keepMounted
              open={Boolean(regionMenuAnchorEl)}
              onClose={closeRegionMenu}
            >
              <MenuItem onClick={editRegion} data-testid={EPGCategoriesTestIds.editRegionButton}>
                {t('edit_region')}
              </MenuItem>
              <MenuItem onClick={cloneRegion} disabled={!canSave} data-testid={EPGCategoriesTestIds.cloneRegionButton}>
                {t('clone_region')}
              </MenuItem>
              <MenuItem onClick={newRegion} disabled={!canSave} data-testid={EPGCategoriesTestIds.newRegionButton}>
                {t('new_region')}
              </MenuItem>
              <MenuItem
                onClick={deleteRegion}
                disabled={!canDelete}
                data-testid={EPGCategoriesTestIds.deleteRegionButton}
              >
                {t('delete_region')}
              </MenuItem>
            </Popover>
          </div>
        </div>
        <div className={classes.controls}>
          <HistoryManager
            id="categories"
            state={selectedCategoryBundle}
            onChange={setSelectedCategoryBundle}
            saveCallback={saveSelectedCategoryBundle}
            publishCallback={publishSelectedCategoryBundle}
            hasPublishPermission={canPublish}
            hasUpsertPermission={canSave}
          />
        </div>
        <div>
          <EPGCategoryOrderingSelector />
        </div>
      </div>
      <div className={classes.categoriesListContainer} data-testid={EPGCategoriesTestIds.categoryList}>
        <ShadowScroller
          paper={true}
          saving={isSaving || isPublishing}
          loading={!categoryBundles || !selectedCategoryBundle}
        >
          {categoryBundles && selectedBundleCategories && (
            <List className={classes.categoriesList}>
              <Sortable
                list={selectedBundleCategories}
                disabled={!canSave || !canPublish}
                setList={setSelectedBundleCategories}
                animation={100}
                ghostClass="sortableGhost"
                dragClass="sortableDragPaper"
                isStringList
              >
                {selectedBundleCategories.map((categoryId: string) => (
                  <EPGCategoryListItem
                    key={categoryId}
                    categoryId={categoryId}
                    categoryBundleId={selectedCategoryBundleId}
                  />
                ))}
              </Sortable>
            </List>
          )}
          {(!categoryBundles || !selectedCategoryBundle) && (
            <Repeat n={15}>
              <Skeleton className={classes.skeletonCategory} animation="wave" />
            </Repeat>
          )}
        </ShadowScroller>
      </div>
      <div className={classes.footer}>
        <Button
          endIcon={<AddOutlined />}
          disabled={!canSave || !categoryBundles}
          onClick={newCategory}
          data-testid={EPGCategoriesTestIds.newCategoryButton}
        >
          {t('new_category')}
        </Button>
      </div>
    </div>
  );
}

export default EPGCategories;
