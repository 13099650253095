import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Collapse,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useLocales, useTheme } from '../../../hooks';
import { useData } from '../../../data-layer';
import { useRecoilValue } from 'recoil';
import { HeroPresetCollectionResponse } from '../../../API';
import { debounce } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { PresetListItem } from '../../HPC/PresetListItem';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import Localized from '../Localized';
import { HPCPickerTestIds } from '../TestsIds';

const useStyles = makeStyles()((theme) => ({
  preview: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 4),
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: theme.shape.borderRadius
  },
  previewLabel: {
    flexGrow: 1
  }
}));

type HPCPickerProps = {
  value: string | undefined;
  onChange?: (value: string | undefined) => unknown;
};

export function HPCPicker({ value, onChange }: HPCPickerProps): React.ReactElement {
  const {
    heroPresetCollections: {
      state: { withRecordById },
      hook: { queueIdToFetch, getPaginated }
    }
  } = useData();
  const { classes } = useStyles();
  const { t, localize } = useLocales();
  const { formControlColor } = useTheme();
  const collection = useRecoilValue(withRecordById(value));
  const [collections, setCollections] = useState<HeroPresetCollectionResponse[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    getInitialCollections();
  }, []);

  useEffect(() => {
    if (value && !collection) {
      queueIdToFetch(value);
    }
  }, [value, collection]);

  const getInitialCollections = () => {
    getCollections();
  };

  const getCollections = async (name = '') => {
    setIsLoading(true);
    const response = await getPaginated({ name });
    setIsLoading(false);
    if (response) {
      setCollections(response.results);
    }
  };

  const onValueChange = (event: SyntheticEvent, newValue: HeroPresetCollectionResponse | null) => {
    if (newValue === null) {
      onChange?.('');
    }
    if (newValue && event?.type === 'click') {
      onChange?.(newValue.entityId);
    }
  };

  const onInputChange = (event: SyntheticEvent, newInputValue: string) => {
    if (event && event.type !== 'click') {
      setInputValue(inputValue);
      if (event.type === 'blur') {
        getInitialCollections();
      } else {
        getCollectionsDebounced(newInputValue);
      }
    }
  };

  const getCollectionsDebounced = debounce(getCollections, 500);

  const toggleExpanded = () => setIsExpanded((prevValue) => !prevValue);

  return (
    <>
      <Autocomplete
        role="listbox"
        onOpen={() => setInputValue('')}
        loading={isLoading}
        loadingText={t('general.loading')}
        data-testid={HPCPickerTestIds.autocomplete}
        clearOnBlur
        options={collections}
        isOptionEqualToValue={(option, value) => option.entityId === value.entityId}
        value={collection || null}
        onChange={onValueChange}
        onInputChange={onInputChange}
        filterOptions={(options) => options}
        getOptionLabel={(option) => localize(option?.name)}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.entityId}
            data-testid={HPCPickerTestIds.option}
            data-option={option.entityId}
          >
            <Localized prop={option.name} />
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('hpc.hero_preset_collection')}
            color={formControlColor}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} data-testid={HPCPickerTestIds.loader} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      {collection && (
        <div className={classes.preview} data-testid={HPCPickerTestIds.preview}>
          <Stack direction="row">
            <FormControlLabel
              data-testid={HPCPickerTestIds.previewLabel}
              className={classes.previewLabel}
              onClick={toggleExpanded}
              label={
                <Typography style={{ fontStyle: 'italic' }} color="textSecondary">
                  {t('hpc.contents')}
                </Typography>
              }
              control={isExpanded ? <ExpandLess /> : <ExpandMore />}
            />
            <Typography data-testid={HPCPickerTestIds.previewCount}>
              {t('hpc.presets', { count: collection.presets.length })}
            </Typography>
          </Stack>
          <Collapse in={isExpanded}>
            {collection?.presets.map((preset) => (
              <PresetListItem key={preset} presetId={preset} displayAs={DisplayAsOptions.LIST} previewMode />
            ))}
            {!collection?.presets.length && <Typography color="textSecondary">{t('hpc.no_contents')}</Typography>}
          </Collapse>
        </div>
      )}
    </>
  );
}
