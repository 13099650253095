import React from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { MenuItemUiModuleBody, MenuSectionUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import LokaliseFallback from '../../../../shared/LokaliseFallback';
import { CustomIcon } from '../../../../shared/CustomIcon';
import { menuIconPaths, menuIconViewbox } from '../../../../../utils/menuIcons';
import { menuListItemTestIds } from '../../../../shared/TestsIds';

export type MenuModuleBody = MenuItemUiModuleBody | MenuSectionUiModuleBody;

export interface MenuListItemProps extends IBaseUIModuleListItemProps<MenuModuleBody> {
  menuSectionIndex?: number;
}

const MenuListItem = ({ uiModule, index, isEditable, menuSectionIndex, ...props }: MenuListItemProps): JSX.Element => {
  const { t } = useLocales();

  const { lt } = useLokalise();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      menuSectionIndex={menuSectionIndex}
      index={index}
      isEditable={isEditable}
      topLineTitle={
        <Stack direction="row" gap={3} alignItems="center">
          {uiModule.iconName && menuIconPaths[uiModule.iconName] && (
            <Box sx={{ width: 22, display: 'flex' }}>
              <CustomIcon pathString={menuIconPaths[uiModule.iconName] as string} viewBox={menuIconViewbox} />
            </Box>
          )}
          {uiModule?.titleLokaliseKey ? (
            <span>{lt(uiModule?.titleLokaliseKey)}</span>
          ) : (
            <LokaliseFallback fallback={uiModule?.title} />
          )}
        </Stack>
      }
      topLine={[
        uiModule.targetId && (
          <>
            <Typography variant="body2" color="textSecondary">
              {t('layouts.target')}:
            </Typography>
            <Chip label={uiModule.targetId} size="small" data-testid={menuListItemTestIds.targetId} />
          </>
        )
      ]}
      {...props}
    />
  );
};

export default MenuListItem;
