/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, RefObject } from 'react';
import MaterialButton, { ButtonProps } from '@mui/material/Button';
import { Box, lighten, PropTypes, Tooltip } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { buttonTestIds } from '../TestsIds';

type customColorsType = 'primary' | 'secondary' | 'error' | 'warning' | 'grey';

export const customColors: customColorsType[] = ['primary', 'secondary', 'error', 'warning', 'grey'];

export type ButtonColor = Exclude<PropTypes.Color, 'default'> | customColorsType;

export interface IButtonProps extends Omit<ButtonProps, 'color'> {
  color?: ButtonColor;
  loading?: boolean;
  tooltip?: string;
}

const useStyles = makeStyles()((theme) => ({
  ...customColors.reduce((obj: any, color) => {
    obj[color] = {
      '&.MuiButton-contained': {
        borderTop: '1px solid transparent',
        '&:not(:disabled)': {
          color: theme.palette.getContrastText(theme.palette[color].main),
          backgroundColor: theme.palette[color].main,
          borderTopColor: `${lighten(theme.palette[color].main, 0.2)} !important`
        },
        '&:hover': {
          borderTopColor: lighten(theme.palette[color].main, 0.25),
          backgroundColor: lighten(theme.palette[color].main, 0.1)
        },
        '&.Mui-disabled': {
          opacity: 0.6
        }
      },
      '&.MuiButton-text': {
        color: theme.palette[color].main
      },
      '&.MuiButton-outlined': {
        color: theme.palette[color].main,
        borderColor: theme.palette[color].main
      }
    };
    return obj;
  }, {})
}));

export const Button = forwardRef(
  (
    {
      disabled,
      className = '',
      children,
      loading,
      color = 'primary',
      variant = 'contained',
      tooltip,
      ...props
    }: IButtonProps,
    ref?
  ): React.ReactElement => {
    const { classes } = useStyles();
    const isCustomColor = customColors.find((c) => c === color);

    const getLoadingSpinnerSize = (size: typeof props.size): number => {
      switch (size) {
        case 'small':
          return 20;
        case 'large':
          return 32;
        case 'medium':
        default:
          return 24;
      }
    };

    if (isCustomColor) {
      className += ' ' + (classes as any)[isCustomColor];
    }
    const isDisabled = disabled || loading;
    const button = (
      <MaterialButton
        variant={variant}
        color="grey"
        disabled={isDisabled}
        className={className}
        data-testid={buttonTestIds.button}
        disableElevation
        {...props}
        ref={ref as unknown as RefObject<HTMLButtonElement>}
      >
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', color: loading ? 'transparent' : 'unset' }}>{children}</Box>
          {loading && (
            <Box sx={{ position: 'absolute', top: 0, right: 0, left: 0 }}>
              <CircularProgress
                size={getLoadingSpinnerSize(props.size)}
                color="inherit"
                data-testid={buttonTestIds.spinner}
              />
            </Box>
          )}
        </Box>
      </MaterialButton>
    );

    if (tooltip && !disabled) {
      return (
        <Tooltip placement="top" arrow title={tooltip}>
          {button}
        </Tooltip>
      );
    }

    return button;
  }
);
Button.displayName = 'Button';
