import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { BackgroundBody, SpinPremiaUiModuleBody } from '../../../../../API';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { useLocales, useTheme } from '../../../../../hooks';
import { Controller, useFormContext } from 'react-hook-form';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { FormControlLabel, Switch } from '@mui/material';
import generateId from '../../../../../utils/generateId';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { spinPremiaFormTestIds } from '../../../../shared/TestsIds';

type SpinPremiaFieldProps = {
  name: keyof SpinPremiaUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
};
interface SpinPremiaBackgroundFieldProps extends Omit<SpinPremiaFieldProps, 'name'> {
  name: keyof BackgroundBody;
  required?: boolean;
  'data-testid'?: string;
}

const SpinPremiaForm = forwardRef<UIModuleTypeForm<SpinPremiaUiModuleBody>>(() => {
  const { control, watch } = useFormContext<SpinPremiaUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: SpinPremiaFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.spin_premia.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={spinPremiaFormTestIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const BackgroundAssetPicker = ({
    name,
    assetType,
    required = false
  }: SpinPremiaBackgroundFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={`background.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.spin_premia.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={spinPremiaFormTestIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const ToggleField = ({
    name
  }: {
    name: keyof Pick<SpinPremiaUiModuleBody, 'planTypeToggle' | 'checkbox'>;
  }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          control={<Switch color={formControlColor} checked={value} onChange={onChange} />}
          label={t(`layouts.spin_premia.fields.${name}`)}
        />
      )}
    />
  );

  return (
    <FormBody data-testid={spinPremiaFormTestIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.spin_premia.headers.logos')}</SectionHeader>
        <AssetPicker name="vixLogo" assetType={AssetTypes.spinPremia.vixLogo} required />
        <AssetPicker name="spinLogo" assetType={AssetTypes.spinPremia.spinLogo} required />
      </div>
      <div>
        <SectionHeader>{t('layouts.spin_premia.headers.background')}</SectionHeader>
        <BackgroundAssetPicker name="portraitFillImage" assetType={AssetTypes.spinPremia.portrait} />
        <BackgroundAssetPicker name="landscapeFillImage" assetType={AssetTypes.spinPremia.landscape} />
        <BackgroundAssetPicker name="mobileFillImage" assetType={AssetTypes.spinPremia.mobile} />
        <BackgroundAssetPicker name="ctvFillImage" assetType={AssetTypes.spinPremia.ctv} />
        <BackgroundAssetPicker name="desktopFillImage" assetType={AssetTypes.spinPremia.desktop} />
        <BackgroundAssetPicker name="tabletFillImage" assetType={AssetTypes.spinPremia.tablet} />
        <FormField>
          <Controller
            control={control}
            name={'background.id'}
            defaultValue={generateId()}
            render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
          />
        </FormField>
      </div>
      <div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.custom_plan_picker')}</SectionHeader>
          <LokaliseField
            name="planPickerHeader"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planPickerHeader')}
            required
          />
          <LokaliseField
            name="planPickerSubHeader"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planPickerSubHeader')}
          />
          <LokaliseField
            name="planTypeOpt1"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planTypeOpt1')}
          />
          <LokaliseField
            name="planTypeOpt2"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planTypeOpt2')}
          />
          <LokaliseField
            name="planName"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planName')}
          />
          <LokaliseField
            name="planCostPoint"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planCostPoint')}
          />
          <LokaliseField
            name="planDescription"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planDescription')}
          />
          <LokaliseField
            name="planValueProp"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planValueProp')}
          />
          <LokaliseField
            name="planValuePropSeeMoreCta"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planValuePropSeeMoreCta')}
          />
          <LokaliseField
            name="planValuePropSeeLessCta"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planValuePropSeeLessCta')}
          />
          <LokaliseField
            name="planCta"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('planCta')}
          />
          <LokaliseField
            name="legalCopy"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('legalCopy')}
            required
          />
          <ToggleField name="planTypeToggle" />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.otp1')}</SectionHeader>
          <LokaliseField
            name="otp1Header"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('otp1Header')}
            required
          />
          <LokaliseField
            name="otp1SubHeader"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('otp1SubHeader')}
          />
          <LokaliseField
            name="opt1CountryCode"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('opt1CountryCode')}
            required
          />
          <LokaliseField
            name="opt1MobileHint"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('opt1MobileHint')}
            required
          />
          <LokaliseField
            name="opt1Cta"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('opt1Cta')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.otp2')}</SectionHeader>
          <LokaliseField
            name="otp2Header"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('otp2Header')}
            required
          />
          <LokaliseField
            name="otp2SubHeader"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('otp2SubHeader')}
          />
          <LokaliseField
            name="opt2EditNumber"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('opt2EditNumber')}
            required
          />
          <LokaliseField
            name="opt2ExpirationNotice"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('opt2ExpirationNotice')}
            required
          />
          <LokaliseField
            name="opt2ResendCode"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('opt2ResendCode')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.plan_summary')}</SectionHeader>
          <LokaliseField
            name="summaryHeader"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryHeader')}
            required
          />
          <LokaliseField
            name="summarySubHeader"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summarySubHeader')}
          />
          <LokaliseField
            name="summaryPlanName"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryPlanName')}
            required
          />
          <LokaliseField
            name="summaryPlanDescription"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryPlanDescription')}
          />
          <LokaliseField
            name="summaryPlanSku"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryPlanSku')}
            required
          />
          <LokaliseField
            name="summaryPlanPoints"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryPlanPoints')}
            required
          />
          <LokaliseField
            name="summaryPlanTotal"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryPlanTotal')}
            required
          />
          <LokaliseField
            name="summaryReminderHeader"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryReminderHeader')}
            required
          />
          <LokaliseField
            name="summaryStartDate"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryStartDate')}
            required
          />
          <LokaliseField
            name="summaryEndDate"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryEndDate')}
            required
          />
          <LokaliseField
            name="summaryLegalCopy"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryLegalCopy')}
            required
          />
          <LokaliseField
            name="summaryCta1"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryCta1')}
            required
          />
          <LokaliseField
            name="summaryCta2"
            control={control}
            toTranslate="layouts.spin_premia.fields"
            dataTestId={spinPremiaFormTestIds.formField('summaryCta2')}
            required
          />
          <ToggleField name="checkbox" />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.used_account_error')}</SectionHeader>
          <LokaliseField
            name="usedAccountError.header"
            control={control}
            label="layouts.spin_premia.labels.header"
            dataTestId={spinPremiaFormTestIds.formField('usedAccountError.header')}
            required
          />
          <LokaliseField
            name="usedAccountError.body"
            control={control}
            label="layouts.spin_premia.labels.body"
            dataTestId={spinPremiaFormTestIds.formField('usedAccountError.body')}
            required
          />
          <LokaliseField
            name="usedAccountError.fstCta"
            control={control}
            label="layouts.spin_premia.labels.fstCta"
            dataTestId={spinPremiaFormTestIds.formField('usedAccountError.fstCta')}
            required
          />
          <LokaliseField
            name="usedAccountError.sndCta"
            control={control}
            label="layouts.spin_premia.labels.sndCta"
            dataTestId={spinPremiaFormTestIds.formField('usedAccountError.sndCta')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.unregistered_number_error')}</SectionHeader>
          <LokaliseField
            name="unregisteredNumberError.header"
            control={control}
            label="layouts.spin_premia.labels.header"
            dataTestId={spinPremiaFormTestIds.formField('unregisteredNumberError.header')}
            required
          />
          <LokaliseField
            name="unregisteredNumberError.body"
            control={control}
            label="layouts.spin_premia.labels.body"
            dataTestId={spinPremiaFormTestIds.formField('unregisteredNumberError.body')}
            required
          />
          <LokaliseField
            name="unregisteredNumberError.fstCta"
            control={control}
            label="layouts.spin_premia.labels.fstCta"
            dataTestId={spinPremiaFormTestIds.formField('unregisteredNumberError.fstCta')}
            required
          />
          <LokaliseField
            name="unregisteredNumberError.sndCta"
            control={control}
            label="layouts.spin_premia.labels.sndCta"
            dataTestId={spinPremiaFormTestIds.formField('unregisteredNumberError.sndCta')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.funds_error')}</SectionHeader>
          <LokaliseField
            name="fundsError.header"
            control={control}
            label="layouts.spin_premia.labels.header"
            dataTestId={spinPremiaFormTestIds.formField('fundsError.header')}
            required
          />
          <LokaliseField
            name="fundsError.body"
            control={control}
            label="layouts.spin_premia.labels.body"
            dataTestId={spinPremiaFormTestIds.formField('fundsError.body')}
            required
          />
          <LokaliseField
            name="fundsError.fstCta"
            control={control}
            label="layouts.spin_premia.labels.fstCta"
            dataTestId={spinPremiaFormTestIds.formField('fundsError.fstCta')}
            required
          />
          <LokaliseField
            name="fundsError.sndCta"
            control={control}
            label="layouts.spin_premia.labels.sndCta"
            dataTestId={spinPremiaFormTestIds.formField('fundsError.sndCta')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.failed_error')}</SectionHeader>
          <LokaliseField
            name="failedError.header"
            control={control}
            label="layouts.spin_premia.labels.header"
            dataTestId={spinPremiaFormTestIds.formField('failedError.header')}
            required
          />
          <LokaliseField
            name="failedError.body"
            control={control}
            label="layouts.spin_premia.labels.body"
            dataTestId={spinPremiaFormTestIds.formField('failedError.body')}
            required
          />
          <LokaliseField
            name="failedError.fstCta"
            control={control}
            label="layouts.spin_premia.labels.fstCta"
            dataTestId={spinPremiaFormTestIds.formField('failedError.fstCta')}
            required
          />
          <LokaliseField
            name="failedError.sndCta"
            control={control}
            label="layouts.spin_premia.labels.sndCta"
            dataTestId={spinPremiaFormTestIds.formField('failedError.sndCta')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.linked_account_confirmation')}</SectionHeader>
          <LokaliseField
            name="linkedAccountConfirmation.body"
            control={control}
            label="layouts.spin_premia.labels.body"
            dataTestId={spinPremiaFormTestIds.formField('linkedAccountConfirmation.body')}
            required
          />
          <LokaliseField
            name="linkedAccountConfirmation.ack"
            control={control}
            label="layouts.spin_premia.labels.ack"
            dataTestId={spinPremiaFormTestIds.formField('linkedAccountConfirmation.ack')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.unlinked_account_confirmation')}</SectionHeader>
          <LokaliseField
            name="unlinkedAccountConfirmation.body"
            control={control}
            label="layouts.spin_premia.labels.body"
            dataTestId={spinPremiaFormTestIds.formField('unlinkedAccountConfirmation.body')}
            required
          />
          <LokaliseField
            name="unlinkedAccountConfirmation.ack"
            control={control}
            label="layouts.spin_premia.labels.ack"
            dataTestId={spinPremiaFormTestIds.formField('unlinkedAccountConfirmation.ack')}
            required
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.spin_premia.headers.unlink_account')}</SectionHeader>
          <LokaliseField
            name="unlinkAccount.header"
            control={control}
            label="layouts.spin_premia.labels.header"
            dataTestId={spinPremiaFormTestIds.formField('unlinkAccount.header')}
            required
          />
          <LokaliseField
            name="unlinkAccount.body"
            control={control}
            label="layouts.spin_premia.labels.body"
            dataTestId={spinPremiaFormTestIds.formField('unlinkAccount.body')}
            required
          />
          <LokaliseField
            name="unlinkAccount.fstCta"
            control={control}
            label="layouts.spin_premia.labels.fstCta"
            dataTestId={spinPremiaFormTestIds.formField('unlinkAccount.fstCta')}
            required
          />
          <LokaliseField
            name="unlinkAccount.sndCta"
            control={control}
            label="layouts.spin_premia.labels.sndCta"
            dataTestId={spinPremiaFormTestIds.formField('unlinkAccount.sndCta')}
            required
          />
        </div>
      </div>
    </FormBody>
  );
});

SpinPremiaForm.displayName = 'SpinPremiaForm';

export default SpinPremiaForm;
