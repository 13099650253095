import React from 'react';
import { Delete, DragHandle, Edit } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useStatsig } from '../../../../../../hooks';
import { HeroModuleItemType } from '../../../../../../API';
import { HeroThumbnail } from '../../../../UIModuleListItem/ContentPreview/Thumbnails/HeroThumbnail';
import { ThumbnailOrientation } from '../../../../../../utils/types/genericTypes';
import IconButton from '../../../../../shared/IconButton';
import { Chip, Stack, Typography } from '@mui/material';
import { IdBadge } from '../../../../../shared/IdBadge';
import ObjectPreview from '../../../../../shared/ObjectPreview';
import { pick } from 'lodash-es';
import { ViewContentButton } from '../../../../../shared/ViewContentButon';
import { CollectionLink } from '../../../../../Collections/CollectionLink';
import { HeroUiModuleItem } from '../../../../../../state/Layouts';
import {
  getContentIdFromHeroItem,
  isClipsHeroItem,
  isCollectionLinkHeroItem
} from '../../../../../../utils/uiModuleUtils';
import ScheduleTimePreview from '../../../../../shared/ScheduleTime/ScheduleTimePreview';
import { SeStatsigGates } from '../../../../../../utils/consts/statsigGates';
import { shouldDisplayScheduleTimes } from '../../../../../../utils/heroContentItemUtils';
import { heroContentItemPreviewTestIds } from '../../../../../shared/TestsIds';

export type HeroContentItemPreviewProps = {
  heroContentItem: HeroUiModuleItem;
  index: number;
  onRemove: (index: number) => unknown;
  onEdit: () => unknown;
};

const thumbnailHeight = 160;

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  dragHandle: {
    cursor: 'grab'
  },
  contentItemInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    overflow: 'hidden'
  },
  landscapeThumbnailContainer: {
    height: thumbnailHeight,
    width: (thumbnailHeight * 16) / 9
  },
  portraitThumbnailContainer: {
    height: thumbnailHeight,
    width: thumbnailHeight
  },
  option: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  controlButtons: {
    whiteSpace: 'nowrap'
  },
  scheduleTime: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  }
}));

export function HeroContentItemPreview({
  heroContentItem,
  index,
  onRemove,
  onEdit
}: HeroContentItemPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { CheckGate } = useStatsig();
  const isScheduleActive = CheckGate(SeStatsigGates.HeroContentsScheduleActive);

  const handleRemove = () => {
    onRemove(index);
  };

  return (
    <div className={classes.rootContainer} data-testid={heroContentItemPreviewTestIds.root}>
      <DragHandle className={classes.dragHandle} />
      <div className={classes.contentItemInfo}>
        <div className={classes.option}>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.type')}:
          </Typography>
          <Chip label={t(`general.${heroContentItem.heroItemType?.toLowerCase()}`)} size="small" />
        </div>

        {!isCollectionLinkHeroItem(heroContentItem) && (
          <div className={classes.option}>
            <Typography variant="body2" color="textSecondary">
              {t('layouts.hero_content_label')}:
            </Typography>
            <IdBadge id={getContentIdFromHeroItem(heroContentItem)} />
            {heroContentItem.heroItemType === HeroModuleItemType.VOD && (
              <ViewContentButton
                contentId={getContentIdFromHeroItem(heroContentItem)}
                size="small"
                variant="text"
                color="formControlColor"
              />
            )}
          </div>
        )}

        {(isCollectionLinkHeroItem(heroContentItem) || isClipsHeroItem(heroContentItem)) &&
          heroContentItem.collectionId && (
            <div className={classes.option}>
              <Typography variant="body2" color="textSecondary">
                {t('collections.collection')}:
              </Typography>
              <CollectionLink collectionId={heroContentItem.collectionId} />
            </div>
          )}

        <div className={classes.option}>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.sponsor.sponsor')}:
          </Typography>
          {!heroContentItem.sponsorLogo && <Chip label={t('general.not_configured')} size="small" />}
          {heroContentItem.sponsorLogo && (
            <>
              <Chip label={t('general.configured')} size="small" />
              <ObjectPreview
                object={pick(heroContentItem, [
                  'sponsorLogo',
                  'trackingPixelUrl',
                  'sponsorStartDate',
                  'sponsorEndDate'
                ])}
                title={t('layouts.sponsor.sponsor')}
              />
            </>
          )}
        </div>

        <div className={classes.option}>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.hero_messages.hero_message')}:
          </Typography>
          {!heroContentItem.subtitleText && <Chip label={t('general.not_configured')} size="small" />}
          {heroContentItem.subtitleText && (
            <>
              <Chip label={t('general.configured')} size="small" />
              <ObjectPreview
                object={pick(heroContentItem, ['subtitleText', 'subtitleDay', 'subtitleDate'])}
                title={t('layouts.hero_messages.hero_message')}
              />
            </>
          )}
        </div>
        {shouldDisplayScheduleTimes(heroContentItem.heroItemType) &&
          isScheduleActive &&
          heroContentItem.scheduleTimes &&
          heroContentItem.scheduleTimes.length > 0 && (
            <div className={classes.scheduleTime}>
              <Typography variant="body2" color="textSecondary">
                {t('scheduled_time.label')}:
              </Typography>
              <ScheduleTimePreview value={heroContentItem.scheduleTimes} />
            </div>
          )}
      </div>
      <Stack direction="row" gap={2}>
        <div className={classes.landscapeThumbnailContainer}>
          <HeroThumbnail heroContentItem={heroContentItem} orientation={ThumbnailOrientation.LANDSCAPE} />
        </div>
        <div className={classes.portraitThumbnailContainer}>
          <HeroThumbnail heroContentItem={heroContentItem} orientation={ThumbnailOrientation.PORTRAIT} />
        </div>
      </Stack>
      <div className={classes.controlButtons}>
        <IconButton
          onClick={onEdit}
          title={t('layouts.hero_edit_content_item')}
          size="large"
          data-testid={heroContentItemPreviewTestIds.editItemButton}
        >
          <Edit />
        </IconButton>
        <IconButton
          onClick={handleRemove}
          title={t('layouts.hero_delete_content_item')}
          size="large"
          data-testid={heroContentItemPreviewTestIds.deleteItemButton}
        >
          <Delete />
        </IconButton>
      </div>
    </div>
  );
}
