import React from 'react';
import { Autocomplete } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../data-layer';
import { useLocales } from '../../../../hooks';
import TextField from '../../../shared/TextField';
import { FieldError } from 'react-hook-form';
import { markAsRequired } from '../../../../utils/formHelpers';
import { LayoutType } from '../../../../API';
import { templateIdPickerTestIds } from '../../../shared/TestsIds';

export interface ITemplateIdPickerProps {
  value: string;
  onChange: (planCode: string) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  fieldError?: FieldError;
}

export default function TemplateIdPicker({
  value,
  onChange,
  className,
  disabled,
  required = false,
  fieldError
}: ITemplateIdPickerProps): React.ReactElement {
  const { t } = useLocales();
  const { pages } = useData();

  const promoPages = useRecoilValue(pages.state.withRecordBucket(LayoutType.PROMOS));

  const templateIds = promoPages
    ?.filter(({ urlPath }) => /^\/promo\//.test(urlPath))
    .map(({ urlPath }) => urlPath.slice(7));

  return (
    <>
      {templateIds && (
        <Autocomplete
          options={templateIds}
          blurOnSelect
          fullWidth
          renderOption={(props, templateId) => (
            <li data-testid={templateIdPickerTestIds.option} data-value={templateId} {...props}>
              {templateId}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              className={className}
              {...params}
              label={markAsRequired(t('payments.template_id'), required)}
              fieldError={fieldError}
            />
          )}
          value={value}
          onChange={(_, changedValue) => {
            onChange(changedValue || '');
          }}
          disabled={disabled}
          data-testid={templateIdPickerTestIds.autocomplete}
        />
      )}
    </>
  );
}
