import { alpha } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PermissionsGroupId } from '../../../API';
import { useTheme } from '../../../hooks';
import { permissionsGroupBadgeTestIds } from '../TestsIds';

export type PermissionsGroupBadgeProps = {
  value: PermissionsGroupId;
  className?: string;
};

const useStyles = makeStyles<{ color: 'primary' | 'secondary' }>()((theme, props) => {
  const x = theme.palette.mode === 'dark' ? 2 : 1;
  return {
    badge: {
      borderRadius: 3,
      padding: '3px 5px',
      border: `1px solid ${theme.palette[props.color].light}`,
      fontSize: 10,
      borderLeftWidth: 3,
      letterSpacing: '0.75px',
      background: `linear-gradient(180deg, transparent 0%, ${alpha(
        theme.palette[props.color].main,
        0.2 * x
      )} 39%, ${alpha(theme.palette[props.color].main, 0.4 * x)} 40%, ${alpha(
        theme.palette[props.color].main,
        0.2 * x
      )} 100%)`
    }
  };
});

export function PermissionsGroupBadge({ value, className }: PermissionsGroupBadgeProps): React.ReactElement {
  const { lowerContrastColor } = useTheme();
  const { cx, classes } = useStyles({ color: lowerContrastColor });

  return (
    <div className={cx(className, classes.badge)} data-testid={permissionsGroupBadgeTestIds.badge}>
      {value}
    </div>
  );
}
