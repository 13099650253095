import React, { useEffect, useMemo, useState } from 'react';
import { ContentPreview } from '..';
import {
  ContentCollectionResponse,
  ContentCollectionSortingType,
  ContentListUIModuleContentTreatment
} from '../../../../../API';
import Media from '../../../../Collections/CollectionsPanel/Media';
import { useData } from '../../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { DisplayAsOptions } from '../../../../../utils/types/genericTypes';
import { autoRotateGetCurrentIndex } from '../../../../../utils/autoRotate';
import { collectionPreviewTestIds } from '../../../../shared/TestsIds';

export interface ICollectionPreviewProps {
  collectionId: string;
  treatment: ContentListUIModuleContentTreatment;
  showing: boolean;
  backgroundImageUrl?: string;
  backgroundPosition?: string;
}

const MAX_PREVIEW_ITEMS = 30;

export function CollectionPreview({
  collectionId,
  treatment,
  showing,
  backgroundImageUrl,
  backgroundPosition
}: ICollectionPreviewProps): React.ReactElement {
  const {
    collections: {
      state: { withLatestPublishedRecordById },
      hook: { queueLatestPublishedIdToFetch }
    },
    media: {
      hook: { getMediaBySmartQuery }
    }
  } = useData();

  const collection = useRecoilValue(withLatestPublishedRecordById(collectionId));

  useEffect(() => {
    if (!collection) {
      queueLatestPublishedIdToFetch(collectionId, true);
    }
  }, [collectionId]);

  const [smartQueryContents, setSmartQueryContents] = useState<string[]>([]);
  const [smartQueryContentsReceivedForId, setSmartQueryContentsReceivedForId] = useState<string>();

  useEffect(() => {
    if (collection && showing && smartQueryContentsReceivedForId !== collection.entityId) {
      getSmartQueryContents();
    }
  }, [collection, showing]);

  const getSmartQueryContents = async () => {
    setSmartQueryContentsReceivedForId(collection?.entityId);
    if (collection?.query) {
      const contents = await getMediaBySmartQuery(collection.query);
      if (contents) {
        setSmartQueryContents(contents.map(({ contentId }) => contentId));
        return;
      }
    }
    setSmartQueryContents([]);
  };

  const displayAs = useMemo(() => {
    const isLandscape =
      treatment === ContentListUIModuleContentTreatment.LANDSCAPE ||
      treatment === ContentListUIModuleContentTreatment.LANDSCAPE_WITH_SUBTITLE;
    return isLandscape ? DisplayAsOptions.LANDSCAPE : DisplayAsOptions.PORTRAIT;
  }, [treatment]);

  const mediaThumbnail = (contentId: string) => {
    return (
      <Media
        key={contentId}
        mediaId={contentId}
        displayAs={displayAs}
        previewMode
        data-testid={collectionPreviewTestIds.mediaThumbnail}
      />
    );
  };

  const getCuratedContents = (collection: ContentCollectionResponse) => {
    const index = autoRotateGetCurrentIndex(collection);
    if (index === -1) return collection.contents;
    return collection.contents.slice(index).concat(collection.contents.slice(0, index));
  };

  const getCollectionContents = () => {
    if (!collection) return;
    const curatedContents = getCuratedContents(collection);
    let collections: string[] = [];
    if (collection.sortingType === ContentCollectionSortingType.CURATED_FIRST) {
      collections = [...curatedContents, ...smartQueryContents];
    }
    if (collection.sortingType === ContentCollectionSortingType.QUERIED_FIRST) {
      collections = [...smartQueryContents, ...curatedContents];
    }
    if (collection.sortingType === ContentCollectionSortingType.FALLBACK_CURATED) {
      collections = [...(smartQueryContents.length ? smartQueryContents : curatedContents)];
    }
    return collections.filter((content_id, index, self) => self.findIndex((id) => id === content_id) === index);
  };

  if (!collection) return <></>;

  return (
    <ContentPreview
      showing={showing}
      displayAs={displayAs}
      model={{
        contentList: getCollectionContents()?.slice(0, MAX_PREVIEW_ITEMS),
        thumbnailComponent: mediaThumbnail,
        idField: 'contentId'
      }}
      backgroundImageUrl={backgroundImageUrl}
      backgroundPosition={backgroundPosition}
    />
  );
}
