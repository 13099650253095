import React from 'react';
import { Chip, Typography } from '@mui/material';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { PageListUiModuleBody } from '../../../../../API';
import { getPageListTreatmentIcon } from '../../../../../utils/uiModuleUtils';
import { pageListListItemTestIds } from '../../../../shared/TestsIds';

export type PageListListItemProps = IBaseUIModuleListItemProps<PageListUiModuleBody>;

const PageListListItem = ({ uiModule, ...props }: PageListListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')}:
          </Typography>
          <Chip
            icon={getPageListTreatmentIcon(uiModule.pageListTreatment)}
            label={toTitleCase(uiModule.pageListTreatment)}
            size="small"
            data-testid={pageListListItemTestIds.treatment}
          />
        </>
      ]}
      {...props}
    />
  );
};

export default PageListListItem;
