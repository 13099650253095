import React from 'react';
import { LandingPromoUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { landingPromoListItemTestIds } from '../../../../shared/TestsIds';

export type LandingPromoListItemProps = IBaseUIModuleListItemProps<LandingPromoUiModuleBody>;

export function LandingPromoListItem({ uiModule, ...props }: LandingPromoListItemProps): JSX.Element {
  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} data-testid={landingPromoListItemTestIds.title} />}
        {...props}
      />
    </>
  );
}
