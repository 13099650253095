import { useRef } from 'react';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getHashFromObject } from '../../utils/generateHash';

// Used to create a promise that we can resolve later
class DeferredPromise {
  promise: Promise<AxiosResponse | undefined>;
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  resolve: ((value?: AxiosResponse) => void) | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  reject: ((reason?: unknown) => void) | undefined;

  constructor() {
    this.promise = new Promise<AxiosResponse | undefined>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

export class DuplicateRequestError extends Error {
  config: AxiosRequestConfig | undefined;
  hash: string;

  constructor(hash: string, config: AxiosRequestConfig | undefined) {
    super('Duplicate request');
    this.name = 'DuplicateRequestError';
    this.hash = hash;
    this.config = config;
  }
}

type UseDeduplicationReturn = {
  setupInterceptor: () => void;
  cleanupInterceptor: () => void;
};

export function useDeduplication(): UseDeduplicationReturn {
  const responses = useRef<Record<string, DeferredPromise>>({});
  const requestInterceptor = useRef<number>();
  const responseInterceptor = useRef<number>();

  // Creates a hash from the request config
  const getHashFromConfig = (config: AxiosRequestConfig): string => {
    // These values should cover any request that we might make
    return getHashFromObject({
      url: config.url,
      params: config.params,
      data: config.data,
      method: config.method
    });
  };

  // Gets the promise for the selected hash
  const getResponsePromise = (hash: string): Promise<AxiosResponse | undefined> | undefined => {
    if (responses.current[hash]) {
      return responses.current[hash].promise;
    }
  };

  // Creates a promise to be used for duplicated requests
  const createResponsePromise = (config: AxiosRequestConfig): void => {
    const hash = getHashFromConfig(config);
    responses.current[hash] = new DeferredPromise();
  };

  const setupInterceptor = () => {
    requestInterceptor.current = axios.interceptors.request.use((config) => {
      // We have encountered this request already
      const hash = getHashFromConfig(config);
      if (responses.current[hash]) {
        // Throw a custom error to be caught by the response interceptor under "rejected"
        // This is how we handle duplicate requests
        throw new DuplicateRequestError(hash, config);
      }
      // create a new promise for this request
      createResponsePromise(config);
      return config;
    });
    responseInterceptor.current = axios.interceptors.response.use(
      (response) => {
        const hash = getHashFromConfig(response.config);
        // we have a response registered for this request
        if (responses.current[hash]) {
          // resolve the DeferredPromise, used in the duplicated requests
          responses.current[hash].resolve?.(response);
          // delete the response to clean up
          delete responses.current[hash];
        }
        return response;
      },
      (rejected) => {
        // standard axios error, not a duplicated request
        if (axios.isAxiosError(rejected)) {
          // look for an existing request to reject the promise for
          const hash = getHashFromConfig(rejected.config as AxiosRequestConfig);
          if (responses.current[hash]) {
            // reject the DeferredPromise, used in the duplicated requests
            responses.current[hash].reject?.(rejected);
            // delete the response to clean up
            delete responses.current[hash];
          }
        }
        // handling the duplicated request
        if (rejected instanceof DuplicateRequestError) {
          // return the promise associated with this request, will be resolved/rejected above
          return getResponsePromise(rejected.hash);
        }
        throw rejected;
      }
    );
  };

  const cleanupInterceptor = () => {
    if (requestInterceptor.current) {
      axios.interceptors.request.eject(requestInterceptor.current);
    }
    if (responseInterceptor.current) {
      axios.interceptors.response.eject(responseInterceptor.current);
    }
  };

  return {
    setupInterceptor,
    cleanupInterceptor
  };
}
