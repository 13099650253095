import React from 'react';
import { OutboundUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { outboundListItemTestIds } from '../../../../shared/TestsIds';

export type OutboundListItemProps = IBaseUIModuleListItemProps<OutboundUiModuleBody>;

export function OutboundListItem({ uiModule, ...props }: OutboundListItemProps): JSX.Element {
  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} data-testid={outboundListItemTestIds.title} />}
        {...props}
      />
    </>
  );
}
