import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  inputBaseClasses,
  MenuItem,
  outlinedInputClasses,
  Select,
  TextField,
  textFieldClasses,
  Typography
} from '@mui/material';
import IconButton from '../IconButton';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { useTheme } from '../../../hooks';
import { PaginationMeta } from '../../../API';
import { DEFAULT_PAGINATION_LIMIT } from '../../../utils/appDefaults';
import { paginationTestIds } from '../TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    gap: theme.spacing(5),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :nth-of-type(2)': {
      flexGrow: 1
    }
  },
  select: {
    '& > div': {
      paddingLeft: 8
    }
  },
  pageInput: {
    width: 42,
    [`& .${inputBaseClasses.root}`]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      height: 28
    },
    [`& .${inputBaseClasses.input}`]: {
      textAlign: 'center',
      cursor: 'pointer',
      padding: 0,
      fontSize: '0.9rem',
      borderRight: 'none'
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderRight: 'none'
    },
    [`&.${textFieldClasses.root}`]: {
      marginBottom: '0 !important'
    }
  },
  goButton: {
    fontSize: '0.7rem',
    padding: theme.spacing(0, 1, 0, 2),
    height: 28,
    minWidth: 'unset',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}));

export type PaginationProps = {
  queryMetaData: PaginationMeta | undefined;
  onPageChange?: (newPage: number) => void;
  onResultsChange?: (newLimit: number) => void;
  native?: boolean;
};

export const LIMIT_OPTIONS = [10, 20, 50, 100];

function Pagination({ queryMetaData, onPageChange, onResultsChange, native }: PaginationProps): JSX.Element | null {
  const { limit = DEFAULT_PAGINATION_LIMIT, page = 1, totalPages = 1 } = queryMetaData ?? {};
  const isLoading = !queryMetaData;

  const { t } = useTranslation();
  const { formControlColor } = useTheme();
  const { classes } = useStyles();
  const [gotoPage, setGotoPage] = useState(`${page}`);

  useEffect(() => {
    setGotoPage(`${page}`);
  }, [page]);

  const pageMinMax = (newPage: number) => Math.max(Math.min(newPage, totalPages), 1);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages && onPageChange) onPageChange(newPage);
  };

  const onPrevPage = () => {
    handlePageChange(page - 1);
  };

  const onNextPage = () => {
    handlePageChange(page + 1);
  };

  const handleResultsChange = (newResults: string | number) => {
    onResultsChange && onResultsChange(+newResults);
  };

  return (
    <div data-testid={paginationTestIds.root} className={classes.root}>
      <div className={classes.column}>
        <Typography variant="body2">{t('pagination.results_per_page')}</Typography>
        <Select
          disabled={isLoading}
          native={native}
          data-testid={paginationTestIds.resultsPerPage}
          className={classes.select}
          size="small"
          value={limit}
          disableUnderline
          onChange={({ target: { value } }) => handleResultsChange(value)}
        >
          {LIMIT_OPTIONS.map((num, i) =>
            native ? (
              <option key={i} value={num}>
                {num}
              </option>
            ) : (
              <MenuItem key={i} value={num}>
                {num}
              </MenuItem>
            )
          )}
        </Select>
      </div>
      <div className={classes.column}>
        <Typography variant="body2">{t('pagination.page_n_of_t', { n: page, t: totalPages })}</Typography>
        <div className={classes.flex}>
          <IconButton
            title={t('pagination.prev_page')}
            size="small"
            onClick={onPrevPage}
            disabled={page <= 1 || isLoading}
            data-testid={paginationTestIds.prevButton}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            title={t('pagination.next_page')}
            size="small"
            onClick={onNextPage}
            disabled={page >= totalPages || isLoading}
            data-testid={paginationTestIds.nextButton}
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
      <div className={classes.column}>
        <Typography variant="body2">{t('pagination.jump_to_page')}</Typography>
        <div className={classes.flex}>
          <TextField
            className={classes.pageInput}
            disabled={isLoading || !queryMetaData.totalDocs}
            data-testid={paginationTestIds.gotoPageInput}
            size="small"
            variant="outlined"
            color={formControlColor}
            value={gotoPage}
            style={{ marginBottom: '0 !important' }}
            onFocus={(event) => event.target.select()}
            onBlur={({ target: { value } }) => {
              if (value === '') setGotoPage('1');
            }}
            onChange={({ target: { value } }) => {
              if (value === '' || /^[0-9]+$/.test(value)) {
                let newValue = value;
                if (newValue) newValue = pageMinMax(+newValue).toString();
                setGotoPage(newValue);
              }
            }}
          />
          <Button
            className={classes.goButton}
            disabled={isLoading || !queryMetaData.totalDocs}
            size="small"
            color={formControlColor}
            disableElevation
            endIcon={<KeyboardArrowRight />}
            onClick={() => handlePageChange(+gotoPage)}
            data-testid={paginationTestIds.gotoPageButton}
          >
            {t('pagination.go')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
