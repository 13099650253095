import React from 'react';
import { Box, SxProps } from '@mui/material';
import { Delete, FilterAlt } from '@mui/icons-material';
import { Theme } from '@mui/system';
import { useLocales, useTheme } from '../../../hooks';
import IconButton from '../IconButton';
import DropdownButton from '../DropdownButton';
import { Controller, useFormContext } from 'react-hook-form';
import { uniq, without } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

export type WithFilters<D, F> = D & { _filters?: F[] };

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    ['& .MuiTextField-root']: {
      marginBottom: '0 !important'
    }
  },
  wrap: {
    display: 'flex',
    alignItems: 'end',
    gap: theme.spacing(1)
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: theme.spacing(4)
  }
}));

interface SearchFiltersProps<T extends string> {
  actions: Record<T, string>;
  onAdd?: (filter: T) => unknown;
  onRemove?: (filter: T) => unknown;
  render: (
    wrapper: (params: Omit<FilterWrapParams<T>, 'onRemove' | 'active'>) => React.ReactElement
  ) => React.ReactElement;
}

type FilterWrapParams<T extends string> = {
  children: JSX.Element;
  name: T;
  active: boolean;
  sx?: SxProps<Theme>;
  onRemove: (filter: T) => unknown;
  'data-testid'?: string;
};

function FilterWrap<T extends string>({
  name,
  children,
  active,
  sx,
  onRemove,
  'data-testid': dataTestId
}: FilterWrapParams<T>): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();

  if (!active) return <></>;

  return (
    <Box className={classes.wrap} sx={sx}>
      {children}
      <IconButton
        sx={{ marginTop: 2 }}
        title={t('collections.advanced_search.filters.remove_filter')}
        data-testid={dataTestId}
        onClick={() => onRemove(name)}
      >
        <Delete fontSize="small" />
      </IconButton>
    </Box>
  );
}

export function SearchFilters<T extends string>({
  actions,
  render,
  onAdd,
  onRemove
}: SearchFiltersProps<T>): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { setValue, control } = useFormContext();

  return (
    <div className={classes.root}>
      <Controller
        name="_filters"
        control={control}
        render={({ field: { value: filters } }) => (
          <>
            <div className={classes.filters}>
              {render((params) => (
                <FilterWrap
                  {...params}
                  sx={{ order: filters?.indexOf(params.name) }}
                  active={filters?.includes(params.name)}
                  onRemove={(remove) => {
                    setValue('_filters', without(filters, remove));
                    onRemove && onRemove(remove);
                  }}
                />
              ))}
            </div>
            <DropdownButton
              actions={actions}
              buttonCaption={t('collections.advanced_search.filters.add_filter')}
              buttonProps={{
                color: formControlColor,
                sx: { minWidth: 90, alignSelf: 'start' },
                endIcon: <FilterAlt fontSize="small" />,
                disabled: filters?.length === Object.keys(actions).length
              }}
              onSelectAction={(action) => {
                setValue('_filters', uniq([...(filters || []), action as T]));
                onAdd && onAdd(action as T);
              }}
              disableAction={(name) => filters?.includes(name as T)}
            />
          </>
        )}
      />
    </div>
  );
}
