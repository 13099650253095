import { Edit } from '@mui/icons-material';
import { ListItem, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../data-layer';
import IconButton from '../../../shared/IconButton';
import Localized from '../../../shared/Localized';
import { SportsLogo } from '../../SportsLogo';
import { teamListItemTestIds } from '../../../shared/TestsIds';

export interface ITeamListItemProps {
  teamId: string;
}

const useStyles = makeStyles()((theme) => ({
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  listItemLeftSide: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  }
}));

export function TeamListItem({ teamId }: ITeamListItemProps): React.ReactElement {
  const { classes } = useStyles();
  const {
    teams: {
      state: { withRecordById },
      hook: { edit: editTeam }
    }
  } = useData();

  const team = useRecoilValue(withRecordById(teamId));

  const handleTeamEdit = (): void => {
    editTeam(teamId);
  };

  return (
    <ListItem
      button
      className={classes.listItem}
      data-testid={teamListItemTestIds.teamListItem}
      data-team-id={teamId}
      data-team-name={team?.name.en}
      onClick={handleTeamEdit}
    >
      {team && (
        <div className={classes.listItemLeftSide}>
          <SportsLogo logoImagePath={team.logo} />
          <Tooltip arrow title={`Opta ID: ${team.teamId}`} placement="top" PopperProps={{ disablePortal: true }}>
            <div>{team.name && <Localized prop={team.name} />}</div>
          </Tooltip>
        </div>
      )}
      <div>
        <IconButton edge="end" size="large" onClick={handleTeamEdit} data-testid={teamListItemTestIds.editTeamButton}>
          <Edit fontSize="small" />
        </IconButton>
      </div>
    </ListItem>
  );
}
