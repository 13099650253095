import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, CircularProgress } from '@mui/material';
import Modal from '../../../../shared/Modal';
import Button from '../../../../shared/Button';
import { useConfirm, useLocales } from '../../../../../hooks';
import { useData } from '../../../../../data-layer';
import { PageLayoutResponseV2, CollectionLayoutResponse } from '../../../../../API';
import { useRecoilState } from 'recoil';
import Localized from '../../../../shared/Localized';
import { LayoutListLineItem } from '../../../../Layouts/LayoutListItem/LayoutListItem';
import { PAST_LAYOUTS, DRAFT_LAYOUTS } from '../../../../../state/Layouts';
import { Delete } from '@mui/icons-material';
import { usePermissions } from '../../../../../hooks/Permissions/usePermissions';
import { CollectionDeleteConfirm } from '../../../CollectionDeleteConfirm';
import { AppRoutes } from '../../../../../Routes';
import { useNavigate } from 'react-router-dom';
import { bigSize } from '../../../../shared/Modal/Modal';
import { usePermissionsGuard } from '../../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../../state/theme';
import { collectionLayoutsModalTestIds } from '../../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  modal: bigSize,
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: theme.spacing(3)
  },
  modalContent: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  layoutType: {
    paddingTop: theme.spacing(3)
  },
  layoutTypeItems: {
    paddingLeft: theme.spacing(3)
  },
  layoutItem: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  modalButtons: {
    display: 'flex',
    gap: theme.spacing(4),
    '& button': {
      minWidth: '120px'
    }
  },
  loadingContainer: {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  loadingContainerInner: {
    textAlign: 'center'
  }
}));

function CollectionLayoutsModal(): JSX.Element {
  const { classes } = useStyles();
  const { hasPermissions } = usePermissions();
  const { canDelete } = usePermissionsGuard({
    homepageOption: HomepageOptions.COLLECTIONS
  });
  const { t } = useLocales();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const {
    collections: {
      state: { withAssociatedLayoutsCollection },
      hook: { getCollectionLayoutsById, remove }
    }
  } = useData();

  const [collection, setCollection] = useRecoilState(withAssociatedLayoutsCollection);
  const [scheduledLayouts, setScheduledLayouts] = useState<Record<string, CollectionLayoutResponse[]>>({});
  const [draftLayouts, setDraftLayouts] = useState<Record<string, CollectionLayoutResponse[]>>({});
  const [isLoading, setIsLoading] = useState(false);

  const { DELETE: hasDeletePermission } = hasPermissions(collection?.ownerPermissionsGroup);

  const getCollectionLayouts = async () => {
    if (!collection) return;
    setIsLoading(true);
    try {
      const data = await getCollectionLayoutsById(collection.entityId);

      const allScheduledLayouts = [...data.live, ...data.drafts.filter((item) => item.startTime)];
      const newScheduledLayouts: Record<string, CollectionLayoutResponse[]> = {};
      allScheduledLayouts.forEach((item) => {
        newScheduledLayouts[item.urlPath] = [...(newScheduledLayouts[item.urlPath] || []), item];
      });
      setScheduledLayouts(newScheduledLayouts);

      const allDraftLayouts = data.drafts.filter((item) => !item.startTime);
      const newDraftLayouts: Record<string, CollectionLayoutResponse[]> = {};
      allDraftLayouts.forEach((item) => {
        newDraftLayouts[item.urlPath] = [...(newDraftLayouts[item.urlPath] || []), item];
      });
      setDraftLayouts(newDraftLayouts);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return closeModal;
  }, []);

  useEffect(() => {
    if (collection) {
      getCollectionLayouts();
    }
  }, [collection]);

  const closeModal = () => {
    setCollection(undefined);
  };

  const handleDeleteConfirm = async () => {
    if (!collection) return;

    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: <CollectionDeleteConfirm collection={collection} />,
      'data-testid': collectionLayoutsModalTestIds.deleteConfirm
    });

    if (result) {
      if (await remove(collection.entityId)) {
        closeModal();
        navigate(AppRoutes.vodCollections);
      }
    }
  };

  const ModalContent = () => {
    return (
      <div className={classes.modalContent} data-testid={collectionLayoutsModalTestIds.modalContent}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {t('collections.collection_layouts.scheduled_layouts')}
        </Typography>
        <div>
          {Object.keys(scheduledLayouts).length === 0 && (
            <Typography variant="body2" color="textSecondary" className={classes.layoutTypeItems}>
              {t('collections.collection_layouts.no_layouts')}
            </Typography>
          )}
          <div data-testid={collectionLayoutsModalTestIds.liveLayouts}>
            {Object.entries(scheduledLayouts).map(([urlPath, layouts]) => (
              <div key={urlPath} className={classes.modalContent}>
                <strong>
                  {layouts[0].layoutType}: {urlPath}
                </strong>
                <div className={classes.layoutTypeItems}>
                  {layouts.map((layout) => (
                    <div
                      key={layout.id}
                      className={classes.layoutItem}
                      data-testid={collectionLayoutsModalTestIds.layout}
                    >
                      <LayoutListLineItem
                        layout={layout as PageLayoutResponseV2}
                        liveCountries={layout.liveInCountries}
                        which={PAST_LAYOUTS}
                        linkToLayout={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Typography variant="h5" sx={{ flexGrow: 1 }} className={classes.layoutType}>
          {t('collections.collection_layouts.draft_layouts')}
        </Typography>
        <div>
          {Object.keys(draftLayouts).length === 0 && (
            <Typography variant="body2" color="textSecondary" className={classes.layoutTypeItems}>
              {t('collections.collection_layouts.no_draft_layouts')}
            </Typography>
          )}
          <div data-testid={collectionLayoutsModalTestIds.draftLayouts}>
            {Object.entries(draftLayouts).map(([urlPath, layouts]) => (
              <div key={urlPath} className={classes.modalContent}>
                <strong>
                  {layouts[0].layoutType}: {urlPath}
                </strong>
                <div className={classes.layoutTypeItems}>
                  {layouts.map((layout) => (
                    <div
                      key={layout.id}
                      className={classes.layoutItem}
                      data-testid={collectionLayoutsModalTestIds.layout}
                    >
                      <LayoutListLineItem
                        key={layout.id}
                        layout={layout as PageLayoutResponseV2}
                        liveCountries={layout.liveInCountries}
                        which={DRAFT_LAYOUTS}
                        linkToLayout={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={!!collection}
      bodyClassName={classes.modal}
      headerLeft={
        <div className={classes.modalTitle}>
          <div>
            {collection && (
              <Typography variant="body1" fontWeight={500} data-testid={collectionLayoutsModalTestIds.title}>
                <Localized prop={collection.title} />
              </Typography>
            )}
            <Typography data-testid={collectionLayoutsModalTestIds.subHeader} variant="body2">
              {t('collections.collection_layouts.associated_layouts')}
            </Typography>
          </div>
        </div>
      }
      headerRight={
        <Button
          color="error"
          endIcon={<Delete />}
          disabled={!hasDeletePermission || !canDelete}
          onClick={handleDeleteConfirm}
        >
          {t('general.delete')}
        </Button>
      }
      footerRight={
        <div className={classes.modalButtons}>
          <Button data-testid={collectionLayoutsModalTestIds.closeBtn} size="small" onClick={closeModal} color="grey">
            {t('general.close')}
          </Button>
        </div>
      }
      data-testid={collectionLayoutsModalTestIds.root}
      onClose={closeModal}
    >
      {isLoading ? (
        <div data-testid={collectionLayoutsModalTestIds.loader} className={classes.loadingContainer}>
          <div className={classes.loadingContainerInner}>
            <CircularProgress size={48} color="primary" />
          </div>
        </div>
      ) : (
        <ModalContent />
      )}
    </Modal>
  );
}

export default CollectionLayoutsModal;
