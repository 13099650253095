import React from 'react';
import { Chip, ChipProps, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { themeColors } from '../../../theme';
import CountryBadge from '../../shared/CountryBadge';
import { countryBadgesMergedTestIds } from '../TestsIds';

const useStyles = makeStyles()((theme) => ({
  countries: {
    backgroundColor: themeColors[theme.palette.mode].countryBadgeColor,
    borderRadius: 99,
    display: 'flex'
  },
  tooltipCountry: {
    '&:not(:hover)': {
      backgroundColor: 'transparent'
    },
    color: '#fff'
  },
  truncate: {
    backgroundColor: themeColors[theme.palette.mode].countryBadgeColor,
    borderRadius: 99,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.2)'
    }
  }
}));

type TagProps = Pick<ChipProps, 'key' | 'disabled' | 'tabIndex' | 'onDelete'> & {
  'data-tag-index': number;
};
export interface ICountryBadgesMergedProps {
  countryCodes: string[];
  ghost?: boolean;
  truncateAfter?: number;
  getTagProps?: Array<() => TagProps>;
  'data-testid'?: string;
}

export default function CountryBadgesMerged({
  countryCodes,
  truncateAfter = 3,
  ghost = false,
  getTagProps,
  ...props
}: ICountryBadgesMergedProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <div className={classes.countries} style={{ opacity: ghost ? 0.5 : 1 }} data-testid={props['data-testid']}>
      {countryCodes
        .slice(0, countryCodes.length === truncateAfter + 1 ? truncateAfter + 1 : truncateAfter)
        .map((countryCode, index) => (
          <CountryBadge
            key={countryCode}
            countryCode={countryCode}
            data-testid={countryBadgesMergedTestIds.countryBadgeMain}
            {...(getTagProps ? getTagProps[index]() : {})}
          />
        ))}
      {countryCodes.length > truncateAfter + 1 && (
        <Tooltip
          title={
            <>
              {countryCodes.slice(truncateAfter).map((countryCode, index) => (
                <CountryBadge
                  className={classes.tooltipCountry}
                  key={countryCode}
                  countryCode={countryCode}
                  data-testid={countryBadgesMergedTestIds.countryBadgeTooltip}
                  {...(getTagProps ? getTagProps[index + truncateAfter]() : {})}
                />
              ))}
            </>
          }
          arrow
          placement="top"
          data-testid={countryBadgesMergedTestIds.tooltip}
        >
          <Chip className={classes.truncate} label={`+${countryCodes.length - truncateAfter}`} size="small" />
        </Tooltip>
      )}
    </div>
  );
}
