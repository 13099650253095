import React, { useEffect, useMemo } from 'react';
import { FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../hooks';
import { isDynamicCollectionPage } from '../../../utils/consts/uiModules';
import FormControl from '../FormControl';
import { LayoutType } from '../../../API';
import { useData } from '../../../data-layer';
import { collectionPagesTestIds } from '../TestsIds';

const useStyles = makeStyles()(() => ({
  label: {
    position: 'absolute',
    transform: 'translate(0, -1.5px) scale(0.75)'
  },
  helperText: {
    margin: 0
  }
}));

type CollectionPagesProps = {
  label?: string;
  required?: boolean;
  value: string | undefined;
  onChange: (event: SelectChangeEvent<string>) => unknown;
  className?: string;
  error?: boolean;
  helperText?: string;
};

export function CollectionPages({
  label,
  required,
  value,
  onChange,
  className,
  error,
  helperText
}: CollectionPagesProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const {
    pages: {
      state: { withRecordBucket: withPagesByType },
      hook: { getBucket: getPagesByType }
    }
  } = useData();
  const pages = useRecoilValue(withPagesByType(LayoutType.PAGE));
  const availablePages = useMemo(() => pages?.filter((page) => isDynamicCollectionPage(page.urlPath)), [pages]);

  useEffect(() => {
    if (!pages?.length) {
      getPagesByType(LayoutType.PAGE);
    }
  }, [pages]);

  return (
    <FormControl fullWidth className={className}>
      {label && (
        <InputLabel id="page-select-label" className={classes.label} shrink required={required} error={error}>
          {label}
        </InputLabel>
      )}
      <Select
        labelId="page-select-label"
        value={value}
        onChange={onChange}
        disabled={!availablePages}
        displayEmpty
        defaultValue=""
        data-testid={collectionPagesTestIds.pages}
        error={error}
      >
        <MenuItem disabled={required} value="">
          <em>{t('general.none')}</em>
        </MenuItem>
        {availablePages?.map((page) => (
          <MenuItem key={page.urlPath} value={page.urlPath} data-testid={collectionPagesTestIds.pageOption}>
            {page.urlPath}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={classes.helperText} error={error}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}
