import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales, useTheme } from '../../../../../hooks';
import { FormBody, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PlanSummaryUiModuleBody } from '../../../../../API';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { planSummaryFormTestIds } from '../../../../shared/TestsIds';

const PlanSummaryForm = forwardRef<UIModuleTypeForm<PlanSummaryUiModuleBody>>(() => {
  const { control } = useFormContext<PlanSummaryUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  return (
    <FormBody data-testid={planSummaryFormTestIds.formBody}>
      <SectionHeader>{t('layouts.plan_summary.headers.plan_summary_screen')}</SectionHeader>
      <Box>
        <LokaliseField
          name="header"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('header')}
        />
        <LokaliseField
          name="planName"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('planName')}
          required
        />
        <LokaliseField
          name="priceFirstCharge"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('priceFirstCharge')}
          required
        />
        <LokaliseField
          name="offerDetails"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('offerDetails')}
        />
        <LokaliseField
          name="taxDisclaimer"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('taxDisclaimer')}
        />
        <LokaliseField
          name="taxTitle"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('taxTitle')}
        />
        <LokaliseField
          name="planCost"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('planCost')}
          required
        />
        <LokaliseField
          name="taxAmount"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('taxAmount')}
        />
        <LokaliseField
          name="totalAmount"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('totalAmount')}
          required
        />
        <LokaliseField
          name="totalSummaryTitle"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('totalSummaryTitle')}
          required
        />
        <LokaliseField
          name="signUpCta"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('signUpCta')}
          required
        />
        <LokaliseField
          name="notNowCta"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('notNowCta')}
          required
        />
        <LokaliseField
          name="legalDisclaimer"
          control={control}
          toTranslate="layouts.plan_summary.fields"
          dataTestId={planSummaryFormTestIds.formField('legalDisclaimer')}
          required
        />
        <Controller
          control={control}
          name="checkboxConfirmation"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              label={t('layouts.plan_summary.fields.checkboxConfirmation')}
              control={<Switch color={formControlColor} checked={value} onChange={onChange} />}
            />
          )}
        />
      </Box>
    </FormBody>
  );
});

PlanSummaryForm.displayName = 'PlanSummaryForm';

export default PlanSummaryForm;
