import React, { useCallback } from 'react';
import { Delete, Edit, MovieFilterOutlined } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  CategoryChannel,
  EntitlementType,
  VersionedDocumentStatus,
  VllCategoryResponse,
  VllChannelResponse
} from '../../../API';
import { useData } from '../../../data-layer';
import { useLocales } from '../../../hooks';
import DraftBadge from '../../shared/DraftBadge';
import HistoryManager from '../../shared/HistoryManager';
import IconButton from '../../shared/IconButton';
import Repeat from '../../shared/Repeat';
import RevisionManager from '../../shared/RevisionManager';
import ShadowScroller from '../../shared/ShadowScroller';
import { Sortable, SortableGroups } from '../../shared/Sortable';
import TextClamp from '../../shared/TextClamp';
import { EPGFeaturedCategoryBadge } from '../EPGCategories/EPGFeaturedCategoryBadge';
import EPGChannelItem from '../EPGChannelItem';
import { useLokalise } from '../../../hooks/General/useLokalise';
import LokaliseFallback from '../../shared/LokaliseFallback';
import { withHistoryManager } from '../../../state/General/withHistoryManager';
import useConfirm from '../../../hooks/General/useConfirm';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { EPGCategoryChannelsTestIds } from '../../shared/TestsIds';

const categoryIconSize = 45;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    minHeight: 160,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2)
  },
  categoryInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    flexGrow: 1,
    marginRight: theme.spacing(4)
  },
  categoryIcon: {
    width: categoryIconSize
  },
  skeletonCategoryIcon: {
    width: categoryIconSize,
    height: categoryIconSize
  },
  categoryTitleAndDesc: {
    flexGrow: 1
  },
  categoryTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  headerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: theme.spacing(3)
  },
  buttonPanel: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  skeletonCategoryTitle: {
    height: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  channelsList: {
    flexGrow: 1
  },
  dummyChannel: {
    display: 'flex',
    paddingRight: theme.spacing(1)
  },
  skeletonChannel: {
    flexGrow: 1,
    height: theme.spacing(6),
    margin: theme.spacing(2, 3)
  },
  emptyState: {
    padding: theme.spacing(6)
  },
  sortable: {
    height: '100%'
  }
}));

function EPGCategoryChannels(): JSX.Element {
  const { canPublish, canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.EPG
  });
  const { classes } = useStyles();
  const { t } = useLocales();

  const {
    categoryBundles: {
      state: {
        withAllRecords: withCategoryBundles,
        withSelectedCategoryExists,
        withSelected: withSelectedCategoryBundle
      }
    },
    categories: {
      hook: { edit: editCategory, getRevisions, update: updateCategory, publish: publishCategory },
      state: {
        withSelected: withSelectedCategory,
        withSelectedId: withSelectedCategoryId,
        withIsSaving,
        withIsPublishing
      }
    },
    channels: {
      state: { withAllRecordsById: withChannelsById, withSpotlightChannelId }
    }
  } = useData();
  const channelsById = useRecoilValue(withChannelsById);
  const [selectedCategory, setSelectedCategory] = useRecoilState(withSelectedCategory);
  const selectedCategoryId = useRecoilValue(withSelectedCategoryId);
  const isSaving = useRecoilValue(withIsSaving);
  const isPublishing = useRecoilValue(withIsPublishing);
  const selectedCategoryExists = useRecoilValue(withSelectedCategoryExists(selectedCategoryId));
  const categoryBundles = useRecoilValue(withCategoryBundles);
  const selectedCategoryBundle = useRecoilValue(withSelectedCategoryBundle);
  const isLoading = !categoryBundles || !selectedCategoryBundle || !selectedCategoryExists || !selectedCategory;
  const spotlightChannelId = useRecoilValue(withSpotlightChannelId);
  const { lt } = useLokalise();
  const { isClean: isHistoryManagerClean } = useRecoilValue(withHistoryManager);
  const { confirmUnsavedChanges } = useConfirm();

  const setChannelsFromSortable = (categoryChannels: CategoryChannel[]) => {
    if (!selectedCategory) return;
    const categoryChannelsTurned = categoryChannels.map((categoryChannel) => {
      if (categoryChannel.channel) return categoryChannel;
      return {
        channel: (categoryChannel as unknown as VllChannelResponse).entityId,
        entitlements: Object.values(EntitlementType) // Default to ALL entitlements
      };
    });
    setSelectedCategory({ ...selectedCategory, categoryChannels: categoryChannelsTurned });
  };

  const saveSelectedCategory = async () => {
    if (!selectedCategory) return;
    const categoryToSave: VllCategoryResponse = { ...selectedCategory };
    await updateCategory(selectedCategoryId, categoryToSave);
  };

  const publishSelectedCategory = async () => {
    if (!selectedCategory) return;
    await publishCategory(selectedCategoryId);
  };

  const getSelectedCategoryRevisions = useCallback(
    async (limit?: number, page?: number) => {
      return await getRevisions(selectedCategoryId, limit, page);
    },
    [selectedCategoryId, getRevisions]
  );

  const handleEdit = async () => {
    const shallContinue = isHistoryManagerClean || (await confirmUnsavedChanges());
    if (shallContinue && selectedCategory) {
      editCategory(selectedCategory.entityId);
    }
  };

  const removeChannel = (channelId: string): void => {
    if (!selectedCategory) return;
    setSelectedCategory({
      ...selectedCategory,
      categoryChannels: selectedCategory.categoryChannels.filter(
        (categoryChannel) => categoryChannel.channel !== channelId
      )
    });
  };

  const isSpotlightChannel = (channelId: string): boolean => {
    return channelId === spotlightChannelId;
  };

  const onCategoryChannelChanged = (channelId: string, categoryChannel: CategoryChannel) => {
    if (!selectedCategory) return;
    const categoryChannels = [...selectedCategory.categoryChannels];
    categoryChannels[categoryChannels.findIndex((categoryChannel) => categoryChannel.channel === channelId)] =
      categoryChannel;
    setSelectedCategory({
      ...selectedCategory,
      categoryChannels
    });
  };

  if (!selectedCategoryId && !selectedCategoryExists) {
    return (
      <div className={classes.emptyState}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t('empty_states.no_category_selected_for_region')}
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTop}>
          <div className={classes.categoryInfo}>
            <div className={classes.categoryIcon}>
              {!isLoading && (
                <>
                  {/* Temp Icon */}
                  <MovieFilterOutlined fontSize="large" />
                </>
              )}
              {isLoading && <Skeleton variant="circular" className={classes.skeletonCategoryIcon} animation="wave" />}
            </div>
            <div className={classes.categoryTitleAndDesc}>
              {!isLoading && (
                <>
                  <div className={classes.categoryTitle}>
                    <Typography variant="h5">
                      <>
                        {lt(selectedCategory.titleLokaliseKey) || (
                          <LokaliseFallback fallback={selectedCategory.title} />
                        )}
                      </>
                    </Typography>

                    {selectedCategory?.isFeatured && <EPGFeaturedCategoryBadge />}
                    {selectedCategory.status === VersionedDocumentStatus.DRAFT && <DraftBadge />}
                    <IconButton
                      data-testid={EPGCategoryChannelsTestIds.editButton}
                      onClick={handleEdit}
                      title={t('edit_category')}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                  <TextClamp lines={2}>
                    <Typography variant="body2">
                      <>
                        {lt(selectedCategory.descriptionLokaliseKey) || (
                          <LokaliseFallback fallback={selectedCategory.description} />
                        )}
                      </>
                    </Typography>
                  </TextClamp>
                </>
              )}
              {isLoading && (
                <>
                  <Skeleton className={classes.skeletonCategoryTitle} animation="wave" />
                  <Skeleton animation="wave" />
                </>
              )}
            </div>
          </div>
          <div>
            {!isLoading && (
              <RevisionManager
                getRevisionsPromise={getSelectedCategoryRevisions}
                state={selectedCategory}
                onChange={setSelectedCategory}
              />
            )}
          </div>
        </div>
        <div className={classes.headerBottom}>
          <div>
            {!isLoading && (
              <Typography variant="body2" color="textSecondary">
                {t('showing_n_channels', { n: selectedCategory.categoryChannels.length })}
              </Typography>
            )}
          </div>
          <div className={classes.buttonPanel}>
            <HistoryManager
              id="channels"
              state={selectedCategory}
              onChange={setSelectedCategory}
              saveCallback={saveSelectedCategory}
              publishCallback={publishSelectedCategory}
              hasPublishPermission={canPublish}
              hasUpsertPermission={canSave}
            />
          </div>
        </div>
      </div>
      <div className={classes.channelsList}>
        <ShadowScroller loading={!selectedCategory || !channelsById} saving={isSaving || isPublishing}>
          {!isLoading && channelsById && (
            <Sortable
              className={classes.sortable}
              group={{ name: SortableGroups.channelCollection, pull: 'clone' }}
              list={selectedCategory.categoryChannels}
              setList={setChannelsFromSortable}
              animation={100}
              ghostClass="sortableGhost"
              filter=".sortableFiltered"
              disabled={!canSave || !canPublish}
            >
              {selectedCategory.categoryChannels.map((categoryChannel: CategoryChannel) => {
                const channelId = categoryChannel.channel;
                if (!channelsById[channelId]) {
                  return (
                    <div className={classes.dummyChannel} key={channelId}>
                      <Skeleton className={classes.skeletonChannel} animation="wave" />
                      <IconButton
                        title={`${t('tooltips.remove_channel')} ${channelId}`}
                        onClick={() => removeChannel(channelId)}
                        size="large"
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </div>
                  );
                }
                return (
                  <EPGChannelItem
                    key={channelId}
                    highlighted={isSpotlightChannel(channelId)}
                    channel={channelsById[channelId]}
                    categoryChannel={categoryChannel}
                    onCategoryChannelChanged={(changedCategoryChannel) =>
                      onCategoryChannelChanged(channelId, changedCategoryChannel)
                    }
                    data-testid={EPGCategoryChannelsTestIds.channelListItem}
                    data-channel-id={channelId}
                  />
                );
              })}
            </Sortable>
          )}
          {(isLoading || !selectedCategory) && (
            <Repeat n={selectedCategory ? selectedCategory.categoryChannels.length : 15}>
              <Skeleton className={classes.skeletonChannel} animation="wave" />
            </Repeat>
          )}
        </ShadowScroller>
      </div>
    </div>
  );
}

export default EPGCategoryChannels;
