/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiErrorResponse, ApiResponseBody, IsDeletableResponse, PaginationMeta } from '../../API';
import { AxiosResponse, AxiosPromise, AxiosRequestHeaders } from 'axios';
import { setImmediate } from 'timers';

export type BaseAPIType = 'configuration' | 'basePath' | 'axios';
export interface IDataApiController<R, B = R> {
  getAll?: () => ApiResponsePromise<R[]>;
  getPaginated?: (limit?: number, page?: number, ...args: any[]) => ApiResponsePromise<Paginated<R>>;
  getPaginatedPost?: <T extends { limit?: number; page?: number }>(params: T) => ApiResponsePromise<Paginated<R>>;
  update?: (id: string, record: R, ...args: any[]) => ApiResponsePromise<R>;
  create?: (record: R | B, ...args: any[]) => ApiResponsePromise<R>;
  getLatestRevision?: (id: string, includeDeleted?: boolean) => ApiResponsePromise<R>;
  getLatestPublishedRevision?: (id: string, includeDeleted?: boolean) => ApiResponsePromise<R>;
  getById?: (id: string, ...args: any[]) => ApiResponsePromise<R>;
  getRevisions?: (id: string, ...args: any[]) => ApiResponsePromise<Paginated<R>>;
  getByIds?: (ids: string[], includeDeleted?: boolean) => ApiResponsePromise<R[]>;
  getLatestRevisionsByIds?: (ids: string[], includeDeleted?: boolean) => ApiResponsePromise<R[]>;
  getLatestPublishedRevisionsByIds?: (ids: string[], includeDeleted?: boolean) => ApiResponsePromise<R[]>;
  remove?: (id: string) => ApiLegacyResponse<unknown>;
  publish?: (id: string) => ApiResponsePromise<R>;
  isDeletable?: (id: string) => ApiResponsePromise<IsDeletableResponse>;
}

export type ApiResponsePromise<T> = AxiosPromise<ApiResponse<T>>;
export type ApiLegacyResponse<T> = AxiosPromise<T>;

export interface ApiResponse<T> extends ApiResponseBody {
  body: T;
}

export type Paginated<T> = {
  results: T[];
  meta: PaginationMeta;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiClass<T> = new (...args: any[]) => T;

function mockAxiosResponseObject(data: unknown): AxiosResponse {
  return {
    data,
    status: 200,
    statusText: 'success',
    headers: {},
    config: {
      headers: {} as AxiosRequestHeaders
    }
  };
}

export function mockAxiosApiResponseError(data: Array<ApiErrorResponse>, errorCode: number): Promise<AxiosResponse> {
  return mockAxiosResponseError({ errors: data }, errorCode);
}

export function mockAxiosResponseError(data: unknown, errorCode: number): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    setImmediate(() => {
      reject({
        data,
        status: errorCode,
        statusText: 'error',
        headers: {},
        config: {}
      });
    });
  });
}

export function mockAxiosApiResponse(data?: unknown): Promise<AxiosResponse> {
  return mockAxiosResponse({ body: data });
}

export function mockAxiosResponse(data?: unknown): Promise<AxiosResponse> {
  return new Promise((resolve) => {
    setImmediate(() => {
      resolve(mockAxiosResponseObject(data));
    });
  });
}

export function mockAxiosApiResponsePromise(data?: unknown): () => Promise<AxiosResponse> {
  return mockAxiosResponsePromise({ body: data });
}

export function mockAxiosResponsePromise(data?: unknown): () => Promise<AxiosResponse> {
  return () => {
    return new Promise((resolve) => {
      setImmediate(() => {
        resolve(mockAxiosResponseObject(data));
      });
    });
  };
}

export function getMockedApi<T>(
  classOrInstance: T | ApiClass<T>,
  mocks: Partial<T>,
  useApiResponse = true
): Omit<T, BaseAPIType> {
  const fullMock: Omit<T, BaseAPIType> = {} as any;

  const mockCall = useApiResponse ? mockAxiosApiResponsePromise : mockAxiosResponsePromise;

  for (const method of Object.getOwnPropertyNames((<ApiClass<T>>classOrInstance).prototype || classOrInstance)) {
    Object.assign(fullMock, { [method]: mocks[method as keyof T] ?? mockCall({}) });
  }

  return fullMock;
}
