import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import globalAxios, { AxiosPromise } from 'axios';
import { AuthLoginBody, AuthLoginResponse, UserResponse, AuthTokenResponse, AuthApi } from '../../API';
import { useLocation } from 'react-router-dom';
import { DecodedJwt, withCurrentUser, withJwtVersion } from '../../state/auth';
import { useLocales } from './useLocales';
import { useNotifications } from './useNotifications';
import { useHandleError } from '.';
import { AppRoutes } from '../../Routes';
import { SERVICE_UNAVAILABLE } from '../../utils/parseError';
import { useAuthAPI } from '../API/Auth/useAuthAPI';
import { DateTime } from 'luxon';
import jwtDecode from 'jwt-decode';
import { ApiResponse } from '../API';

export const USER_DATA_TOKEN = 'USER_DATA';

const automationLoginForm = '/automation/login';

function getUserData(): AuthLoginResponse {
  return JSON.parse(localStorage.getItem(USER_DATA_TOKEN) as string) as AuthLoginResponse;
}

let refreshTokenPromise: AxiosPromise<ApiResponse<AuthTokenResponse>> | undefined;

function setupInterceptor(authApi: AuthApi, clearAuthenticationData: () => void) {
  // Sets local storage and access token state
  function setAuthenticationData(parsedUserData: AuthLoginResponse, accessToken: string) {
    const data = { ...parsedUserData, accessToken };
    localStorage.setItem(USER_DATA_TOKEN, JSON.stringify(data));
  }

  // Refresh token on requests; checks token expiration first
  globalAxios.interceptors.request.use(
    async (config) => {
      try {
        const parsedUserData = getUserData();
        if (!parsedUserData) {
          return config;
        }

        let accessToken = parsedUserData.accessToken;
        const { exp } = jwtDecode<{ exp: number }>(parsedUserData?.accessToken);
        // Check expiration time, give a 5 second head start to avoid potential issues
        const expired = DateTime.fromSeconds(exp - 5) < DateTime.utc();

        // Only process potential access token refresh if we are using the Authorization header
        if (config.headers?.Authorization) {
          if (expired) {
            // Create a new promise for the refresh token
            if (!refreshTokenPromise) {
              refreshTokenPromise = authApi.getRefreshToken({ token: parsedUserData.accessToken }).then((data) => {
                // Unset the promise after the response comes back
                refreshTokenPromise = undefined;
                return data;
              });
            }
            const result = await refreshTokenPromise;
            setAuthenticationData(parsedUserData, result.data.body.accessToken);
            accessToken = result.data.body.accessToken;
          }
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      } catch (err) {
        clearAuthenticationData();
        return Promise.reject(err);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  // Fallback to refresh token on response if we encounter an error, should normally never hit this anymore
  globalAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.response && error.message === 'Network Error') {
        error.response = { status: SERVICE_UNAVAILABLE.statusCode, message: SERVICE_UNAVAILABLE.message };
      }
      const originalRequest = error.config;
      if ([401, 403].includes(error.response?.status) && !originalRequest._retry) {
        try {
          originalRequest._retry = true;

          const parsedUserData = getUserData();

          const result = await authApi.getRefreshToken({ token: parsedUserData?.accessToken });
          setAuthenticationData(parsedUserData, result.data.body.accessToken);

          originalRequest.headers['Authorization'] = `Bearer ${result.data.body.accessToken}`;
        } catch (err) {
          clearAuthenticationData();
          return Promise.reject(err);
        }

        return globalAxios(originalRequest);
      }

      return Promise.reject(error);
    }
  );
}

export type useAuthReturnType = {
  currentUser: UserResponse | null;
  login: (payload: AuthLoginBody, provider?: string) => Promise<void>;
  logout: () => void;
  checkAuthentication: () => Promise<void>;
  updateCurrentUser: (user: UserResponse) => void;
};

export function useAuth(): useAuthReturnType {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useRecoilState(withCurrentUser);
  const { notifySuccess } = useNotifications();
  const { handleError } = useHandleError();
  const { t } = useLocales();
  const authApi = useAuthAPI();
  const setJwtVersion = useSetRecoilState(withJwtVersion);

  const setJwtInfo = (accessToken?: string) => {
    if (!accessToken) {
      setJwtVersion(undefined);
      return undefined;
    }
    const decodedJwt = jwtDecode<DecodedJwt>(accessToken);
    setJwtVersion(decodedJwt?.claims?.jwtVersion);
  };

  const login = async (payload: AuthLoginBody, provider?: string) => {
    try {
      provider = provider ? provider : 'google';
      const {
        data: { body }
      } = await authApi.login(provider, payload);
      setCurrentUser({ ...body.profile, permissionsGroups: body.profile.permissionsGroups || [] });
      localStorage.setItem(USER_DATA_TOKEN, JSON.stringify(body));
      setJwtInfo(body.accessToken);
      notifySuccess(t('auth.login_success'));
    } catch (err) {
      console.error(err);
      handleError(err, t('auth.login_error'));
    }
  };

  const logout = () => {
    clearAuthenticationData();
  };

  const checkAuthentication = async () => {
    setupInterceptor(authApi, clearAuthenticationData);

    const persistedUserData = localStorage.getItem(USER_DATA_TOKEN);

    try {
      if (persistedUserData) {
        const parsedUserData = JSON.parse(persistedUserData) as AuthLoginResponse;
        setCurrentUser(parsedUserData.profile);
        setJwtInfo(parsedUserData.accessToken);
      } else {
        throw new Error();
      }
    } catch {
      clearAuthenticationData();
    }
  };

  const clearAuthenticationData = () => {
    localStorage.removeItem(USER_DATA_TOKEN);
    setCurrentUser(null);
    setJwtInfo(undefined);
    if (location.pathname !== automationLoginForm) {
      navigate(AppRoutes.login);
    }
  };

  const updateCurrentUser = (user: UserResponse) => {
    setCurrentUser(user);
    const currentLocalStorage = localStorage.getItem(USER_DATA_TOKEN);
    if (currentLocalStorage) {
      const parsedUserData = JSON.parse(currentLocalStorage) as AuthLoginResponse;
      localStorage.setItem(USER_DATA_TOKEN, JSON.stringify({ ...parsedUserData, profile: user }));
    }
  };

  return {
    currentUser,
    login,
    logout,
    checkAuthentication,
    updateCurrentUser
  };
}
