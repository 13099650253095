import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Localized from '../../shared/Localized';
import { withPreviewLeagueId, withPreviewSportId } from '../../../state/Layouts';
import { LEAGUE_ID_ALL } from '../../../utils/consts/uiModules';
import { useLocales, useTheme } from '../../../hooks';
import { useData } from '../../../data-layer';
import { layoutLeaguePreviewChooserTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  selector: {
    minWidth: 260
  },
  skeleton: {
    width: 400,
    height: theme.spacing(6)
  }
}));

const LayoutPreviewChooser = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const {
    sports: {
      state: { withAllRecords: withAllSports },
      hook: { getAll: getAllSports }
    },
    tournaments: {
      state: { withAllRecords: withAllTournaments, withTournamentsBySport },
      hook: { getAll: getAllTournaments }
    }
  } = useData();

  const [previewSportId, setPreviewSportId] = useRecoilState(withPreviewSportId);
  const [previewLeagueId, setPreviewLeagueId] = useRecoilState(withPreviewLeagueId);
  const allSports = useRecoilValue(withAllSports);
  const allTournaments = useRecoilValue(withAllTournaments);
  const leaguesBySport = useRecoilValue(withTournamentsBySport(previewSportId));

  useEffect(() => {
    if (!allSports) getAllSports();
    if (!allTournaments) getAllTournaments();
  }, []);

  useEffect(() => {
    if (allSports && !previewSportId) {
      setPreviewSportId(allSports[0].id);
    }
  }, [allSports]);

  const handlePreviewSportIdChange = (value: string) => {
    setPreviewSportId(value);
    setPreviewLeagueId(undefined);
  };

  return (
    <div className={classes.root} data-testid={layoutLeaguePreviewChooserTestIds.root}>
      {allSports && leaguesBySport && (
        <>
          <FormControl>
            <InputLabel color={formControlColor} id="sports-select-label">
              {t('sports.sport')}
            </InputLabel>
            <Select
              label={t('sports.sport')}
              className={classes.selector}
              labelId="sports-select-label"
              value={previewSportId || ''}
              color={formControlColor}
              onChange={({ target: { value } }) => handlePreviewSportIdChange(value)}
              data-testid={layoutLeaguePreviewChooserTestIds.sportSelect}
            >
              {allSports.map((sport) => (
                <MenuItem key={sport.id} value={sport.id}>
                  <Localized prop={sport.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            {!!previewSportId && leaguesBySport && (
              <>
                <InputLabel color={formControlColor} id="league-selector">
                  {t('sports.league')}
                </InputLabel>
                <Select
                  label={t('sports.league')}
                  className={classes.selector}
                  labelId="league-selector"
                  value={previewLeagueId || LEAGUE_ID_ALL}
                  onChange={({ target: { value } }) => setPreviewLeagueId(value)}
                  color={formControlColor}
                  data-testid={layoutLeaguePreviewChooserTestIds.leagueSelect}
                >
                  <MenuItem value={LEAGUE_ID_ALL}>{t('sports.all_leagues')}</MenuItem>
                  {leaguesBySport.map((league, i) => (
                    <MenuItem
                      key={i}
                      value={league.tournamentId}
                      data-testid={layoutLeaguePreviewChooserTestIds.leagueSelectOption(league.tournamentId)}
                    >
                      <Localized prop={league.name} />
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </>
      )}
      {(!allSports || !leaguesBySport) && <Skeleton className={classes.skeleton} animation="wave" />}
    </div>
  );
};

export default LayoutPreviewChooser;
