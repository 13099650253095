import React, { useState } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { emphasize } from '@mui/system';
import Button from '../../shared/Button';
import CollectionQueryDynamicDateChooser from './CollectionQueryDynamicDateChooser';
import {
  dynamicDateLegibleName,
  getSmartQueryDateTimeTokens,
  TimeOrder,
  UnitOfTime,
  DateLabel
} from '../../../utils/queryBuilder';
import { useLocales } from '../../../hooks';
import ClearableDatePicker from '../../shared/ClearableDatePicker';
import { collectionQueryDateDropdownTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  drowpDownButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: emphasize(theme.palette.background.paper, 0.1),
    color: theme.palette.getContrastText(emphasize(theme.palette.background.paper, 0.1)),
    '&:hover': {
      color: theme.palette.primary.contrastText
    }
  },
  dropDownContent: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
  },
  dateTimeContent: {
    padding: theme.spacing(4)
  }
}));

interface CollectionQueryDateDropdownProps {
  isDynamicDate: boolean;
  value: string;
  onChange: (newValue: string) => void;
  nodeKey: string;
  operator: string;
  className?: string;
  disabled?: boolean;
}

function CollectionQueryDateDropdown({
  isDynamicDate,
  value,
  onChange,
  nodeKey,
  operator,
  className,
  disabled
}: CollectionQueryDateDropdownProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = `date-dropdown-${nodeKey}`;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getDropdownLabel = () => {
    if (isDynamicDate) {
      const legibleName = dynamicDateLegibleName(value);
      if (legibleName) {
        return t(`query_builder.date_labels.${legibleName}`);
      }
      const tokens = getSmartQueryDateTimeTokens(value);
      if (!tokens) return value;
      const [period, unitOfTime, order, referenceDate] = tokens;
      return (
        period +
        ' ' +
        t(`query_builder.units_of_time.${unitOfTime as UnitOfTime}`) +
        ' ' +
        t(`query_builder.operators.${order as TimeOrder}`) +
        ' ' +
        t(`query_builder.date_labels.${referenceDate as DateLabel}`)
      );
    }
    return value;
  };

  return (
    <div className={className} data-testid={collectionQueryDateDropdownTestIds.root}>
      <Button
        data-testid={collectionQueryDateDropdownTestIds.dropdownButton}
        className={classes.drowpDownButton}
        aria-describedby={popoverId}
        variant="contained"
        endIcon={popoverOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={handlePopoverOpen}
        disableElevation={true}
        disabled={disabled}
      >
        {getDropdownLabel()}
      </Button>
      <Popover
        id={popoverId}
        open={popoverOpen}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div className={classes.dropDownContent}>
          {isDynamicDate ? (
            <div data-testid={collectionQueryDateDropdownTestIds.dynamicDateChooser}>
              <CollectionQueryDynamicDateChooser value={value} onChange={onChange} operator={operator} />
            </div>
          ) : (
            <div className={classes.dateTimeContent} data-testid={collectionQueryDateDropdownTestIds.staticDateChooser}>
              <ClearableDatePicker
                value={value}
                onChange={(newValue) => onChange(newValue || '')}
                isIngestionDateFormat
                clearable={false}
              />
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}

export default CollectionQueryDateDropdown;
