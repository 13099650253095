import React, { forwardRef } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CollectionSplashHeroUiModuleBody } from '../../../../../API/model/collection-splash-hero-ui-module-body';
import { useLocales, useTheme } from '../../../../../hooks';
import { COLLECTION_ID_PARAM, isDynamicCollectionPage } from '../../../../../utils/consts/uiModules';
import FormControl from '../../../../shared/FormControl';
import CollectionPicker from '../../CollectionPicker';
import { FormBody } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { useRecoilValue } from 'recoil';
import { withSelectedLayout } from '../../../../../state/Layouts';
import { ContentCollectionType } from '../../../../../API';
import { collectionSplashHeroTestIds } from '../../../../shared/TestsIds';

const CollectionSplashHero = forwardRef<UIModuleTypeForm<CollectionSplashHeroUiModuleBody>>(() => {
  const { control, watch, setValue } = useFormContext<CollectionSplashHeroUiModuleBody>();
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const selectedLayout = useRecoilValue(withSelectedLayout);

  const [collectionId, entitlements] = watch(['collectionId', 'entitlements']);
  const shouldShowCollectionPreview = selectedLayout && isDynamicCollectionPage(selectedLayout.urlPath);
  const collectionIdParamIsSelected = collectionId === COLLECTION_ID_PARAM;

  const collectionTypes = Object.values(ContentCollectionType).filter(
    (type) => type !== ContentCollectionType.MIXED_LIST
  );

  return (
    <FormBody>
      {shouldShowCollectionPreview && (
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={collectionIdParamIsSelected}
                color={formControlColor}
                onChange={(event, value) => setValue('collectionId', value ? COLLECTION_ID_PARAM : '')}
                data-testid={collectionSplashHeroTestIds.dynamicCollectionSwitch}
              />
            }
            label={t('layouts.use_dynamic_collection_id_param')}
          />
        </FormControl>
      )}
      {!collectionIdParamIsSelected && (
        <Controller
          control={control}
          name="collectionId"
          rules={{ required: t('general.field_is_required') }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CollectionPicker
              label={t('collections.collection')}
              value={value}
              onChange={onChange}
              entitlements={entitlements}
              error={!!error}
              helperText={error?.message}
              filters={{ collectionTypes }}
            />
          )}
        />
      )}
    </FormBody>
  );
});

CollectionSplashHero.displayName = 'CollectionSplashHero';

export default CollectionSplashHero;
