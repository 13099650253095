import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const testIds = {
  componentRoot: 'code.root'
};

const useStyles = makeStyles()((theme) => ({
  root: {
    border: '2px solid ' + theme.palette.background.paper,
    padding: theme.spacing(2, 4),
    borderRadius: 5
  },
  pre: {
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Menlo, "DejaVu Sans Mono", Consolas, "Lucida Console", monospace',
    margin: theme.spacing(2, 0),
    borderRadius: 5,
    padding: theme.spacing(2)
  }
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Code({ label = 'Code', obj }: { label?: string; obj: any }): JSX.Element {
  const { classes } = useStyles();
  const [on, setOn] = useState(false);

  if (/localhost/.test(window.location.href)) {
    return (
      <div className={classes.root} data-testid={testIds.componentRoot}>
        <FormControlLabel
          label={label}
          control={<Checkbox value={on} onChange={({ target: { checked } }) => setOn(checked)} />}
        />
        {on && <pre className={classes.pre}>{JSON.stringify(obj, null, 2)}</pre>}
      </div>
    );
  }
  return <></>;
}

export default Code;
