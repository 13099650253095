import React, { useEffect, useState } from 'react';
import { Chip, Typography } from '@mui/material';
import { ContentListUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import { getContentTreatmentIcon } from '../../../../../utils/uiModuleUtils';
import { CollectionLink } from '../../../../Collections/CollectionLink';
import Localized from '../../../../shared/Localized';
import { CollectionPreview } from '../../ContentPreview/CollectionPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { useData } from '../../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { COLLECTION_ID_PARAM } from '../../../../../utils/consts/uiModules';
import { withPreviewCollectionId } from '../../../../../state/Layouts';
import CollectionLabel from '../../../../shared/CollectionLabel';
import { UIModuleListItemMarker } from '../../UIModuleListItemMarker';
import { contentListItemTestIds } from '../../../../shared/TestsIds';

export type ContentListListItemProps = IBaseUIModuleListItemProps<ContentListUiModuleBody>;

const defaultBackgroundPosition = 'center';

const ContentListListItem = ({ uiModule, ...props }: ContentListListItemProps): JSX.Element => {
  const { t } = useLocales();
  const {
    assets: {
      hook: { getByFilePath, getBackgroundPosition: getBgPosition },
      state: { withAssetByFilePath }
    }
  } = useData();
  const [backgroundPosition, setBackgroundPosition] = useState(defaultBackgroundPosition);
  const landscapeFillImageAsset = useRecoilValue(withAssetByFilePath(uiModule.landscapeFillImage));
  const previewCollectionId = useRecoilValue(withPreviewCollectionId);

  const isDynamic = uiModule.contentListId === COLLECTION_ID_PARAM;
  const collectionId = isDynamic ? previewCollectionId : uiModule.contentListId;

  useEffect(() => {
    getBackgroundPosition();
  }, [uiModule, landscapeFillImageAsset]);

  const getBackgroundPosition = async () => {
    if (uiModule.landscapeFillImage) {
      const asset = landscapeFillImageAsset || (await getByFilePath(uiModule.landscapeFillImage));
      if (asset) {
        return setBackgroundPosition(getBgPosition(asset, defaultBackgroundPosition));
      }
    }
    setBackgroundPosition(defaultBackgroundPosition);
  };

  const getBackgroundImageUrl = () => {
    if (!uiModule.landscapeFillImage) return;
    return getAssetUrl(uiModule.landscapeFillImage);
  };

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={
        uiModule.useCollectionTitle ? (
          <CollectionLabel collectionId={uiModule.contentListId} onlyName />
        ) : (
          <Localized prop={uiModule.title} />
        )
      }
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')}:
          </Typography>
          <Chip
            icon={getContentTreatmentIcon(uiModule.contentTreatment)}
            label={toTitleCase(uiModule.contentTreatment)}
            size="small"
            data-testid={contentListItemTestIds.treatment}
          />
        </>
      ]}
      collectionLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.collection')}:
          </Typography>
          {!isDynamic && (
            <UIModuleListItemMarker>
              <CollectionLink
                data-testid={contentListItemTestIds.collectionLink}
                collectionId={uiModule.contentListId}
              />
            </UIModuleListItemMarker>
          )}
          {isDynamic && <Chip size="small" label={t('layouts.collection_id_param')} />}
        </>
      ]}
      renderPreview={(showPreview) => (
        <div data-testid={contentListItemTestIds.preview}>
          {collectionId && (
            <CollectionPreview
              collectionId={collectionId}
              treatment={uiModule.contentTreatment}
              showing={showPreview}
              backgroundImageUrl={getBackgroundImageUrl()}
              backgroundPosition={backgroundPosition}
            />
          )}
        </div>
      )}
      {...props}
    />
  );
};

export default ContentListListItem;
