import React from 'react';
import { TheaterComedy } from '@mui/icons-material';
import { Box, Chip, SxProps, Theme, Tooltip } from '@mui/material';
import { capitalize } from 'lodash-es';
import { themeColors } from '../../../theme';
import { useLocales } from '../../../hooks';
import { makeStyles } from 'tss-react/mui';

export const testIds = {
  forSimilars: 'genre-badge.for-similars'
};

export interface GenreBadgeProps {
  label: string;
  forSimilars?: boolean;
  'data-testid'?: string;
  sx?: SxProps<Theme>;
}

const useStyles = makeStyles()((theme) => ({
  genre: {
    height: 19,
    background: themeColors[theme.palette.mode].countryBadgeColor
  },
  wrapper: {
    height: 19,
    lineHeight: '19px',
    background: themeColors.orange,
    borderRadius: 99,
    display: 'inline-flex'
  },
  forSimilars: {
    marginLeft: 5,
    marginRight: 3,
    fontWeight: 'bold',
    textShadow: '0 -1px 0 rgba(0, 0, 0, 0.2)',
    color: 'white',
    cursor: 'default'
  }
}));

export default function GenreBadge({
  label,
  forSimilars = false,
  sx,
  'data-testid': dataTestId
}: GenreBadgeProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const genre = capitalize(label);

  return (
    <Box className={classes.wrapper} sx={sx}>
      {forSimilars && (
        <Tooltip
          title={t('collections.advanced_search.for_similars.name')}
          placement="top"
          arrow
          data-testid={testIds.forSimilars}
        >
          <div className={classes.forSimilars}>S</div>
        </Tooltip>
      )}
      <Chip className={classes.genre} data-testid={dataTestId} icon={<TheaterComedy />} label={genre} size="small" />
    </Box>
  );
}
