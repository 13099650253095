import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { withCurrentUserPermissionsByGroup } from '../../../state/auth';
import { PermissionsGroupId } from '../../../API';
import { useTheme } from '../../../hooks';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { permissionsGroupSelectorTestIds } from '../TestsIds';

type PermissionsGroupSelectorProps = {
  value?: PermissionsGroupId;
  required?: boolean;
  onChange: (groupId: PermissionsGroupId | undefined) => void;
  error?: boolean;
};

export const PERMISSIONS_GROUP_NONE_OPTION = 'NONE';

export function PermissionsGroupSelector({
  value,
  onChange,
  required,
  error
}: PermissionsGroupSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const { formControlColor } = useTheme();
  const userPermissionsByGroup = useRecoilValue(withCurrentUserPermissionsByGroup);
  const { isAdmin } = usePermissionsGuard();
  const permissionGroupIds = Object.values(PermissionsGroupId).filter(
    (group) => isAdmin || (group !== PermissionsGroupId.ADMIN && userPermissionsByGroup[group]?.UPSERT)
  );

  const handleOnChange = (value: string) => {
    if (permissionGroupIds.includes(value as PermissionsGroupId)) {
      onChange(value as PermissionsGroupId);
      return;
    }
    onChange(undefined);
  };

  const selectedValue = permissionGroupIds.includes(value as PermissionsGroupId)
    ? value
    : PERMISSIONS_GROUP_NONE_OPTION;

  return (
    <Select
      label={t('permissions.permissions_group')}
      value={selectedValue}
      onChange={({ target: { value } }) => handleOnChange(value)}
      data-testid={permissionsGroupSelectorTestIds.root}
      error={error}
      color={formControlColor}
    >
      <MenuItem
        value={PERMISSIONS_GROUP_NONE_OPTION}
        data-testid={permissionsGroupSelectorTestIds.option}
        disabled={required}
      >
        <em>{t('general.none')}</em>
      </MenuItem>
      {permissionGroupIds.map((option) => (
        <MenuItem key={option} value={option} data-testid={permissionsGroupSelectorTestIds.option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}
