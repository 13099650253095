import React from 'react';
import { SportsHeroUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { SportsHeroPreview } from '../../ContentPreview/SportsHeroPreview';
import { sportsheroListItemTestIds } from '../../../../shared/TestsIds';

export type SportsHeroListItemProps = IBaseUIModuleListItemProps<SportsHeroUiModuleBody>;

const SportsHeroListItem = ({ uiModule, ...props }: SportsHeroListItemProps): JSX.Element => {
  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      renderPreview={(showPreview) => (
        <div data-testid={sportsheroListItemTestIds.preview}>
          <SportsHeroPreview matchHeroContents={uiModule.matchHeroContents} showing={showPreview} />
        </div>
      )}
      {...props}
    />
  );
};

export default SportsHeroListItem;
