import { Path } from 'react-hook-form';
import {
  AccountCreationUiModuleBody,
  AccountLoadingUiModuleBody,
  AiSearchUiModuleBody,
  AiSearchUiModuleBodySuggestionsBody,
  BackgroundBody,
  DocumentLocale,
  GeoBlockUiModuleBody,
  LandingPromoUiModuleBackgroundBody,
  LandingPromoUiModuleBody,
  LandingPromoUiModuleFAQBody,
  LandingPromoUiModuleLogoBody,
  LandingPromoUiModuleStepBody,
  LinkingAccountUiModuleBody,
  NewPartnerLandingUiModuleBody,
  NewPartnerLandingUiModuleFaqQuestionsBody,
  NewPartnerLandingUiModuleStepsToRedeemBody,
  OneBrandPromoUiModuleBody,
  OneBrandPromoUiModuleBodyPaymentMethodOptionBody,
  OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody,
  OutboundUiModuleBody,
  OutboundUiModuleBodySection2ProviderLogoLogoBody,
  PlanSummaryUiModuleBody,
  PlaybackPaywallUiModuleBody,
  ProfileUiModuleBody,
  RegistrationWallUiModuleBody,
  SharedModalBody,
  SpinPremiaUiModuleBody,
  TdsGiftCardsUiModuleBody,
  TdsGiftCardsUiModuleCodeHintBody,
  TdsGiftCardsUiModuleTopUpDoneBody,
  WhoIsWatchingUiModuleBody,
  WhoIsWatchingUiModuleBodyCtaMain
} from '../../../API';
import { CollectionFilterOptions } from '../../Collections/CollectionsPanel/CollectionsBrowser';
import { HPCFilterOptions } from '../../HPC/HPCBrowser';
import { DateRangeOption } from '../DateRange';

//AppSettings.tsx
export const appSettingsTestIds = {
  appSettingsModal: 'navbarmenu-app-settings-modal',
  darkModeToggle: 'navbarmenu-dark-mode-toggle',
  closeButton: 'app-settings.close-button',
  clearLocalStorageButton: 'app-settings.clear-local-storage-button',
  primaryColorPicker: 'app-settings.primary-color-picker',
  secondaryColorPicker: 'app-settings.secondary-color-picker',
  themeSpacingSelector: 'app-settings.theme-spacing-selector',
  themeSpacingSelectorOption: 'app-settings.theme-spacing-selector-option'
};

//CollectionExpand.tsx
export const expandTestIds = {
  expandButton: 'CollectionsExpand.expandButton'
};

//DropdownButton.tsx
export const dropDownTestIds = {
  menuButton: 'drop-down-menu.menu-button',
  popover: 'drop-down-menu.popover',
  menuItem: (action: string): string => `drop-down-menu.menu-item-${action}`
};

//AddAllToCuratedButton.tsx
export const addAllToCuratedTestIds = {
  addAllButton: 'collection-manager.move-all-query-button'
};

//AdvancedSearch.tsx
export const advancedSearchTestIds = {
  openButton: 'advanced-search.open-button'
};

//CollectionLabel.tsx
export const collectionLabelTestIds = {
  title: 'CollectionLabel-title',
  draftBadge: 'CollectionLabel-draftBadge',
  genreBadge: 'CollectionLabel-genreBadge',
  similarBadge: 'CollectionLabel-similarBadge'
};

//CollectionListItem.tsx
export const collectionListItemTestIds = {
  listItem: 'collection-list-item.list-item',
  draftBadge: collectionLabelTestIds.draftBadge,
  publishCollectionButton: 'collection-list-item.publish-button',
  editButton: (entityId: string): string => `collection-list-item.edit-button.${entityId}`,
  cloneButton: (entityId: string): string => `collection-list-item.clone-button.${entityId}`,
  layoutsButton: (entityId: string): string => `collection-list-item.layouts-button.${entityId}`,
  editContentButton: 'collection-list-item.edit-content-button',
  editQueryButton: 'collection-list-item.edit-query-button',
  genreBadge: collectionLabelTestIds.genreBadge,
  similarBadge: collectionLabelTestIds.similarBadge,
  menuButton: 'collection-list-item.menu-button',
  menuPopover: 'collection-list-item.menu-popover',
  menuPaperPopover: (entityId: string): string => `collection-list-item.menu-paper-popover.${entityId}`,
  deleteButton: (entityId: string): string => `collection-list-item.delete-button.${entityId}`,
  deleteConfirm: 'collection-list-item.delete-confirm'
};

//SearchFilter.tsx
export const searchFilterTestIds = {
  addFilter: dropDownTestIds.menuButton,
  filterMenuItem: dropDownTestIds.menuItem
};

//License.tsx
export const licenseTestIds = {
  licenseLabel: 'License-label'
};

//MediaButtons.tsx
export const mediaButtonTestIds = {
  addButton: 'MediaButtons-addBtn',
  editButton: 'MediaButtons-editBtn',
  searchButton: 'MediaButtons-searchBtn',
  deleteButton: 'MediaButtons-deleteBtn',
  pinButton: 'MediaButtons-pinBtn',
  infoButton: 'MediaButtons-infoBtn',
  cleanUpButton: 'MediaButtons-cleanupBtn'
};

//EntitlementsMultiSelect.tsx
export const entitlementsMultiSelectTestIds = {
  autocomplete: 'entitlements-filter.autocomplete',
  autocompleteOption: 'entitlements-filter.autocomplete-option'
};

//SportOptionsMultiselect.tsx
export const sportOptionsMultiselectTestIds = {
  sportsSelect: 'sports-event-form.sports-select',
  sportSelectTextField: 'sports-event-form.sport-select-text-field',
  sportsSelectItem: 'sports-event-form.sports-select-item'
};

//ContentAlerts.tsx
export const contentAlertsTestIds = {
  switch: 'content-alerts.switch'
};

//HistoryManager.tsx
export const historyManagerTestIds = {
  resetButton: (key: string): string => `history.reset-button-${key}`,
  undoButton: (key: string): string => `history.undo-button-${key}`,
  redoButton: (key: string): string => `history.redo-button-${key}`,
  saveButton: (key: string): string => `history.save-button-${key}`,
  publishButton: (key: string): string => `history.publish-button-${key}`
};

//AssetCard.tsx
export const assetCardTestIds = {
  listItem: 'AssetBrowser-listItem',
  loader: 'AssetBrowser-loader',
  cardRoot: 'AssetCard-root',
  cardName: 'AssetCard-title',
  cardDate: 'AssetCard-date',
  cardImage: 'AssetCard-image',
  cardInvalid: 'AssetCard-invalid',
  cardBlank: 'AssetCard-blank',
  cardError: 'AssetCard-error',
  skeletonCard: 'AssetCard-skeleton',
  cardClearButton: 'AssetCard-clearBtn',
  cardEditButton: 'AssetCard-editBtn'
};

//AssetUploader.tsx
export const assetUploaderTestIds = {
  assetName: 'AssetUploader-assetName',
  validatorForm: 'AssetUploader-validatorForm',
  formSubmit: 'AssetUploader-formSubmit',
  fileInput: 'AssetUploader-fileInput',
  focusModeSelector: 'AssetUploader-focusModeSelector',
  focusX: 'AssetUploader-focusX',
  focusY: 'AssetUploader-focusY'
};

//ConfirmDialog.tsx
export const confirmdialogTestIds = {
  root: 'confirm-dialog-root',
  confirm: 'confirm-dialog.confirm',
  cancel: 'confirm-dialog.cancel'
};

//AssetBrowser.tsx
export const assetBrowserTestIds = {
  assetBrowserRoot: 'AssetBrowser-root',
  assetBrowserBrowseButton: 'AssetBrowser-browseBtn',
  assetBrowserFilter: 'AssetBrowser-filter',
  assetBrowserUploadButton: 'AssetBrowser-uploadBtn',
  ...assetCardTestIds
};

//AssetsManager.tsx
export const assetsManagerTestIds = {
  root: 'asset-manager.root',
  filter: 'asset-manager.filter',
  uploadButton: 'asset-manager.upload-button',
  assetCategory: 'asset-manager.asset-category',
  assetCard: assetCardTestIds,
  assetUploader: assetUploaderTestIds,
  deleteConfirm: confirmdialogTestIds,
  loader: 'asset-manager.loader'
};

//AssetCard.tsx - AssetsManager
export const assetCardManagerTestIds = {
  card: 'am.asset-card',
  remove: 'am.asset-card-remove'
};

//Collections..tsx
export const collectionsTestIds = {
  root: 'collections.root',
  expandButton: expandTestIds.expandButton,
  closeIcon: 'collections.expand-button.close-icon',
  openIcon: 'collections.expand-button.open-icon'
};

//CollectionForm.tsx
export const collectionFormTestIds = {
  root: 'collection-form.root',
  formBody: 'collection-form.form-body',
  cancelButton: 'collection-form.cancel-button',
  saveButton: 'collection-form.save-button',
  publishButton: 'collection-form.publish-button',
  editorNotes: 'collection-form.editor-notes',
  genresSelect: 'collection-form.genres-select',
  forSimilars: 'collection-form.for-similars',
  contentTypeSelect: 'collection-form.content-type-select',
  ownerPermissionsGroupSelect: 'collection-form.owner-permissions-group-select',
  confirmDialog: 'collection-form.confirm-dialog',
  deleteButton: 'collection-form.delete-button',
  sortingType: 'collection-form.sorting-type',
  cloneBadge: 'collection-form.clone-badge',
  headerLeft: 'collection-form.header-left',
  splashPortrait: 'collection-form.splash-portrait',
  splashLandscape: 'collection-form.splash-landscape',
  cardImageLandscape: 'collection-form.card-image',
  autoRotateCheckbox: 'collection-form.auto-rotate-checkbox',
  autoRotateNumItemsInput: 'collection-form.auto-rotate-num-items-input',
  autoRotateNumDaysInput: 'collection-form.auto-rotate-num-days-input',
  kids: 'collection-form.kids'
};

//CollectionQueryBuilder.tsx
export const collectionQeryBuilderTestIds = {
  root: 'collection-query-builder.root',
  addQueryButton: 'collection-query-builder.add-query-button',
  expandAllButton: 'collection-query-builder.expand-all-button',
  showCompactTreeModeButton: 'collection-query-builder.show-compact-tree-mode-button',
  blankState: 'collection-query-builder.blank-state',
  queryTree: 'collection-query-builder.query-tree',
  queryErrors: 'collection-query-builder.query-errors'
};

//CollectionQueryDateDropdown.tsx
export const collectionQueryDateDropdownTestIds = {
  root: 'collection-query-date-dropdown.root',
  dropdownButton: 'collection-query-date-dropdown.dropdown-button',
  dynamicDateChooser: 'collection-query-date-dropdown.dynamic-date-chooser',
  staticDateChooser: 'collection-query-date-dropdown.static-date-chooser'
};

//CollectionQueryDynamicDateChooser.tsx
export const collectionQueryDynamicDateChooserTestIds = {
  root: 'collection-query-dynamic-date-chooser.root',
  periodInput: 'collection-query-dynamic-date-chooser.period-input',
  unitOfTimeInput: 'collection-query-dynamic-date-chooser.unitOfTime-input',
  orderInput: 'collection-query-dynamic-date-chooser.order-input',
  referenceDateInput: 'collection-query-dynamic-date-chooser.referenceDate-input',
  presets: dropDownTestIds.menuButton,
  preset: (preset: string): string => dropDownTestIds.menuItem(preset)
};

//CollectionQueryNodeForm.tsx
export const collectionQueryNodeFormTestIds = {
  container: (key: string): string => `collection-query-node-form.container-${key}`,
  groupForm: (key: string): string => `collection-query-node-form.group-form-${key}`,
  ruleForm: (key: string): string => `collection-query-node-form.rule-form-${key}`,
  removeNodeButton: (key: string): string => `collection-query-node-form.remove-node-button-${key}`,
  addGroupNodeButton: (key: string): string => `collection-query-node-form.add-group-node-button-${key}`,
  addRuleNodeButton: (key: string): string => `collection-query-node-form.add-rule-node-button-${key}`,
  andButton: (key: string): string => `collection-query-node-form.and-button-${key}`,
  orButton: (key: string): string => `collection-query-node-form.or-button-${key}`,
  fieldSelect: (key: string): string => `collection-query-node-form.field-select-${key}`,
  valueInput: (key: string): string => `collection-query-node-form.field-input-${key}`,
  statementOperator: (key: string, operator: string): string =>
    `collection-query-node-form.statement-operator-toggle-${key}-${operator}`,
  dynamicDateSwitch: (key: string): string => `collection-query-node-form.dynamic-date-switch-${key}`
};

//CollectionQueryNodeLabel.tsx
export const collectionQueryNodeLabelTestIds = {
  field: 'collection-query-label.field',
  operator: 'collection-query-label.operator',
  value: 'collection-query-label.value'
};

//CollectionQuerySortingEditor.tsx
export const collectionQuerySortingEditorTestIds = {
  container: 'collection-query-sortings-editor.container',
  sortingField: (field: string): string => `collection-query-sortings-editor.sorting-field-${field}`,
  enableSortingButton: (field: string): string => `collection-query-sortings-editor.enable-sorting-button-${field}`,
  disableSortingButton: (field: string): string => `collection-query-sortings-editor.disable-sorting-button-${field}`,
  ascButton: (field: string): string => `collection-query-sortings-editor.asc-button-${field}`,
  descButton: (field: string): string => `collection-query-sortings-editor.desc-button-${field}`,
  blankState: 'collection-query-sortings-editor.empty-state',
  currentSortingsList: 'collection-query-sortings-editor.current-sortings-list',
  availableSortingsList: 'collection-query-sortings-editor.available-sortings-list'
};

//CollectionsPanel.tsx
export const collectionsPanelTestIds = {
  root: 'collections-panel.root',
  tabs: 'collections-panel.tabs',
  searchTab: 'collections-panel.search-tab',
  browseTab: 'collections-panel.browse-tab',
  queryTab: 'collections-panel.query-tab',
  vod: 'collections-panel.vod-tab',
  live: 'collections-panel.live-tab',
  channel: 'collections-panel.channel-tab'
};

//CollectionManager.tsx
export const collectionManagerTestIds = {
  root: 'collection-manager.root',
  backButton: 'collection-manager.back-button',
  editButton: 'collection-manager.edit-button',
  editCuratedButton: 'collection-manager.edit-curated-button',
  editQueryButton: 'collection-manager.edit-query-button',
  saveButton: historyManagerTestIds.saveButton,
  publishButton: historyManagerTestIds.publishButton,
  addAllButton: addAllToCuratedTestIds.addAllButton,
  title: 'collection-manager.title',
  description: 'collection-manager.description',
  curatedItemsCount: 'collection-manager.curated-items-count',
  curatedItems: 'collection-manager.curated-items',
  queryItemsCount: 'collection-manager.query-items-count',
  queryItems: 'collection-manager.query-items',
  draftBadge: 'collection-manager.draft-badge',
  countryPicker: 'collection-manager.country-picker',
  textFilter: 'collection-manager.text-filter',
  exportContent: 'collection-manager.export-content',
  associatedLayoutsButton: 'collection-manager.associated-layouts-button'
};

//AutoRotateBadge.tsx
export const autoRotateBadgeTestIds = {
  enabledBadge: 'auto-rotate-badge.enabled-badge',
  disabledBadge: 'auto-rotate-badge.disabled-badge'
};

//KidsBadge.tsx
export const kidsBadgeTestIds = {
  badge: 'auto-rotate-badge.badge'
};

//SortingType.tsx
export const sortingTypeTestIds = {
  curatedFirst: 'collections.sorting.curated_first',
  queriedFirst: 'collections.sorting.queried_first'
};

//CollectionsBrowser.tsx
export const collectionsBrowserTestIds = {
  root: 'collections-browser.root',
  filterInput: 'collections-browser.filter-input',
  newButton: 'collections-browser.new-button',
  sortBySelector: 'collections-browser.sort-by-selector',
  searchButton: 'collections-browser.search-button',
  loader: 'collections-browser.loader',
  listItem: collectionListItemTestIds.listItem,
  draftBadge: collectionListItemTestIds.draftBadge,
  publishCollectionButton: collectionListItemTestIds.publishCollectionButton,
  editButton: collectionListItemTestIds.editButton,
  editContentButton: collectionListItemTestIds.editContentButton,
  editQueryButton: collectionListItemTestIds.editQueryButton,
  genreChip: collectionListItemTestIds.genreBadge,
  layoutsButton: collectionListItemTestIds.layoutsButton,
  listItemIds: collectionListItemTestIds,
  openAdvancedSearch: advancedSearchTestIds.openButton,
  addSearchFilter: searchFilterTestIds.addFilter,
  searchFilterItem: searchFilterTestIds.filterMenuItem,
  filter: (type: CollectionFilterOptions): string => `collections-browser.filter.${type}`,
  removeFilter: (type: CollectionFilterOptions): string => `collections-browser.filter.remove.${type}`,
  filterOptions: (type: CollectionFilterOptions, param: string): string =>
    `collections-browser.filter.${type}.${param}`,
  hasAssetsTheseTypesOption: 'collections.advanced_search.has_assets_these_types.option'
};

//CollectionLayoutsModal.tsx
export const collectionLayoutsModalTestIds = {
  root: 'collection-layouts-modal.root',
  modalContent: 'collection-layouts-modal.modal-content',
  title: 'collection-layouts-modal.title',
  subHeader: 'collection-layouts-modal.sub-header',
  closeBtn: 'collection-layouts-modal.close-button',
  draftLayouts: 'collection-layouts-modal.draft-layouts',
  liveLayouts: 'collection-layouts-modal.live-layouts',
  layout: 'collection-layouts-modal.layout',
  deleteConfirm: 'collection-layouts-modal.delete-confirm',
  loader: 'collection-layouts-modal.loader'
};

//ContentSearch.tsx
export const contentSearchTestIds = {
  root: 'content-search.root'
};

//ContentSearchButton.tsx
export const contentSearchButtonTestIds = {
  buttonGroup: 'buttonGroup.buttonGroup',
  spinner: 'buttonGroup.spinner'
};

//ContentSearchFilters.tsx
export const contentSearchFiltersTestIds = {
  searchBar: 'content-search.search-bar',
  contentType: 'content-search.content-type',
  searchButton: 'content-search.search-button',
  sortBySelector: 'content-search.sort-by-selector',
  activeSearchFiltersCount: 'content-search.active-search-filters-count',
  filter: (filter: string): string => `content-search.filter.${filter}`,
  removeFilter: (filter: string): string => `content-search.remove-filter.${filter}`,
  openAdvancedSearch: advancedSearchTestIds.openButton,
  category: 'content-search.filter.category',
  isPremium: 'content-search.filter.isPremium',
  rating: 'content-search.filter.rating',
  downloadable: 'content-search.filter.downloadable'
};

//ContentSearchResults.tsx
export const contentSearchResultsTestIds = {
  root: 'content-search-results.root',
  list: 'content-search-results.list',
  media: 'content-search-results.media',
  loading: 'content-search-results.loading'
};

//SavedSearches.tsx
export const savedSearchesTestIds = {
  root: 'saved-searches.root',
  open: 'saved-searches.open',
  confirmDialog: 'saved-searches.confirm-dialog',
  savedSearch: 'saved-searches.saved-search',
  edit: 'saved-searches.edit',
  delete: 'saved-searches.delete',
  loader: 'saved-searches.loader',
  saveButton: 'saved-searches.save-button'
};

//SavedSearchForm.tsx
export const savedSearchFormTestIds = {
  root: 'saved-search-form.root',
  name: 'saved-search-form.name',
  formBody: 'saved-search-form.form-body',
  cancelButton: 'saved-search-form.cancel-button',
  saveButton: 'saved-search-form.save-button',
  headerLeft: 'saved-search-form.header-left'
};

//Match.tsx
export const matchTestIds = {
  root: 'match-root',
  matchListItem: 'match.list-item'
};

//Media.tsx
export const mediaTestIds = {
  root: 'Media-root',
  ...mediaButtonTestIds,
  ...licenseTestIds,
  confirmDialog: 'Media-confirmDialog'
};

//MediaImage.tsx
export const mediaImageTestIds = {
  title: 'media-image.title',
  license: 'media-image.license',
  premium: 'media-image.premium',
  info: 'media-image.info'
};

//MediaList.tsx
export const mediaListTestIds = {
  container: 'media-list.container',
  title: 'media-list.title',
  image: 'media-list.image',
  contentBadge: 'media-list.contentBadge',
  premiumBadge: 'media-list.premiumBadge',
  licenseLabel: 'media-list.licenseLabel',
  infoBadge: 'media-list.infoBadge'
};

//DisplayAs.tsx
export const displayAsTestIds = {
  buttonGroup: 'display-as.button-group',
  listViewButton: 'display-as.list-view-button',
  portraitViewButton: 'display-as.portrait-view-button',
  landscapeViewButton: 'display-as.landscape-view-button'
};

//LiveSearch.tsx
export const liveSearchTestIds = {
  matchFilter: 'match-picker.match-filter',
  matchList: 'live-search.match-list'
};

//Config.tsx
export const configTestIds = {
  root: 'config-panel.root',
  tabs: 'config-panel.tabs',
  sportsTab: 'config-panel.sports-tab',
  metaTab: 'config-panel.meta-tab'
};

//FallBackAssets.tsx
export const fallBackAssetsTestIds = {
  assetContainer: 'fallback-assets.asset-container',
  root: 'fallback-assets.root',
  saveButton: 'fallback-assets.save-button',
  validatorForm: 'fallback-assets.validator-form'
};

//Seo.tsx
export const seoTestIds = {
  root: 'config.root',
  saveButton: 'config.save-button',
  validatorForm: 'config.validator-form'
};

//EPG.tsx
export const epgTestIds = {
  componentRoot: 'epg-root'
};

//EPGCategories.tsx
export const EPGCategoriesTestIds = {
  categoryList: 'categories.list',
  regionSelectorItem: 'categories.region-selector-item',
  regionSelector: 'categories.region-selector',
  regionMenuButton: 'categories.region-menu-button',
  editRegionButton: 'categories.edit-region-button',
  newRegionButton: 'categories.new-region-button',
  cloneRegionButton: 'categories.clone-region-button',
  deleteRegionButton: 'categories.delete-region-button',
  newCategoryButton: 'categories.new-category-button',
  deleteRegionConfirmDialog: 'categories.delete-region-confirm-dialog'
};

//EPGCategoryForm.tsx
export const EPGCategoryFormTestIds = {
  root: 'category-form.root',
  formBody: 'category-form-body',
  titleLokaliseKey: 'category-title-lokalise-key',
  descriptionLokaliseKey: 'category-description-lokalise-key',
  deleteButton: 'category-form.delete-button',
  saveButton: 'category-form.save-button',
  publishButton: 'category-form.publish-button',
  isPremiumCheckbox: 'category-form.isPremium-checkbox',
  cancelButton: 'category-form.cancel-button',
  confirmDialog: 'category-form.confirm-dialog'
};

//EPGCategoryListItem.tsx
export const EPGCategoryListItemTestIds = {
  categoryListItem: 'categories.list-item',
  categoryListItemEditButton: 'categories.list-item.edit-button'
};

//EPGCategoryOrderingForm.tsx
export const EPGCategoryOrderingFormTestIds = {
  formBody: 'category-ordering-form.form-body',
  deleteButton: 'category-ordering-form.delete-button',
  submitButton: 'category-ordering-form.save-button',
  entitlementCheckbox: 'category-ordering-form.entitlement-checkbox',
  confirmDialog: 'category-ordering-form.delete-confirm-dialog'
};

//EPGCategoryOrderingSelector.tsx
export const EPGCategoryOrderingSelectorTestIds = {
  editButton: 'epg-category-ordering-selector.edit-button',
  newButton: 'epg-category-ordering-selector.new-button',
  autocomplete: 'epg-category-ordering-selector.autocomplete',
  autocompleteOption: 'epg-category-ordering-selector.autocomplete-option'
};

//EPGRegionForm.tsx
export const EPGRegionFormTestIds = {
  formBody: 'region-form-body',
  regionNameInput: 'region-name-input',
  cloneBadge: 'region-clone-badge',
  defaultLocaleSelector: 'region-default-locale-selector',
  saveButton: 'region-save-button',
  publishButton: 'region-publish-button',
  cancelButton: 'region-cancel-button'
};

//EPGRegionSelector.tsx
export const EPGRegionSelectorTestIds = {
  autocomplete: 'epg-region-selector.autocomplete',
  textField: 'epg-region-selector.text-field',
  regionOption: 'epg-region-selector.region-option',
  draftBadge: 'epg-region-selector.draft-badge',
  countriesBadge: 'epg-region-selector.countries-badge'
};

//EPGCategoryChannels.tsx
export const EPGCategoryChannelsTestIds = {
  channelListItem: 'category-channels-list-item',
  publishButton: 'category-channels-publish-button',
  editButton: 'category-channels-edit-button'
};

//EPGChannelForm.tsx
export const EPGChannelFormTestIds = {
  root: 'channel-form.root',
  formBody: 'channel-form.body',
  channelNumberInput: 'channel-form.channel-number-input',
  cancelButton: 'channel-form.cancel-button',
  saveButton: 'channel-form.save-button',
  publishButton: 'channel-form.publish-button',
  deleteButton: 'channel-form.delete-button',
  channelMetaData: 'channel-form.channel-metadata',
  backgroundColorCheckbox: 'channel-form.background-color-checkbox',
  backgroundColorField: 'channel-form.background-color-field',
  backgroundColorValue: 'channel-form.background-color-value',
  adsKey: 'channel-form.ads-key',
  editorNotes: 'channel-form.editor-notes',
  confirmDialog: 'channel-form.confirm-dialog'
};

//EPGChannelItem.tsx
export const EPGChannelItemTestIds = {
  addChannelButton: 'channels.add-channel-button',
  linkChannelButton: 'channels.link-channel-button',
  editChannelButton: 'channels.edit-channel-button',
  removeChannelButton: 'channels.remove-channel-button',
  channelSpotlightButton: 'channels.channel-spotlight-button',
  channelTitle: 'channels.channel-title',
  channelDescription: 'channels.channel-description',
  channelEditorNotes: 'channels.channel-editor-notes',
  channelNumber: 'channels.channel-channel-number',
  draftBadge: 'channels.draft-badge',
  channelPremiumBadge: 'channels.premium-badge',
  imageTitle: 'channels.image-title'
};

//EPGChannels.tsx
export const EPGChannelsTestIds = {
  root: 'channels.root',
  searchBar: 'channels.search-bar',
  blockedChannelsCheckbox: 'channels.blocked-channels-checkbox',
  showLinkedTab: 'channels.show-linked-tab',
  showUnlinkedTab: 'channels.show-unlinked-tab'
};

//EPGChannelFilters.tsx
export const EPGChannelFiltersTestIds = {
  channelTypeSelector: 'channel-filters.channel-type-selector',
  channelTypeMenuItem: 'channel-filters.channel-type-menu-item',
  isLiveSelector: 'channel-filters.is-live-selector',
  isLiveMenuItem: 'channel-filters.is-live-menu-item',
  isPremiumSelector: 'channel-filters.is-premium-selector',
  isPremiumMenuItem: 'channel-filters.is-premium-menu-item'
};

//EPGChannelsTab.tsx
export const EPGChannelsTabTestIds = {
  channelsListItem: 'channels.channel-list-item'
};

//EPGReport.tsx
export const EPGReportTestIds = {
  root: 'epg-report.root',
  filterInput: 'epg-report.filter-input',
  linkedFilter: 'epg-report.linked-filter',
  linkedFilterOption: 'epg-report.linked-filter-option'
};

//HPC.tsx
export const HPCTestIds = {
  root: 'hpc.root'
};

//HPCBrowser.tsx
export const HPCBrowserTestIds = {
  root: 'hpc-browser.root',
  newButton: 'hpc-browser.new-collection',
  searchButton: 'hpc-browser.search-button',
  searchInput: 'hpc-browser.search-input',
  filter: (type: HPCFilterOptions): string => `hpc-browser.filter.${type}`,
  removeFilter: (type: HPCFilterOptions): string => `hpc-browser.filter.remove.${type}`,
  filterOptions: (type: HPCFilterOptions, param: string): string => `hpc-browser.filter.${type}.${param}`
};

//HPCForm.tsx
export const HPCFormTestIds = {
  root: 'hpc-form.root',
  saveButton: 'hpc-form.save-button',
  publishButton: 'hpc-form.publish-button',
  deleteButton: 'hpc-form.delete-button',
  cancelButton: 'hpc-form.cancel-button',
  draftBadge: 'hpc-form.draft-badge',
  name: 'hpc-form.name',
  editorNotes: 'hpc-form.editor-notes',
  confirmDialog: 'hpc-form.confirm-dialog',
  cloneBadge: 'hpc-form.clone-badge'
};

//HPCListItem.tsx
export const HPCListItemTestIds = {
  root: 'hpc-list-item.root',
  editContentsButton: 'hpc-list-item.edit-contents-button',
  editMetadataButton: 'hpc-list-item.edit-metadata-button',
  itemButton: 'hpc-list-item.item-button',
  cloneButton: 'hpc-list-item.clone-button',
  name: 'hpc-list-item.name',
  draftBadge: 'hpc-list-item.draft-badge',
  contentsBubble: 'hpc-list-item.contents-bubble'
};

//HPCManager.tsx
export const HPCManagerTestIds = {
  root: 'hpc-manager.root',
  title: 'hpc-manager.title',
  draft: 'hpc-manager.draft',
  backButton: 'hpc-manager.back-button',
  editButton: 'hpc-manager.edit-button',
  presetListLeft: 'hpc-manager.preset-list-left'
};

//PresetListItem.tsx
export const presetListItemTestIds = {
  root: 'preset-list-item.root'
};

//PresetSearch.tsx
export const presetSearchTestIds = {
  root: 'preset-search.root',
  name: 'preset-search.name',
  searchButton: 'preset-search.search-button',
  loader: 'preset-search.loader',
  newButton: 'preset-search.new-button',
  presetListRight: 'preset-search.preset-list-right'
};

//NavBar.tsx
export const navBarTestIds = {
  navBar: 'navbar.navbar',
  epgGroup: 'navbar.epg-link',
  epgProgramming: 'navbar.epg-programming',
  epgReport: 'navbar.epg-report',
  collectionsGroup: 'navbar.collections-link',
  collections: 'navbar.collections',
  hpcCollections: 'navbar.hpc-collections',
  vodCollections: 'navbar.vod-collections',
  layoutsLink: 'navbar.layouts-link',
  sportsLink: 'navbar.sports-link',
  paymentsLink: 'navbar.payments-link',
  metadataLink: 'navbar.metadata-link'
};

//DebugMenu.tsx
export const debugMenuTestIds = {
  closeModal: 'debug-menu-close-modal',
  featureFlagTab: 'debug-menu-feature-flag-tab',
  featureFlagToggle: 'debug-menu-flag-toggle',
  debugMenuModal: 'debug-menu-root'
};

//LanguagePopover.tsx
export const languagePopoverTestIds = {
  menuButton: 'language-popover.menu-button',
  popover: 'language-popover.popover',
  languageMenuItem: 'language-popover.menu-item'
};

//NavBarMenu.tsx
export const navBarMenuTestIds = {
  menuButton: 'navbarmenu-button',
  logout: 'navbarmenu-logout',
  debugMenuButton: 'navbarmenu-debug-button',
  usersLink: 'navbarmenu-users-link',
  assetsLink: 'navbarmenu-assets-link',
  heroLink: 'navbarmenu-hero-link',
  appSettingsButton: 'navbarmenu-app-settings-button',
  versionLogButton: 'navbarmenu-version-log-button',
  settings: 'navbarmenu-settings-link',
  clearLocalStorage: 'navbarmenu-clear-local-storage'
};

//NotificationDrawer.tsx
export const notificationDrawerTestIds = {
  menuButton: 'notifs.menu-button',
  clearAll: 'notifs.clear-all',
  drawerBody: 'notifs.drawer-body',
  noNotifications: 'notifs.no-notifications',
  clearNotification: 'notifs.clear-notification',
  successFilter: 'notifs.success-filter',
  errorFilter: 'notifs.error-filter',
  toggleDetails: 'notifs.toggle-details'
};

//TimezonePopover.tsx
export const timezonePopoverTestIds = {
  menuButton: 'Timezone-popover.menu-button',
  popover: 'Timezone-popover.popover',
  TimezoneMenuItem: 'Timezone-popover.menu-item'
};

//VersionLog.tsx
export const versionLogTestIds = {
  closeModal: 'version-log-close-modal',
  cmsTab: 'version-log-cms-tab',
  editorialTab: 'version-log-editorial-tab',
  versionLogModal: 'version-log-root'
};

//VersionLogTagItem.tsx
export const versionLogTagItemTestIds = {
  tagItem: (tag: string): string => `tagitem-${tag}`,
  toggleTagItem: (tag: string): string => `tagitem-toggle-${tag}`
};

//Layouts.tsx
export const layoutsTestIds = {
  componentRoot: 'layouts.root',
  newLayoutButton: 'layouts.new-layout-button',
  newPageButton: 'layouts.new-page-button',
  pageMenuButton: 'layouts.page-menu-button',
  menuSelector: 'layouts.menu-selector',
  menuSelectorItem: 'layouts.menu-selector-item',
  layoutsTypeSelector: 'layouts.type-selector',
  layoutsDeletePopUp: 'layouts.delete-confirm-popup',
  hideLayoutUrl: 'layouts.hide-layout-url',
  unHideLayoutUrl: 'layouts.unhide-layout-url'
};

//LayoutForm.tsx
export const layoutFormTestIds = {
  formBody: 'layout-form.form-body',
  cancelButton: 'layout-form.cancel-button',
  saveButton: 'layout-form.save-button',
  cloneBadge: 'layout-form.clone-badge',
  urlPathChip: 'layout-form.url-path-chip',
  editorNotes: 'layout-form.editor-notes',
  ownerPermissionsGroupSelect: 'layout-form.owner-permissions-group-select',
  draftRadio: 'layout-form.draft-radio',
  scheduledRadio: 'layout-form.scheduled-radio',
  quickTimeRadio: 'layout-form.quick-time-radio',
  quickTimeSelect: 'layout-form.quick-time-select'
};

//LayoutLeaguePreviewChooser.tsx
export const layoutLeaguePreviewChooserTestIds = {
  root: 'layout-preview-league-chooser.root',
  sportSelect: 'layout-preview-league-chooser.sport-select',
  leagueSelect: 'layout-preview-league-chooser.league-select',
  leagueSelectOption: (id: string): string => `layout-preview-league-chooser.league-select-option-${id}`
};

//LayoutListItem.tsx
export const layoutListItemTestIds = {
  componentRoot: 'layout-list-item',
  draftBadge: 'lli.draft-badge',
  liveBadge: 'lli.live-badge',
  pageName: 'lli.page-name',
  startTime: 'lli.start-time',
  layoutMenuButton: 'lli.menu-button',
  editLayoutButton: 'lli.edit-layout-button',
  cloneLayoutButton: 'lli.clone-layout-button',
  deleteLayoutButton: 'lli.delete-layout-button',
  lineItem: 'lli.line-item'
};

//LayoutListItemGraph.tsx
export const layoutListItemGraphTestIds = {
  canvas: 'layout-list-item-graph.canvas',
  tooltip: 'layout-list-item-graph.tooltip'
};

//LayoutDrafts.tsx
export const layoutDraftsTestIds = {
  componentRoot: 'layouts-drafts.root'
};

//LayoutExportModal.tsx
export const layoutExportModalTestIds = {
  root: 'layouts-export-modal.root',
  date: 'layouts-export-modal.date',
  cancelButton: 'layouts-export-modal.cancel-button',
  downloadButton: 'layouts-export-modal.download-button'
};

//LayoutPageSelector.tsx
export const layoutPageSelectorTestIds = {
  autocomplete: 'layouts-page-selector.autocomplete',
  autocompleteOption: 'layouts-page-selector.autocomplete-option',
  textField: 'layouts-page-selector.text-field'
};

//LayoutsTimeline.tsx
export const layoutsTimelineTestIds = {
  componentRoot: 'layouts-timeline.root',
  presentDivider: 'layouts-timeline.present-divider',
  loadOlderButton: 'layouts-timeline.load-older'
};

//LayoutsTimelineExtender.tsx
export const layoutsTimelineExtenderTestIds = {
  canvas: 'layout-timeline-extender.canvas'
};

//LayoutsViewTabs.tsx
export const layoutsViewTabsTestIds = {
  scheduleTab: 'layouts-view-tabs.schedule-tab',
  draftsTab: 'layouts-view-tabs.drafts-tab',
  draftsCounter: 'layouts-view-tabs.drafts-counter'
};

//LayoutView.tsx
export const layoutViewTestIds = {
  componentRoot: 'layout-view.root',
  pageName: 'layout-view.page-name',
  draftBadge: 'layout-view.draft-badge',
  startTime: 'layout-view.start-time',
  cloneLayoutButton: 'layout-view.clone-layout-button',
  saveLayoutButton: 'layout-view.save-module-button',
  editLayoutButton: 'layout-view.edit-module-button',
  uiModuleMenuButton: 'layout-view.new-ui-module-button',
  uiModuleMenuIconButton: 'layout-view.new-ui-module-icon-button'
};

//LayoutsViewModuleActions.tsx
export const layoutsViewModuleActionsTestIds = {
  selectAllCheckbox: 'module-actions.select-all',
  copyButton: 'module-actions.copy-button',
  pasteButton: 'module-actions.paste-button',
  deleteButton: 'module-actions.delete-button'
};

//LayoutViewSearch.tsx
export const layoutViewSearchTestIds = {
  textField: 'layout-view-search.text-field'
};

//LayoutWarningsModal.tsx
export const layoutWarningsModalTestIds = {
  root: 'layout-warnings-modal.root',
  saveBtn: 'layout-warnings-modal.save-btn',
  cancelBtn: 'layout-warnings-modal.cancel-btn',
  numberOfErrors: 'layout-warnings-modal.number-of-errors',
  numberOfWarnings: 'layout-warnings-modal.number-of-warnings',
  numberOfCorrect: 'layout-warnings-modal.number-of-correct',
  moduleTitle: (idx: number): string => `layout-warnings-modal.module-title-${idx}`,
  moduleEmptyCountries: (idx: number): string => `layout-warnings-modal.module-empty-countries-${idx}`,
  moduleIncompleteCountries: (idx: number): string => `layout-warnings-modal.module-incomplete-countries-${idx}`,
  moduleCollectionLink: (idx: number): string => `layout-warnings-modal.module-collection-id-${idx}`,
  mediaTitle: (idxModule: number, idxMedia: number): string =>
    `layout-warnings-modal.media-title-${idxModule}-${idxMedia}`,
  mediaId: (idxModule: number, idxMedia: number): string => `layout-warnings-modal.media-id-${idxModule}-${idxMedia}`,
  mediaUnavailableCounties: (idxModule: number, idxMedia: number): string =>
    `layout-warnings-modal.media-unavailable-countries-${idxModule}-${idxMedia}`,
  mediaInvalidPublishWindowCountries: (idxModule: number, idxMedia: number): string =>
    `layout-warnings-modal.media-invalid-publish-window-countries-${idxModule}-${idxMedia}`
};

//LiveCountriesBadge.tsx
export const liveCountriesBadgeTestIds = {
  componentRoot: 'live-countries-badge.component-root'
};

//PageForm.tsx
export const pageFormTestIds = {
  formBody: 'page-form.form-body',
  saveButton: 'page-form.save-button',
  cancelButton: 'page-form.cancel-button',
  urlPathTextField: 'page-form.url-path-text-field'
};

//UIModuleForm.tsx
export const UIModuleFormTestIds = {
  validatorForm: 'uiModule-form.validator-form',
  cancelButton: 'uiModule-form.cancel-button',
  saveButton: 'uiModule-form.save-button',
  publishButton: 'uiModule-form.publish-button',
  treatmentSelector: 'uiModule-form.treatment-selector',
  treatmentSelectorItem: 'uiModule-form.treatment-selector-item',
  typeSelector: 'uiModule-form.type-selector',
  typeSelectorItem: 'uiModule-form.type-selector-item'
};

//CollectionPicker.tsx
export const collectionPickerTestIds = {
  autocomplete: 'collection-picker.autocomplete',
  option: 'collection-picker.option',
  entitlements: entitlementsMultiSelectTestIds.autocomplete,
  entitlementsOption: entitlementsMultiSelectTestIds.autocompleteOption
};

//ContentPicker.tsx
export const contentPickerTestIds = {
  root: 'content-picker.root',
  title: 'content-picker.title',
  noMedia: 'content-picker.no-media',
  closeDrawerButton: 'content-picker.close-drawer-button'
};

//ContentTypeSelector.tsx
export const contentTypeSelectorTestIds = {
  contentTypeSelector: 'content-type-selector',
  contentTypeSelectorItem: 'content-type-selector.menu-item'
};

//ContinueWatchingContentTypePicker.tsx
export const continueWatchingContentTypePickerTestIds = {
  treatmentSelector: 'treatment-selector',
  treatmentSelectorItem: 'treatment-selector.menu-item'
};

//EPGChannelPicker.tsx
export const EPGChannelPickerTestIds = {
  root: 'epg-channel-picker.root',
  title: 'epg-channel-picker.title',
  noChannel: 'epg-channel-picker.no-channel',
  closeDrawerButton: 'epg-channel-picker.close-drawer-button'
};

//MatchPicker.tsx
export const matchPickerTestIds = {
  matchFilter: 'match-picker.match-filter',
  title: 'match-picker.title'
};

//RecommendationsContentTypePicker.tsx
export const recommendationsContentTypePickerTestIds = {
  contentTypeSelector: 'recommendation-content-type-selector',
  chip: 'recommendation-chip',
  option: 'recommendation-option'
};

//ReccomendationsTypePicker.tsx
export const reccomendationsTypePickerTestIds = {
  recommendationSelector: 'recommendation-selector',
  recommendationItem: 'recommendation-selector.menu-item'
};

//TreatmentPicker.tsx
export const treatmentPickerTestIds = {
  treatmentSelector: 'treatment-selector',
  treatmentSelectorItem: 'treatment-selector.menu-item'
};

//AccountCreationForm.tsx
export const accountCreationFormTestIds = {
  formBody: 'account-creation.form-body',
  formField: (name: keyof AccountCreationUiModuleBody): string => `account-creation.form-field.${name}`
};

//AccountLoadingForm.tsx
export const accountLoadingFormTestIds = {
  formBody: 'account-loading.form-body',
  formField: (name: keyof AccountLoadingUiModuleBody): string => `account-loading.form-field.${name}`
};

//AccountSuccessForm.tsx
export const accountSuccessFormTestIds = {
  formBody: 'account-success-form.form-body',
  formField: (name: string): string => `account-success-form.${name}-field`
};

//AiSearchForm.tsx
export const aiSearchFormTestIds = {
  formBody: 'ai-search.form-body',
  formField: (name: keyof AiSearchUiModuleBody | keyof AiSearchUiModuleBodySuggestionsBody): string =>
    `ai-search.form-field.${name}`,
  suggestions: 'ai-search.suggestions',
  suggestionsAddButton: 'ai-search.suggestions-add-button',
  suggestionsRemoveButton: 'ai-search.suggestions-remove-button'
};

//BecauseYouForm.tsx
export const becauseYouFormTestIds = {
  formBody: 'because-you-form.form-body',
  becauseYouType: 'because-you-form.because-you-type',
  becauseYouSource: 'because-you-form.because-you-sorce',
  becauseYouTypeItem: 'because-you-form.treatment-selector.menu-item',
  textTitle: 'because-you-form.text-title'
};

//ChannelsForm.tsx
export const channelsFormTestIds = {
  formBody: 'liveNews-module-form.formBody'
};

//CollectionListForm.tsx
export const collectionListFormTestIds = {
  formBody: 'collection-list-form.form-body',
  addButton: 'collection-list-form.add-button'
};

//CollectionListFormListItem.tsx
export const collectionListFormListItemTestIds = {
  root: 'collection-list-form-list-item.root',
  image: 'collection-list-form-list-item.image',
  title: 'collection-list-form-list-item.title',
  description: 'collection-list-form-list-item.description',
  curatedLabel: 'collection-list-form-list-item.curated-label',
  smartQueryLabel: 'collection-list-form-list-item.smart-query-label',
  deleteButton: 'collection-list-form-list-item.delete-button'
};

//CollectionSplashHero.tsx
export const collectionSplashHeroTestIds = {
  dynamicCollectionSwitch: 'collection-splash-hero-form.dynamic-collection-switch'
};

//ContentListForm.tsx
export const contentListFormTestIds = {
  formBody: 'content-list-form.form-body',
  assetBrowserLandscape: 'content-list-form.asset-browser-landscape',
  assetBrowserPortrait: 'content-list-form.asset-browser-portrait',
  dynamicCollectionSwitch: 'content-list-form.dynamic-collection-switch'
};

//ContinueWatchingForm.tsx
export const continueWatchingFormTestIds = {
  formBody: 'continue-watching-form.form-body'
};

//CopyForm.tsx
export const copyFormTestIds = {
  copyFormBody: 'ui-module-copy-form.validation-form',
  textInput: 'ui-module-copy-form.text-input'
};

//GeoBlockForm.tsx
export const geoBlockFormTestIds = {
  formBody: 'geo-block.form-body',
  formField: (name: keyof GeoBlockUiModuleBody): string => `geo-block.form-field.${name}`
};

//HeroForm.tsx
export const heroFormTestIds = {
  formBody: 'hero-form.form-body',
  hpcConvert: 'hero-form.hpc-convert',
  contentItem: 'hero-form.content-item',
  scrollingTimeSeconds: 'hero-form.scrolling-time-seconds'
};

//HeroContentItemForm.tsx
export const heroContentItemFormTestIds = {
  formBody: 'hero-content-item-form.form-body',
  deleteButton: 'hero-content-item-form.delete-button',
  submitButton: 'hero-content-item-form.save-button',
  oneBrand: {
    title: 'hero-content-item.one-brand.title',
    description: 'hero-content-item.one-brand.description',
    cta: 'hero-content-item.one-brand.cta',
    price: 'hero-content-item.one-brand.price'
  }
};

//HeroContentItemPreview.tsx
export const heroContentItemPreviewTestIds = {
  root: 'hero-content-item-preview.root',
  deleteItemButton: 'hero-content-item-preview.deleteItemButton',
  editItemButton: 'hero-content-item-preview.editItemButton'
};

//HeroMessage.tsx
export const heroMessageTestIds = {
  collapse: 'hero-message-collapse',
  keySelector: 'hero-message.key-selector',
  keyOption: 'hero-message.key-option',
  daySelector: 'hero-message.day-selector',
  dayOption: 'hero-message.day-option',
  clearButton: 'hero-message.clear-button'
};

//InlinePageForm.tsx
export const inlinePageFormTestIds = {
  root: 'inline-page-form.root',
  ctaName: 'inline-page-form.cta-name',
  ctaUrlPath: 'inline-page-form.cta-url-path',
  header: 'inline-page-form.header',
  assetField: (name: string): string => `inline-page-form.${name}-field`
};

//InlinePromoForm.tsx
export const inlinePromoFormTestIds = {
  formBody: 'inline-promo-form.form-body',
  ctaNameField: 'inline-promo-form.cta-name-field',
  ctaPathField: 'inline-promo-form.cta-path-field',
  priceTextField: 'inline-promo-form.price-text-field',
  assetField: (name: string): string => `inline-promo-form.${name}-field`
};

//LandingPromoForm.tsx
export const landingPromoFormTestIds = {
  formBody: 'landing-promo.form-body',
  formField: (
    name:
      | keyof LandingPromoUiModuleBody
      | keyof LandingPromoUiModuleLogoBody
      | keyof LandingPromoUiModuleBackgroundBody
      | keyof LandingPromoUiModuleStepBody
      | keyof LandingPromoUiModuleFAQBody
  ): string => `landing-promo.form-field.${name}`,
  bundleLogo: 'landing-promo.bundle-logo',
  bundleLogoAddButton: 'landing-promo.bundle-logo-add-button',
  bundleLogoRemoveButton: 'landing-promo.bundle-logo-remove-button',
  background: 'landing-promo.background',
  backgroundAddButton: 'landing-promo.background-add-button',
  backgroundRemoveButton: 'landing-promo.background-remove-button',
  planPartnerLogo: 'landing-promo.plan-partner-logo',
  planPartnerLogoAddButton: 'landing-promo.plan-partner-logo-add-button',
  planPartnerLogoRemoveButton: 'landing-promo.plan-partner-logo-remove-button',
  planBrandLogos: 'landing-promo.plan-brand-logos',
  planBrandLogosAddButton: 'landing-promo.plan-brand-logos-add-button',
  planBrandLogosRemoveButton: 'landing-promo.plan-brand-logos-remove-button',
  planBrandTiles: 'landing-promo.plan-brand-tiles',
  planBrandTilesAddButton: 'landing-promo.plan-brand-tiles-add-button',
  planBrandTilesRemoveButton: 'landing-promo.plan-brand-tiles-remove-button',
  steps: 'landing-promo.steps',
  stepsAddButton: 'landing-promo.steps-add-button',
  stepsRemoveButton: 'landing-promo.steps-remove-button',
  FAQ: 'landing-promo.faq',
  FAQAddButton: 'landing-promo.faq-add-button',
  FAQRemoveButton: 'landing-promo.faq-remove-button'
};

//LinkingAccountForm.tsx
export const linkingAccountFormTestIds = {
  formBody: 'linking-account.form-body',
  formField: (name: keyof LinkingAccountUiModuleBody): string => `linking-account.form-field.${name}`
};

//LivePlusForm.tsx
export const livePlusFormTestIds = {
  formBody: 'live-plus-form.form-body',
  formField: (name: string): string => `live-plus-form.${name}`
};

//MenuItemForm.tsx
export const menuItemFormTestIds = {
  formBody: 'menu-item-form.form-body',
  actionType: 'menu-item-form.treatment-selector',
  actionTypeItem: 'menu-item-form.treatment-selector-item',
  targetSelector: 'menu-item-form.target-selector',
  targetSelectorItem: 'menu-item-form.target-selector-item',
  pagesAutocomplete: 'menu-item-form.pages-autocomplete',
  linkInput: 'menu-item-form.link-input',
  keySelector: 'hero-message.key-selector',
  keyOption: 'hero-message.key-option'
};

//MenuSectionForm.tsx
export const menuSectionFormTestIds = {
  formBody: 'menu-section-form.form-body',
  actionType: 'menu-section-form.action-type',
  actionTypeItem: 'menu-section-form.action-type-item',
  targetSelector: 'menu-section-form.target-selector',
  targetSelectorItem: 'menu-section-form.target-selector-item',
  keySelector: 'hero-message.key-selector',
  keyOption: 'hero-message.key-option'
};

//MixedListForm.tsx
export const mixedListFormTestIds = {
  formBody: 'mixed-list-form.form-body',
  assetBrowserLandscape: 'mixed-list-form.asset-browser-landscape',
  assetBrowserPortrait: 'mixed-list-form.asset-browser-portrait',
  dynamicCollectionSwitch: 'mixed-list-form.dynamic-collection-switch'
};

//NewPartnerLandingForm.tsx
export const newPartnerLandingFormTestIds = {
  formBody: 'new-partner-landing.form-body',
  formField: (
    name:
      | keyof NewPartnerLandingUiModuleBody
      | keyof NewPartnerLandingUiModuleFaqQuestionsBody
      | keyof NewPartnerLandingUiModuleStepsToRedeemBody
  ): string => `new-partner-landing.form-field.${name}`,
  faqQuestions: 'new-partner-landing.faqQuestions',
  faqQuestionsAddButton: 'new-partner-landing.faqQuestions-add-button',
  faqQuestionsRemoveButton: 'new-partner-landing.faqQuestions-remove-button',
  stepsToRedeem: 'new-partner-landing.stepsToRedeem',
  stepsToRedeemAddButton: 'new-partner-landing.stepsToRedeem-add-button',
  stepsToRedeemRemoveButton: 'new-partner-landing.stepsToRedeem-remove-button'
};

//OneBrandPromoForm.tsx
export const oneBrandPromoFormTestIds = {
  formBody: 'one-brand-promo.form-body',
  formField: (
    name:
      | Path<OneBrandPromoUiModuleBody>
      | keyof OneBrandPromoUiModuleBodyPaymentMethodOptionBody
      | keyof OneBrandPromoUiModuleBodyPlanCardPartnerLogoBody
  ): string => `one-brand-promo.form-field.${name}`,
  paymentMethodOptions: 'one-brand-promo.payment-method-options',
  paymentMethodOptionsAddButton: 'one-brand-promo.payment-method-options-add-button',
  paymentMethodOptionsRemoveButton: 'one-brand-promo.payment-method-options-remove-button',
  planCardPartnerLogos: 'one-brand-promo.plan-card-partner-logos',
  planCardPartnerLogosAddButton: 'one-brand-promo.plan-card-partner-logos-add-button',
  planCardPartnerLogosRemoveButton: 'one-brand-promo.plan-card-partner-logos-remove-button'
};

//OutboundForm.tsx
export const outboundFormTestIds = {
  formBody: 'outbound.form-body',
  providerLogo: 'outbound.provider-logo',
  providerLogoRemoveButton: 'outbound.provider-logo-remove-button',
  providerLogoAddButton: 'outbound.provider-logo-add-button',
  errorMessage: 'outbound.error-message',
  errorMessageRemoveButton: 'outbound.error-message-remove-button',
  errorMessageAddButton: 'outbound.error-message-add-button',
  formField: (
    name: keyof OutboundUiModuleBody | keyof BackgroundBody | keyof OutboundUiModuleBodySection2ProviderLogoLogoBody
  ): string => `outbound.form-field.${name}`
};

//PageListContentItem.tsx
export const pageListContentItemTestIds = {
  nameInput: 'page-list-content-item.name-input',
  pagePathSelector: 'page-list-content-item.page-path-selector',
  pagePathMenuItem: 'page-list-content-item.page-path-menu-item',
  deleteItemButton: 'page-list-content-item.delete-item-button',
  formField: (name: string): string => `page-list-content-item.${name}-field`
};

//PageListForm.tsx
export const pageListFormTestIds = {
  formBody: 'page-list-form.form-body',
  addPageListItem: 'page-list-form.add-page-list-item',
  pageListItemContainer: 'page-list-form.page-list-item-container'
};

//PlanSummaryForm.tsx
export const planSummaryFormTestIds = {
  formBody: 'plan_summary.form-body',
  formField: (name: keyof PlanSummaryUiModuleBody): string => `plan_summary.form-field.${name}`
};

//PlaybackPaywallForm.tsx
export const playbackPaywallFormTestIds = {
  root: 'playback-paywall.root',
  formBody: 'playback-paywall.form-body',
  formField: (name: keyof PlaybackPaywallUiModuleBody): string => `playback-paywall.form-field.${name}`
};

//ProfileForm.tsx
export const profileFormTestIds = {
  formBody: 'profile.form-body',
  formField: (name: Path<ProfileUiModuleBody>): string => `profile.form-field.${name}`
};

//PromotionForm.tsx
export const promotionFormTestIds = {
  formBody: 'promotion.form-body',
  formField: (name: string): string => `promotion-form.field.${name}`,
  // Images
  upsellImages: 'promotion-form.upsell-images',
  upsellImagesRemoveButton: 'promotion-form.upsell-images-remove-button',
  upsellImagesAddButton: 'promotion-form.upsell-images-add-button'
};

//RecommendationsForm.tsx
export const recommendationsFormTestIds = {
  formBody: 'recommendations-form.form-body',
  premiumContentPercentage: 'recommendations-form.premium-content-percentage'
};

//RegWallForm.tsx
export const regWallFormTestIds = {
  formBody: 'reg_wall.form-body',
  formField: (name: keyof RegistrationWallUiModuleBody): string => `reg_wall.form-field.${name}`
};

//SpinPremiaForm.tsx
export const spinPremiaFormTestIds = {
  formBody: 'spin-premia.form-body',
  formField: (name: Path<SpinPremiaUiModuleBody> | keyof BackgroundBody): string => `spin-premia.form-field.${name}`
};

//SportsEventForm.tsx
export const sportsEventFormTestIds = {
  formBody: 'sports-event-form.form-body',
  eventTypeSelect: 'sports-event-form.event-type-select',
  eventTypeItem: 'sports-event-form.event-type-item',
  ...sportOptionsMultiselectTestIds
};

//SportsEventFilters.tsx
export const sportsEventFiltersTestIds = {
  sportEventFilterComponent: 'sportEvent-filters.sportEvent-component',
  sportEventFilterSportSelector: 'sportEvent-filters.sportEvent-sport-selector',
  sportEventFilterLeagueSelector: 'sportEvent-filters.sportEvent-league-selector',
  sportEventFilterSportMenuItem: 'sportEvent-filters.sportEvent-sport-menu-item',
  sportEventFilterLeagueMenuItem: 'sportEvent-filters.sportEvent-League-menu-item'
};

//TdsGiftCardsForm.tsx
export const tdsGiftCardsFormTestIds = {
  formBody: 'tds-gift-cards.form-body',
  formField: (
    name:
      | keyof TdsGiftCardsUiModuleBody
      | keyof TdsGiftCardsUiModuleCodeHintBody
      | keyof SharedModalBody
      | keyof TdsGiftCardsUiModuleTopUpDoneBody
      | keyof BackgroundBody
  ): string => `tds-gift-cards.form-field.${name}`,
  codeHint: 'tds-gift-cards.codeHint',
  codeHintAddButton: 'tds-gift-cards.codeHint-add-button',
  codeHintRemoveButton: 'tds-gift-cards.codeHint-remove-button',
  error: 'tds-gift-cards.error',
  errorAddButton: 'tds-gift-cards.error-add-button',
  errorRemoveButton: 'tds-gift-cards.error-remove-button'
};

//TournamentListForm.tsx
export const tournamentListFormTestIds = {
  formBody: 'tournament-list-module-form.form-body',
  sportSelect: 'tournament-list-module-form.sport-select',
  sportSelectItem: 'tournament-list-module-form.sport-select-item'
};

//TournamentSplashHeroForm.tsx
export const tournamentSplashHeroFormTestIds = {
  formBody: 'tournament-splash-hero-form.form-body',
  sportSelect: 'tournament-splash-hero-form.sport-select',
  sportSelectItem: 'tournament-splash-hero-form.sport-select-item',
  tournamentSelect: 'tournament-splash-hero-form.tournament-select',
  tournamentSelectItem: 'tournament-splash-hero-form.tournament-select-item',
  dynamicLeagueSwitch: 'tournament-splash-hero-form.dynamic-league-switch'
};

//WatchListForm.tsx
export const watchListFormTestIds = {
  formBody: 'watch-list-form.form-body'
};

//WhoIsWatchingForm.tsx
export const whoIsWatchingFormTestIds = {
  formBody: 'who-is-watching.form-body',
  formField: (name: keyof WhoIsWatchingUiModuleBody | keyof WhoIsWatchingUiModuleBodyCtaMain): string =>
    `who-is-watching.form-field.${name}`,
  ctaMain: 'who-is-watching.cta-main',
  ctaMainAddButton: 'who-is-watching.cta-main-add-button',
  ctaMainRemoveButton: 'who-is-watching.cta-main-remove-button'
};

//UIModuleImportInconsistenciesModal.tsx
export const UIModuleImportInconsistenciesModalTestIds = {
  root: 'uimodule-import-inconsistencies-modal.root',
  closeAndFixBtn: 'uimodule-import-inconsistencies-modal.save-btn',
  numberOfErrors: 'uimodule-import-inconsistencies-modal.number-of-errors',
  numberOfWarnings: 'uimodule-import-inconsistencies-modal.number-of-warnings',
  numberOfCorrect: 'uimodule-import-inconsistencies-modal.number-of-correct',
  unspecifiedProperties: 'uimodule-import-inconsistencies-modal.unspecified-properties',
  unspecifiedPropertiesItem: 'uimodule-import-inconsistencies-modal.unspecified-properties-item',
  unexpectedProperties: 'uimodule-import-inconsistencies-modal.unexpected-properties',
  unexpectedPropertiesItem: 'uimodule-import-inconsistencies-modal.unexpected-properties-item',
  emptyProperties: 'uimodule-import-inconsistencies-modal.empty-properties',
  emptyPropertiesItem: 'uimodule-import-inconsistencies-modal.empty-properties-item',
  emptyPropertiesSubText: 'uimodule-import-inconsistencies-modal.empty-properties-subtext'
};

//UIModuleListItem.tsx
export const UIModuleListItemTestIds = {
  componentRoot: 'umli.component-root',
  editButton: 'umli.edit-button',
  deleteButton: 'umli.delete-button',
  topLineTitle: 'umli.top-line-title',
  emptyUIModuleListItem: 'umli.empty-ui-module-list-item'
};

//AccountCreationPreview.tsx
export const accountCreationPreviewTestIds = {
  root: 'account-creation-preview.root',
  container: 'account-creation-preview.container'
};

//AccountSuccessPreview.tsx
export const accountSuccessPreviewTestIds = {
  root: 'account-success-preview.root',
  container: 'account-success-preview.container'
};

//ChannelsPreview.tsx
export const channelsPreviewTestIds = {
  thumbnail: 'live-news-preview.thumbnail'
};

//CollectionListPreview.tsx
export const collectionListPreviewTestIds = {
  preview: 'collection-list.preview',
  asset: 'collection-list-preview.asset',
  noImage: 'collection-list-preview.no-image'
};

//CollectionPreview.tsx
export const collectionPreviewTestIds = {
  mediaThumbnail: 'collection-preview.media-thumbnail'
};

//CopyPreview.tsx
export const copyPreviewTestIds = {
  copyContainer: 'copy-preview.copy-container',
  loaderContainer: 'copy-preview.loader-container'
};

//HeroPreview.tsx
export const heroPreviewTestIds = {
  heroContentItem: 'hero-preview.hero-content-item'
};

//InlinePagePreview.tsx
export const inlinePagePreviewTestIds = {
  background: 'inline-page-preview.background',
  cta: 'inline-page-preview.cta'
};

//InlinePromoPreview.tsx
export const inlinePromoPreviewTestIds = {
  background: 'inline-promo-preview.background',
  cta: 'inline-promo-preview.cta'
};

//LinkingAccountPreview.tsx
export const linkingAccountPreviewTestIds = {
  root: 'linking-account-preview.root',
  registeredUsersContainer: 'linking-account-preview.registered-users-container',
  svodUsersContainer: 'linking-account-preview.svod-users-container'
};

//LivePlusPreview.tsx
export const livePlusPreviewTestIds = {
  root: 'live-plus-preview.root',
  livePlusContainer: 'live-plus-preview.live-container',
  homeContainer: 'live-plus-preview.home-container',
  endGameContainer: 'live-plus-preview.end-game-container'
};

//MixedListPreview.tsx
export const mixedListPreviewTestIds = {
  mediaThumbnail: 'collection-preview.media-thumbnail'
};

//OneBrandPromoPreview.tsx
export const oneBrandPromoPreviewTestIds = {
  root: 'one-brand-promo-preview.root',
  popupContainer: 'one-brand-promo-preview.popup-container',
  fullContainer: 'one-brand-promo-preview.full-container'
};

//PlanSummaryPreview.tsx
export const planSummaryPreviewTestIds = {
  root: 'plan-summary-preview.root',
  container: 'plan-summary-preview.container'
};

//PlaybackPaywallPreview.tsx
export const playbackPaywallPreviewTestIds = {
  root: 'playback-paywall-preview.root',
  trailer: 'playback-paywall-preview.trailer',
  upNext: 'playback-paywall-preview.upNext'
};

//PromotionPreview.tsx
export const promotionPreviewTestIds = {
  root: 'promotion-preview-root'
};

//RegWallPreview.tsx
export const regWallPreviewTestIds = {
  root: 'regwall-preview.root',
  container: 'regwall-preview.container',
  popupContainer: 'regwall-preview.popup-container',
  fullContainer: 'regwall-preview.full-container'
};

//SportsHeroPreview.tsx
export const sportsHeroPreviewTestIds = {
  matchThumbnail: 'sports-hero-preview.match-thumbnail'
};

//SportsPreview.tsx
export const sportsPreviewTestIds = {
  matchThumbnail: 'sports-preview.match-thumbnail'
};

//HeroThumbnail.tsx
export const heroThumbnailTestIds = {
  heroContentItem: 'hero-thumbnail.hero-content-item'
};

//TournamentListPreview.tsx
export const tournamentListPreviewTestIds = {
  thumbnail: 'tournament-list-preview.thumbnail'
};

//TournamentSplashHeroPreview.tsx
export const tournamentSplashHeroPreviewTestIds = {
  heroContentItem: 'tournament-splash-hero-preview.hero-content-item'
};

//AccountCreationListItem.tsx
export const accountCreationListItemTestIds = {
  button: 'account-creation-list-item.preview-button'
};

//AccountSuccessListItem.tsx
export const accountSuccessListItemTestIds = {
  button: 'account-success-list-item.preview-button'
};

//ChannelsListItem.tsx
export const channelsListItemTestIds = {
  treatment: 'live-news-list-item.treatment',
  preview: 'live-news-list-item.preview'
};

//ContentListItem.tsx
export const contentListItemTestIds = {
  treatment: 'content-list-list-item.treatment',
  collectionLink: 'content-list-list-item.collectionLink',
  preview: 'content-list-list-item.preview'
};

//ContinueWatchingListItem.tsx
export const continueWatchingListItemTestIds = {
  treatment: 'continue-watching-list-item.treatment',
  contentType: 'continue-watching-list-item.contentType'
};

//HeroListItem.tsx
export const heroListItemTestIds = {
  treatment: 'hero-list-item.treatment',
  preview: 'hero-list-item.preview',
  hpcLink: 'hero-list-item.hpc-link'
};

//InllinePageListItem.tsx
export const inllinePageListItemTestIds = {
  preview: 'inline-page-list-item.preview'
};

//InlinePromoListItem.tsx
export const inlinePromoListItemTestIds = {
  preview: 'inline-promo-list-item.preview'
};

//LandingPromoListItem.tsx
export const landingPromoListItemTestIds = {
  title: 'landing-promo-list-item.title'
};

//LinkingAccountListItem.tsx
export const linkingAccountListItemTestIds = {
  button: 'linking-account-list-item.preview-button'
};

//LivePlusListItem.tsx
export const livePlusListItemTestIds = {
  button: 'live-plus-list-item.button'
};

//MenuListItem.tsx
export const menuListItemTestIds = {
  targetId: 'menu-list-item.targetId'
};

//OneBrandPromoListItem.tsx
export const oneBrandPromoListItemTestIds = {
  button: 'one-brand-promo-list-item.button',
  title: 'one-brand-promo-list-item.title'
};

//OutboundListItem.tsx
export const outboundListItemTestIds = {
  button: 'outbound-list-item.button',
  title: 'outbound-list-item.title'
};

//PageListListItem.tsx
export const pageListListItemTestIds = {
  treatment: 'page-list-list-item.treatment'
};

//PlanSummaryListItem.tsx
export const planSummaryListItemTestIds = {
  button: 'plan-summary-list-item.button'
};

//PlaybackPaywallListItem.tsx
export const playbackPaywallListItemTestIds = {
  button: 'playback-paywall-list-item.button',
  title: 'playback-paywall-list-item.title'
};

//ProfileListItem.tsx
export const profileListItemTestIds = {
  button: 'profile-list-item.button',
  title: 'profile-list-item.title'
};

//PromotionListItem.tsx
export const promotionListItemTestIds = {
  button: 'promotion-list-item.button',
  title: 'promotion-list-item.title'
};

//RecommendationsListItem.tsx
export const recommendationsListItemTestIds = {
  treatment: 'recommendations-list-item.treatment',
  recommendationsType: 'recommendations-list-item.type',
  contentTypes: 'recommendations-list-item.content-types'
};

//RegWallListItem.tsx
export const regWallListItemTestIds = {
  button: 'reg-wall-list-item.button',
  title: 'reg-wall-list-item.title'
};

//SportsEventListItem.tsx
export const sportsEventListItemTestIds = {
  treatment: 'sports-event-list-item.treatment',
  sportsEventType: 'sports-event-list-item.sports-event-type',
  sportId: 'sports-event-list-item.sport-id',
  leagueId: 'sports-event-list-item.league-id',
  preview: 'sports-event-list-item.preview'
};

//SportsheroListItem.tsx
export const sportsheroListItemTestIds = {
  preview: 'sports-hero-list-item.preview'
};

//TdsGiftCardsListItem.tsx
export const tdsGiftCardsListItemTestIds = {
  title: 'tds-gift-cards-list-item.title'
};

//TournamentSplashHeroListItem.tsx
export const tournamentSplashHeroListItemTestIds = {
  treatment: 'tournament-splash-hero-list-item.treatment',
  leagueId: 'tournament-splash-hero-list-item.league-id',
  preview: 'tournament-splash-hero-list-item.preview'
};

//WatchListListItem.tsx
export const watchListListItemTestIds = {
  treatment: 'watch-list-list-item.treatment'
};

//AutomationForm.tsx
export const automationFormTestIds = {
  validatorForm: 'login.validatorForm',
  emailInput: 'login.emailInput',
  passwordInput: 'login.passwordInput',
  loginButton: 'login.loginButton'
};

//LoginForm.tsx
export const loginFormTestIds = {
  loginButton: 'login.login-button'
};

//MetadataManager.tsx
export const metadataManagerTestIds = {
  root: 'collections.root',
  expandButton: expandTestIds.expandButton,
  closeIcon: 'collections.expand-button.close-icon',
  openIcon: 'collections.expand-button.open-icon'
};

//Payments.tsx
export const paymentsTestIds = {
  tabs: 'payments.tabs',
  tabPlans: 'payments.tabPlans',
  tabPromotions: 'payments.tabsPromotion',
  tabCoupons: 'payments.tabCoupons',
  tabDefaults: 'payments.tabDefaults',
  tabOfferMappings: 'payments.tabOfferMappings'
};

//CouponBadge.tsx
export const couponBadgeTestIds = {
  chip: 'coupon-badge.chip',
  tooltip: 'coupon-badge.tooltip'
};

//CouponDiscount.tsx
export const couponDiscountTestIds = {
  componentRoot: 'coupon-discount.component-root'
};

//CouponDuration.tsx
export const couponDurationTestIds = {
  componentRoot: 'coupon-duration.component-root'
};

//CouponPicker.tsx
export const couponPickerTestIds = {
  autocomplete: 'coupon-picker.autocomplete',
  option: 'coupon-picker.option'
};

//CouponsGrid.tsx
export const couponsGridTestIds = {
  textFilter: 'coupons-grid.text-filter',
  stateFilter: 'coupons-grid.state-filter',
  stateFilterOption: 'coupons-grid.state-filter-option',
  typeFilter: 'coupons-grid.type-filter',
  typeFilterOption: 'coupons-grid.type-filter-option'
};

//OfferMappingForm.tsx
export const offerMappingFormTestIds = {
  formBody: 'offer-mapping-form.form-body',
  saveButton: 'offer-mapping-form.save-button',
  offerMappingId: 'offer-mapping-form.promo-id',
  nameInput: 'offer-mapping-form.name-input',
  offerCode: 'offer-mapping-form.offer-code',
  notes: 'offer-mapping-form.notes',
  addSubManagerCouponMappingItem: 'offer-mapping-form.add-sub-manager-coupon-mapping-item'
};

//SubscriptionManagerCouponMappingItem.tsx
export const subscriptionManagerCouponMappingItemTestIds = {
  root: 'subscription-manager-coupon-mapping-item.root',
  deleteItemButton: 'subscription-manager-coupon-mapping-item.delete-button',
  codeInput: 'subscription-manager-coupon-mapping-item.code-input',
  countryInput: 'subscription-manager-coupon-mapping-item.country-input',
  option: 'subscription-manager-coupon-mapping-item.option',
  autocomplete: 'subscription-manager-coupon-mapping-item.autocomplete'
};

//OfferMappingManager.tsx
export const offerMappingManagerTestIds = {
  sourceCode: 'offer-mapping-manager.source-code',
  textFilter: 'offer-mapping-manager.text-filter',
  offerMappings: 'offer-mapping-manager.mappings'
};

//PlanBadge.tsx
export const planBadgeTestIds = {
  chip: 'plan-badge.chip',
  tooltip: 'plan-badge.tooltip'
};

//PlanForm.tsx
export const planFormTestIds = {
  formBody: 'plan-form.form-body',
  saveButton: 'plan-form.save-button',
  templateIdInput: 'plan-form.template-id-input',
  promoPriceUnitInput: 'plan-form.promo-price-unit-input',
  promoPriceLengthInput: 'plan-form.promo-price-length-input',
  freeTrialUnitInput: 'plan-form.free-trial-unit-input'
};

//PlanPicker.tsx
export const planPickerTestIds = {
  autocomplete: 'plan-picker.autocomplete',
  option: 'plan-picker.option'
};

//PlansGrid.tsx
export const plansGridTestIds = {
  textFilter: 'plans-grid.text-filter',
  planGroupFilter: 'plans-grid.plan-group-filter',
  planGroupFilterOption: 'plans-grid.plan-group-filter-option',
  deviceFilter: 'plans-grid.device-filter',
  deviceFilterOption: 'plans-grid.device-filter-option',
  countryFilter: 'plans-grid.country-filter'
};

//PromoForm.tsx
export const promoFormTestIds = {
  formBody: 'promo-form.form-body',
  saveButton: 'promo-form.save-button',
  endPromotionButton: 'promo-form.end-promotion-button',
  startPromotionButton: 'promo-form.start-promotion-button',
  nameInput: 'promo-form.name-input',
  newPlanItemButton: 'promo-form.new-plan-item-button',
  promoTypes: 'promo-form.promo-types',
  promoTypesItem: 'promo-form.promo-types-item',
  promoAudiences: 'promo-form.promo-audiences',
  promoAudiencesItem: 'promo-form.promo-audiences-item',
  promoId: 'promo-form.promo-id'
};

//PromoPlanItem.tsx
export const promoPlanItemTestIds = {
  root: 'promo-plan-item.root',
  deleteItemButton: 'promo-plan-item.delete-button',
  promoPriceUnitInput: 'promo-plan-item.promo-price-unit-input',
  promoPriceLengthInput: 'promo-plan-item.promo-price-length-input',
  templateIdInput: 'promo-plan-item.template-id-input'
};

//PromoGroupPicker.tsx
export const promoGroupPickerTestIds = {
  autocomplete: 'promo-group-picker.autocomplete',
  option: 'promo-group-picker.option'
};

//PromoManager.tsx
export const promoManagerTestIds = {
  textFilter: 'promo-manager.text-filter',
  statusFilter: 'promo-manager.status-filter',
  statusFilterOption: 'promo-manager.status-filter-option',
  audienceFilter: 'promo-manager.audience-filter',
  audienceFilterOption: 'promo-manager.audience-filter-option',
  promoTypeFilter: 'promo-manager.promo-type-filter',
  promoTypeFilterOption: 'promo-manager.promo-type-filter-option',
  promoIds: 'promo-manager.promo-id'
};

//PromoStatus.tsx
export const promoStatusTestIds = {
  componentRoot: 'promo-status.component-root',
  draftStatus: 'promo-status.draft-status',
  activeStatus: 'promo-status.active-status',
  endedStatus: 'promo-status.ended-status'
};

//TemplateIdPicker.tsx
export const templateIdPickerTestIds = {
  autocomplete: 'template-id-picker.autocomplete',
  option: 'template-id-picker.option'
};

//AdTargeting.tsx
export const adTargetingTestIds = {
  root: 'ad-targeting.root',
  keyValueRow: 'ad-targeting.key-value-row',
  keyTextField: 'ad-targeting.key-text-field',
  valueTextField: 'ad-targeting.value-text-field',
  removeKeyValuePairButton: 'ad-targeting.remove-key-value-pair-button',
  addKeyValuePairButton: 'ad-targeting.add-key-value-pair-button'
};

//Button.tsx
export const buttonTestIds = {
  button: 'button.button',
  spinner: 'button.spinner'
};

//ClearableDatePicker.tsx
export const clearableDatePickerTestIds = {
  textField: 'text-field',
  closeButton: 'close-button'
};

//ClearableDateTimePicker.tsx
export const clearableDateTimePickerTestIds = {
  componentRoot: 'clearable-date-time-picker.root',
  textField: 'text-field',
  clearButton: 'clear-button'
};

//CollectionPages.tsx
export const collectionPagesTestIds = {
  pages: 'collection-pages.pages',
  pageOption: 'collection-pages.page-option'
};

//ConfirmDialog.tsx
export const confirmDialogTestIds = {
  root: 'confirm-dialog-root',
  confirm: 'confirm-dialog.confirm',
  cancel: 'confirm-dialog.cancel'
};

//ContentDrawer.tsx
export const contentDrawerTestIds = {
  root: 'content-drawer.root',
  closeBtn: 'content-drawer.close-btn',
  saveAndPublishBtn: 'content-drawer.save-and-publish-btn',
  contentAlertsTestIds
};

//AssociatedCollections.tsx
export const associatedCollectionsTestIds = {
  form: 'associated-collections.form',
  searchField: 'associated-collections.search-field',
  submitBtn: 'associated-collections.submit-btn',
  removeAllBtn: 'associated-collections.remove-all-btn',
  collectionList: 'associated-collections.collection-list',
  collectionItem: 'associated-collections.collection-item',
  removeAllConfirm: 'associated-collections.remove-all-confirm'
};

//HeroPresets.tsx
export const heroPresetsTestIds = {
  newPresetButton: 'hero-presets.new-preset-button',
  deleteConfirm: 'hero-presets.delete-confirm',
  heroPresetItem: 'hero-presets.hero-preset-item',
  loader: 'hero-presets.loader'
};

//HeroPresetItem.tsx
export const heroPresetItemTestIds = {
  edit: 'hero-preset-item.edit',
  delete: 'hero-preset-item.delete'
};

//ReverseChronological.tsx
export const reverseChronologicalTestIds = {
  formBody: 'reverse-chron.form-body',
  reverseChronSelector: 'reverse-chron.selector',
  reverseChronSelectorItem: 'reverse-chron.selector-item',
  contentGroupSelector: 'content-group.selector',
  contentGroupSelectorItem: 'content-group.selector-item',
  contentEntitlementFilteringAddButton: 'content-entitlement-filtering.add-button',
  contentEntitlementFilteringRemoveButton: 'content-entitlement-filtering.remove-button'
};

//CountryBadge.tsx
export const countryBadgeTestIds = {
  chip: 'country-badge.chip',
  avatar: 'country-bage.avatar'
};

//CountryBadgesMerged.tsx
export const countryBadgesMergedTestIds = {
  countryBadgeMain: 'country-badges-merged.country-badge-main',
  countryBadgeTooltip: 'country-badges-merged.country-badge-tooltip',
  tooltip: 'country-badges-merged.tooltip'
};

//CountryExceptions.tsx
export const countryExceptionsTestIds = {
  root: 'country-exceptions.root',
  blankState: 'country-exceptions.empty-state',
  addCountry: 'country-exceptions.add-country',
  countriesBadge: 'country-exceptions.countries-badge',
  exception: (key: string): string => `country-exceptions.exception-${key}`,
  countryInput: (key: string): string => `country-exceptions.country-input-${key}`,
  removeCountry: (key: string): string => `country-exceptions.remove-country-${key}`
};

//CountryPicker.tsx
export const countryPickerTestIds = {
  textFilter: 'country-picker.filter-text-field',
  continentsFilter: 'country-picker.continents-filter',
  listItem: 'country-picker.list-item',
  countryChip: 'country-picker.country-chip'
};

//CountryPickerLite.tsx
export const countryPickerLiteTestIds = {
  autocomplete: 'cpl.autocomplete',
  textField: 'cpl.text-field',
  option: 'cpl.option',
  chip: 'cpl.chip',
  presetMenuButton: 'cpl.preset-menu-button',
  presetMenuItem: 'cpl.preset-menu-item',
  onlyButton: 'cpl.only-button'
};

//DataGrid.tsx
export const dataGridTestIds = {
  root: 'data-grid.root',
  row: 'data-grid.row',
  headerColumn: 'data-grid.header-column',
  rowCell: 'data-grid.row-cell',
  sortAsc: 'data-grid.sort-asc',
  sortDesc: 'data-grid.sort-desc',
  loadingState: 'data-grid.loading-state'
};

//DateRange.tsx
export const dateRangeTestIds = {
  root: 'date-range.root',
  pickerTypeSelect: 'date-range.pick-type-select',
  option: (option: DateRangeOption): string => `date-range.option.${option}`,
  startDatePicker: 'date-range.start-date-picker',
  endDatePicker: 'date-range.end-date-picker'
};

//DateTimeRange.tsx
export const dateTimeRangeTestIds = {
  startCheck: 'dtr.start-check',
  endCheck: 'dtr.end-check',
  startTime: 'dtr.start-time',
  endTime: 'dtr.end-time'
};

//DynamicDatePicker.tsx
export const dynamicDatePickerTestIds = {
  value: 'dynamic-date-picker.value',
  timeUnit: 'dynamic-date-picker.time-unit',
  direction: 'dynamic-date-picker.direction',
  option: (option: string): string => `dynamic-date-picker.option-${option}`
};

//EntitlementsMultiSelectLite.tsx
export const entitlementsMultiSelectLiteTestIds = {
  badgeContainer: 'entitlements-multi-select-lite.badge-container',
  popover: 'entitlements-multi-select-lite.popover',
  checkbox: 'entitlements-multi-select-lite.checkbox'
};

//FoldableSection.tsx
export const foldableSectionTestIds = {
  root: 'foldable-section.root',
  summary: 'foldable-section.summary'
};

//HPCPicker.tsx
export const HPCPickerTestIds = {
  autocomplete: 'hpc-picker.autocomplete',
  loader: 'hpc-picker.loader',
  option: 'hpc-picker.option',
  preview: 'hpc-picker.preview',
  previewLabel: 'hpc-picker.preview-label',
  previewCount: 'hpc-picker.preview-count'
};

//HybridDatePicker.tsx
export const hybridDatePickerTestIds = {
  root: 'hybrid-date-picker.root',
  switch: 'hybrid-date-picker.switch'
};

//IdBadge.tsx
export const idBadgeTestIds = {
  copyButton: 'id-badge.copy-button',
  linkButton: 'id-badge.link-button'
};

//LocalizedInputCollection.tsx
export const localizedInputCollectionTestIds = {
  field: (language: DocumentLocale, fieldName: string): string => `lic-field-${language}-${fieldName}`,
  addButton: 'lic-add-button',
  removeButton: (language: DocumentLocale): string => `lic-remove-${language}`,
  languageSelect: 'lic-language-select',
  languageMenuItem: 'lic-language-menu-item'
};

//LokaliseAutocomplete.tsx
export const lokaliseAutocompleteTestIds = {
  autoCompleteSearch: 'auto-complete-search',
  autoCompleteSearchInput: 'auto-complete-search.input',
  autoCompleteOption: 'auto-complete-search.option'
};

//MultiSelect.tsx
export const multiSelectTestIds = {
  autocomplete: 'multi-select.autocomplete',
  option: 'multi-select.option',
  chip: 'multi-select.chip',
  textField: 'text-field'
};

//Pagination.tsx
export const paginationTestIds = {
  root: 'pagination-root',
  resultsPerPage: 'pagination-resultsPerPage',
  prevButton: 'pagination-prevBtn',
  nextButton: 'pagination-nextBtn',
  gotoPageInput: 'pagination-gotoPageInput',
  gotoPageButton: 'pagination-gotoPageBtn'
};

//PermissionsGroupBadge.tsx
export const permissionsGroupBadgeTestIds = {
  badge: 'permissions-group-badge.badge'
};

//PermissionsGroupSelector.tsx
export const permissionsGroupSelectorTestIds = {
  root: 'permissions-group-selector.root',
  option: 'permissions-group-selector.option'
};

//PlatformMultiSelect.tsx
export const platformMultiSelectTestIds = {
  autocomplete: 'device-filter.autocomplete',
  autocompleteOption: 'device-filter.autocomplete-option'
};

//Presets.tsx
export const presetsTestIds = {
  autocomplete: 'presets-autocomplete',
  autocompleteOption: 'presets-autocompleteOption'
};

//PresetForm.tsx
export const presetFormTestIds = {
  root: 'preset-form.root',
  saveButton: 'preset-form.save-button',
  deleteButton: 'preset-form.delete-button',
  cancelButton: 'preset-form.cancel-button'
};

//PresetModal.tsx
export const presetModalTestIds = {
  validatorForm: 'presetModal-validatorForm',
  presetNameInput: 'presetModal-presetNameInput'
};

//RevisionManager.tsx
export const revisionManagerTestIds = {
  button: 'revisionmanager-button',
  revisionListItem: 'revisionmanager-listitem',
  revisionLoadMore: 'revisionmanager-loadmore',
  spinner: 'revisionmanager-spinner'
};

//ScheduleTimePicker.tsx
export const scheduleTimePickerTestIds = {
  root: 'schedule-time.root',
  keyValueRow: 'schedule-time.key-value-row',
  addScheduleButton: 'schedule-time.add-schedule-button',
  removeScheduleButton: 'schedule-time.remove-schedule-button'
};

//Slug.tsx
export const slugTestIds = {
  input: 'slug.input',
  button: 'slug.button'
};

//SuggestionsAutocomplete.tsx
export const suggestionsAutocompleteTestIds = {
  autocomplete: 'suggestions-autocomplete.autocomplete',
  textField: 'suggestions-autocomplete.text-field'
};

//TextField.tsx
export const textFieldTestIds = {
  textField: 'text-field',
  closeButton: 'close-button'
};

//TextValidator.tsx
export const textValidatorTestIds = {
  textValidator: 'text-validator'
};

//TransferList.tsx
export const transferListTestIds = {
  root: 'transfer-list.root',
  leftSearchBox: 'transfer-list.left-search-box',
  rightSearchBox: 'transfer-list.right-search-box',
  leftTitle: 'transfer-list.left-title',
  rightTitle: 'transfer-list.right-title',
  leftSkeleton: 'transfer-list.left-skeleton',
  rightSkeleton: 'transfer-list.right-skeleton',
  removeItemButton: (key: string): string => `transfer-list.remove-item-${key}`,
  addItemButton: (key: string): string => `transfer-list.add-item-${key}`,
  leftText: (key: string): string => `transfer-list.left-text-${key}`,
  rightText: (key: string): string => `transfer-list.right-text-${key}`,
  leftListItem: 'transfer-list.left-list-item',
  rightListItem: 'transfer-list.right-list-item',
  allRightBtn: 'transfer-list.all-right-btn',
  allLeftBtn: 'transfer-list.all-left-btn'
};

//ViewContentButton.tsx
export const viewContentButtonTestIds = {
  root: 'view-content-button.root'
};

//LeagueBadge.tsx
export const leagueBadgeTestIds = {
  root: 'league-badge.root'
};

//LeaguesBadge.tsx
export const leaguesBadgeTestIds = {
  leagueTooltip: 'leagues-badge.league-tooltip'
};

//MatchList.tsx
export const matchListTestIds = {
  matchList: 'match-list.list',
  matchListItem: 'match-list.list-item',
  skeleton: 'match-list.skeleton',
  skeletonRow: 'match-list.skeleton-row'
};

//SportsForm.tsx
export const sportsFormTestIds = {
  cancelButton: 'sports-form.cancel-button',
  saveButton: 'sports-form.save-button',
  deleteButton: 'sports-form.delete-button',
  confirmDialog: 'sports-form.confirm-delete-dialog',
  sportName: 'sports-form.sport-name',
  sportId: 'sports-form.sport-id',
  sportPortraitImage: 'sports-form.sport-portrait-image',
  sportLandscapeImage: 'sports-form.sport-landscape-image',
  formBody: 'sports-form.form-body'
};

//SportsManager.tsx
export const sportsManagerTestIds = {
  addNewTeam: 'sports-manager.add-new-team',
  addNewLeague: 'sports-manager.add-new-league',
  eventSpreadsheet: 'sports-manager.event-spreedsheet',
  leagueListItem: 'sports-manager.league-list-item',
  editLeagueButton: 'sports-manager.edit-league-button',
  leagueTextFilter: 'sports-manager.league-text-filter',
  teamTextFilter: 'sports-manager.team-text-filter',
  sportSelect: 'sports-manager.select-sport',
  sportSelectItem: 'sports-manager.select-sport-item',
  globalSearchCheckbox: 'sports-manager.global-search-checkbox',
  sportMenuButton: 'sports-manager.menu-button',
  editSportButton: 'sports-manager.edit-sport-button',
  newSportButton: 'sports-manager.new-sport-button',
  deleteSportButton: 'sports-manager.delete-sport-button',
  deleteSportConfirmDialog: 'sports-manager.delete-sport-confirm-dialog'
};

//TeamListItem.tsx
export const teamListItemTestIds = {
  teamListItem: 'sports-manager.team-list-item',
  editTeamButton: 'sports-manager.edit-team-button'
};

//TeamForm.tsx
export const teamFormTestIds = {
  cancelButton: 'team-form.cancel-button',
  saveButton: 'team-form.save-button',
  deleteButton: 'team-form.delete-button',
  confirmDialog: 'team-form.confirm-delete-dialog',
  sportTypeSelect: 'team-form.sport-type-select',
  sportTypeSelectItem: 'team-form.sport-type-select-item',
  teamName: 'team-form.team-name',
  teamId: 'team-form.team-id',
  parentId: 'team-form.team-parent-id',
  teamLogo: 'team-form.team-logo',
  alternateLogo: 'team-form.alternate-logo',
  formBody: 'team-form.form-body'
};

//TournamentForm.tsx
export const tournamentFormTestIds = {
  formBody: 'league-form.form-body',
  leagueIdInput: 'league-form.leagueId-input',
  nameInput: 'league-form.nameInput',
  landscapeImageInput: 'league-form.landscape-input',
  portraitImageInput: 'league-form.portrait-input',
  sportSelector: 'league-form.sport-selector',
  cancelButton: 'league-form.cancel-button',
  saveButton: 'league-form.save-button',
  deleteButton: 'league-form.delete-button',
  confirmDialog: 'league-form.confirm-delete-dialog'
};

//TournamentsMultiSelect.tsx
export const tournamentsMultiSelectTestIds = {
  autocomplete: 'sports-picker-multi-select.autocomplete',
  option: 'sports-picker-multi-select.option',
  chip: 'sports-picker-multi-select.chip'
};

//Users.tsx
export const usersTestIds = {
  newUserButton: 'users.new-user-button',
  textFilter: 'users.text-filter',
  groupFilterSelect: 'users.group-filter-select',
  groupFilterSelectItem: 'users.group-filter-select-item'
};

//UserForm.tsx
export const userFormTestIds = {
  formBody: 'user-form.form-body',
  firstNameInput: 'user-form.first-name-input',
  lastNameInput: 'user-form.last-name-input',
  emailInput: 'user-form.email-input',
  roleSelector: 'user-form.role-selector',
  cancelButton: 'user-form.cancel-button',
  saveButton: 'user-form.save-button',
  deleteButton: 'user-form.delete-button',
  confirmDialog: 'user-form.confirm-delete-dialog'
};

//UserPermissionsGroups.tsx
export const userPermissionsGroupsTestIds = {
  root: 'user-permissions-groups.root',
  userPermissionsRow: 'user-permissions-groups.row',
  addPermissionsGroupButton: 'user-permissions-groups.add-button',
  groupSelector: 'user-permissions-groups.group-selector',
  groupSelectorItem: 'user-permissions-groups.group-selector-item',
  roleSelector: 'user-permissions-groups.role-selector',
  roleSelectorItem: 'user-permissions-groups.role-selector-item',
  removePermissionsGroupButton: 'user-permissions-group.remove-button'
};

//usePrompt.tsx
export const usePromptTestIds = {
  abandonMessage: 'history.abandon-message'
};
