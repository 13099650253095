import React from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../hooks';
import { RecommendationsType } from '../../../../API';
import FormControl from '../../../shared/FormControl';
import { without } from 'lodash-es';
import { reccomendationsTypePickerTestIds } from '../../../shared/TestsIds';

interface IRecommendationsTypePickerProps {
  value: RecommendationsType | undefined;
  onChange: (changedValues: RecommendationsType | undefined) => unknown;
}

const useStyles = makeStyles()(() => ({
  selector: {
    minWidth: 260
  }
}));

export function RecommendationsTypePicker({ value, onChange }: IRecommendationsTypePickerProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  // const options = Object.values(RecommendationsType); // TODO use this once we remove 'SIMILARS' from the enum
  const options: RecommendationsType[] = without(Object.values(RecommendationsType), 'SIMILARS' as RecommendationsType);

  return (
    <FormControl>
      <InputLabel id="recommendationSelector" shrink variant="standard">
        {t('layouts.recommendations_type')}
      </InputLabel>
      <Select
        label={t('layouts.recommendations_type')}
        labelId="recommendationSelector"
        className={classes.selector}
        value={value}
        onChange={({ target: { value } }) => {
          onChange(value as RecommendationsType);
        }}
        data-testid={reccomendationsTypePickerTestIds.recommendationSelector}
      >
        {options.map((value, index) => (
          <MenuItem
            key={index}
            value={RecommendationsType[value]}
            data-testid={reccomendationsTypePickerTestIds.recommendationItem}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
