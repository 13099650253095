import React from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useLocales, useTheme } from '../../../../hooks';
import { TargetPlatform } from '../../../../API';
import { PlatformBadge } from '../PlatformBadge';
import { difference } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { platformMultiSelectTestIds } from '../../TestsIds';

export interface IPlatformMultiSelectProps {
  label: string;
  value: TargetPlatform[];
  onChange: (values: TargetPlatform[]) => void;
  singleSelect?: boolean;
  platforms?: TargetPlatform[];
}

const useStyles = makeStyles()(() => ({
  autocomplete: {
    '& .MuiFormControl-root': {
      marginBottom: '0 !important'
    },
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible'
    }
  },
  textField: {
    caretColor: 'transparent',
    '&, & input': {
      cursor: 'pointer',
      minWidth: '0 !important'
    },
    '& .MuiInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  }
}));

const allPlatforms = Object.values(TargetPlatform);

export function PlatformMultiSelect({
  value,
  onChange,
  label,
  singleSelect,
  platforms = allPlatforms
}: IPlatformMultiSelectProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  return (
    <Autocomplete<TargetPlatform, true, boolean>
      sx={{ width: `${singleSelect ? 220 : 260}px` }}
      multiple={true}
      className={classes.autocomplete}
      options={platforms}
      getOptionLabel={(option) => String(option)}
      disableClearable={!singleSelect || value.length === platforms.length}
      disableCloseOnSelect={!singleSelect}
      renderOption={(props, option, { selected }) => (
        <li {...props} data-testid={platformMultiSelectTestIds.autocompleteOption} data-option={option}>
          {!singleSelect && <Checkbox color={formControlColor} checked={selected} />}
          {t(`platforms.platform_name.${option}`)}
        </li>
      )}
      renderTags={(values: string[]) => (
        <PlatformBadge platforms={values as TargetPlatform[]} allPlatforms={platforms} />
      )}
      renderInput={(params) => {
        const inputProps = { ...params.InputProps, disabled: true };
        return (
          <TextField
            className={classes.textField}
            color={formControlColor}
            label={label}
            {...params}
            InputProps={inputProps}
          />
        );
      }}
      value={value}
      onChange={(_, changedValues) => {
        if (singleSelect) {
          if (!changedValues.length) {
            onChange(platforms);
          } else if (value.length === 1) {
            onChange(difference(changedValues, value));
          } else {
            onChange(difference(value, changedValues));
          }
        } else if (changedValues.length) {
          onChange(changedValues);
        }
      }}
      data-testid={platformMultiSelectTestIds.autocomplete}
    />
  );
}
