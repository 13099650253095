import React, { forwardRef } from 'react';
import { Controller, FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';

import {
  BackgroundBody,
  OutboundUiModuleBody,
  OutboundUiModuleBodySection2ProviderLogoLogoBody,
  OutboundUiModuleBodySection7ErrorMessageBody
} from '../../../../../API';
import { FormBody, FormField } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { withStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../../../hooks';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import generateId from '../../../../../utils/generateId';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import FoldableSection from '../../../../shared/FoldableSection';
import { intersection } from 'lodash';
import { useRecoilValue } from 'recoil';
import { withLayoutErrors } from '../../../../../state/Layouts';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { Sortable } from '../../../../shared/Sortable';
import { Add, Delete } from '@mui/icons-material';
import IconButton from '../../../../shared/IconButton';
import Button from '../../../../shared/Button';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { outboundFormTestIds } from '../../../../shared/TestsIds';

type OutboundProps = keyof OutboundUiModuleBody;

type BackgroundFieldProp = 'section1Background' | 'section5Background' | 'section10Background';

interface BackgroundFieldProps {
  prop: BackgroundFieldProp;
  name: keyof BackgroundBody;
  required?: boolean;
  'data-testid'?: string;
}

interface OutboundUiModuleBodySection2ProviderLogoLogoBodyProps {
  name: keyof OutboundUiModuleBodySection2ProviderLogoLogoBody;
  required?: boolean;
  'data-testid'?: string;
  assetType: AssetTypesEnum;
  position: number;
}

const CustomFoldableSection = withStyles(FoldableSection, (theme) => ({
  root: {
    ['&.MuiAccordion-root']: {
      backgroundColor: theme.palette.background.default
    }
  },
  region: {
    ['& .MuiAccordionDetails-root']: {
      padding: theme.spacing(2)
    }
  }
}));

const OutboundForm = forwardRef<UIModuleTypeForm<OutboundUiModuleBody>>(() => {
  const { t } = useLocales();
  const { formControlColor, theme } = useTheme();
  const { control, watch } = useFormContext<OutboundUiModuleBody>();
  const layoutErrors = useRecoilValue(withLayoutErrors);

  const moduleId = watch('moduleId');

  const headerColor = (required: string[]) =>
    intersection(layoutErrors, required).length
      ? {
          color: theme.palette.error.main
        }
      : {};

  const {
    append: appendProviderLogo,
    remove: removeProviderLogo,
    fields: fieldsProviderLogo,
    replace: replaceProviderLogo
  } = useFieldArray({ control, name: 'section2ProviderLogo' });

  const {
    append: appendErrorMessage,
    remove: removeErrorMessage,
    fields: fieldsErrorMessage,
    replace: replaceErrorMessage
  } = useFieldArray({ control, name: 'section7ErrorMessages' });

  const AssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: {
    name: OutboundProps;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField>
            <AssetBrowser
              label={isRequired(label)}
              assetId={moduleId || ''}
              assetType={assetType}
              value={value as string}
              data-testid={outboundFormTestIds.formField(name)}
              hasError={!!error}
              onChange={onChange}
            />
          </FormField>
        )}
      />
    );
  };

  const renderAssetPicker = (
    name: keyof OutboundUiModuleBodySection2ProviderLogoLogoBody,
    assetType: AssetTypesEnum,
    moduleId: string,
    required: boolean,
    value: string,
    onChange: (event: string | React.ChangeEvent<Element>) => void,
    error: FieldError | undefined
  ) => (
    <FormField>
      <AssetBrowser
        label={markAsRequired(t(`layouts.outbound_form.fields.${name}`), required)}
        assetId={moduleId}
        assetType={assetType}
        value={value}
        data-testid={outboundFormTestIds.formField(name)}
        hasError={!!error}
        onChange={onChange}
      />
    </FormField>
  );

  const AssetPickerProviderLogo = ({
    name,
    assetType,
    position,
    required = false
  }: OutboundUiModuleBodySection2ProviderLogoLogoBodyProps): React.ReactElement => (
    <Controller
      control={control}
      name={`section2ProviderLogo.${position}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) =>
        renderAssetPicker(name, assetType, moduleId || '', required, value as string, onChange, error)
      }
    />
  );

  const BackgroundAssetPicker = ({
    prop,
    name,
    assetType,
    required = false
  }: BackgroundFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={`${prop}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.outbound_form.fields.background.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={outboundFormTestIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const BackgroundField = ({ prop, section }: { prop: BackgroundFieldProp; section: number }) => {
    function getAssetType(backgroundVariation: string): AssetTypesEnum {
      const key = `section${section}${backgroundVariation}` as keyof typeof AssetTypes.outbound;
      return AssetTypes.outbound[key];
    }

    return (
      <>
        <Typography sx={{ margin: theme.spacing(4, 0), fontWeight: 'semibold' }} variant="body2">
          {t('layouts.outbound_form.headers.background')}
        </Typography>
        <Controller
          control={control}
          name={`${prop}.id`}
          defaultValue={generateId()}
          render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="portraitFillImage"
          assetType={getAssetType('BackgroundPortraitFillImage')}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="landscapeFillImage"
          assetType={getAssetType('BackgroundLandscapeFillImage')}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="mobileFillImage"
          assetType={getAssetType('BackgroundMobileFillImage')}
        />
        <BackgroundAssetPicker prop={prop} name="ctvFillImage" assetType={getAssetType('BackgroundCtvFillImage')} />
        <BackgroundAssetPicker
          prop={prop}
          name="desktopFillImage"
          assetType={getAssetType('BackgroundDesktopFillImage')}
        />
        <BackgroundAssetPicker
          prop={prop}
          name="tabletFillImage"
          assetType={getAssetType('BackgroundTabletFillImage')}
        />
      </>
    );
  };

  const onAddProviderLogo = () => {
    appendProviderLogo({
      id: generateId(),
      providerLogo: ''
    });
  };

  const onAddErrorMessage = () => {
    appendErrorMessage({
      id: generateId(),
      section7Header: '',
      section7SubHeader: '',
      section7Body: '',
      section7Cta1: '',
      section7Cta2: '',
      section7Cta3: ''
    });
  };

  const LokaliseErrorMessageField = (
    name: keyof OutboundUiModuleBodySection7ErrorMessageBody,
    position = 0
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`section7ErrorMessages.${position}.${name}` as keyof OutboundUiModuleBody}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.outbound_form.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={false}
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={outboundFormTestIds.formBody}>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section1')}
          </Typography>
        }
        expanded
        disableGutters
      >
        <BackgroundField prop="section1Background" section={1} />
        <Box>
          <AssetPicker
            name="section1Logo"
            label={t('layouts.outbound_form.fields.section1Logo')}
            assetType={AssetTypes.outbound.section1Logo}
          />
        </Box>
        <Box>
          <LokaliseField
            name="section1HeaderGeneric"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section1HeaderGeneric')}
          />
          <LokaliseField
            name="section1HeaderWithProviderName"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section1HeaderWithProviderName')}
          />
          <LokaliseField
            name="section1SubHeader"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section1SubHeader')}
          />
          <LokaliseField
            name="section1CtaGeneric"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section1CtaGeneric')}
          />
          <LokaliseField
            name="section1CtaProviderName"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section1CtaProviderName')}
          />
          <LokaliseField
            name="section1CtaSignIn"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section1CtaSignIn')}
          />
          <LokaliseField
            name="section1CtaHome"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section1CtaHome')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section2')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section2Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section2Header')}
          />
        </Box>
        <Box>
          <Sortable
            list={fieldsProviderLogo}
            setList={replaceProviderLogo}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsProviderLogo.map((providerLogo, i) => (
              <Stack
                key={providerLogo.id}
                data-testid={outboundFormTestIds.providerLogo}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                <AssetPickerProviderLogo
                  name="providerLogo"
                  assetType={AssetTypes.outbound.section2ProviderLogo}
                  position={i}
                  required
                />
                <IconButton
                  title={t('layouts.outbound_form.actions.remove_provider_logo')}
                  onClick={() => removeProviderLogo(i)}
                  sx={{ mt: 4 }}
                  data-testid={outboundFormTestIds.providerLogoRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddProviderLogo}
            data-testid={outboundFormTestIds.providerLogoAddButton}
          >
            {t('layouts.outbound_form.actions.add_provider_logo')}
          </Button>
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section3')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section3Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3Header')}
          />
          <LokaliseField
            name="section3HeaderAlternative"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3HeaderAlternative')}
          />
          <LokaliseField
            name="section3SubHeader"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3SubHeader')}
          />
          <LokaliseField
            name="section3SubHeaderAlternative"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3SubHeaderAlternative')}
          />
          <LokaliseField
            name="section3MailHint"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3MailHint')}
          />
          <LokaliseField
            name="section3PasswordHint"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3PasswordHint')}
          />
          <LokaliseField
            name="section3LegalDisclaimer"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3LegalDisclaimer')}
          />
          <LokaliseField
            name="section3CtaCreateAccount"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3CtaCreateAccount')}
          />
          <LokaliseField
            name="section3CtaSkip"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3CtaSkip')}
          />
          <LokaliseField
            name="section3CtaSignIn"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3CtaSignIn')}
          />
          <LokaliseField
            name="section3ErrorEmail"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3ErrorEmail')}
          />
          <LokaliseField
            name="section3ErrorPassword"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section3ErrorPassword')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section4')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section4Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section4Header')}
          />
          <LokaliseField
            name="section4SubHeader"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section4SubHeader')}
          />
          <LokaliseField
            name="section4Body"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section4Body')}
          />
          <LokaliseField
            name="section4CtaLinkAccount"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section4CtaLinkAccount')}
          />
          <LokaliseField
            name="section4CtaCreateAccount"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section4CtaCreateAccount')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section5')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <BackgroundField prop="section5Background" section={5} />
        </Box>
        <Box>
          <LokaliseField
            name="section5Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section5Header')}
          />
          <LokaliseField
            name="section5Body"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section5Body')}
          />
          <LokaliseField
            name="section5CtaWatchNow"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section5CtaWatchNow')}
          />
          <LokaliseField
            name="section5CtaDownloadApp"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section5CtaDownloadApp')}
          />
          <LokaliseField
            name="section5CtaContinueBrowser"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section5CtaContinueBrowser')}
          />
          <LokaliseField
            name="section5CtaNotifications"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section5CtaNotifications')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section6')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section6Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section6Header')}
          />
          <LokaliseField
            name="section6MainHint"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section6MainHint')}
          />
          <LokaliseField
            name="section6PasswordHint"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section6PasswordHint')}
          />
          <LokaliseField
            name="section6CtaSignIn"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section6CtaSignIn')}
          />
          <LokaliseField
            name="section6CtaCreateAccount"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section6CtaCreateAccount')}
          />
          <LokaliseField
            name="section6CtaForgotPassword"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section6CtaForgotPassword')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section7')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <Sortable
            list={fieldsErrorMessage}
            setList={replaceErrorMessage}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsErrorMessage.map((errorMessage, i) => (
              <Stack
                key={errorMessage.id}
                data-testid={outboundFormTestIds.errorMessage}
                gap={6}
                marginBottom={5}
                padding={2}
                direction="row"
                width="100%"
              >
                <Box width="100%">
                  {LokaliseErrorMessageField('section7Header', i)}
                  {LokaliseErrorMessageField('section7SubHeader', i)}
                  {LokaliseErrorMessageField('section7Body', i)}
                  {LokaliseErrorMessageField('section7Cta1', i)}
                  {LokaliseErrorMessageField('section7Cta2', i)}
                  {LokaliseErrorMessageField('section7Cta3', i)}
                </Box>
                <IconButton
                  title={t('layouts.outbound_form.actions.remove_error_message')}
                  onClick={() => removeErrorMessage(i)}
                  sx={{ mt: 4 }}
                  data-testid={outboundFormTestIds.errorMessageRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddErrorMessage}
            data-testid={outboundFormTestIds.errorMessageAddButton}
          >
            {t('layouts.outbound_form.actions.add_error_message')}
          </Button>
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section8')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section8HeaderRoku"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section8HeaderRoku')}
          />
          <LokaliseField
            name="section8HeaderCtv"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section8HeaderCtv')}
          />
          <LokaliseField
            name="section8Step1Roku"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section8Step1Roku')}
          />
          <LokaliseField
            name="section8Step1UrlRoku"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section8Step1UrlRoku')}
          />
          <LokaliseField
            name="section8Step2Roku"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section8Step2Roku')}
          />
          <LokaliseField
            name="section8CodeLength"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section8CodeLength')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section9')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section9Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section9Header')}
          />
          <LokaliseField
            name="section9Step1"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section9Step1')}
          />
          <LokaliseField
            name="section9Step2"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section9Step2')}
          />
          <LokaliseField
            name="section9Step3"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section9Step3')}
          />
          <LokaliseField
            name="section9CtaActivate"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section9CtaActivate')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section10')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <BackgroundField prop="section10Background" section={10} />
        </Box>
        <Box>
          <LokaliseField
            name="section10Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section10Header')}
          />
          <LokaliseField
            name="section10Body"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section10Body')}
          />
          <LokaliseField
            name="section10CtaContinue"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section10CtaContinue')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section11')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section11Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section11Header')}
          />
          <LokaliseField
            name="section11CtaWatchNow"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section11CtaWatchNow')}
          />
          <LokaliseField
            name="section11ErrorMessage"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section11ErrorMessage')}
          />
        </Box>
      </CustomFoldableSection>
      <CustomFoldableSection
        summary={
          <Typography sx={headerColor([])} variant="h6">
            {t('layouts.outbound_form.headers.section12')}
          </Typography>
        }
        disableGutters
      >
        <Box>
          <LokaliseField
            name="section12Header"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section12Header')}
          />
          <LokaliseField
            name="section12HeaderAvod"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section12HeaderAvod')}
          />
          <LokaliseField
            name="section12Body"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section12Body')}
          />
          <LokaliseField
            name="section12BodyAvod"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section12BodyAvod')}
          />
          <LokaliseField
            name="section12CtaContinueWeb"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section12CtaContinueWeb')}
          />
          <LokaliseField
            name="section12CtaDownloadApp"
            control={control}
            toTranslate="layouts.outbound_form.fields"
            dataTestId={outboundFormTestIds.formField('section12CtaDownloadApp')}
          />
        </Box>
      </CustomFoldableSection>
    </FormBody>
  );
});

OutboundForm.displayName = 'OutboundForm';

export default OutboundForm;
