import React, { useEffect } from 'react';
import { CopyAll, Edit, SaveAlt } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { LayoutType, MenuSectionUiModuleBody, MenuSectionUiModuleBodyModuleTypeEnum } from '../../../API';
import { useLocales, useLayoutView, useLayouts } from '../../../hooks';
import { useConfig } from '../../../hooks/Config';
import type { LocaleKeys } from '../../../locales/i18n';
import { withAllSettings } from '../../../state/Config';
import {
  DRAFT_LAYOUTS,
  PAST_LAYOUTS,
  withAllLayouts,
  withEntitlementsFilter,
  withLayoutsType,
  withLayoutWarnings,
  withLiveLayouts,
  withPlatformsFilter,
  withPreviewCollectionId,
  withPreviewLeagueId,
  withSelectedLayout,
  withSelectedLayoutUIModules,
  withSelectedWhichLayouts,
  withSetCloningLayoutId,
  withSetEditingLayoutId,
  withShouldSaveLayoutWithWarnings,
  withShowLayoutWarnings
} from '../../../state/Layouts';
import { SpacingEnum, themeColors } from '../../../theme';
import { isDynamicCollectionPage, isDynamicLeaguePage } from '../../../utils/consts/uiModules';
import Button from '../../shared/Button';
import CountryBadgesMerged from '../../shared/CountryBadgesMerged';
import DraftBadge from '../../shared/DraftBadge';
import { EditorNotes } from '../../shared/EditorNotes';
import { EntitlementsFilter } from '../../shared/Entitlements';
import HistoryManager from '../../shared/HistoryManager';
import IconButton from '../../shared/IconButton';
import { LiveTimestamp } from '../../shared/LiveTimestamp';
import Localized from '../../shared/Localized';
import LayoutLeaguePreviewChooser from '../LayoutLeaguePreviewChooser';
import LiveCountriesBadge from '../LiveCountriesBadge';

import LayoutViewModules from './LayoutViewModules/LayoutViewModules';
import { UIModuleMenu } from './UIModuleMenu';
import { UserBadge } from '../../shared/UserBadge';
import { PlatformMultiSelect } from '../../shared/Platforms/PlatformMultiSelect';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { LayoutViewModuleActions } from './LayoutViewModuleActions';
import CollectionPicker, { collectionPickerClasses } from '../UIModuleForm/CollectionPicker';
import { useData } from '../../../data-layer';
import ConcurrentEditors from '../../shared/ConcurrentEditors/ConcurrentEditors';
import { useFirestore } from '../../../hooks/Google/useFirestore';
import { HomepageOptions, withThemeSpacing } from '../../../state/theme';
import { LayoutViewSearch } from './LayoutViewSearch';
import { withHistoryManager } from '../../../state/General/withHistoryManager';
import useConfirm from '../../../hooks/General/useConfirm';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { layoutViewTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles<{ spacing: SpacingEnum }>()((theme, { spacing }) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  topSection: {
    padding: theme.spacing(4, 4, 2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minHeight: [181, 193, 205][[SpacingEnum.COMPACT, SpacingEnum.BALANCED, SpacingEnum.SPACIOUS].indexOf(spacing)],
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    overflowX: 'auto'
  },
  layoutTitle: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap'
  },
  layoutDescription: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    minWidth: 300
  },
  liveTimestamp: {
    marginBottom: theme.spacing(2)
  },
  skeletonTitle: {
    width: 300,
    height: theme.spacing(5)
  },
  skeletonSubtitle: {
    width: 200,
    height: theme.spacing(4)
  },
  topSectionUpper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(4)
  },
  topSectionLower: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    alignItems: 'flex-end'
  },
  topSectionBottomRight: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  filterSection: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(2)
  },
  bottomPanel: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(8)
  },
  ctaButton: {
    minWidth: 180
  },
  bullet: {
    color: themeColors.orange
  },
  uiModulesParams: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(4),
    padding: theme.spacing(4, 6),
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  collectionPickerForm: {
    flexGrow: 1,
    marginLeft: theme.spacing(6)
  },
  previewCollection: {
    [`& .${collectionPickerClasses.root}`]: {
      flexGrow: 1
    },
    [`& .${collectionPickerClasses.input}`]: {
      marginBottom: '0 !important'
    }
  }
}));

function LayoutView(): JSX.Element {
  const spacing = useRecoilValue(withThemeSpacing);
  const { classes, cx } = useStyles({ spacing });
  const { t } = useLocales();
  const { saveSelectedLayout, validateLayout, getLayoutById } = useLayouts();
  const { shouldHideUiModule } = useLayoutView();
  const { fetchCmsConfig } = useConfig();
  const { isClean: isHistoryManagerClean } = useRecoilValue(withHistoryManager);
  const { confirmUnsavedChanges } = useConfirm();
  const [selectedLayout, setSelectedLayout] = useRecoilState(withSelectedLayout);
  const { currentLayoutEditors, setHistoryManageIsClean, setIsSaved } = useFirestore({
    fetchEntityCallback: getLayoutById
  });
  const whichLayout = useRecoilValue(withSelectedWhichLayouts);
  const setLayoutWarnings = useSetRecoilState(withLayoutWarnings);
  const setShowLayoutWarnings = useSetRecoilState(withShowLayoutWarnings);
  const [shouldSave, setShouldSave] = useRecoilState(withShouldSaveLayoutWithWarnings);
  const setPreviewLeagueId = useSetRecoilState(withPreviewLeagueId);
  const [previewCollectionId, setPreviewCollectionId] = useRecoilState(withPreviewCollectionId);

  const [entitlements, setEntitlements] = useRecoilState(withEntitlementsFilter);
  const [platforms, setPlatforms] = useRecoilState(withPlatformsFilter);

  const setCloningLayoutId = useSetRecoilState(withSetCloningLayoutId);
  const uiModules = useRecoilValue(withSelectedLayoutUIModules);
  const setEditingLayoutId = useSetRecoilState(withSetEditingLayoutId);
  const liveLayouts = useRecoilValue(withLiveLayouts);
  const layouts = useRecoilValue(withAllLayouts);
  const layoutsType = useRecoilValue(withLayoutsType);
  const config = useRecoilValue(withAllSettings);
  const {
    pages: {
      state: { withSelected: withSelectedPage, withRecordBucket: withPagesByType }
    }
  } = useData();
  const selectedPage = useRecoilValue(withSelectedPage);

  const pages = useRecoilValue(withPagesByType(layoutsType));
  const { canSave, canPublish } = usePermissionsGuard({
    homepageOption: HomepageOptions.LAYOUTS
  });
  const { hasPermissions } = usePermissions();
  const { UPSERT: hasUpsertPermission, PUBLISH: hasPublishPermission } = hasPermissions(
    selectedPage?.ownerPermissionsGroup
  );

  const shouldShowLeaguePreview = selectedLayout && isDynamicLeaguePage(selectedLayout.urlPath);
  const shouldShowCollectionPreview = selectedLayout && isDynamicCollectionPage(selectedLayout.urlPath);

  const isPage = layoutsType === LayoutType.PAGE;
  const isMenu = layoutsType === LayoutType.MENU;

  useEffect(() => {
    fetchConfigIfNeeded();
    setPreviewLeagueId(undefined);
  }, [selectedLayout]);

  useEffect(() => {
    if (shouldSave) {
      (async () => {
        await handleSaveSelectedLayout();
        setShouldSave(false);
      })();
    }
  }, [shouldSave]);

  const getLiveCountries = () => {
    if (selectedLayout && liveLayouts && liveLayouts[selectedLayout.id]) {
      return liveLayouts[selectedLayout.id].countries;
    }
  };

  const fetchConfigIfNeeded = () => {
    if (!selectedLayout) return;
    if (!config) {
      fetchCmsConfig();
    }
  };

  const handleClone = () => {
    if (selectedLayout) setCloningLayoutId({ id: selectedLayout.id, canPublish });
  };

  const liveCountries = getLiveCountries();
  let nonLiveCountries = selectedLayout?.countries;
  if (selectedLayout && liveCountries) {
    nonLiveCountries = selectedLayout.countries.filter((country) => liveCountries.indexOf(country) === -1);
  }
  const isPast = whichLayout === PAST_LAYOUTS;
  const isDraft = whichLayout === DRAFT_LAYOUTS;

  const handleSaveSelectedLayout = async () => {
    if (!selectedLayout) return;
    const isLayoutValid = isDraft || shouldSave || (await validateLayout(selectedLayout));
    if (isLayoutValid) {
      await saveSelectedLayout();
      setShowLayoutWarnings(false);
      setLayoutWarnings(undefined);
      setIsSaved(true);
    }
  };

  const renderCount = () => {
    let counts;
    let localeString: LocaleKeys;
    if (isPage) {
      counts = numUiModules();
      localeString = 'layouts.x_ui_modules';
    } else if (isMenu) {
      counts = numMenuItems();
      localeString = 'layouts.x_menu_items';
    } else return <></>;
    let countLabel = t(localeString, counts);
    if (counts.y) {
      countLabel += ' ' + t('layouts.y_not_shown', counts);
    }
    return countLabel;
  };

  const numUiModules = () => {
    const numHidden = uiModules?.filter(shouldHideUiModule).length || 0;
    const numShown = (uiModules?.length || 0) - numHidden;
    return {
      x: numShown,
      y: numHidden
    };
  };

  const numMenuItems = () => {
    let totalCount = 0;
    let hiddenCount = 0;
    uiModules?.forEach((uiModule) => {
      totalCount++;
      if (shouldHideUiModule(uiModule)) hiddenCount++;
      if (uiModule.moduleType === MenuSectionUiModuleBodyModuleTypeEnum.MENU_SECTION) {
        totalCount += (uiModule as MenuSectionUiModuleBody).menuItems.length;
        hiddenCount += (uiModule as MenuSectionUiModuleBody).menuItems.filter(shouldHideUiModule).length;
      }
    });
    return { x: totalCount - hiddenCount, y: hiddenCount };
  };

  const handleEdit = async () => {
    const shallContinue = isHistoryManagerClean || (await confirmUnsavedChanges());
    if (shallContinue && selectedLayout) {
      setEditingLayoutId(selectedLayout.id);
    }
  };

  if (pages && layouts && !selectedLayout)
    return <div data-testid={layoutViewTestIds.componentRoot} className={classes.root} />;

  return (
    <div data-testid={layoutViewTestIds.componentRoot} className={classes.root}>
      <div className={classes.topSection}>
        <div className={classes.topSectionUpper}>
          {/* Top Left */}
          <div>
            <div className={classes.layoutTitle}>
              {selectedLayout && (
                <Typography variant="h5" data-testid={layoutViewTestIds.pageName}>
                  <Localized prop={selectedLayout.pageName} />
                </Typography>
              )}
              {liveCountries && <LiveCountriesBadge countryCodes={liveCountries} />}
              {nonLiveCountries && !!nonLiveCountries.length && <CountryBadgesMerged countryCodes={nonLiveCountries} />}
              {selectedLayout && !isPast && (
                <IconButton
                  size="small"
                  onClick={handleEdit}
                  data-testid={layoutViewTestIds.editLayoutButton}
                  title={t('layouts.edit_layout')}
                >
                  <Edit />
                </IconButton>
              )}
              {selectedLayout && (
                <IconButton
                  size="small"
                  disabled={!selectedLayout}
                  onClick={handleClone}
                  title={t('layouts.clone_layout')}
                  data-testid={layoutViewTestIds.cloneLayoutButton}
                >
                  <CopyAll />
                </IconButton>
              )}
              {!layouts && <Skeleton className={classes.skeletonTitle} animation="wave" />}
            </div>
            <div>
              {selectedLayout && !isDraft && (
                <LiveTimestamp
                  className={classes.liveTimestamp}
                  dateString={selectedLayout.startTime as string}
                  data-testid={layoutViewTestIds.startTime}
                />
              )}
              <div className={classes.layoutDescription}>
                {isDraft && layouts && <DraftBadge data-testid={layoutViewTestIds.draftBadge} />}
                {layouts && <UserBadge record={selectedLayout} />}
                <EditorNotes object={selectedLayout} />
                {!layouts && <Skeleton className={classes.skeletonSubtitle} animation="wave" />}
              </div>
            </div>
          </div>
          {/* Top Right */}
          <div>
            {layouts && (
              <>
                <div className={classes.filterSection}>
                  <PlatformMultiSelect
                    label={t('platforms.filter_by_platform')}
                    value={platforms}
                    onChange={setPlatforms}
                    singleSelect
                  />
                  <EntitlementsFilter entitlements={entitlements} setEntitlements={setEntitlements} />
                </div>
                <div className={classes.searchContainer}>
                  <LayoutViewSearch />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={classes.topSectionLower}>
          {/* Bottom Left */}
          <div>
            {!layouts && <Skeleton className={classes.skeletonSubtitle} animation="wave" />}
            {selectedLayout && <LayoutViewModuleActions isEditable={!isPast} />}
          </div>
          {/* Bottom Right */}
          <div className={classes.topSectionBottomRight}>
            <div>
              <ConcurrentEditors users={currentLayoutEditors} />
            </div>
            <div>
              {selectedLayout && !isPast && (
                <HistoryManager
                  id="layout"
                  state={selectedLayout}
                  onChange={setSelectedLayout}
                  onCleanChange={setHistoryManageIsClean}
                  saveCallback={handleSaveSelectedLayout}
                  customButton={
                    <Button
                      className={classes.ctaButton}
                      size="small"
                      endIcon={<SaveAlt />}
                      data-testid={layoutViewTestIds.saveLayoutButton}
                    >
                      {t('layouts.save_layout')}
                    </Button>
                  }
                  hasUpsertPermission={hasUpsertPermission && canSave}
                  hasPublishPermission={hasPublishPermission && canPublish}
                  showRedoButton
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {shouldShowLeaguePreview && layouts && (
        <div className={classes.uiModulesParams}>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.preview_league_as')}
          </Typography>
          <LayoutLeaguePreviewChooser />
        </div>
      )}
      {shouldShowCollectionPreview && layouts && (
        <div className={cx(classes.uiModulesParams, classes.previewCollection)}>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.preview_collection_as')}
          </Typography>
          <div className={classes.collectionPickerForm}>
            <CollectionPicker
              label={t('collections.collection')}
              value={previewCollectionId}
              onChange={setPreviewCollectionId}
              entitlements={entitlements}
              hideEntitlements
            />
          </div>
        </div>
      )}
      <LayoutViewModules isEditable={!isPast} hasUpsertPermission={hasUpsertPermission && canSave} />
      <div className={classes.bottomPanel}>
        {!layouts && (
          <>
            <div>
              <Skeleton className={classes.skeletonSubtitle} animation="wave" />
            </div>
            <div>
              <Skeleton className={classes.skeletonSubtitle} animation="wave" />
            </div>
          </>
        )}
        {selectedLayout && (
          <>
            <div>
              {uiModules && (
                <Typography variant="body2" color="textSecondary">
                  {renderCount()}
                </Typography>
              )}
            </div>
            {isPast && (
              <div>
                <Typography variant="body2" color="textSecondary">
                  <span className={classes.bullet}>&#9679;</span>&nbsp; {t('layouts.cant_edit_layout')}
                </Typography>
              </div>
            )}
            {!isPast && (
              <UIModuleMenu
                disabled={!hasUpsertPermission || !canSave}
                data-testid={layoutViewTestIds.uiModuleMenuButton}
                icon-data-testid={layoutViewTestIds.uiModuleMenuIconButton}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default LayoutView;
