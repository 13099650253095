import React, { useEffect, useState } from 'react';
import { useLocales } from '../../../../hooks';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import Localized from '../../../shared/Localized';
import EPGChannels from '../../../EPG/EPGChannels';
import { VllChannelResponse } from '../../../../API';
import { useData } from '../../../../data-layer';
import { EPGChannelPickerTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  contentPreview: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer'
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  error: {
    borderColor: theme.palette.error.main
  }
}));

interface IEPGChannelPickerProps {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
}

function EPGChannelPicker({ value, onChange, error }: IEPGChannelPickerProps): JSX.Element {
  const { classes, cx } = useStyles();
  const { t } = useLocales();
  const {
    channels: {
      hook: { getById: getChannel }
    }
  } = useData();
  const [channel, setChannel] = useState<VllChannelResponse>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      fetchChannel(value);
    }
  }, [value]);

  const fetchChannel = async (channelId: string) => {
    const theChannel = await getChannel(channelId);
    if (!theChannel) return;
    setChannel(theChannel);
  };

  const onChannelPicked = (pickedChannel: VllChannelResponse) => {
    setChannel(pickedChannel);
    onChange(pickedChannel.entityId);
    closeDrawer();
  };

  const openDrawer = () => {
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        data-testid={EPGChannelPickerTestIds.root}
        className={cx(classes.contentPreview, { [classes.error]: error })}
        onClick={openDrawer}
      >
        {channel && <Localized data-testid={EPGChannelPickerTestIds.title} prop={channel.title} />}
        {!channel && (
          <Typography data-testid={EPGChannelPickerTestIds.noChannel} color="textSecondary">
            {t('layouts.select_channel')}
          </Typography>
        )}
      </div>
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        headerLeft={<Typography variant="h6">{t('layouts.channel_search')}</Typography>}
        footerRight={
          <Button
            color="grey"
            className={classes.footerButton}
            onClick={closeDrawer}
            data-testid={EPGChannelPickerTestIds.closeDrawerButton}
          >
            {t('general.cancel')}
          </Button>
        }
      >
        <EPGChannels isChannelPicker onChannelPicked={onChannelPicked} />
      </Drawer>
    </>
  );
}

export default EPGChannelPicker;
