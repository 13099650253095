import { MonetizationOn } from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import { Chip, Tooltip, TooltipProps } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { useLocales } from '../../../../hooks';
import CountryBadge from '../../../shared/CountryBadge';
import { IdBadge } from '../../../shared/IdBadge';
import { planBadgeTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    fontSize: '1.2em',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  }
}));

export interface IPlanBadgeProps extends ChipProps {
  planCode: string;
  tooltipPlacement?: TooltipProps['placement'];
}

export default function PlanBadge({
  planCode,
  tooltipPlacement = 'top',
  ...props
}: IPlanBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { plans } = useData();
  const { t } = useLocales();

  const plan = useRecoilValue(plans.state.withRecordById(planCode));

  const planIcon = <MonetizationOn fontSize="small" />;

  if (!plan) return <Chip icon={planIcon} disabled label={planCode} data-testid={planBadgeTestIds.chip} {...props} />;

  return (
    <Tooltip
      title={
        <div className={classes.tooltip} data-testid={planBadgeTestIds.tooltip}>
          <div>
            <b>SKU</b>: <IdBadge id={plan.code} />
          </div>
          <div>
            <b>{t('payments.base_price')}</b>: {plan.price} {plan.currency}
          </div>
          <div>
            <b>{t('general.country')}</b>: <CountryBadge countryCode={plan.country.toUpperCase()} />
          </div>
          <div>
            <b>{t('payments.device')}</b>: {plan.device}
          </div>
        </div>
      }
      placement={tooltipPlacement}
      arrow
    >
      <Chip icon={planIcon} label={plan.name} disabled={!plan} data-testid={planBadgeTestIds.chip} {...props} />
    </Tooltip>
  );
}
