import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useEffect, useMemo, useState } from 'react';
import { DateTimeUnit, DynamicDate, DynamicDateWithFallback, TimeQueryDirection } from '../../../API';
import { useLocales, useTheme } from '../../../hooks';
import DynamicDatePicker from '../DynamicDatePicker';
import { DateTime } from 'luxon';
import { makeStyles } from 'tss-react/mui';
import { hybridDatePickerTestIds } from '../TestsIds';

export interface IHybridDatePickerProps {
  value?: DynamicDateWithFallback;
  onChange: (value: DynamicDateWithFallback) => void;
  maxDate?: DateTime;
  minDate?: DateTime;
  allowDynamicPicker?: boolean;
  'data-testid'?: string;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(-2, 0, 0, 0)
  },
  switch: {
    margin: theme.spacing(2, 0, 0, 0)
  },
  dateLabel: {
    marginBottom: '0 !important',
    width: '128px'
  }
}));

// value.toMillis is undefined in tests, so fallback to date.getTime
const getMillis = (value?: DateTime | null) =>
  value ? (value?.toMillis && value.toMillis()) || new Date(value as unknown as string).getTime() : value;

export function HybridDatePicker({
  value,
  onChange,
  maxDate,
  minDate,
  'data-testid': dataTestId,
  allowDynamicPicker = true
}: IHybridDatePickerProps): JSX.Element {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { classes } = useStyles();
  const [isDynamicDatePicker, setIsDynamicDatePicker] = useState(false);

  const dateValue = useMemo(() => {
    return value?.isoDate ? DateTime.fromISO(value.isoDate) : null;
  }, [value]);

  useEffect(() => {
    if (!allowDynamicPicker) {
      setIsDynamicDatePicker(false);
    } else if (!!value?.dynamicDate && !isDynamicDatePicker) {
      setIsDynamicDatePicker(!!value?.dynamicDate);
      setDefaultValuesForDynamicDate(value?.dynamicDate || ({} as DynamicDate));
    }
  }, [value]);

  const handleSwitchChange = (_: unknown, checked: boolean) => {
    setIsDynamicDatePicker(checked);
    if (checked) {
      setDefaultValuesForDynamicDate(value?.dynamicDate || ({} as DynamicDate));
    } else {
      onChange({ isoDate: getMillis(DateTime.now()) as unknown as string });
    }
  };

  const setDefaultValuesForDynamicDate = (dynamicDate: DynamicDate) => {
    if (dynamicDate && (!dynamicDate.value || !dynamicDate.timeUnit || !dynamicDate.direction)) {
      onChange({
        dynamicDate: {
          value: dynamicDate.value || 1,
          timeUnit: dynamicDate.timeUnit || DateTimeUnit.DAYS,
          direction: dynamicDate.direction || TimeQueryDirection.AFTER
        }
      });
    }
  };

  return (
    <div
      data-testid={hybridDatePickerTestIds.root}
      className={classes.container}
      style={{ marginBottom: isDynamicDatePicker ? '-16px' : '0px' }}
    >
      {isDynamicDatePicker && allowDynamicPicker ? (
        <DynamicDatePicker value={value?.dynamicDate} onChange={(newValue) => onChange({ dynamicDate: newValue })} />
      ) : (
        <DatePicker
          label=" "
          value={dateValue}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(newValue) => onChange({ isoDate: newValue?.toISO() || '' })}
          slotProps={{
            textField: { 'data-testid': dataTestId, title: ' ', color: formControlColor, className: classes.dateLabel }
          }}
        />
      )}
      <FormControlLabel
        className={isDynamicDatePicker ? '' : classes.switch}
        sx={{ order: 4 }}
        control={
          <Tooltip title={t('dynamic_date.label')} placement="top" arrow>
            <Switch
              size="medium"
              color={formControlColor}
              checked={isDynamicDatePicker}
              onChange={handleSwitchChange}
              data-testid={hybridDatePickerTestIds.switch}
              disableRipple
            />
          </Tooltip>
        }
        disabled={!allowDynamicPicker}
        label=" "
      />
    </div>
  );
}
