import { Edit, PlaylistAdd } from '@mui/icons-material';
import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { difference, range } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { CategoryOverrideBody, EntitlementType } from '../../../../../API';
import { useData } from '../../../../../data-layer';
import { useLocales, useTheme } from '../../../../../hooks';
import { EntitlementsShorthand, EntitlementsVisibilityBadge } from '../../../../shared/Entitlements';
import IconButton from '../../../../shared/IconButton';
import { EPGCategoryOrderingForm, EPGCategoryOrderingFormMetadata } from '../EPGCategoryOrderingForm';
import { EPGCategoryOrderingSelectorTestIds } from '../../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: theme.spacing(2, 2, 4, 4)
  },
  autocomplete: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    '& .MuiFormControl-root': {
      marginBottom: '0 !important'
    }
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    color: theme.palette.text.primary
  },
  textField: {
    caretColor: 'transparent',
    '&, & input': {
      cursor: 'pointer'
    },
    '& input': {
      minWidth: '0 !important'
    },
    '& .MuiInput-root': {
      paddingRight: '0 !important',
      '&.Mui-disabled:before': {
        borderBottomStyle: 'solid'
      }
    }
  }
}));

interface CategoryOrdering extends CategoryOverrideBody {
  default?: boolean;
}

function EPGCategoryOrderingTag({ ordering }: { ordering: CategoryOrdering }): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div className={classes.listItem}>
      <EntitlementsVisibilityBadge entitlements={ordering.entitlements} />
      <span>
        <EntitlementsShorthand entitlements={ordering.entitlements} />
      </span>
    </div>
  );
}

export function EPGCategoryOrderingSelector(): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const {
    categoryBundles: {
      state: { withSelected: withSelectedCategoryBundle, withSelectedCategoryOverrideIndex }
    }
  } = useData();
  const [selectedCategoryBundle, setSelectedCategoryBundle] = useRecoilState(withSelectedCategoryBundle);
  const [categoryOrderings, setCategoryOrderings] = useState<CategoryOrdering[]>([]);
  const [selectedIndex, setSelectedIndex] = useRecoilState(withSelectedCategoryOverrideIndex);
  const [formMetadata, setFormMetadata] = useState<EPGCategoryOrderingFormMetadata>();

  useEffect(() => {
    generateCategoryOrderings();
  }, [selectedCategoryBundle]);

  const generateCategoryOrderings = () => {
    if (!selectedCategoryBundle) return;
    const newCategoryOrderings: CategoryOrdering[] = [];
    const overriddenEntitlements: EntitlementType[] = [];
    selectedCategoryBundle.categoryOverrides?.forEach((override) => {
      override.entitlements.forEach((entitlement) => {
        overriddenEntitlements.push(entitlement);
      });
      newCategoryOrderings.push(override);
    });

    const unusedEntitlements = difference(Object.keys(EntitlementType) as EntitlementType[], overriddenEntitlements);
    newCategoryOrderings.unshift({
      entitlements: unusedEntitlements,
      order: selectedCategoryBundle.categories,
      default: true
    });
    setCategoryOrderings(newCategoryOrderings);

    // Reset the index if it is now out of bounds
    if (selectedIndex >= newCategoryOrderings.length) {
      setSelectedIndex(0);
    }
  };

  const onAutocompleteChange = (orderings: number[]) => {
    setSelectedIndex(orderings.filter((x) => x !== selectedIndex)[0] || 0);
  };

  const onEditOrderingClick = () => {
    if (!categoryOrderings.length) return;
    setFormMetadata({
      isShowingForm: true,
      isEditing: true,
      index: selectedIndex - 1, // Subtract 1 to use the index of the overrides array
      allowedEntitlements: [...categoryOrderings[0].entitlements, ...categoryOrderings[selectedIndex].entitlements],
      record: categoryOrderings[selectedIndex]
    });
  };

  const onNewOrderingClick = () => {
    if (!selectedCategoryBundle || !categoryOrderings.length) return;
    setFormMetadata({
      isShowingForm: true,
      isNew: true,
      allowedEntitlements: categoryOrderings[0].entitlements,
      record: {
        entitlements: [],
        order: selectedCategoryBundle.categories
      }
    });
  };

  const onFormSubmit = (metadata: EPGCategoryOrderingFormMetadata) => {
    if (!metadata.record || !selectedCategoryBundle) return;
    if (metadata.isNew) {
      // Add the new override to the end of the list and select it
      const categoryOverrides = [...(selectedCategoryBundle?.categoryOverrides || []), metadata.record];
      setSelectedCategoryBundle({ ...selectedCategoryBundle, categoryOverrides });
      setSelectedIndex(categoryOverrides.length);
    }
    if (metadata.isEditing && selectedCategoryBundle.categoryOverrides && metadata.index !== undefined) {
      // Update the ordering with the new overrides
      const categoryOverrides = selectedCategoryBundle.categoryOverrides
        .slice(0, metadata.index)
        .concat(metadata.record)
        .concat(selectedCategoryBundle.categoryOverrides.slice(metadata.index + 1));
      setSelectedCategoryBundle({
        ...selectedCategoryBundle,
        categoryOverrides
      });
    }
  };

  const onDelete = (metadata: EPGCategoryOrderingFormMetadata) => {
    if (!selectedCategoryBundle?.categoryOverrides || metadata.index === undefined) return;
    const categoryOverrides = selectedCategoryBundle.categoryOverrides
      .slice(0, metadata.index)
      .concat(selectedCategoryBundle.categoryOverrides.slice(metadata.index + 1));
    setSelectedCategoryBundle({
      ...selectedCategoryBundle,
      categoryOverrides
    });
  };

  return (
    <div className={classes.rootContainer}>
      <Autocomplete
        multiple={true}
        className={classes.autocomplete}
        options={range(0, categoryOrderings.length)}
        value={[selectedIndex]}
        getOptionLabel={(number) => String(number)} // Not relevant since typing is disabled
        disableClearable
        renderOption={(props, index) => (
          <li {...props} data-testid={EPGCategoryOrderingSelectorTestIds.autocompleteOption} data-index={index}>
            <EPGCategoryOrderingTag ordering={categoryOrderings[index]} />
          </li>
        )}
        renderTags={(values: number[]) => {
          return (
            <>
              {categoryOrderings[values[0]] && <EPGCategoryOrderingTag ordering={categoryOrderings[values[0]]} />}
              {!categoryOrderings[values[0]] && <div>{t('general.loading')}</div>}
            </>
          );
        }}
        renderInput={(params) => {
          const inputProps = { ...params.InputProps, disabled: true };
          return (
            <TextField
              className={classes.textField}
              color={formControlColor}
              label={`${t('epg.ordering')} (${selectedIndex === 0 ? t('epg.default') : t('epg.override')})`}
              {...params}
              InputProps={inputProps}
            />
          );
        }}
        onChange={(_, changedValues) => {
          if (changedValues.length) onAutocompleteChange(changedValues);
        }}
        data-testid={EPGCategoryOrderingSelectorTestIds.autocomplete}
      />
      <div>
        <IconButton
          onClick={onEditOrderingClick}
          disabled={selectedIndex === 0}
          data-testid={EPGCategoryOrderingSelectorTestIds.editButton}
          title={t('epg.edit_category_override')}
        >
          <Edit />
        </IconButton>
      </div>
      <div>
        {/* Disable creating a new override if all entitlements are being used by other overrides */}
        <IconButton
          onClick={onNewOrderingClick}
          disabled={categoryOrderings[0]?.entitlements.length === 0}
          data-testid={EPGCategoryOrderingSelectorTestIds.newButton}
          title={t('epg.new_category_override')}
        >
          <PlaylistAdd />
        </IconButton>
      </div>
      <EPGCategoryOrderingForm formMetadata={formMetadata} onSubmit={onFormSubmit} onDelete={onDelete} />
    </div>
  );
}
