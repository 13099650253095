import React, { useEffect, useRef, useState } from 'react';
import { useLocales, useNotifications, useTheme } from '../../../../hooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import { VllCategoriesBundleResponse, VllCategoryResponse } from '../../../../API';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DateTimeRange from '../../../shared/DateTimeRange';
import { Controller, useForm } from 'react-hook-form';
import { handleSaveDraft, handleSavePublish, isFormValid as isFormValidHelper } from '../../../../utils/formHelpers';
import useConfirm from '../../../../hooks/General/useConfirm';
import { useData } from '../../../../data-layer';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../Routes';
import PremiumBadge from '../../../shared/PremiumBadge';
import { LokaliseAutocomplete } from '../../../shared/LokaliseAutocomplete';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { EPGCategoryFormTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  dates: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    '& > *': {
      minWidth: 300,
      marginRight: theme.spacing(7)
    }
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  premiumBadgeLabel: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  }
}));

function EPGCategoryForm(): JSX.Element {
  const { canSave, canPublish, canDelete } = usePermissionsGuard({ homepageOption: HomepageOptions.EPG });
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const {
    categoryBundles: {
      state: { withSelected: withSelectedCategoryBundle },
      hook: { update: saveCategoryBundle }
    },
    categories: {
      state: { withFormMetadata, withIsSaving, withIsPublishing },
      hook: { closeForm, save: saveCategoryDraft, saveAndPublish: saveAndPublishCategory }
    }
  } = useData();
  const { notifySuccess } = useNotifications();
  const formMetadata = useRecoilValue(withFormMetadata);
  const isSaving = useRecoilValue(withIsSaving);
  const isPublishing = useRecoilValue(withIsPublishing);
  const [selectedCategoryBundle, setSelectedCategoryBundle] = useRecoilState(withSelectedCategoryBundle);
  const category = formMetadata.record;

  const [startTime, setStartTime] = useState<string | undefined>();
  const [endTime, setEndTime] = useState<string | undefined>();

  const { handleSubmit, control, reset } = useForm<VllCategoryResponse>({
    defaultValues: category
  });

  useEffect(() => {
    reset(category);
    setStartTime(category?.startTime);
    setEndTime(category?.endTime);
  }, [category]);

  const isFormValid = async () => {
    return startTime !== '' && endTime !== '' && (await isFormValidHelper(formRef));
  };

  const onSubmit = async (category: VllCategoryResponse, shouldPublish = false) => {
    if (!(await isFormValid())) return;
    const isNew = formMetadata.isNew;
    const categoryToSave: VllCategoryResponse = { ...category, startTime, endTime };
    let savedCategory: VllCategoryResponse | undefined;
    if (shouldPublish) {
      savedCategory = await saveAndPublishCategory(categoryToSave);
    } else {
      savedCategory = await saveCategoryDraft(categoryToSave);
    }
    // Add the new category to the selected bundle
    if (isNew && selectedCategoryBundle && savedCategory) {
      const newId = savedCategory.entityId;
      const newCategoryBundle = {
        ...selectedCategoryBundle,
        categories: [...selectedCategoryBundle.categories, newId],
        categoryOverrides: selectedCategoryBundle.categoryOverrides?.map((categoryOverride) => ({
          ...categoryOverride,
          order: [...categoryOverride.order, newId]
        }))
      };
      await updateSelectedCategoryBundle(newCategoryBundle);
      // Timeout is necessary to prevent history manager from prompting to discard unsaved changes
      setTimeout(() => {
        navigate(AppRoutes.epg(selectedCategoryBundle.entityId, newId));
      });
    }
    closeForm();
  };

  const handleOnClose = () => {
    if (!isSaving && !isPublishing) {
      closeForm();
    }
  };

  const handleDelete = async () => {
    if (!category || !selectedCategoryBundle) return;

    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: t('delete_category_dialog'),
      'data-testid': EPGCategoryFormTestIds.confirmDialog
    });

    if (result) {
      // Remove the category from the default list and every override (if applicable)
      const newCategoryBundle = {
        ...selectedCategoryBundle,
        categories: selectedCategoryBundle.categories.filter((categoryId) => categoryId !== category.entityId),
        categoryOverrides: selectedCategoryBundle.categoryOverrides?.map((categoryOverride) => ({
          ...categoryOverride,
          order: categoryOverride.order.filter((categoryId) => categoryId !== category.entityId)
        }))
      };
      const updatedBundle = await updateSelectedCategoryBundle(newCategoryBundle);
      if (updatedBundle) {
        notifySuccess(t('success.categories.delete'));
        // Timeout is necessary to prevent history manager from prompting to discard unsaved changes
        setTimeout(() => {
          navigate(AppRoutes.epg(updatedBundle.entityId, updatedBundle.categories[0]));
        });
      }
      closeForm();
    }
  };

  const updateSelectedCategoryBundle = async (updatedCategoryBundle: VllCategoriesBundleResponse) => {
    setSelectedCategoryBundle(updatedCategoryBundle);
    return await saveCategoryBundle(updatedCategoryBundle.entityId, updatedCategoryBundle);
  };

  return (
    <>
      <Drawer
        data-testid={EPGCategoryFormTestIds.root}
        open={formMetadata.isShowingForm}
        formRef={formRef}
        onClose={handleOnClose}
        headerLeft={
          <Typography variant="h6">{t(formMetadata.isEditing ? 'edit_category' : 'new_category')}</Typography>
        }
        headerRight={
          formMetadata.isEditing &&
          !category?.isFeatured && (
            <Button
              color="error"
              endIcon={<Delete />}
              disabled={!canDelete}
              onClick={handleDelete}
              data-testid={EPGCategoryFormTestIds.deleteButton}
            >
              {t('general.delete')}
            </Button>
          )
        }
        footerLeft={
          <>
            <Button
              className={classes.footerButton}
              color="secondary"
              loading={isSaving}
              disabled={!canSave || isSaving || isPublishing}
              onClick={handleSaveDraft(handleSubmit, onSubmit)}
              data-testid={EPGCategoryFormTestIds.saveButton}
            >
              {t('general.saveAsDraft')}
            </Button>
            <Button
              className={classes.footerButton}
              loading={isPublishing}
              disabled={!canSave || !canPublish || isSaving || isPublishing}
              onClick={handleSavePublish(handleSubmit, onSubmit)}
              data-testid={EPGCategoryFormTestIds.publishButton}
            >
              {t('general.saveAndPublish')}
            </Button>
          </>
        }
        footerRight={
          <Button
            color="grey"
            disabled={isSaving || isPublishing}
            className={classes.footerButton}
            onClick={handleOnClose}
            data-testid={EPGCategoryFormTestIds.cancelButton}
          >
            {t('general.cancel')}
          </Button>
        }
      >
        {category && (
          <div className={classes.formBody} data-testid={EPGCategoryFormTestIds.formBody}>
            {!category.isFeatured && (
              <div>
                <Controller
                  name="isPremium"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!value}
                          onChange={onChange}
                          color={formControlColor}
                          data-testid={EPGCategoryFormTestIds.isPremiumCheckbox}
                        />
                      }
                      label={
                        <div className={classes.premiumBadgeLabel}>
                          Premium
                          <PremiumBadge />
                        </div>
                      }
                    />
                  )}
                />
              </div>
            )}

            <Controller
              control={control}
              name="titleLokaliseKey"
              render={({ field: { value, name, onChange } }) => (
                <LokaliseAutocomplete
                  label={t('general.title')}
                  name={name}
                  value={value as string}
                  onChange={onChange}
                  keyPrefix="epg_"
                  data-testid={EPGCategoryFormTestIds.titleLokaliseKey}
                />
              )}
            />
            <Controller
              control={control}
              name="descriptionLokaliseKey"
              render={({ field: { value, name, onChange } }) => (
                <LokaliseAutocomplete
                  label={t('general.description')}
                  name={name}
                  value={value as string}
                  onChange={onChange}
                  data-testid={EPGCategoryFormTestIds.descriptionLokaliseKey}
                />
              )}
            />
            <div>
              <Typography variant="h6">{t('general.dates')}</Typography>
              <DateTimeRange
                className={classes.dates}
                startTimeState={[startTime, setStartTime]}
                endTimeState={[endTime, setEndTime]}
                minDate={category.revision ? undefined : new Date().toISOString()}
              />
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}

export default EPGCategoryForm;
