import React, { useRef, useState } from 'react';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Button, ButtonColor } from '../../../../shared/Button/Button';
import { useLocales } from '../../../../../hooks/General';
import { contentSearchButtonTestIds } from '../../../../shared/TestsIds';

export interface IButtonGroupProps extends Omit<ButtonGroupProps, 'color'> {
  handleDropdownClick: () => void;
  color?: ButtonColor;
  loading?: boolean;
}

export const testIds = {
  componentRoot: 'buttonGroup.component-root',
  buttonGroup: 'buttonGroup.buttonGroup',
  spinner: 'buttonGroup.spinner'
};

function ContentSearchButton({
  handleDropdownClick,
  disabled,
  className = '',
  loading,
  color = 'primary',
  variant = 'contained',
  children,
  ...props
}: IButtonGroupProps): JSX.Element {
  const { t } = useLocales();

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleMenuItemClick = () => {
    handleDropdownClick();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div data-testid={testIds.componentRoot}>
      <ButtonGroup
        ref={anchorRef}
        variant={variant}
        className={className}
        disabled={disabled || loading}
        aria-label="export"
        data-testid={contentSearchButtonTestIds.buttonGroup}
        disableElevation
        {...props}
      >
        {children}
        <Button
          size="small"
          color={color}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select export format"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={handleMenuItemClick}>{t('collections.export_csv')}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default ContentSearchButton;
