import React, { useCallback, useEffect, useState } from 'react';
import { Box, emphasize } from '@mui/material';
import { MediaComponentProps, MediaProps } from '..';
import { getMediaAspectRatio } from '../../../../../utils/styleUtils';
import License, { ImageClass, LabelClass, LicenseClass, LicenseHeight } from '../License';
import Image from '../../../../shared/Image';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../../data-layer';
import { useLocales } from '../../../../../hooks';
import { checkLicense, isChannel, isMatch } from '../../../../../utils/mediaUtilities';
import StyledPremiumBadge from '../../../../shared/StyledPremiumBadge';
import { makeStyles } from 'tss-react/mui';
import { InfoBadge } from '../../../../shared/InfoBadge/InfoBadge';
import { useRatings } from '../../../../../hooks/Media/useRatings';
import { MatchListItem } from '../../../../Sports/MatchListItem';
import { VllChannelResponse } from '../../../../../API';
import EPGChannelItem from '../../../../EPG/EPGChannelItem';
import { mediaImageTestIds } from '../../../../shared/TestsIds';

const TitleClass = 'title';

type StyleProps = Pick<MediaProps, 'displayAs'>;
type StyleClasses = 'showLicense' | 'collapsed' | 'disabled';

const useStyles = makeStyles<StyleProps, StyleClasses>()((theme, { displayAs }) => ({
  license: {
    position: 'absolute',
    bottom: 0,
    borderRadius: `0 ${theme.shape.borderRadius}px 0 0`,
    paddingRight: theme.spacing(2)
  },
  media: {
    position: 'relative',
    background: emphasize(theme.palette.background.paper, 0.1),
    fontSize: '0.9rem',
    userSelect: 'none',
    borderRadius: theme.shape.borderRadius,
    aspectRatio: getMediaAspectRatio(displayAs),
    overflow: 'hidden',
    '&:hover': {
      '& > div.title': {
        display: 'unset'
      }
    }
  },
  image: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    aspectRatio: getMediaAspectRatio(displayAs)
  },
  fallback: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  titleOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxHeight: '100%',
    whiteSpace: 'break-spaces'
  },
  collapsed: {
    display: 'none',
    background: 'rgb(0 0 0 / 70%)',
    backdropFilter: 'blur(2px)',
    color: 'white'
  },
  showLicense: {
    paddingBottom: `calc(${LicenseHeight}px + ${theme.spacing(1)})`
  },
  imageContainer: {
    opacity: 1,
    height: '100%'
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: 0.5
  },
  hoverOverlay: {
    position: 'absolute',
    inset: 0,
    [`& > .${LicenseClass} > .${LabelClass}`]: {
      display: 'none'
    },
    ['&:hover']: {
      [`& > .${TitleClass}`]: {
        display: 'unset'
      },
      [`& > .${LicenseClass}`]: {
        [`& > .${LabelClass}`]: {
          display: 'unset'
        },
        [`& > .${ImageClass}`]: {
          display: 'none'
        }
      }
    }
  },
  infoBadge: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: theme.spacing(1)
  }
}));

export function MediaImage({
  mediaId,
  imageUrl,
  displayAs,
  inCollection,
  collectionForKids,
  isEditing,
  previewMode,
  collectionSelected,
  children,
  isSmartQuery = false
}: MediaComponentProps): JSX.Element {
  const { t } = useLocales();
  const { classes, cx } = useStyles({ displayAs });
  const invalidImage = imageUrl.includes('undefined');
  const {
    media: {
      state: { withRecordById }
    },
    channels: {
      state: { withAllRecords: withAllChannels }
    }
  } = useData();
  const media = useRecoilValue(withRecordById(mediaId));
  const { localize } = useLocales();
  const [expandedTitle, setExpandedTitle] = useState(invalidImage);
  const [channel, setChannel] = useState<VllChannelResponse | undefined>(undefined);
  const { isSuitableForKids } = useRatings(mediaId);
  const allChannels = useRecoilValue(withAllChannels);

  const onImageLoad = useCallback(() => setExpandedTitle(false), [imageUrl]);
  const onImageError = useCallback(() => setExpandedTitle(true), [imageUrl]);

  const aptForKids = isSuitableForKids();

  const licenseShown = !!checkLicense(media?.publishWindowsDefault);

  const fallback = <Box className={classes.fallback} />;

  useEffect(() => {
    setChannel(allChannels?.find((c) => c.entityId === media?.contentId) as VllChannelResponse);
  }, [allChannels]);

  const getTitle = () => {
    if (media?.contentId && isChannel(media.contentId)) {
      return channel?.title || media?.title;
    }
    return media?.title;
  };

  const renderThumbnail = () => {
    if (media?.contentId) {
      if (isMatch(media.contentId)) {
        return <MatchListItem match={media} showContent={false} />;
      }
      if (isChannel(media.contentId) && channel) {
        return (
          <EPGChannelItem channel={channel} isChannelPicker={false} isContentServiceChannel={false} isStandalone />
        );
      }
    }

    if (imageUrl && !invalidImage) {
      return (
        <Image
          className={classes.image}
          draggable={false}
          src={imageUrl}
          fallback={fallback}
          onError={onImageError}
          onLoad={onImageLoad}
        />
      );
    }

    return fallback;
  };

  return (
    <Box className={classes.media}>
      <Box
        className={cx(classes.imageContainer, {
          [classes.disabled]: (inCollection && (!isEditing || isSmartQuery)) || (collectionForKids && !aptForKids)
        })}
      >
        {renderThumbnail()}
      </Box>
      <div className={classes.hoverOverlay}>
        <Box
          className={cx(TitleClass, classes.titleOverlay, {
            [classes.showLicense]: licenseShown,
            [classes.collapsed]: !expandedTitle
          })}
          data-testid={mediaImageTestIds.title}
        >
          {localize(getTitle())}
        </Box>
        {!previewMode && (
          <License
            className={classes.license}
            publishWindow={media?.publishWindowsDefault}
            data-testid={mediaImageTestIds.license}
          />
        )}
      </div>
      {media?.isPremium && <StyledPremiumBadge data-testid={mediaImageTestIds.premium} />}
      {collectionSelected && collectionForKids && !aptForKids && (
        <InfoBadge
          className={classes.infoBadge}
          title={t('collections.kids.infoTooltip')}
          data-testid={mediaImageTestIds.info}
        />
      )}
      {children}
    </Box>
  );
}
