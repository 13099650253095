import { keyBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { ContentPreview } from '..';
import { MediaResponse, SportsHeroModuleContentItem } from '../../../../../API';
import { useMatches } from '../../../../../hooks';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { IMatchThumbnailProps, MatchThumbnail } from '../../../../Sports/MatchThumbnail';
import { sportsHeroPreviewTestIds } from '../../../../shared/TestsIds';

export interface ISportsHeroPreviewProps {
  matchHeroContents: SportsHeroModuleContentItem[];
  showing: boolean;
}

export interface ISportsHeroPreviewModel extends IMatchThumbnailProps {
  matchId: string;
}

export function SportsHeroPreview({ matchHeroContents, showing }: ISportsHeroPreviewProps): React.ReactElement {
  const { getMatchesByIds } = useMatches();
  const [matches, setMatches] = useState<MediaResponse[]>();
  const [previewItems, setPreviewItems] = useState<ISportsHeroPreviewModel[]>();

  useEffect(() => {
    if (showing && !matches) {
      fetchMatches();
    }
  }, [showing]);

  useEffect(() => {
    setMatches(undefined);
    if (showing) {
      fetchMatches();
    }
  }, [matchHeroContents]);

  const fetchMatches = async () => {
    const matchIds = matchHeroContents.map((heroContentItem) => heroContentItem.matchId);
    if (!matchIds.length) return setPreviewItems([]);
    const matches = await getMatchesByIds(matchIds);
    const matchesHash = keyBy(matches, 'contentId');
    setMatches(matches);
    setPreviewItems(
      matchHeroContents.map((heroContentItem) => ({
        matchData: matchesHash[heroContentItem.matchId],
        customBackground: heroContentItem.landscapeFillImage
          ? getAssetUrl(heroContentItem.landscapeFillImage)
          : undefined,
        matchId: heroContentItem.matchId
      }))
    );
  };

  const matchThumbnail = (previewItem: ISportsHeroPreviewModel) => (
    <MatchThumbnail {...previewItem} data-testid={sportsHeroPreviewTestIds.matchThumbnail} />
  );

  return (
    <ContentPreview
      showing={showing}
      displayAs="heroLandscape" // Only landscape supported for now
      model={{
        contentList: previewItems,
        thumbnailComponent: matchThumbnail,
        idField: 'matchId'
      }}
      loaderCount={matchHeroContents.length}
    />
  );
}
