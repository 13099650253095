import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { toNumber } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { DateTimeUnit, DocumentLocale, DynamicDate, TimeQueryDirection } from '../../../API';
import { useLocales, useTheme } from '../../../hooks';
import { dynamicDatePickerTestIds } from '../TestsIds';

export interface IDynamicDatePickerProps {
  value?: DynamicDate;
  onChange: (value: DynamicDate) => void;
}

const useStyles = makeStyles()(() => ({
  dynamicValue: {
    width: '2rem'
  },
  textField: {
    marginBottom: '0 !important'
  },
  select: {
    overflow: 'auto',
    maxWidth: 'unset',
    minWidth: '100px'
  }
}));

export function DynamicDatePicker({ value: dynamicDate, onChange }: IDynamicDatePickerProps): JSX.Element {
  const { formControlColor } = useTheme();
  const { t, currentLang } = useLocales();
  const { classes } = useStyles();

  const getFieldsOrder = () => {
    return currentLang === DocumentLocale.PT ? [2, 3, 1] : [1, 2, 3];
  };

  const [fieldsOrder, setFieldsOrder] = useState(getFieldsOrder());

  useEffect(() => {
    setFieldsOrder(getFieldsOrder());
  }, [currentLang]);

  return (
    <>
      <FormControl
        color={formControlColor}
        data-testid={dynamicDatePickerTestIds.value}
        className={classes.dynamicValue}
        sx={{ order: fieldsOrder[0] }}
      >
        <TextField
          value={dynamicDate?.value}
          color={formControlColor}
          onChange={({ target: { value } }) => onChange({ ...dynamicDate, value: toNumber(value) } as DynamicDate)}
          className={classes.textField}
        />
      </FormControl>

      <FormControl
        color={formControlColor}
        data-testid={dynamicDatePickerTestIds.timeUnit}
        sx={{ order: fieldsOrder[1] }}
      >
        <Select
          value={dynamicDate?.timeUnit}
          onChange={({ target: { value } }) => onChange({ ...dynamicDate, timeUnit: value } as DynamicDate)}
          className={classes.select}
        >
          {Object.values(DateTimeUnit).map((timeUnit) => (
            <MenuItem data-testid={dynamicDatePickerTestIds.option(timeUnit)} value={timeUnit} key={timeUnit}>
              {t(`dynamic_date.time_unit_${timeUnit}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        color={formControlColor}
        data-testid={dynamicDatePickerTestIds.direction}
        sx={{ order: fieldsOrder[2] }}
      >
        <Select
          value={dynamicDate?.direction}
          onChange={({ target: { value } }) => onChange({ ...dynamicDate, direction: value } as DynamicDate)}
          className={classes.select}
        >
          {Object.values(TimeQueryDirection).map((direction) => (
            <MenuItem data-testid={dynamicDatePickerTestIds.option(direction)} value={direction} key={direction}>
              {t(`dynamic_date.direction_${direction}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
