import React from 'react';
import { AutorenewOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useLocales, useTheme } from '../../../hooks';
import { CustomValidators } from '../../../utils/customValidators';
import { generateSlug } from '../../../utils/generateSlug';
import Button from '../Button';
import TextValidator from '../TextValidator';
import { makeStyles } from 'tss-react/mui';
import { markAsRequired } from '../../../utils/formHelpers';
import { slugTestIds } from '../TestsIds';

export const slugClasses = {
  root: 'slug-root',
  input: 'slug-input',
  button: 'slug-button'
};

type SlugProps = {
  value: string | React.ChangeEvent<Element> | undefined;
  onChange: (event: string | React.ChangeEvent<Element>) => void;
  required?: boolean;
  slugify: string | undefined;
  className?: string;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    // Targets the div element from TextValidator
    ['& > div']: {
      flexGrow: 1
    }
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

export function Slug({ value, onChange, required, slugify, className }: SlugProps): React.ReactElement {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { classes, cx } = useStyles();

  const onGenerate = () => {
    if (slugify) {
      onChange(generateSlug(slugify));
    }
  };

  const validators = [CustomValidators.isSlugValid, ...(required ? ['required'] : [])];

  return (
    <Stack direction="row" gap={2} alignItems="start" className={cx(classes.root, className)}>
      <TextValidator
        color={formControlColor}
        name="slug"
        label={markAsRequired(t('general.slug'), !!required)}
        value={value ?? ''}
        fullWidth
        className={slugClasses.input}
        validators={validators}
        errorMessages={[t('general.slug_is_required'), t('general.field_is_required')]}
        onChange={({ target: { value } }) => onChange(value)}
        helperText={t('general.slug_sample_string')}
        data-testid={slugTestIds.input}
      />
      <Button
        endIcon={<AutorenewOutlined />}
        disabled={!slugify}
        className={cx(classes.button, slugClasses.button)}
        onClick={onGenerate}
        data-testid={slugTestIds.button}
      >
        {t('general.generate')}
      </Button>
    </Stack>
  );
}
