import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { BecauseYouUiModuleBody } from '../../../../../API';
import { getContentTreatmentIcon } from '../../../../../utils/uiModuleUtils';

export type BecauseYouListItemProps = IBaseUIModuleListItemProps<BecauseYouUiModuleBody>;

export const testIds = {
  treatment: 'because-you-list-item.treatment',
  source: 'because-you-list-item.source',
  type: 'because-you-list-item.type'
};

const BecauseYouListItem = ({ uiModule, ...props }: BecauseYouListItemProps): JSX.Element => {
  const { t } = useLocales();

  const Title = () => {
    if (uiModule.title && Object.values(uiModule.title).every((value) => value.length > 0)) {
      return <Localized prop={uiModule.title} />;
    }
    const title = {
      en: uiModule.textTitle,
      es: uiModule.textTitle,
      pt: uiModule.textTitle
    };
    return <Localized prop={title} />;
  };

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Title />}
      topLine={[
        <Box key="treatment" display="flex" alignItems="center" gap={2}>
          <Box key="treatment" display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="textSecondary">
              {t('layouts.treatment')}:
            </Typography>
            <Chip
              icon={getContentTreatmentIcon(uiModule.contentTreatment)}
              label={toTitleCase(uiModule.contentTreatment)}
              size="small"
              data-testid={testIds.treatment}
            />
          </Box>
          <Box key="treatment" display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="textSecondary">
              {t('layouts.because_you.source')}:
            </Typography>
            <Chip label={toTitleCase(uiModule.source)} size="small" data-testid={testIds.source} />
          </Box>
          <Box key="treatment" display="flex" alignItems="center" gap={1}>
            <Typography variant="body2" color="textSecondary">
              {t('layouts.because_you.type')}:
            </Typography>
            <Chip label={toTitleCase(uiModule.type)} size="small" data-testid={testIds.type} />
          </Box>
        </Box>
      ]}
      {...props}
    />
  );
};

export default BecauseYouListItem;
