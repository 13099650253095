/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ContentCollectionType {
    VIDEO_LIST = 'VIDEO_LIST',
    SPORTS_EVENTS = 'SPORTS_EVENTS',
    EPISODE = 'EPISODE',
    MOVIE = 'MOVIE',
    SERIES = 'SERIES',
    NOVELA = 'NOVELA',
    SPORTS = 'SPORTS',
    CLIP = 'CLIP',
    SHORT_CLIP = 'SHORT_CLIP',
    TRAILER = 'TRAILER',
    MIXED_LIST = 'MIXED_LIST',
    SHORT_NOVELA = 'SHORT_NOVELA'
}



