import React, { useEffect, useState } from 'react';
import { useLocales, useTextFilter, useMatches } from '../../../../hooks';
import { InputAdornment, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import Localized from '../../../shared/Localized';
import ShadowScroller from '../../../shared/ShadowScroller';
import { Search } from '@mui/icons-material';
import TextField from '../../../shared/TextField';
import { LocalizedFieldBody, MediaResponse } from '../../../../API';
import { useRecoilValue } from 'recoil';
import { withAllMatches } from '../../../../state/Sports';
import Repeat from '../../../shared/Repeat';
import { MatchListItem } from '../../../Sports/MatchListItem';
import { withSelectedLayoutCountries } from '../../../../state/Layouts';
import { matchPickerTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  contentPreview: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer'
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  skeletonRow: {
    margin: theme.spacing(4),
    height: theme.spacing(6)
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    padding: theme.spacing(4, 4, 0),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  searchBar: {
    width: '50%',
    minWidth: 280,
    marginTop: theme.spacing(1)
  },
  matchListItem: {
    padding: theme.spacing(3, 4),
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.divider
    }
  }
}));

interface IMatchPickerPickerProps {
  onChange: (value: { contentId: string; title: LocalizedFieldBody }) => void;
  title: LocalizedFieldBody | undefined;
}

function MatchPicker({ title, onChange }: IMatchPickerPickerProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const matches = useRecoilValue(withAllMatches);
  const countries = useRecoilValue(withSelectedLayoutCountries);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    onFilterInputChange,
    clearFilter,
    filteredResults: filterMatches
  } = useTextFilter(['title', { field: 'sportsEvent' }]);
  const { getAllUpcomingMatches } = useMatches();

  useEffect(() => {
    getAllUpcomingMatches(countries);
  }, []);

  const handleMatchPicked = (event: React.MouseEvent, pickedMatch: MediaResponse) => {
    onChange({ contentId: pickedMatch.contentId, title: pickedMatch.title });
    closeDrawer();
  };

  const openDrawer = () => {
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const filteredMatches: MediaResponse[] = matches ? filterMatches(matches) : [];
  return (
    <>
      <div className={classes.contentPreview} onClick={openDrawer} data-testid={matchPickerTestIds.title}>
        {title && <Localized prop={title} />}
        {!title?.es && <Typography color="textSecondary">{t('layouts.select_match')}</Typography>}
      </div>
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        headerLeft={<Typography variant="h6">{t('layouts.match_search')}</Typography>}
        footerRight={
          <Button color="grey" className={classes.footerButton} onClick={closeDrawer}>
            {t('general.cancel')}
          </Button>
        }
      >
        <div className={classes.contentContainer}>
          <div className={classes.header}>
            <TextField
              onChange={onFilterInputChange}
              className={classes.searchBar}
              label={t('sports.browse_matches')}
              disabled={!matches}
              clearable
              onClear={clearFilter}
              data-testid={matchPickerTestIds.matchFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <ShadowScroller loading={!matches}>
            {!matches && (
              <Repeat n={15}>
                <Skeleton className={classes.skeletonRow} animation="wave" />
              </Repeat>
            )}
            {matches &&
              filteredMatches.map((match, i) => (
                <MatchListItem
                  key={i}
                  match={match}
                  className={classes.matchListItem}
                  onClick={(evt) => handleMatchPicked(evt, match)}
                  asList
                />
              ))}
          </ShadowScroller>
        </div>
      </Drawer>
    </>
  );
}

export default MatchPicker;
