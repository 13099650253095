import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ContentCollectionType,
  RecommendationContentType,
  RecommendationsUiModuleBody,
  RecommendationsUIModuleContentTreatment
} from '../../../../../API';
import { useLocales, useNotifications, useTheme } from '../../../../../hooks';
import CollectionPicker from '../../CollectionPicker';
import { FormBody } from '../../styles';
import TreatmentPicker from '../../TreatmentPicker';
import { UIModuleTypeForm } from '../../UIModuleForm';
import TextValidator from '../../../../shared/TextValidator';
import { makeStyles } from 'tss-react/mui';
import RecommendationsTypePicker from '../../RecommendationsTypePicker';
import RecommendationsContentTypePicker from '../../RecommendationsContentTypePicker';
import { recommendationsFormTestIds } from '../../../../shared/TestsIds';

const useClasses = makeStyles()({
  root: {
    '& .MuiFormControl-root.MuiTextField-root': {
      marginBottom: '0 !important'
    }
  },
  percentage: {
    width: 300,
    flexGrow: 1
  }
});

const RecommendationsForm = forwardRef<UIModuleTypeForm<RecommendationsUiModuleBody>>((_, ref) => {
  const { control, watch, setError } = useFormContext<RecommendationsUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { classes } = useClasses();
  const { notifyError } = useNotifications();

  const collectionTypes = Object.values(ContentCollectionType).filter(
    (type) => type !== ContentCollectionType.MIXED_LIST
  );

  const entitlements = watch('entitlements');
  useImperativeHandle(ref, () => ({
    isValid(module) {
      let valid = true;
      if (!module.contentTypes || module.contentTypes.length === 0) {
        notifyError(t('errors.layouts.no_empty_content_types'));
        setError('contentTypes', { type: 'required' });
        valid = false;
      }
      return valid;
    }
  }));

  return (
    <FormBody className={classes.root} data-testid={recommendationsFormTestIds.formBody}>
      <Controller
        control={control}
        name="contentTreatment"
        render={({ field: { value, onChange } }) => (
          <TreatmentPicker
            options={RecommendationsUIModuleContentTreatment}
            value={value}
            onChange={(value) => onChange(value as RecommendationsUIModuleContentTreatment)}
          />
        )}
      />
      <Controller
        control={control}
        name="recommendationsType"
        render={({ field: { value, onChange } }) => <RecommendationsTypePicker value={value} onChange={onChange} />}
      />
      <Controller
        control={control}
        name="contentTypes"
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <RecommendationsContentTypePicker
            value={value}
            onChange={(values) => onChange(values as RecommendationContentType[])}
            error={!!errors.contentTypes}
          />
        )}
      />
      <Controller
        control={control}
        name="premiumContentPercentage"
        render={({ field: { value, onChange } }) => (
          <TextValidator
            className={classes.percentage}
            type="number"
            name="channelNumber"
            value={value || ''}
            onChange={({ target: { value } }) => {
              onChange(value ? parseInt(value) : undefined);
            }}
            label={t('general.svod_percentage')}
            validators={['minNumber:0', 'maxNumber:100', 'matchRegexp:^[0-9]+$']}
            color={formControlColor}
            errorMessages={t('general.field_needs_to_be_number_between', { min: 0, max: 100 })}
            data-testid={recommendationsFormTestIds.premiumContentPercentage}
          />
        )}
      />
      <Controller
        control={control}
        name="collectionId"
        render={({ field: { value, onChange } }) => (
          <CollectionPicker
            label={t('layouts.fallback_collection')}
            value={value}
            onChange={onChange}
            entitlements={entitlements}
            filters={{ collectionTypes }}
          />
        )}
      />
    </FormBody>
  );
});

RecommendationsForm.displayName = 'RecommendationsForm';

export default RecommendationsForm;
