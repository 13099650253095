import React, { useEffect, useRef } from 'react';
import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { IsDeletableResponse, SportBody, SportResponse } from '../../../API';
import { useData } from '../../../data-layer';
import { useConfirm, useLocales, useTheme } from '../../../hooks';
import { AssetTypes } from '../../../utils/assetTypes';
import { CustomValidators } from '../../../utils/customValidators';
import { markAsRequired } from '../../../utils/formHelpers';
import AssetBrowser from '../../Assets/AssetBrowser';
import { Button } from '../../shared/Button/Button';
import Drawer from '../../shared/Drawer';
import InputController from '../../shared/InputController';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import { ObjectTreeView } from '../../shared/ObjectTreeView';
import TextValidator from '../../shared/TextValidator';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { sportsFormTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  formField: {
    maxWidth: 500,
    marginBottom: theme.spacing(4)
  },
  textInput: {
    minWidth: 400,
    marginBottom: theme.spacing(4)
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  treeView: {
    flexGrow: 1,
    overflowY: 'auto',
    width: 400,
    maxHeight: 300,
    padding: theme.spacing(2, 0),
    wordBreak: 'break-all'
  }
}));

function SportsForm(): JSX.Element {
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { canSave, canDelete } = usePermissionsGuard({
    homepageOption: HomepageOptions.SPORTS
  });
  const { canSave: hasUpsertPermission, canDelete: hasDeletePermission } = usePermissionsGuard({
    homepageOption: HomepageOptions.SPORTS
  });

  const {
    sports: {
      state: { withFormMetadata, withIsSaving, withIsDeleting },
      hook: { create: createSport, update: updateSport, closeForm, remove: deleteSport, validateDeletion }
    }
  } = useData();
  const formMetadata = useRecoilValue(withFormMetadata);
  const sport: SportResponse = formMetadata.record as SportResponse;
  const isSavingSport = useRecoilValue(withIsSaving);
  const isDeleting = useRecoilValue(withIsDeleting);

  const { handleSubmit, control, reset, getValues } = useForm<SportBody | SportResponse>();
  const { confirm } = useConfirm();

  useEffect(() => {
    reset(sport);
  }, [sport]);

  const saveSport = async (sportToSave: SportBody | SportResponse) => {
    let savedSport: SportResponse | undefined;
    if (formMetadata.isNew) {
      savedSport = await createSport(sportToSave);
    } else {
      savedSport = await updateSport(sport.id, sportToSave as SportResponse);
    }
    if (savedSport) closeForm();
  };

  const handleSportDeletionMessages = (deletionResponse: IsDeletableResponse) => {
    if (deletionResponse.code) {
      return (
        <>
          <Typography>{t('sports.sport_has_associations')}</Typography>
          {deletionResponse.teams && (
            <ObjectTreeView
              defaultExpanded={[]}
              object={deletionResponse.teams}
              title={t('sports.teams')}
              className={classes.treeView}
            />
          )}
          {deletionResponse.tournaments && (
            <ObjectTreeView
              defaultExpanded={[]}
              object={deletionResponse.tournaments}
              title={t('sports.tournaments')}
              className={classes.treeView}
            />
          )}
          {deletionResponse.pageLayouts && (
            <ObjectTreeView
              defaultExpanded={[]}
              object={deletionResponse.pageLayouts}
              title={t('sports.layouts')}
              className={classes.treeView}
            />
          )}
        </>
      );
    }
    return t('sports.delete_confirm_sport');
  };

  const validateSportDeletion = async () => {
    const deletionCode = await validateDeletion(sport.id);
    let result = false;
    if (deletionCode) {
      result = await confirm({
        confirmText: t('general.confirm_delete'),
        confirmColor: 'error',
        cancelText: deletionCode.code ? t('general.ok') : t('general.cancel'),
        body: handleSportDeletionMessages(deletionCode),
        'data-testid': sportsFormTestIds.confirmDialog
      });
    } else {
      result = await confirm({
        confirmText: t('general.confirm_delete'),
        confirmColor: 'error',
        cancelText: t('general.cancel'),
        body: t('sports.delete_confirm_sport'),
        'data-testid': sportsFormTestIds.confirmDialog
      });
    }

    if (result) {
      const deleted = await deleteSport(sport.id);
      if (deleted) closeForm();
    }
  };

  const onClose = () => {
    if (!isSavingSport && !isDeleting) {
      closeForm();
    }
  };

  const onSubmit: SubmitHandler<SportBody | SportResponse> = (sport) => {
    void saveSport(sport);
  };

  return (
    <>
      <Drawer
        open={formMetadata.isShowingForm}
        formRef={formRef}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
        headerLeft={<Typography variant="h6">{t(`sports.${formMetadata.isNew ? 'new' : 'edit'}_sport`)}</Typography>}
        headerRight={
          !formMetadata.isNew && (
            <Button
              color="error"
              endIcon={<Delete />}
              loading={isDeleting}
              onClick={validateSportDeletion}
              data-testid={sportsFormTestIds.deleteButton}
              disabled={!hasDeletePermission || !canDelete}
            >
              {t('general.delete')}
            </Button>
          )
        }
        footerLeft={
          <>
            <Button
              type="submit"
              loading={isSavingSport}
              disabled={isDeleting || !hasUpsertPermission || !canSave}
              className={classes.footerButton}
              data-testid={sportsFormTestIds.saveButton}
            >
              {t('general.save')}
            </Button>
          </>
        }
        footerRight={
          <Button
            color="grey"
            className={classes.footerButton}
            disabled={isDeleting}
            onClick={onClose}
            data-testid={sportsFormTestIds.cancelButton}
          >
            {t('general.cancel')}
          </Button>
        }
      >
        {sport && (
          <div className={classes.formBody} data-testid={sportsFormTestIds.formBody}>
            <LocalizedInputCollection
              containerClassName={classes.formField}
              reset={reset}
              getValues={getValues}
              fields={[
                {
                  component: (
                    <InputController
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextValidator
                          className={classes.textInput}
                          fullWidth
                          name="name"
                          color={formControlColor}
                          value={value}
                          onChange={onChange}
                          label={markAsRequired(t('general.name'))}
                          validators={[CustomValidators.requiredIfDefined]}
                          errorMessages={[t('general.field_is_required')]}
                          data-testid={sportsFormTestIds.sportName}
                        />
                      )}
                    />
                  )
                }
              ]}
            />
            <div className={classes.formField}>
              <Controller
                name="sportId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextValidator
                    fullWidth
                    color={formControlColor}
                    name="sportId"
                    label={markAsRequired(t('sports.sport_id'))}
                    value={value}
                    validators={['required']}
                    errorMessages={[t('general.field_is_required')]}
                    onChange={onChange}
                    data-testid={sportsFormTestIds.sportId}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Typography variant="body2" color="textSecondary">
                {t('sports.portrait_image')}
              </Typography>
              <Controller
                name="portraitImage"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AssetBrowser
                    value={value}
                    assetId={sport.id || AssetTypes.sports.portrait}
                    assetType={AssetTypes.sports.portrait}
                    onChange={onChange}
                    data-testid={sportsFormTestIds.sportPortraitImage}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Typography variant="body2" color="textSecondary">
                {t('sports.landscape_image')}
              </Typography>
              <Controller
                name="landscapeImage"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AssetBrowser
                    value={value}
                    assetId={sport.id || AssetTypes.sports.landscape}
                    assetType={AssetTypes.sports.landscape}
                    onChange={onChange}
                    data-testid={sportsFormTestIds.sportLandscapeImage}
                  />
                )}
              />
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}

export default SportsForm;
