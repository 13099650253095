import React, { useState, useEffect } from 'react';
import { AddOutlined, Check, Delete, DeleteSweep, DragHandle, Search, LibraryAdd } from '@mui/icons-material';
import { InputAdornment, Skeleton, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isFunction, noop, partial } from 'lodash-es';
import { useLocales } from '../../../hooks';
import generateId from '../../../utils/generateId';
import Button from '../Button';
import TextField from '../TextField';
import FormControl from '../FormControl';
import IconButton from '../IconButton';
import Repeat from '../Repeat';
import ShadowScroller from '../ShadowScroller';
import { Sortable } from '../Sortable';
import { transferListTestIds } from '../TestsIds';

export interface ITransferListProps<T> {
  className?: string;
  leftList: T[];
  rightList: T[];
  setLeftList: (newState: T[]) => void;
  keyExtractor: (child: T) => string;
  renderChild: (child: T) => React.ReactNode;
  leftTitle?: React.ReactNode;
  rightTitle?: React.ReactNode;
  isLoading?: boolean;
  skeleton?: React.ReactNode;
  skeletonNumber?: number;
  searchFn?: (searchText: string, child: T) => boolean;
}

const listItemHeight = 40;
const buttonSize = 30;

const draggingClass = 'dragging';
const ghostClass = 'ghost';
const dragHandleClass = 'dragHandle';
const addIconClass = 'addIcon';
const draggableClass = 'draggable';

const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    minHeight: 400
  },
  listColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 1,
    flexGrow: 1
  },
  scrollerContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    overflow: 'hidden'
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: listItemHeight,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    justifyContent: 'space-between'
  },
  listItemContent: {
    gap: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  topBarContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchBarContainer: {
    flexGrow: 1
  },
  sortable: {
    height: '100%',
    [`& .${draggableClass}`]: {
      cursor: 'grab',
      userSelect: 'none'
    },
    [`& .${addIconClass}`]: {
      minWidth: 0,
      flexShrink: 0,
      padding: 0,
      height: buttonSize,
      width: buttonSize
    },
    [`& .${dragHandleClass}`]: {
      display: 'none'
    },
    [`& .${ghostClass}`]: {
      opacity: 0.2
    },
    [`& .${draggingClass}`]: {
      backgroundColor: theme.palette.background.default
    },
    [`& .${draggingClass}, & .${ghostClass}`]: {
      [`& .${dragHandleClass}`]: {
        display: 'block'
      },
      [`& .${addIconClass}`]: {
        display: 'none'
      }
    }
  },
  skeleton: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: listItemHeight,
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  }
}));

export function TransferList<T>({
  leftList,
  rightList,
  setLeftList,
  renderChild,
  keyExtractor,
  leftTitle,
  rightTitle,
  className,
  isLoading,
  skeleton,
  skeletonNumber,
  searchFn
}: ITransferListProps<T>): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const [sortableGroupName] = useState(generateId());
  const [isItemSelected, setIsItemSelected] = useState<Record<string, boolean>>({});
  const [leftSearchText, setLeftSearchText] = useState('');
  const [rightSearchText, setRightSearchText] = useState('');

  const hasSearch = isFunction(searchFn);
  const isMatched = (searchText: string, child: T) => !hasSearch || !searchText || searchFn?.(searchText, child);

  useEffect(() => {
    if (!leftList) return;
    const selectedHash: Record<string, boolean> = {};
    leftList.forEach((item) => {
      selectedHash[keyExtractor(item)] = true;
    });
    setIsItemSelected(selectedHash);
  }, [leftList]);

  const addListItem = (listItem: T) => {
    setLeftList([...leftList, listItem]);
  };

  const removeAllItems = () => {
    setLeftList([]);
  };

  const removeItem = (index: number) => {
    setLeftList(leftList.slice(0, index).concat(leftList.slice(index + 1)));
  };

  const addAllItems = () => {
    setLeftList([...rightList]);
  };

  const LoadingPanel = ({ testId }: { testId: string }) => (
    <ShadowScroller loading>
      <div data-testid={testId}>
        <Repeat n={skeletonNumber || 5}>
          {skeleton || (
            <div className={classes.skeleton}>
              <Skeleton animation="wave" />
            </div>
          )}
        </Repeat>
      </div>
    </ShadowScroller>
  );

  return (
    <div className={`${classes.rootContainer} ${className}`} data-testid={transferListTestIds.root}>
      <div className={classes.listColumn}>
        {leftTitle && <div data-testid={transferListTestIds.leftTitle}>{leftTitle}</div>}
        <div className={classes.topBarContainer}>
          {hasSearch && (
            <FormControl className={classes.searchBarContainer}>
              <TextField
                placeholder={t('general.search')}
                value={leftSearchText}
                clearable={true}
                onClear={() => setLeftSearchText('')}
                onChange={(evt) => setLeftSearchText(evt.target.value)}
                disabled={isLoading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  )
                }}
                fullWidth
                data-testid={transferListTestIds.leftSearchBox}
              />
            </FormControl>
          )}
          {!hasSearch && <div></div>}
          <div>
            <IconButton
              disabled={isLoading || leftList.length === 0}
              onClick={removeAllItems}
              data-testid={transferListTestIds.allRightBtn}
              title={t('transfer_list.remove_all')}
            >
              <DeleteSweep />
            </IconButton>
          </div>
        </div>
        <div className={classes.scrollerContainer}>
          {!isLoading && (
            <ShadowScroller>
              <Sortable
                className={classes.sortable}
                list={leftList}
                setList={setLeftList}
                group={{ name: sortableGroupName, pull: true, put: true }}
                ghostClass={ghostClass}
                dragClass={draggingClass}
                disabled={isLoading || !!leftSearchText.length}
              >
                {leftList.filter(partial(isMatched, leftSearchText)).map((listItem, i) => {
                  const key = keyExtractor(listItem);
                  return (
                    <div
                      key={key}
                      className={`${classes.listItem} ${draggableClass}`}
                      data-testid={transferListTestIds.leftListItem}
                    >
                      <div className={classes.listItemContent} data-testid={transferListTestIds.leftText(key)}>
                        <DragHandle color={leftSearchText.length ? 'disabled' : 'inherit'} />
                        {renderChild(listItem)}
                      </div>
                      <IconButton onClick={() => removeItem(i)} data-testid={transferListTestIds.removeItemButton(key)}>
                        <Delete />
                      </IconButton>
                    </div>
                  );
                })}
              </Sortable>
            </ShadowScroller>
          )}
          {isLoading && <LoadingPanel testId={transferListTestIds.leftSkeleton} />}
        </div>
      </div>
      <div className={classes.listColumn}>
        {rightTitle && <div data-testid={transferListTestIds.rightTitle}>{rightTitle}</div>}
        <div className={classes.topBarContainer}>
          <Tooltip title={t('transfer_list.add_all')} placement="top" arrow>
            <div>
              <IconButton
                disabled={isLoading || leftList.length === rightList.length}
                onClick={addAllItems}
                data-testid={transferListTestIds.allLeftBtn}
              >
                <LibraryAdd />
              </IconButton>
            </div>
          </Tooltip>
          {hasSearch && (
            <FormControl className={classes.searchBarContainer}>
              <TextField
                placeholder={t('general.search')}
                value={rightSearchText}
                clearable={true}
                onClear={() => setRightSearchText('')}
                onChange={(evt) => setRightSearchText(evt.target.value)}
                disabled={isLoading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  )
                }}
                fullWidth
                data-testid={transferListTestIds.rightSearchBox}
              />
            </FormControl>
          )}
        </div>
        <div className={classes.scrollerContainer}>
          {!isLoading && (
            <ShadowScroller>
              <Sortable
                className={classes.sortable}
                list={rightList}
                setList={noop}
                group={{ name: sortableGroupName, pull: 'clone', put: false }}
                sort={false}
                disabled={!!rightSearchText.length}
                handle={`.${draggableClass}`}
                ghostClass={ghostClass}
                dragClass={draggingClass}
              >
                {rightList.filter(partial(isMatched, rightSearchText)).map((listItem) => {
                  const key = keyExtractor(listItem);
                  const isSelected = isItemSelected[key];
                  return (
                    <div
                      key={key}
                      className={`${classes.listItem} ${isSelected ? '' : draggableClass}`}
                      data-testid={transferListTestIds.rightListItem}
                    >
                      <div className={classes.listItemContent} data-testid={transferListTestIds.rightText(key)}>
                        <Button
                          className={addIconClass}
                          variant="contained"
                          disableElevation={true}
                          disabled={isSelected}
                          color="grey"
                          onClick={() => addListItem(listItem)}
                          data-testid={transferListTestIds.addItemButton(key)}
                        >
                          {!isSelected && <AddOutlined />}
                          {isSelected && <Check />}
                        </Button>
                        <DragHandle className={dragHandleClass} />
                        {renderChild(listItem)}
                      </div>
                    </div>
                  );
                })}
              </Sortable>
            </ShadowScroller>
          )}
          {isLoading && <LoadingPanel testId={transferListTestIds.rightSkeleton} />}
        </div>
      </div>
    </div>
  );
}
