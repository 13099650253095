import React, { useEffect } from 'react';
import { AddOutlined } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { DataPresetResponse, DataPresetType, HeroModuleItemType } from '../../../../API';
import useConfirm from '../../../../hooks/General/useConfirm';
import { useLocales } from '../../../../hooks';
import Button from '../../Button';
import Repeat from '../../Repeat';
import { HeroPresetItem } from './HeroPresetItem';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { heroPresetsTestIds } from '../../TestsIds';

export interface HeroPresetsProps {
  contentId: string;
}

export const useStyles = makeStyles()((theme) => ({
  newPresetButton: {
    minWidth: 180,
    margin: theme.spacing(4)
  },
  skeleton: {
    flexBasis: 1,
    flexGrow: 1,
    height: 120,
    margin: theme.spacing(2)
  },
  noPresets: {
    padding: theme.spacing(6, 4)
  }
}));

export function HeroPresets({ contentId }: HeroPresetsProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { confirm } = useConfirm();
  const {
    presets: {
      state: { withFormMetadata, withRecordBucket, withIsFetching },
      hook: { removePreset, new: newPreset, closeForm: closePresetForm, getBucket }
    }
  } = useData();
  const isLoading = useRecoilValue(withIsFetching);
  const formPreset = useRecoilValue(withFormMetadata);

  const presets = useRecoilValue(withRecordBucket(contentId));

  useEffect(() => {
    getBucket(contentId);
  }, [contentId]);

  const onNewPresetClick = () => {
    newPreset({
      type: DataPresetType.HERO_ITEM_VOD,
      data: {
        type: DataPresetType.HERO_ITEM_VOD,
        contentType: HeroModuleItemType.VOD,
        contentId
      }
    });
  };

  const onPresetDelete = async (index: number) => {
    const preset = presets?.[index];
    if (preset?.id) {
      const confirmed = await confirm({
        confirmColor: 'error',
        confirmText: t('general.confirm_delete'),
        body: t('presets.delete_preset_confirm'),
        'data-testid': heroPresetsTestIds.deleteConfirm
      });
      if (!confirmed) return;
      const deleted = await removePreset(preset.id);
      if (!deleted) return;
      if (preset.id === (formPreset.record as DataPresetResponse)?.id) closePresetForm();
    }
  };

  return (
    <div>
      {!isLoading &&
        !!presets?.length &&
        presets.map((preset, i) => (
          <HeroPresetItem
            key={i}
            presetId={preset.id as string}
            onRemove={() => onPresetDelete(i)}
            data-testid={heroPresetsTestIds.heroPresetItem}
          />
        ))}
      {!isLoading && !presets?.length && (
        <div className={classes.noPresets}>
          <Typography variant="body1" color="textSecondary">
            {t('presets.no_presets')}
          </Typography>
        </div>
      )}
      {isLoading && (
        <div data-testid={heroPresetsTestIds.loader}>
          <Repeat n={3}>
            <Skeleton className={classes.skeleton} animation="wave" />
          </Repeat>
        </div>
      )}
      <Button
        className={classes.newPresetButton}
        endIcon={<AddOutlined />}
        onClick={onNewPresetClick}
        data-testid={heroPresetsTestIds.newPresetButton}
        disabled={isLoading || formPreset.isShowingForm}
      >
        {t('presets.new_preset')}
      </Button>
    </div>
  );
}
