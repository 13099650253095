import React, { useState } from 'react';
import { ProfileUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import Button from '../../../../shared/Button';
import { StayPrimaryPortrait } from '@mui/icons-material';
import { useLocales, useTheme } from '../../../../../hooks';
import { ProfilePreview } from '../../ContentPreview/ProfilePreview';
import { profileListItemTestIds } from '../../../../shared/TestsIds';

export type ProfileListItemProps = IBaseUIModuleListItemProps<ProfileUiModuleBody>;

export function ProfileListItem({ uiModule, ...props }: ProfileListItemProps): JSX.Element {
  const { t } = useLocales();
  const [isPreviewShowing, setIsPreviewShowing] = useState(false);
  const { formControlColor } = useTheme();

  const onPreviewButtonClick = () => {
    setIsPreviewShowing(true);
  };

  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={<Localized prop={uiModule.title} data-testid={profileListItemTestIds.title} />}
        collectionLine={[
          <Button
            key="preview"
            variant="text"
            size="small"
            startIcon={<StayPrimaryPortrait />}
            color={formControlColor}
            onClick={onPreviewButtonClick}
            data-testid={profileListItemTestIds.button}
          >
            {t('layouts.preview_contents')}
          </Button>
        ]}
        {...props}
      />
      <ProfilePreview module={uiModule} open={isPreviewShowing} setOpen={setIsPreviewShowing} />
    </>
  );
}
