import React from 'react';
import Modal from '@mui/material/Modal';
import { ModalProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button, ButtonColor } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { confirmDialogTestIds } from '../TestsIds';

export interface IConfirmDialogProps extends ModalProps {
  confirmColor?: ButtonColor;
  confirmText?: string;
  cancelColor?: ButtonColor;
  cancelText?: string;
  onConfirm: () => unknown;
  isShown?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  body: {
    width: '31vw',
    minWidth: 400,
    height: '31vh',
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.background.default,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[15]
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
    fontSize: '1.2em',
    padding: theme.spacing(4),
    background: theme.palette.background.default
  },
  footer: {
    padding: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    display: 'flex',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  footerSingleButton: {
    justifyContent: 'center'
  },
  footerButtons: {
    justifyContent: 'space-between'
  }
}));

export function ConfirmDialog({
  className,
  confirmColor = 'primary',
  confirmText,
  cancelColor = 'grey',
  cancelText,
  onClose,
  onConfirm,
  children,
  isShown = true,
  ...props
}: IConfirmDialogProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (!confirmText) confirmText = t('general.confirm');
  if (!cancelText) cancelText = t('general.cancel');

  const doConfirm = () => {
    onConfirm();
  };

  const doClose = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <Modal className={className} onClose={onClose} {...props}>
      <div className={classes.body} data-testid={confirmDialogTestIds.root}>
        <div className={classes.content}>{children}</div>
        <div className={`${classes.footer} ${!isShown ? classes.footerSingleButton : classes.footerButtons}`}>
          <Button color={cancelColor} onClick={doClose} data-testid={confirmDialogTestIds.cancel}>
            {cancelText}
          </Button>
          {isShown && (
            <Button color={confirmColor} onClick={doConfirm} data-testid={confirmDialogTestIds.confirm}>
              {confirmText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
