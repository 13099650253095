import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

export const saveAsFile = (response: AxiosResponse | { data: Blob; filename: string }): void => {
  if ('data' in response && 'filename' in response) {
    saveAs(response.data, response.filename);
    return;
  }

  const filename = response?.headers['content-disposition'].split('filename=')[1].replace(/[/" ]/g, '');
  const content = response?.headers['content-type'];
  if (content === 'application/json; charset=utf-8') {
    const blob = new Blob([JSON.stringify(response?.data)], { type: 'application/json' });
    saveAs(blob, filename);
  } else {
    const blob = new Blob([response?.data as unknown as BlobPart]);
    saveAs(blob, filename);
  }
};
