import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { Accordion, AccordionDetails, AccordionSummary, emphasize, Typography } from '@mui/material';
import Modal from '../../shared/Modal';
import Button from '../../shared/Button';
import { useLocales } from '../../../hooks';
import {
  withIsSavingLayout,
  withLayout,
  withLayoutWarnings,
  withSelectedLayout,
  withShouldSaveLayoutWithWarnings,
  withShouldSubmitLayoutFormWithWarnings,
  withShowLayoutForm,
  withShowLayoutWarnings
} from '../../../state/Layouts';
import { CheckCircle, Error, ExpandMore, Report, Warning } from '@mui/icons-material';
import Localized from '../../shared/Localized';
import CountryBadgesMerged from '../../shared/CountryBadgesMerged';
import Media from '../../Collections/CollectionsPanel/Media';
import { CollectionLink } from '../../Collections/CollectionLink';
import { DisplayAsOptions } from '../../../utils/types/genericTypes';
import { bigSize } from '../../shared/Modal/Modal';
import { layoutWarningsModalTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  modal: bigSize,
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: theme.spacing(3)
  },
  modalContent: {
    padding: theme.spacing(2)
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  mediaPanel: {
    background: emphasize(theme.palette.background.paper, 0.05),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(3)
  },
  mediaErrorPanel: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  moduleTitle: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  mediaTitle: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  mediaThumbnail: {
    width: 72
  },
  mediaId: {
    opacity: 0.5
  },
  errorMessage: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  errorMessageIcon: {
    opacity: 0.25
  },
  errorMessageText: {
    opacity: 0.85
  },
  errorSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  countriesField: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'flex-start'
  },
  modalButtons: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  collectionId: {
    display: 'flex',
    alignItems: 'center'
  },
  layoutStats: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3)
  },
  layoutStat: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}));

function LayoutWarningsModal(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();

  const isLayoutFormOpen = useRecoilValue(withShowLayoutForm);
  const [isOpen, setIsOpen] = useRecoilState(withShowLayoutWarnings);
  const [layoutWarnings, setLayoutWarnings] = useRecoilState(withLayoutWarnings);
  const setShouldSubmitForm = useSetRecoilState(withShouldSubmitLayoutFormWithWarnings);
  const setShouldSaveSelectedLayout = useSetRecoilState(withShouldSaveLayoutWithWarnings);
  const formLayout = useRecoilValue(withLayout);
  const selectedLayout = useRecoilValue(withSelectedLayout);
  const isSavingLayout = useRecoilValue(withIsSavingLayout);
  const layout = isLayoutFormOpen ? formLayout : selectedLayout;
  const layoutUiModulesCount = layout?.uiModules.length || 0;
  const emptyModulesCount =
    layoutWarnings?.contentErrors?.reduce((m, e) => m + (e.emptyCountries?.length ? 1 : 0), 0) || 0;
  const incompleteModulesCount =
    layoutWarnings?.contentErrors?.reduce(
      (m, e) => m + (e.incompleteCountries?.length && !e.emptyCountries?.length ? 1 : 0),
      0
    ) || 0;
  const correctModulesCount = layoutUiModulesCount - incompleteModulesCount - emptyModulesCount;
  const hasErrors = !!emptyModulesCount;

  const closeModal = () => {
    if (isSavingLayout) return;
    setIsOpen(false);
    setLayoutWarnings(undefined);
  };

  const saveLayoutWithWarnings = () => {
    if (isLayoutFormOpen) {
      setShouldSubmitForm(true);
    } else {
      setShouldSaveSelectedLayout(true);
    }
  };

  return (
    <Modal
      open={isOpen}
      bodyClassName={classes.modal}
      headerLeft={
        <div className={classes.modalTitle}>
          {hasErrors ? (
            <>
              <Error color="error" />
              <div>
                <Typography variant="body1" fontWeight={500}>
                  {t('layouts.error_saving_layout')}
                </Typography>
                <Typography variant="body2">{t('layouts.layout_has_errors')}</Typography>
              </div>
            </>
          ) : (
            <>
              <Warning color="warning" />
              <div>
                <Typography variant="body1" fontWeight={500}>
                  {t('layouts.warning_saving_layout')}
                </Typography>
                <Typography variant="body2">{t('layouts.layout_has_warnings')}</Typography>
              </div>
            </>
          )}
        </div>
      }
      footerRight={
        <div className={classes.layoutStats}>
          <div className={classes.layoutStat}>
            <Error fontSize="small" color="error" />{' '}
            <span data-testid={layoutWarningsModalTestIds.numberOfErrors}>{emptyModulesCount}</span>{' '}
            {t('general.errors')}
          </div>
          <div className={classes.layoutStat}>
            <Warning fontSize="small" color="warning" />{' '}
            <span data-testid={layoutWarningsModalTestIds.numberOfWarnings}>{incompleteModulesCount}</span>{' '}
            {t('general.warnings')}
          </div>
          <div className={classes.layoutStat}>
            <CheckCircle fontSize="small" color="success" />{' '}
            <span data-testid={layoutWarningsModalTestIds.numberOfCorrect}>{correctModulesCount}</span>{' '}
            {t('general.correct')}
          </div>
        </div>
      }
      footerLeft={
        <div className={classes.modalButtons}>
          {!hasErrors && (
            <Button
              size="small"
              onClick={saveLayoutWithWarnings}
              startIcon={<Warning fontSize="small" />}
              data-testid={layoutWarningsModalTestIds.saveBtn}
              loading={isSavingLayout}
            >
              {t('layouts.save_with_warnings')}
            </Button>
          )}
          <Button
            size="small"
            onClick={closeModal}
            color="grey"
            data-testid={layoutWarningsModalTestIds.cancelBtn}
            disabled={isSavingLayout}
          >
            {t('layouts.close_and_fix')}
          </Button>
        </div>
      }
      data-testid={layoutWarningsModalTestIds.root}
      onClose={closeModal}
    >
      {layoutWarnings?.contentErrors ? (
        <div className={classes.modalContent}>
          {layoutWarnings.contentErrors.map((moduleError, indexModule) => (
            <Accordion key={indexModule}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="module-warning"
                id={`module-accordion-${indexModule}`}
              >
                <div className={classes.moduleTitle}>
                  {moduleError.emptyCountries?.length ? (
                    <Error fontSize="small" color="error" />
                  ) : (
                    <Warning fontSize="small" color="warning" />
                  )}
                  <Typography
                    variant="body1"
                    fontWeight="500"
                    data-testid={layoutWarningsModalTestIds.moduleTitle(indexModule)}
                  >
                    <Localized prop={moduleError.moduleTitle} />
                  </Typography>
                  {moduleError.collectionId && (
                    <Typography variant="caption" className={classes.collectionId}>
                      {t('collections.collection')}:{' '}
                      {Array.isArray(moduleError.collectionId) &&
                        moduleError.collectionId.map((collectionId) => (
                          <CollectionLink
                            key={collectionId}
                            collectionId={collectionId}
                            data-testid={layoutWarningsModalTestIds.moduleCollectionLink(indexModule)}
                          />
                        ))}
                      {!Array.isArray(moduleError.collectionId) && (
                        <CollectionLink
                          collectionId={String(moduleError.collectionId)}
                          data-testid={layoutWarningsModalTestIds.moduleCollectionLink(indexModule)}
                        />
                      )}
                    </Typography>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionContent}>
                {!!moduleError.emptyCountries?.length && (
                  <div className={classes.countriesField}>
                    <Typography variant="caption">{t('layouts.countries_where_empty')}</Typography>
                    <span data-testid={layoutWarningsModalTestIds.moduleEmptyCountries(indexModule)}>
                      <CountryBadgesMerged truncateAfter={10} countryCodes={moduleError.emptyCountries} />
                    </span>
                  </div>
                )}
                {!!moduleError.incompleteCountries?.length && (
                  <div className={classes.countriesField}>
                    <Typography variant="caption">{t('layouts.countries_where_partial_content')}</Typography>
                    <span data-testid={layoutWarningsModalTestIds.moduleIncompleteCountries(indexModule)}>
                      <CountryBadgesMerged truncateAfter={10} countryCodes={moduleError.incompleteCountries} />
                    </span>
                  </div>
                )}
                <div className={classes.errorSection}>
                  <Typography variant="caption">
                    {t('layouts.media_with_issues')} ({moduleError.mediaContentErrors.length} {t('general.out_of')}{' '}
                    {moduleError.mediaCount})
                  </Typography>
                  {moduleError.mediaContentErrors.map((mediaError, indexMedia) => {
                    return (
                      <div key={indexMedia} className={classes.mediaPanel}>
                        <div className={classes.mediaThumbnail}>
                          <Media
                            previewMode={true}
                            displayAs={DisplayAsOptions.PORTRAIT}
                            mediaId={mediaError.mediaId}
                          />
                        </div>
                        <div className={classes.mediaErrorPanel}>
                          <div className={classes.mediaTitle}>
                            <Typography
                              fontWeight="500"
                              data-testid={layoutWarningsModalTestIds.mediaTitle(indexModule, indexMedia)}
                            >
                              <Localized prop={mediaError.mediaTitle} />
                            </Typography>
                            <Typography
                              className={classes.mediaId}
                              data-testid={layoutWarningsModalTestIds.mediaId(indexModule, indexMedia)}
                            >
                              {mediaError.mediaId}
                            </Typography>
                          </div>
                          <div>
                            {mediaError.errorMessages.map((errorMessage, indexError) => (
                              <div className={classes.errorMessage} key={indexError}>
                                <Report className={classes.errorMessageIcon} fontSize="small" />
                                <Typography variant="body2" className={classes.errorMessageText}>
                                  <Localized prop={errorMessage.message} />
                                </Typography>
                              </div>
                            ))}
                          </div>
                          {mediaError.mediaUnavailableCountries?.length > 0 && (
                            <div className={classes.countriesField}>
                              <Typography variant="caption">
                                {t('layouts.countries_where_media_unavailable')}
                              </Typography>
                              <span
                                data-testid={layoutWarningsModalTestIds.mediaUnavailableCounties(
                                  indexModule,
                                  indexMedia
                                )}
                              >
                                <CountryBadgesMerged
                                  truncateAfter={10}
                                  countryCodes={mediaError.mediaUnavailableCountries}
                                />
                              </span>
                            </div>
                          )}
                          {mediaError.mediaInvalidPublishWindowCountries?.length > 0 && (
                            <div className={classes.countriesField}>
                              <Typography variant="caption">
                                {t('layouts.countries_where_media_invalid_publish_window')}
                              </Typography>
                              <span
                                data-testid={layoutWarningsModalTestIds.mediaInvalidPublishWindowCountries(
                                  indexModule,
                                  indexMedia
                                )}
                              >
                                <CountryBadgesMerged
                                  truncateAfter={10}
                                  countryCodes={mediaError.mediaInvalidPublishWindowCountries}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default LayoutWarningsModal;
