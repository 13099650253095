import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../hooks';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { advancedSearchTestIds } from '../TestsIds';

type AdvancedSearchProps = {
  open?: boolean;
  onChange?: (value: boolean) => unknown;
};

export function AdvancedSearch({ open, onChange }: AdvancedSearchProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(open);
  const { t } = useTranslation();
  const { formControlColor } = useTheme();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    onChange && onChange(!isOpen);
  };

  return (
    <Button
      color={formControlColor}
      variant="text"
      onClick={toggleOpen}
      size="small"
      style={{ fontSize: '0.8rem', textTransform: 'unset' }}
      data-testid={advancedSearchTestIds.openButton}
    >
      {isOpen ? (
        <>
          {t('collections.hide_advanced_search')}
          <ArrowDropUp />
        </>
      ) : (
        <>
          {t('collections.show_advanced_search')}
          <ArrowDropDown />
        </>
      )}
    </Button>
  );
}
