import React, { useRef, useState, useEffect } from 'react';
import { useLocales, useNotifications, useTheme } from '../../../hooks';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  withLayout,
  withShowLayoutForm,
  withIsNewLayout,
  withIsCloningLayout,
  withLayoutsTypeLowercase,
  withShouldSubmitLayoutFormWithWarnings,
  withShowLayoutWarnings,
  withLayoutWarnings,
  withIsSavingLayout
} from '../../../state/Layouts';
import { Chip, FormControlLabel, Typography, RadioGroup, Radio, Select, MenuItem, InputLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useLayouts } from '../../../hooks';
import Button from '../../shared/Button';
import Drawer from '../../shared/Drawer';
import CountryPickerLite from '../../shared/CountryPickerLite';
import { DateTime } from 'luxon';
import { SaveAlt } from '@mui/icons-material';
import { QUICK_TIME_SCHEDULES as quickTimeSchedules } from '../../../utils/appDefaults';
import { DocumentLocale, PageLayoutBodyV2, QuickScheduling } from '../../../API';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import { markAsRequired } from '../../../utils/formHelpers';
import { CustomValidators } from '../../../utils/customValidators';
import ClearableDateTimePicker from '../../shared/ClearableDateTimePicker';
import InputController from '../../shared/InputController';
import { withGlobalTimeDiff } from '../../../state/Timezone';
import { HelpText } from '../../shared/HelpText/HelpText';
import PermissionsGroupSelector from '../../shared/PermissionsGroupSelector';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import FormControl from '../../shared/FormControl';
import { useData } from '../../../data-layer';
import TextValidator from '../../shared/TextValidator';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { layoutFormTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  cloneBadge: {
    marginLeft: theme.spacing(4)
  },
  pageLabel: {
    display: 'inline-flex',
    marginRight: theme.spacing(2)
  },
  dateTimePickerContainer: {
    width: 360
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(6)
  },
  textInput: {
    minWidth: 340,
    marginBottom: theme.spacing(4)
  },
  draftScheduledRadios: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(4),
    gap: theme.spacing(4)
  },
  footerButton: {
    minWidth: 120,
    marginRight: theme.spacing(4)
  },
  quickTime: {
    width: 300,
    display: 'flex',
    flexDirection: 'column'
  },
  pageNameHelperText: {
    margin: theme.spacing(1, 0, 3)
  }
}));

enum LayoutScheduleType {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  QUICK_TIME = 'QUICK_TIME'
}

function LayoutForm(): JSX.Element {
  const { canSave, canPublish } = usePermissionsGuard({
    homepageOption: HomepageOptions.LAYOUTS
  });
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { notifyError } = useNotifications();
  const { saveFormLayout, validateLayout } = useLayouts();

  const [isOpen, setIsOpen] = useRecoilState(withShowLayoutForm);
  const [shouldSubmit, setShouldSubmit] = useRecoilState(withShouldSubmitLayoutFormWithWarnings);
  const setShowLayoutWarnings = useSetRecoilState(withShowLayoutWarnings);
  const setLayoutWarnings = useSetRecoilState(withLayoutWarnings);
  const layout = useRecoilValue(withLayout);
  const isNewLayout = useRecoilValue(withIsNewLayout);
  const isCloningLayout = useRecoilValue(withIsCloningLayout);
  const [isSavingLayout, setIsSavingLayout] = useRecoilState(withIsSavingLayout);
  const {
    pages: {
      state: { withSelected: withSelectedPage }
    }
  } = useData();
  const selectedPage = useRecoilValue(withSelectedPage);
  const { hasPermissions } = usePermissions();
  const { UPSERT: hasUpsertPermission, PUBLISH: hasPublishPermission } = hasPermissions(
    selectedPage?.ownerPermissionsGroup
  );

  const layoutsType = useRecoilValue(withLayoutsTypeLowercase);
  // layoutScheduleType tracks the selected layout type in order to not compute it from startTime when it changes
  const [layoutScheduleType, setLayoutScheduleType] = useState<LayoutScheduleType>(LayoutScheduleType.DRAFT);
  const [quickTime, setQuickTime] = useState('0');
  const globalTimeDiff = useRecoilValue(withGlobalTimeDiff);

  const { handleSubmit, control, reset, getValues } = useForm<PageLayoutBodyV2>({
    defaultValues: layout
  });

  const hasUiModules = (layout?.uiModules?.length || 0) > 0;
  const ableToPublish = hasPublishPermission && canPublish && hasUiModules;

  useEffect(() => {
    reset(prepareLayoutForForm(layout));
    if (layout) {
      setLayoutScheduleType(
        layout.startTime && ableToPublish ? LayoutScheduleType.SCHEDULED : LayoutScheduleType.DRAFT
      );
    }
  }, [layout]);

  const prepareLayoutForForm = (layout: PageLayoutBodyV2 | undefined) => {
    if (layout) {
      return { ...layout, seoDescription: layout.seoDescription ?? { [DocumentLocale.ES]: '' } };
    }
    return layout;
  };

  const isFormValid = (layout: PageLayoutBodyV2) => {
    const valid =
      layout.countries.length > 0 && (layoutScheduleType === LayoutScheduleType.DRAFT || !!layout.startTime);
    const validateSelectedDateAndTime =
      layoutScheduleType === LayoutScheduleType.DRAFT ||
      layoutScheduleType === LayoutScheduleType.SCHEDULED ||
      DateTime.fromISO(layout.startTime as string) > DateTime.now().plus({ milliseconds: -globalTimeDiff });
    const isValidLayoutUIModules =
      layoutScheduleType === LayoutScheduleType.DRAFT ||
      (layout.startTime && layout.uiModules && layout.uiModules.length > 0);
    let errorMsg;
    if (!valid) errorMsg = t('general.form_error');
    if (!isValidLayoutUIModules) errorMsg = t('errors.layouts.scheduled_layout_no_ui_modules');
    if (!validateSelectedDateAndTime) errorMsg = t('errors.layouts.layout_past_date');
    if (errorMsg) notifyError(errorMsg);
    return valid && validateSelectedDateAndTime && isValidLayoutUIModules;
  };

  const setQuickDate = (layout: PageLayoutBodyV2) => {
    return {
      ...layout,
      startTime:
        DateTime.local()
          .plus({ ...quickTimeSchedules[parseInt(quickTime, 10)], milliseconds: -globalTimeDiff })
          .toISO() || ''
    };
  };

  const layoutTransformStartTime = (layout: PageLayoutBodyV2) => {
    if (layoutScheduleType === LayoutScheduleType.QUICK_TIME) {
      return setQuickDate(layout);
    }
    if (layoutScheduleType === LayoutScheduleType.DRAFT) {
      return { ...layout, startTime: undefined };
    }
    return layout;
  };

  const onSubmit: SubmitHandler<PageLayoutBodyV2> = async (layout) => {
    const isDraft = layoutScheduleType === LayoutScheduleType.DRAFT;
    layout = layoutTransformStartTime(layout);
    setIsSavingLayout(true);
    const isLayoutValid = isDraft || shouldSubmit || (await validateLayout(layout));
    if (isFormValid(layout) && isLayoutValid) {
      const isQuickSchedule = layoutScheduleType === LayoutScheduleType.QUICK_TIME;
      if (isQuickSchedule) {
        const quickTimeOption = parseInt(quickTime);
        const quickSchedule = Object.values(QuickScheduling)[quickTimeOption];
        await saveFormLayout(layoutTransformStartTime(layout), quickSchedule as QuickScheduling);
      } else {
        await saveFormLayout(layoutTransformStartTime(layout));
      }
      setShowLayoutWarnings(false);
      setLayoutWarnings(undefined);
      setIsOpen(false);
    }
    setIsSavingLayout(false);
  };

  const onClose = () => {
    if (isSavingLayout) return;
    setIsOpen(false);
  };

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit(onSubmit)();
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  return (
    <Drawer
      open={isOpen}
      onSubmit={handleSubmit(onSubmit)}
      formRef={formRef}
      onClose={onClose}
      headerLeft={
        <div className={classes.header}>
          <Typography variant="h6">{t(`layouts.${isNewLayout ? 'new' : 'edit'}_${layoutsType}_layout`)}</Typography>
          {isCloningLayout && (
            <Chip
              color="secondary"
              label={t('general.clone')}
              size="small"
              className={classes.cloneBadge}
              data-testid={layoutFormTestIds.cloneBadge}
            />
          )}
        </div>
      }
      footerLeft={
        <Button
          endIcon={<SaveAlt />}
          type="submit"
          disabled={!canSave || !hasUpsertPermission}
          data-testid={layoutFormTestIds.saveButton}
          loading={isSavingLayout}
        >
          {t('layouts.save_layout')}
        </Button>
      }
      footerRight={
        <Button
          color="grey"
          className={classes.footerButton}
          onClick={onClose}
          data-testid={layoutFormTestIds.cancelButton}
          disabled={isSavingLayout}
        >
          {t('general.cancel')}
        </Button>
      }
    >
      {layout && (
        <div className={classes.formBody} data-testid={layoutFormTestIds.formBody}>
          <div>
            <Typography className={classes.pageLabel} variant="body2" color="textSecondary">
              {t(`layouts.${layoutsType}`)}
            </Typography>
            <Chip label={layout.urlPath} data-testid={layoutFormTestIds.urlPathChip} />
          </div>
          <div>
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={layoutScheduleType}
              onChange={(_event, value) => {
                setLayoutScheduleType(value as LayoutScheduleType);
              }}
              className={classes.draftScheduledRadios}
            >
              <FormControlLabel
                value={LayoutScheduleType.DRAFT}
                control={<Radio color={formControlColor} />}
                label={t('general.draft')}
                data-testid={layoutFormTestIds.draftRadio}
              />
              <FormControlLabel
                value={LayoutScheduleType.SCHEDULED}
                control={<Radio color={formControlColor} />}
                label={t('general.scheduled')}
                data-testid={layoutFormTestIds.scheduledRadio}
                disabled={!ableToPublish}
              />
              <FormControlLabel
                value={LayoutScheduleType.QUICK_TIME}
                control={<Radio color={formControlColor} />}
                label={t('date_picker.quick_time_scheduling')}
                data-testid={layoutFormTestIds.quickTimeRadio}
                disabled={!ableToPublish}
              />
            </RadioGroup>
            {layoutScheduleType === LayoutScheduleType.QUICK_TIME && (
              <>
                <div className={classes.quickTime}>
                  <Typography variant="caption">{t('date_picker.quick_time_scheduling')}</Typography>
                  <Select
                    required
                    value={quickTime}
                    onChange={({ target: { value } }) => {
                      setQuickTime(value);
                    }}
                    color={formControlColor}
                    data-testid={layoutFormTestIds.quickTimeSelect}
                  >
                    {quickTimeSchedules.map((time, index) => {
                      const [key] = Object.entries(time)[0];
                      return (
                        <MenuItem key={index} value={index}>
                          {`${t(`date_picker.in_${key}`, time)}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </>
            )}
            {layoutScheduleType === LayoutScheduleType.SCHEDULED && (
              <div className={classes.dateTimePickerContainer}>
                <Controller
                  name="startTime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ClearableDateTimePicker
                      label={t('date_picker.start_date_time')}
                      value={value}
                      onChange={onChange}
                      clearable={false}
                      disablePast
                      showTimezones
                      error={!!error}
                      helperText={error ? t('date_picker.error_datetime') : undefined}
                    />
                  )}
                />
              </div>
            )}
            {layoutScheduleType === LayoutScheduleType.DRAFT && (
              <HelpText>
                {!layout.uiModules.length && <>{t('layouts.draft_layout_helper_text_no_modules')}&nbsp;</>}
                {t('layouts.draft_layout_helper_text')}
              </HelpText>
            )}
          </div>
          <Controller
            name="countries"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CountryPickerLite
                label={t('layouts.countries')}
                value={value}
                onChange={onChange}
                required={true}
                fullWidth={true}
              />
            )}
          />
          <div>
            <HelpText className={classes.pageNameHelperText}>{t('layouts.page_name_helper_text')}</HelpText>
            <LocalizedInputCollection
              reset={reset}
              getValues={getValues}
              fields={[
                {
                  component: (
                    <InputController
                      name="pageName"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextValidator
                          className={classes.textInput}
                          fullWidth
                          name="pageName"
                          color={formControlColor}
                          value={value}
                          onChange={onChange}
                          label={markAsRequired(t(`layouts.${layoutsType}_name`))}
                          validators={[CustomValidators.requiredIfDefined]}
                          errorMessages={[t('general.field_is_required')]}
                        />
                      )}
                    />
                  )
                },
                {
                  component: (
                    <InputController
                      name="seoDescription"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextValidator
                          className={classes.textInput}
                          fullWidth
                          name="seoDescription"
                          color={formControlColor}
                          value={value}
                          onChange={onChange}
                          label={t('layouts.seo_description')}
                        />
                      )}
                    />
                  )
                }
              ]}
            />
          </div>
          <Controller
            name="editorNotes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextValidator
                fullWidth
                multiline
                color={formControlColor}
                name="editorNotes"
                label={t('general.editor_notes')}
                value={value}
                onChange={onChange}
                data-testid={layoutFormTestIds.editorNotes}
              />
            )}
          />

          <FormControl data-testid={layoutFormTestIds.ownerPermissionsGroupSelect}>
            <InputLabel>{t('permissions.permissions_group')}</InputLabel>
            <Controller
              name="ownerPermissionsGroup"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PermissionsGroupSelector value={value} onChange={onChange} />
              )}
            />
          </FormControl>
        </div>
      )}
    </Drawer>
  );
}

export default LayoutForm;
