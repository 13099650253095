import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { get } from 'lodash-es';
import { Typography } from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import { alpha } from '@mui/system';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import {
  AddBoxOutlined,
  FilterAltOutlined,
  FilterList,
  IndeterminateCheckBoxOutlined,
  UnfoldLess,
  UnfoldMore,
  WarningRounded
} from '@mui/icons-material';
import Button from '../../shared/Button';
import { useLocales, useTheme } from '../../../hooks';
import CollectionQueryNodeForm from './CollectionQueryNodeForm';
import CollectionQueryNodeLabel from './CollectionQueryNodeLabel';
import CollectionQuerySortingsEditor from './CollectionQuerySortingsEditor';
import { useData } from '../../../data-layer';
import { SmartQueryBody, SmartQueryConditionBody, SmartQueryOperatorSymbol } from '../../../API';
import ShadowScroller from '../../shared/ShadowScroller';
import ObjectPreview from '../../shared/ObjectPreview';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { isEntityDeleted } from '../../../utils/generalUtils';
import { collectionQeryBuilderTestIds } from '../../shared/TestsIds';

export type SmartQueryBodyValue = string | number | string[] | number[];

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%'
  },
  headerActions: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  queryBuilder: {
    padding: theme.spacing(4),
    paddingRight: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  formButton: {
    minWidth: 120
  },
  treeView: {
    flex: 1,
    overflowY: 'auto',
    paddingRight: '20px'
  },
  blankState: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    flex: 1
  },
  blankStateActions: {
    marginTop: theme.spacing(4)
  },
  queryErrorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },
  queryError: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  }
}));

const StyledTreeItem = withStyles(TreeItem, (theme, { nodeId, children }, classes) => {
  const isRoot = nodeId.indexOf('.') === -1;
  const isInnerNode = !(children as Array<boolean>)?.[0];
  return {
    root: {
      marginBottom: theme.spacing(2),
      [`& .${classes.content}`]: {
        background: 'none !important',
        cursor: 'default',
        padding: 0
      },
      [`& .${classes.label}`]: {
        paddingLeft: isRoot ? 0 : theme.spacing(1)
      },
      [`& .${classes.iconContainer}`]: {
        display: isRoot ? 'none' : 'block',
        cursor: 'pointer',
        marginTop: 6,
        '& .close': {
          opacity: 0.3
        },
        '& path': {
          pointerEvents: 'none'
        }
      },
      [`& .${classes.group}`]: {
        marginTop: theme.spacing(2),
        marginLeft: isRoot ? 0 : 7,
        paddingLeft: isRoot ? 0 : 16,
        borderLeft: !isRoot && isInnerNode ? `1px dashed ${alpha(theme.palette.text.primary, 0.4)}` : 'none'
      }
    }
  };
});

type TreeNode = { [key: string]: TreeNode };

const buildQueryTree = (query: SmartQueryBody): TreeNode | undefined => {
  // Result of this procedure is an object like { 0: { 0: {}, 1: { 0: {}, 1: {} 2: {} } } }
  // Think of it as a tree of the following shape
  //        0
  //      /   \
  //      0    1
  //          / \
  //          0  1
  // This structure is easily searchable with lodash get method: get('0.1.1')
  const rootKey = query?.conditions?.find(({ key }) => key.length === 1)?.key;
  if (!rootKey) return;
  return query.conditions
    .map(({ key }) => key.split('.')) // Map the keys e.g. [[0], [0, 0], [0, 1]]
    .sort((a, b) => a.length - b.length) // Sort them to ensure parent nodes are created before children
    .slice(1) // The root node shouldn't be considered as it's the initial value
    .reduce(
      (result, key) => {
        const parentKey = key.slice(0, -1).join('.'); // If current key is 0.1.2 this will produce 0.1
        const parent = get(result, parentKey) as TreeNode; // Use lodash get to find the parent node
        const childKey = key.slice(-1).join('.'); // If current key is 0.1.2 this will produce 2
        if (parent) parent[childKey] = {}; // If parent found, add empty child at position
        return result;
      },
      { [rootKey]: {} } as TreeNode
    );
};

const renderTreeNode = (
  node: TreeNode,
  query: SmartQueryBody,
  subPath?: string,
  onAddQueryNode?: (key: string) => void,
  onRemoveQueryNode?: (key: string) => void
): JSX.Element => {
  return (
    <>
      {Object.keys(node).map((key) => {
        const path = [subPath, key].filter(Boolean).join('.');
        const queryNode = query.conditions.find(({ key }) => key === path);
        if (!queryNode) return <></>;
        const { field, operator, value } = queryNode || {};
        const isConjunction = [SmartQueryOperatorSymbol.AND, SmartQueryOperatorSymbol.OR].includes(queryNode.operator);
        const label = <CollectionQueryNodeLabel field={field} operator={operator} value={value} />;
        const form = (
          <CollectionQueryNodeForm
            onAddQueryNode={onAddQueryNode}
            onRemoveQueryNode={onRemoveQueryNode}
            queryNodeKey={queryNode.key}
          />
        );
        return (
          <StyledTreeItem key={path} nodeId={path} label={isConjunction ? form : label}>
            {!isConjunction && form}
            {renderTreeNode(node[key], query, path, onAddQueryNode, onRemoveQueryNode)}
          </StyledTreeItem>
        );
      })}
    </>
  );
};

const renderTree = (
  query?: SmartQueryBody,
  onAddQueryNode?: (key: string) => void,
  onRemoveQueryNode?: (key: string) => void
): JSX.Element | undefined => {
  if (!query) return <></>;
  const root = buildQueryTree(query);
  return root && renderTreeNode(root, query, undefined, onAddQueryNode, onRemoveQueryNode);
};

function CollectionQueryBuilder(): JSX.Element {
  const {
    collections: {
      state: { withCollectionQueryFields, withSelected, withSelectedCollectionQuery },
      hook: { allLeafNodesValuesAreDefined }
    }
  } = useData();

  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const [isRendered, setIsRendered] = useState(false);
  const collection = useRecoilValue(withSelected);
  const [collectionQuery, setCollectionQuery] = useRecoilState(withSelectedCollectionQuery);
  const queryFields = useRecoilValue(withCollectionQueryFields);
  const [expandedTreeItems, setExpandedTreeItems] = useState<string[]>([]);
  const [queryErrors, setQueryErrors] = useState<string[]>([]);
  const [showQueryErrors, setShowQueryErrors] = useState<boolean>(false);
  const { hasUpsertPermission } = usePermissions();
  const hasCollectionUpsertPermission = hasUpsertPermission(collection?.ownerPermissionsGroup);
  const isDeleted = isEntityDeleted(collection);

  const handleAddQueryNode = (addedKey: string) => {
    setExpandedTreeItems((previousExpandedTreeItems) => previousExpandedTreeItems?.concat(addedKey));
  };

  const handleRemoveQueryNode = (removedKey: string) => {
    setExpandedTreeItems((previousExpandedTreeItems) => previousExpandedTreeItems?.filter((key) => key !== removedKey));
  };

  const handleExpandAllNodes = () => {
    setExpandedTreeItems(collectionQuery?.conditions.map<string>(({ key }) => key) || []);
  };

  const handleShowCompactTreeMode = () => {
    setExpandedTreeItems(
      collectionQuery?.conditions
        ?.filter(
          ({ operator }) => operator === SmartQueryOperatorSymbol.AND || operator === SmartQueryOperatorSymbol.OR
        )
        ?.map<string>(({ key }) => key) || []
    );
  };

  const memoizedTreeRender = useMemo<JSX.Element | undefined>(
    () => collectionQuery && renderTree(collectionQuery, handleAddQueryNode, handleRemoveQueryNode),
    [collectionQuery]
  );

  useEffect(() => {
    if (!isRendered && collectionQuery) {
      handleShowCompactTreeMode();
      setShowQueryErrors(false);
      setIsRendered(true);
    }
  }, [isRendered, collectionQuery]);

  const allInnerNodesContainOneOrMoreLeaves = (): boolean => {
    if (!collectionQuery) return true;
    return collectionQuery?.conditions?.every(
      ({ operator, key: parentKey }) =>
        !(operator === SmartQueryOperatorSymbol.AND || operator === SmartQueryOperatorSymbol.OR) ||
        collectionQuery.conditions.some(
          ({ key }) => key.split('.').length === parentKey.split('.').length + 1 && key.startsWith(parentKey)
        )
    );
  };

  const findQueryErrors = (): string[] => {
    if (!collectionQuery) return [];
    const errors = [
      !allInnerNodesContainOneOrMoreLeaves() && t('query_builder.errors.all_groups_need_one_child'),
      !allLeafNodesValuesAreDefined(collectionQuery) && t('query_builder.errors.all_rules_need_to_have_a_value')
    ].filter(Boolean);
    return errors as string[];
  };

  useEffect(() => {
    const errors = findQueryErrors();
    setQueryErrors(errors);
  }, [collectionQuery]);

  const handleToggleTreeItem = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if ((event.target as HTMLElement).dataset.treeItemToggler) {
      setExpandedTreeItems(nodeIds);
    }
  };

  const handleAddQueryRoot = () => {
    setIsRendered(true);
    setCollectionQuery({
      conditions: [
        { operator: SmartQueryOperatorSymbol.AND, key: '0' },
        { field: queryFields[0]?.symbol, operator: SmartQueryOperatorSymbol.EQ, value: '', key: '0.0' }
      ] as SmartQueryConditionBody[],
      sortings: []
    } as SmartQueryBody);
    handleAddQueryNode('0');
    handleAddQueryNode('0.0');
  };

  const isValidQuery = collectionQuery && collectionQuery.conditions;

  return (
    <div className={classes.container} data-testid={collectionQeryBuilderTestIds.root}>
      <div className={classes.queryBuilder}>
        {collection && isValidQuery && (
          <>
            <div className={classes.headerActions}>
              <Button
                color={formControlColor}
                variant="outlined"
                size="small"
                className={classes.formButton}
                onClick={handleShowCompactTreeMode}
                endIcon={<UnfoldLess />}
                data-testid={collectionQeryBuilderTestIds.showCompactTreeModeButton}
              >
                {t('query_builder.compact_mode')}
              </Button>
              <Button
                color={formControlColor}
                variant="outlined"
                size="small"
                className={classes.formButton}
                onClick={handleExpandAllNodes}
                endIcon={<UnfoldMore />}
                data-testid={collectionQeryBuilderTestIds.expandAllButton}
              >
                {t('query_builder.expand_all')}
              </Button>
              <ObjectPreview object={collectionQuery} size="small" title={t('query_builder.query')} />
            </div>
            <ShadowScroller>
              <TreeView
                className={classes.treeView}
                defaultCollapseIcon={<IndeterminateCheckBoxOutlined data-tree-item-toggler />}
                defaultExpandIcon={<AddBoxOutlined data-tree-item-toggler />}
                onNodeToggle={handleToggleTreeItem}
                expanded={expandedTreeItems}
                data-testid={collectionQeryBuilderTestIds.queryTree}
              >
                {memoizedTreeRender}
              </TreeView>
            </ShadowScroller>
            {showQueryErrors && !!queryErrors?.length && (
              <div className={classes.queryErrorsContainer} data-testid={collectionQeryBuilderTestIds.queryErrors}>
                {queryErrors.map((error) => (
                  <Typography key={error} className={classes.queryError} variant="caption">
                    <WarningRounded color="warning" fontSize="small" />
                    {error}
                  </Typography>
                ))}
              </div>
            )}
            <CollectionQuerySortingsEditor />
          </>
        )}
        {collection && !isValidQuery && (
          <div className={classes.blankState} data-testid={collectionQeryBuilderTestIds.blankState}>
            <FilterList style={{ fontSize: 80 }} />
            <Typography variant="h5">{t('query_builder.blank_state_header')}</Typography>
            <Typography variant="body1">{t('query_builder.blank_state_text')}</Typography>
            <div className={classes.blankStateActions}>
              <Button
                color="secondary"
                className={classes.formButton}
                onClick={handleAddQueryRoot}
                endIcon={<FilterAltOutlined />}
                disabled={!hasCollectionUpsertPermission || isDeleted}
                data-testid={collectionQeryBuilderTestIds.addQueryButton}
              >
                {t('query_builder.add_query')}
              </Button>
            </div>
          </div>
        )}
        {!collection && (
          <div className={classes.blankState} data-testid={collectionQeryBuilderTestIds.blankState}>
            <FilterList style={{ fontSize: 80 }} />
            <Typography variant="h5">{t('query_builder.no_collection_selected_header')}</Typography>
            <Typography variant="body1">{t('query_builder.no_collection_selected_text')}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default CollectionQueryBuilder;
