import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { useLocales } from '../../../hooks';
import LeagueBadge from '../LeagueBadge';
import { makeStyles } from 'tss-react/mui';
import { leaguesBadgeTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& > .MuiChip-root': {
      background: 'none'
    }
  }
}));

interface LeaguesBadgeProps {
  leagueIds: string[] | undefined;
  useTournamentId?: boolean;
  'data-testid'?: string;
}

export function LeaguesBadge({
  leagueIds,
  'data-testid': dataTestId,
  useTournamentId = false
}: LeaguesBadgeProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  if (!leagueIds || leagueIds.length <= 1) {
    return <LeagueBadge leagueId={leagueIds?.[0]} data-testid={dataTestId} useTournamentId={useTournamentId} />;
  }

  const leagues = leagueIds.map((leagueId, index) => (
    <LeagueBadge key={index} leagueId={leagueId} data-testid={dataTestId} useTournamentId={useTournamentId} />
  ));

  return (
    <>
      <Tooltip
        title={<div className={classes.tooltip}>{leagues}</div>}
        arrow
        data-testid={leaguesBadgeTestIds.leagueTooltip}
      >
        <Chip label={t('leagues.n_leagues', { n: leagueIds.length })} size="small" data-testid={dataTestId} />
      </Tooltip>
    </>
  );
}
