import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme, useLocales } from '../../../hooks';

import CollectionsBrowser from './CollectionsBrowser';
import ContentSearch from './ContentSearch';
import DisplayAs from '../DisplayAs';
import { useRecoilState } from 'recoil';
import CollectionQueryBuilder from '../CollectionQueryBuilder';
import { FilterAlt, Search, Subscriptions } from '@mui/icons-material';
import { CollectionsPanelTabViewOptions, useData } from '../../../data-layer';
import { collectionsHeaderHeight } from '../../../utils/consts/collectionsConsts';
import CollectionManager from './CollectionManager';
import { ContentCollectionResponse } from '../../../API';
import LiveSearch from '../LiveSearch';
import EPGChannels from '../../EPG/EPGChannels';
import { collectionsPanelTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(4),
    minHeight: collectionsHeaderHeight,
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tabs: {
    minHeight: 0
  },
  tab: {
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    minHeight: collectionsHeaderHeight,
    '& > svg': {
      margin: '0 !important'
    }
  }
}));

export type CollectionPanelOptions = {
  tabs?: CollectionsPanelTabViewOptions[];
};

type CollectionsPanelProps = {
  forKids?: boolean;
  options?: CollectionPanelOptions;
};

function CollectionsPanel({
  forKids = false,
  options = {} as CollectionPanelOptions
}: CollectionsPanelProps): JSX.Element {
  const {
    collections: {
      state: { withCollectionsPanelTabView, withDisplayAs }
    }
  } = useData();

  const tabsToRender = options.tabs || [
    CollectionsPanelTabViewOptions.CONTENT_SEARCH,
    CollectionsPanelTabViewOptions.QUERY_BUILDER,
    CollectionsPanelTabViewOptions.BROWSE_COLLECTONS
  ];

  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const [whichView, setWhichView] = useRecoilState(withCollectionsPanelTabView);
  const [selectedCollection, setSelectedCollection] = useState<ContentCollectionResponse | undefined>();

  const renderTab = (tab: CollectionsPanelTabViewOptions) => {
    switch (tab) {
      case CollectionsPanelTabViewOptions.CONTENT_SEARCH:
      case CollectionsPanelTabViewOptions.VOD_SEARCH:
        return (
          <Tab
            key={tab}
            className={classes.tab}
            data-testid={collectionsPanelTestIds.searchTab}
            label={tab === CollectionsPanelTabViewOptions.CONTENT_SEARCH ? t('general.content') : t('general.vod')}
            icon={<Search fontSize="small" />}
            value={tab}
          />
        );
      case CollectionsPanelTabViewOptions.QUERY_BUILDER:
        return (
          <Tab
            key={tab}
            className={classes.tab}
            data-testid={collectionsPanelTestIds.queryTab}
            label={t('query_builder.query')}
            icon={<FilterAlt fontSize="small" />}
            disabled={forKids}
            value={tab}
          />
        );
      case CollectionsPanelTabViewOptions.BROWSE_COLLECTONS:
        return (
          <Tab
            key={tab}
            className={classes.tab}
            data-testid={collectionsPanelTestIds.browseTab}
            label={t('collections.collections')}
            icon={<Subscriptions fontSize="small" />}
            value={tab}
          />
        );
      case CollectionsPanelTabViewOptions.LIVE_SEARCH:
        return (
          <Tab
            key={tab}
            className={classes.tab}
            data-testid={collectionsPanelTestIds.live}
            label={t('general.live')}
            icon={<Search fontSize="small" />}
            value={tab}
          />
        );
      case CollectionsPanelTabViewOptions.CHANNEL_SEARCH:
        return (
          <Tab
            key={tab}
            className={classes.tab}
            data-testid={collectionsPanelTestIds.channel}
            label={t('general.channel')}
            icon={<Search fontSize="small" />}
            value={tab}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div data-testid={collectionsPanelTestIds.root} className={classes.root}>
      <div className={classes.topBar}>
        <Tabs
          className={classes.tabs}
          data-testid={collectionsPanelTestIds.tabs}
          value={!whichView ? false : whichView}
          indicatorColor={formControlColor}
          textColor={formControlColor}
          onChange={(_, newValue) => setWhichView(newValue as CollectionsPanelTabViewOptions)}
        >
          {tabsToRender.map((tab) => renderTab(tab))}
        </Tabs>
        <div>
          <DisplayAs state={withDisplayAs} />
        </div>
      </div>
      {(whichView === CollectionsPanelTabViewOptions.CONTENT_SEARCH ||
        whichView === CollectionsPanelTabViewOptions.VOD_SEARCH) && <ContentSearch />}
      {whichView === CollectionsPanelTabViewOptions.LIVE_SEARCH && <LiveSearch />}
      {whichView === CollectionsPanelTabViewOptions.BROWSE_COLLECTONS &&
        (!selectedCollection ? (
          <CollectionsBrowser
            useSelected={false}
            selectedCollection={selectedCollection}
            setSelectedCollection={setSelectedCollection}
          />
        ) : (
          <CollectionManager
            useSelected={false}
            collection={selectedCollection}
            onGoBack={() => setSelectedCollection(undefined)}
          />
        ))}
      {whichView === CollectionsPanelTabViewOptions.CHANNEL_SEARCH && <EPGChannels isChannelPicker />}
      {whichView === CollectionsPanelTabViewOptions.QUERY_BUILDER && <CollectionQueryBuilder />}
    </div>
  );
}

export default CollectionsPanel;
