import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { InputLabel, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../../../../data-layer';
import { useLocales, useTheme } from '../../../../../../hooks';
import { LEAGUE_ID_ALL } from '../../../../../../utils/consts/uiModules';
import FormControl from '../../../../../shared/FormControl';
import Localized from '../../../../../shared/Localized';
import TextField from '../../../../../shared/TextField';
import { StyledSelect } from '../../../styles';
import { sportsEventFiltersTestIds } from '../../../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  filterDropdown: {
    minWidth: 230,
    maxWidth: 230
  },
  filterDropdownContainer: {
    flexBasis: 1,
    flexGrow: 1
  },
  leagueIdTextField: {
    '&.MuiTextField-root': {
      marginBottom: '0 !important',
      minWidth: '260px'
    }
  }
}));

type SportsEventFiltersProp = {
  onSportIdChange: (sport: string) => void;
  onLeagueIdChange: (league: string) => void;
};

export function SportsEventFilters({ onSportIdChange, onLeagueIdChange }: SportsEventFiltersProp): ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const {
    sports: {
      state: { withAllRecords: withAllSports },
      hook: { getAll: getAllSports }
    },
    tournaments: {
      state: { withAllRecords: withAllTournaments, withTournamentsBySport },
      hook: { getAll: getAllTournaments }
    }
  } = useData();
  const sports = useRecoilValue(withAllSports);
  const leagues = useRecoilValue(withAllTournaments);

  const [selectedSportLabel, setSelectedSportLabel] = useState('all');
  const [leagueFilter, setLeagueFilter] = useState('');
  const [sportFilter, setSportFilter] = useState('');

  const selectedSportId = useMemo(
    () => sports?.find((sport) => sport.sportId === sportFilter)?.id,
    [sports, sportFilter]
  );
  const selectedSportTournaments = useRecoilValue(withTournamentsBySport(selectedSportId));
  const leagueIdParamIsSelected = false;

  useEffect(() => {
    if (!sports) getAllSports();
    if (!leagues) getAllTournaments();
  }, []);

  useEffect(() => {
    if (sportFilter) {
      setSelectedSportLabel(sportFilter);
    }
    if (sports && !sportFilter) {
      setSportFilter(''); // Initialize to the first sport
      setSelectedSportLabel('all'); // Initialize to the first sport
    }
  }, [sports, selectedSportLabel]);

  useEffect(() => {
    onLeagueIdChange(leagueFilter);
    onSportIdChange(sportFilter);
  }, [leagueFilter, sportFilter]);

  const onSportChange = (value: string) => {
    setSelectedSportLabel(value);
    setSportFilter(value === 'all' ? '' : value);
    setLeagueFilter('');
  };

  const onLeagueChange = (value: string) => {
    setLeagueFilter(value === LEAGUE_ID_ALL ? '' : value);
  };

  return (
    <div className={classes.container}>
      <FormControl
        data-testid={sportsEventFiltersTestIds.sportEventFilterComponent}
        className={classes.filterDropdownContainer}
      >
        <InputLabel>{t('sports.sports')}</InputLabel>
        {sports && (
          <StyledSelect
            className={classes.filterDropdown}
            label={t('sports.sport')}
            labelId="sports-select-label"
            value={selectedSportLabel}
            data-testid={sportsEventFiltersTestIds.sportEventFilterSportSelector}
            onChange={({ target: { value } }) => onSportChange(value as string)}
          >
            <MenuItem data-testid={sportsEventFiltersTestIds.sportEventFilterSportMenuItem} value="all">
              {t('layouts.all_sports')}
            </MenuItem>
            {sports.map((sport, i) => (
              <MenuItem
                data-testid={sportsEventFiltersTestIds.sportEventFilterSportMenuItem}
                key={i}
                value={sport.sportId}
              >
                <Localized prop={sport.name} />
              </MenuItem>
            ))}
          </StyledSelect>
        )}
      </FormControl>
      <FormControl className={classes.filterDropdownContainer}>
        {!!selectedSportId && selectedSportTournaments && (
          <>
            <FormControl>
              {!leagueIdParamIsSelected && (
                <>
                  <InputLabel id="league-selector">{t('sports.league')}</InputLabel>
                  <StyledSelect
                    labelId="league-selector"
                    className={classes.filterDropdown}
                    value={leagueFilter || LEAGUE_ID_ALL}
                    color={formControlColor}
                    data-testid={sportsEventFiltersTestIds.sportEventFilterLeagueSelector}
                    onChange={({ target: { value } }) => onLeagueChange(value as string)}
                  >
                    <MenuItem
                      data-testid={sportsEventFiltersTestIds.sportEventFilterLeagueMenuItem}
                      value={LEAGUE_ID_ALL}
                    >
                      {t('sports.all_leagues')}
                    </MenuItem>
                    {selectedSportTournaments.map((league, i) => (
                      <MenuItem
                        data-testid={sportsEventFiltersTestIds.sportEventFilterLeagueMenuItem}
                        key={i}
                        value={league.id}
                      >
                        <Localized prop={league.name} />
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </>
              )}
            </FormControl>
          </>
        )}
        {leagueIdParamIsSelected && (
          <TextField
            className={classes.leagueIdTextField}
            label={t('sports.league')}
            value={t('layouts.league_id_as_param')}
            color={formControlColor}
            disabled
          />
        )}
      </FormControl>
    </div>
  );
}
