import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormControlLabel, InputLabel, MenuItem, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { TournamentSplashHeroUiModuleBody } from '../../../../../API';
import { useData } from '../../../../../data-layer';
import { useLocales, useNotifications, useTheme } from '../../../../../hooks';
import { LEAGUE_ID_PARAM, isDynamicLeaguePage } from '../../../../../utils/consts/uiModules';
import { markAsRequired } from '../../../../../utils/formHelpers';
import FormControl from '../../../../shared/FormControl';
import Localized from '../../../../shared/Localized';
import { FormBody } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { makeStyles } from 'tss-react/mui';
import TextField from '../../../../shared/TextField';
import Select from '../../../../shared/Select';
import { tournamentSplashHeroFormTestIds } from '../../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  leagueTextField: {
    minWidth: 260,
    ['&.MuiTextField-root']: {
      marginBottom: '0 !important'
    }
  },
  leagueSelector: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center'
  }
}));

const TournamentSplashHeroForm = forwardRef<UIModuleTypeForm<TournamentSplashHeroUiModuleBody>>((_, ref) => {
  const { control, setValue, watch } = useFormContext<TournamentSplashHeroUiModuleBody>();

  const { classes } = useStyles();
  const { t } = useLocales();
  const { notifyError } = useNotifications();
  const { formControlColor } = useTheme();

  const tournamentId = watch('tournamentReferenceId');

  const {
    sports: {
      state: { withAllRecords: withAllSports },
      hook: { getAll: getAllSports }
    },
    tournaments: {
      state: { withAllRecords: withAllTournaments, withTournamentsBySport },
      hook: { getAll: getAllTournaments }
    },
    pages: {
      state: { withSelectedId: withSelectedPagePath }
    }
  } = useData();

  const sports = useRecoilValue(withAllSports);
  const tournaments = useRecoilValue(withAllTournaments);
  const [selectedSportId, setSelectedSportId] = useState<string>('');
  const leaguesBySport = useRecoilValue(withTournamentsBySport(selectedSportId));

  const leagueIdParamIsSelected = tournamentId === LEAGUE_ID_PARAM;
  const currentPagePath = useRecoilValue(withSelectedPagePath);
  const shouldShowDynamicLeague = isDynamicLeaguePage(currentPagePath);

  useEffect(() => {
    if (!sports) getAllSports();
    if (!tournaments) getAllTournaments();
  }, []);

  useEffect(() => {
    if (!sports) return;
    if (tournamentId && !leagueIdParamIsSelected) {
      const tournament = tournaments?.find((league) => league.id === tournamentId);
      if (tournament) setSelectedSportId(tournament.sport);
    } else if (!selectedSportId) {
      setSelectedSportId(sports[0].id);
    }
  }, [sports, tournamentId]);

  useImperativeHandle(ref, () => ({
    isValid(uiModule) {
      if (!uiModule.tournamentReferenceId) {
        notifyError(t('errors.layouts.empty_tournament_id'));
        return false;
      }
      return true;
    }
  }));

  const onSportChange = (value: string) => {
    setSelectedSportId(value);
    setValue('tournamentReferenceId', '');
  };

  return (
    <FormBody data-testid={tournamentSplashHeroFormTestIds.formBody}>
      <div>
        <FormControl>
          <InputLabel id="sports-select-label">{markAsRequired(t('sports.sport'))}</InputLabel>
          {sports && (
            <Select
              label={t('sports.sport')}
              sx={{ minWidth: 260 }}
              labelId="sports-select-label"
              value={leagueIdParamIsSelected ? '' : selectedSportId}
              color={formControlColor}
              onChange={({ target: { value } }) => onSportChange(value)}
              disabled={leagueIdParamIsSelected}
              data-testid={tournamentSplashHeroFormTestIds.sportSelect}
            >
              {sports.map((sport, i) => (
                <MenuItem key={i} value={sport.id} data-testid={tournamentSplashHeroFormTestIds.sportSelectItem}>
                  <Localized prop={sport.name} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </div>
      {((!!selectedSportId && leaguesBySport) || leagueIdParamIsSelected) && (
        <div className={classes.leagueSelector}>
          <FormControl>
            {!leagueIdParamIsSelected && (
              <>
                <InputLabel id="league-selector">{markAsRequired(t('sports.league'))}</InputLabel>
                <Controller
                  control={control}
                  name="tournamentReferenceId"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      label={t('sports.league')}
                      sx={{ minWidth: 260 }}
                      labelId="league-selector"
                      value={value || ''}
                      color={formControlColor}
                      onChange={onChange}
                      data-testid={tournamentSplashHeroFormTestIds.tournamentSelect}
                    >
                      {leaguesBySport?.map((league, i) => (
                        <MenuItem
                          key={i}
                          value={league.id}
                          data-testid={tournamentSplashHeroFormTestIds.tournamentSelectItem}
                          data-value={league.id}
                        >
                          <Localized prop={league.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </>
            )}
            {leagueIdParamIsSelected && (
              <TextField
                className={classes.leagueTextField}
                label={t('sports.league')}
                value={t('layouts.league_id_as_param')}
                color={formControlColor}
                disabled
              />
            )}
          </FormControl>
          {shouldShowDynamicLeague && (
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={leagueIdParamIsSelected}
                    color={formControlColor}
                    onChange={(event, value: boolean) =>
                      setValue('tournamentReferenceId', value ? LEAGUE_ID_PARAM : '')
                    }
                    data-testid={tournamentSplashHeroFormTestIds.dynamicLeagueSwitch}
                  />
                }
                label={t('layouts.use_dynamic_league_id_param')}
              />
            </FormControl>
          )}
        </div>
      )}
    </FormBody>
  );
});

TournamentSplashHeroForm.displayName = 'TournamentSplashHeroForm';

export default TournamentSplashHeroForm;
