import React, { useState } from 'react';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { InputAdornment } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useTheme, useTimezones } from '../../../hooks';
import { Close, Event } from '@mui/icons-material';
import { DateTimeTz } from '../DateTime/DateTimeTz';
import IconButton from '../IconButton';
import { DateTime } from 'luxon';
import { clearableDateTimePickerTestIds } from '../TestsIds';

export type ClearableDateTimePickerProps = {
  value: string | undefined;
  minDateTime?: string;
  maxDateTime?: string;
  minDate?: string;
  maxDate?: string;
  onChange: (value: string | undefined) => unknown;
  showTimezones?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  clearable?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles()(() => ({
  textInput: {
    minWidth: 300
  },
  clearIcon: {
    margin: -5
  }
}));

export function ClearableDateTimePicker({
  value,
  onChange,
  fullWidth,
  showTimezones,
  minDate,
  maxDate,
  minDateTime,
  maxDateTime,
  clearable = true,
  error,
  helperText,
  disabled,
  ...props
}: Omit<
  DateTimePickerProps<DateTime>,
  'renderInput' | 'value' | 'onChange' | 'minDateTime' | 'maxDateTime' | 'minDate' | 'maxDate'
> &
  ClearableDateTimePickerProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { getTimezonesList } = useTimezones();
  const timezones = getTimezonesList();
  const [open, setOpen] = useState(false);

  const onChangeOverride = (date: DateTime | null) => {
    onChange(date?.toISO() || '');
  };

  const onClearClicked = () => {
    onChange('');
  };

  const clearButton = (
    <IconButton
      className={classes.clearIcon}
      data-testid={clearableDateTimePickerTestIds.clearButton}
      onClick={onClearClicked}
      title={t('tooltips.clear')}
      size="small"
    >
      <Close />
    </IconButton>
  );

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <div data-testid={clearableDateTimePickerTestIds.componentRoot}>
      <DateTimePicker
        value={value ? DateTime.fromISO(value) : null}
        onChange={onChangeOverride}
        slots={{
          inputAdornment: (props) => (
            <InputAdornment {...props} position="end" sx={{ gap: 2 }}>
              {clearable && value && clearButton}
              <IconButton onClick={onOpen}>
                <Event />
              </IconButton>
            </InputAdornment>
          )
        }}
        slotProps={{
          textField: {
            color: formControlColor,
            className: classes.textInput,
            fullWidth,
            error,
            helperText,
            'data-testid': clearableDateTimePickerTestIds.textField
          }
        }}
        format="yyyy/MM/dd hh:mm a"
        minDate={minDate ? DateTime.fromISO(minDate) : undefined}
        maxDate={maxDate ? DateTime.fromISO(maxDate) : undefined}
        minDateTime={minDateTime ? DateTime.fromISO(minDateTime) : undefined}
        maxDateTime={maxDateTime ? DateTime.fromISO(maxDateTime) : undefined}
        disabled={disabled}
        open={open}
        onClose={onClose}
        {...props}
      />
      {showTimezones && <DateTimeTz time={value} timezones={timezones} disabled={disabled} />}
    </div>
  );
}
