import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Typography, MenuItem, Popover, ListItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { MoreVertOutlined } from '@mui/icons-material';
import { PageLayoutResponseV2, CollectionLayoutResponse } from '../../../API';
import {
  withSetEditingLayoutId,
  withSetCloningLayoutId,
  WhichLayouts,
  PAST_LAYOUTS,
  DRAFT_LAYOUTS,
  withLiveLayouts,
  withSelectedLayoutId,
  withLayoutsTypeLowercase,
  FUTURE_LAYOUTS,
  withLayoutSelectedCountries
} from '../../../state/Layouts';
import Localized from '../../shared/Localized';
import { useLocales, useNotifications, useLayouts } from '../../../hooks';
import LiveCountriesBadge from '../LiveCountriesBadge';
import CountryBadgesMerged from '../../shared/CountryBadgesMerged';
import DraftBadge from '../../shared/DraftBadge';
import IconButton from '../../shared/IconButton';
import LayoutListItemGraph from './LayoutListItemGraph';
import { LiveTimestamp } from '../../shared/LiveTimestamp';
import LinkWithIcon from '../../shared/LinkWithIcon';
import { intersection } from 'lodash-es';
import useConfirm from '../../../hooks/General/useConfirm';
import { AppRoutes } from '../../../Routes';
import { EditorNotes } from '../../shared/EditorNotes';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { useData } from '../../../data-layer';
import { pagePathToParam } from '../../../utils/layouts';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../state/theme';
import { layoutListItemTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  listItemContainer: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: 0
  },
  listItemSelected: {
    background: theme.palette.divider
  },
  container: {
    flexGrow: 1,
    alignItems: 'center'
  },
  row: {
    display: 'table'
  },
  content: {
    display: 'table-cell',
    verticalAlign: 'top'
  },
  contentPadding: {
    padding: theme.spacing(2, 0)
  },
  draftBadgeContainer: {
    padding: theme.spacing(0, 3, 0, 4),
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  topLine: {
    display: 'block'
  },
  topLineItem: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    marginBottom: 2,
    verticalAlign: 'middle'
  }
}));

export interface ILayoutListItemProps {
  layout: PageLayoutResponseV2;
  index: number;
  which: WhichLayouts;
}

function LayoutListItem({ layout, which }: ILayoutListItemProps): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { onLayoutChange, deleteDraftLayout } = useLayouts();
  const setEditingLayoutId = useSetRecoilState(withSetEditingLayoutId);
  const setCloningLayoutId = useSetRecoilState(withSetCloningLayoutId);
  const selectedLayoutId = useRecoilValue(withSelectedLayoutId);
  const [layoutMenuAnchorEl, setLayoutMenuAnchorEl] = useState<null | HTMLElement>(null);
  const liveLayouts = useRecoilValue(withLiveLayouts);
  const layoutsType = useRecoilValue(withLayoutsTypeLowercase);
  const { notifyError } = useNotifications();
  const { confirm } = useConfirm();

  const {
    pages: {
      state: { withSelected: withSelectedPage }
    }
  } = useData();
  const selectedPage = useRecoilValue(withSelectedPage);
  const { canSave, canDelete, canPublish } = usePermissionsGuard({
    homepageOption: HomepageOptions.LAYOUTS
  });
  const { hasPermissions } = usePermissions();
  const { UPSERT: hasLayoutUpsertPermission, DELETE: hasLayoutDeletePermission } = hasPermissions(
    selectedPage?.ownerPermissionsGroup
  );

  const getLiveCountries = () => {
    if (liveLayouts && liveLayouts[layout.id]) {
      return liveLayouts[layout.id].countries;
    }
  };

  const handleClick = () => {
    onLayoutChange(layout.id);
  };

  const openLayoutMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLayoutMenuAnchorEl(event.currentTarget);
  };

  const closeLayoutMenu = () => {
    setLayoutMenuAnchorEl(null);
  };

  const editLayout = () => {
    closeLayoutMenu();
    setEditingLayoutId(layout.id);
  };

  const cloneLayout = () => {
    closeLayoutMenu();
    setCloningLayoutId({ id: layout.id, canPublish });
  };

  const handleDeleteLayout = async () => {
    closeLayoutMenu();
    // Superfluous conditional, should probably actually check if the layout is live
    if (which === FUTURE_LAYOUTS) {
      notifyError(t('errors.layouts.scheduled_layout_delete'));
      return false;
    }

    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: t('layouts.delete_layout_popup_confirm')
    });

    if (result) {
      deleteDraftLayout();
    }
  };

  const liveCountries = getLiveCountries();

  return (
    <div className={classes.listItemContainer}>
      <ListItem
        button
        color="secondary"
        data-testid={layoutListItemTestIds.componentRoot}
        data-id={layout.id}
        className={`${classes.listItem} ${selectedLayoutId === layout.id ? classes.listItemSelected : ''}`}
        onClick={handleClick}
      >
        <LayoutListLineItem layout={layout} which={which} liveCountries={liveCountries} showGraph={true} />
        <div>
          <IconButton onClick={openLayoutMenu} data-testid={layoutListItemTestIds.layoutMenuButton} size="large">
            <MoreVertOutlined />
          </IconButton>
          <Popover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorEl={layoutMenuAnchorEl}
            keepMounted
            open={Boolean(layoutMenuAnchorEl)}
            onClose={closeLayoutMenu}
            data-testid={`layout-menu-${layout.id}`}
          >
            {which !== PAST_LAYOUTS && (
              <MenuItem
                onClick={editLayout}
                disabled={!hasLayoutUpsertPermission || !canSave}
                data-testid={layoutListItemTestIds.editLayoutButton}
              >
                {t(`layouts.edit_${layoutsType}_layout`)}
              </MenuItem>
            )}
            <MenuItem onClick={cloneLayout} data-testid={layoutListItemTestIds.cloneLayoutButton}>
              {t(`layouts.clone_${layoutsType}_layout`)}
            </MenuItem>
            {which !== PAST_LAYOUTS && (
              <MenuItem
                onClick={handleDeleteLayout}
                disabled={!hasLayoutDeletePermission || !canDelete}
                data-testid={layoutListItemTestIds.deleteLayoutButton}
              >
                {t(`layouts.delete_${layoutsType}_layout`)}
              </MenuItem>
            )}
          </Popover>
        </div>
      </ListItem>
    </div>
  );
}

interface ILayoutListLineItemProps {
  layout: PageLayoutResponseV2;
  which: WhichLayouts;
  liveCountries: string[] | undefined;
  showGraph?: boolean;
  linkToLayout?: boolean;
  disablePadding?: boolean;
}

export const LayoutListLineItem = ({
  layout,
  which,
  liveCountries,
  showGraph,
  linkToLayout,
  disablePadding
}: ILayoutListLineItemProps): JSX.Element => {
  const { classes, cx } = useStyles();

  const nonLiveCountries = liveCountries
    ? layout.countries.filter((country) => liveCountries.indexOf(country) === -1)
    : layout.countries;

  return (
    <div className={classes.container} data-testid={layoutListItemTestIds.lineItem}>
      <div className={classes.row}>
        {which !== DRAFT_LAYOUTS && showGraph && <LayoutListItemGraph layout={layout} />}
        {which === DRAFT_LAYOUTS && (
          <div className={classes.draftBadgeContainer}>
            <DraftBadge />
          </div>
        )}
        <div className={cx(classes.content, { [classes.contentPadding]: !disablePadding })}>
          <div className={classes.topLine}>
            {linkToLayout ? (
              <LayoutLink layout={layout as CollectionLayoutResponse} />
            ) : (
              <Typography
                component="div"
                variant="body1"
                data-testid={layoutListItemTestIds.pageName}
                className={classes.topLineItem}
              >
                <Localized prop={layout.pageName} />
              </Typography>
            )}
            {liveCountries && (
              <div className={classes.topLineItem}>
                <LiveCountriesBadge countryCodes={liveCountries} />
              </div>
            )}
            {!!nonLiveCountries.length && (
              <div className={classes.topLineItem}>
                <CountryBadgesMerged countryCodes={nonLiveCountries} />
              </div>
            )}
            <div className={classes.topLineItem}>
              <EditorNotes object={layout} />
            </div>
          </div>
          {which !== DRAFT_LAYOUTS && (
            <LiveTimestamp data-testid={layoutListItemTestIds.startTime} dateString={layout.startTime as string} />
          )}
        </div>
      </div>
    </div>
  );
};

const LayoutLink = ({ layout }: { layout: CollectionLayoutResponse }) => {
  const selectedCountries = useRecoilValue(withLayoutSelectedCountries);
  const pathCountries = [...selectedCountries];
  if (intersection(selectedCountries, layout.countries).length === 0) {
    pathCountries.push(layout.countries[0]);
  }
  const pathname = AppRoutes.layouts({
    type: layout.layoutType,
    countries: pathCountries.join('-'),
    layoutId: layout.id,
    pageUrl: pagePathToParam(layout.urlPath)
  });

  return <LinkWithIcon pathname={pathname} title={layout.pageName} />;
};

export default LayoutListItem;
