import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add, Delete, Search } from '@mui/icons-material';
import { InputAdornment, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { textFilterToRegExp, useLocales } from '../../../../hooks';
import { withPromotionsSplitPane } from '../../../../state/General';
import Button from '../../../shared/Button';
import { DataGrid, DataGridColDef } from '../../../shared/DataGrid';
import TextField from '../../../shared/TextField';
import { withPromotionsFilters } from '../../../../state/Payments/withPaymentsFilters';
import { AppStoreResponse, OfferMappingResponse, PermissionsGroupId } from '../../../../API';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import CountryBadge from '../../../shared/CountryBadge';
import { IdBadge } from '../../../shared/IdBadge';
import { AppRoutes } from '../../../../Routes';
import IconButton from '../../../shared/IconButton';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { offerMappingManagerTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  filters: {
    display: 'flex',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  textFilterContainer: {
    flexGrow: 1,
    display: 'flex',
    gap: theme.spacing(4)
  },
  textFilter: {
    width: 500,
    marginBottom: '0 !important'
  },
  gridContainer: {
    display: 'flex',
    flexGrow: 1
  },
  mapping: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    '&:not(:first-of-type)': {
      paddingTop: theme.spacing(2)
    },
    '&:not(:last-of-type)': {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px dotted ${theme.palette.divider}`
    }
  }
}));

export function OfferMappingManager(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useLocales();
  const navigate = useNavigate();
  const {
    offerMappings: {
      state: { withAllRecords: withAllOfferMappings },
      hook: { edit: editOfferMapping, new: newOfferMapping, remove: removeOfferMapping, getAppStores }
    },
    coupons: {
      state: { withAllRecords: withAllCoupons }
    }
  } = useData();
  const offerMappings = useRecoilValue(withAllOfferMappings);
  const coupons = useRecoilValue(withAllCoupons);
  const [appStores, setAppStores] = React.useState<AppStoreResponse[]>([]);

  const { canSave, canDelete, canRead } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });
  const { hasUpsertPermission, hasDeletePermission, hasReadPermission } = usePermissions();

  const hasMonetizationReadPermissions = hasReadPermission(PermissionsGroupId.MONETIZATION) || canRead;

  const [filters, setFilters] = useRecoilState(withPromotionsFilters);
  const [filteredOfferMappings, setFilteredOfferMappings] = useState<OfferMappingResponse[] | undefined>();

  const allLoaded = !!offerMappings && !!coupons;

  useEffect(() => {
    if (!hasMonetizationReadPermissions) {
      navigate(AppRoutes.welcome);
    }
  }, [hasMonetizationReadPermissions]);

  useEffect(() => {
    updateFilteredOfferMappings();
  }, [offerMappings, filters]);

  useEffect(() => {
    const initAppStores = async () => {
      const appStoresResponse = await getAppStores();
      setAppStores(appStoresResponse?.appStores ?? []);
    };

    initAppStores();
  }, []);

  function deleteOfferMappingHandler(offerMapping: OfferMappingResponse) {
    if (window.confirm(t('payments.offer_mapping.delete_confirmation'))) {
      removeOfferMapping(offerMapping.id);
    }
  }

  const columns: DataGridColDef<OfferMappingResponse>[] = [
    {
      key: 'name',
      cellRenderer: (offerMapping) =>
        appStores.find((appStore) => appStore.key === offerMapping.source.name)?.name ?? offerMapping.source.name,
      label: t('payments.offer_mapping.source_name'),
      sortFunction: (a, b) => a.source.name.localeCompare(b.source.name),
      baseWidth: '20%'
    },
    {
      key: 'code',
      cellRenderer: (offerMapping) => (
        <IdBadge id={offerMapping.source.offer.code} data-testid={offerMappingManagerTestIds.sourceCode} />
      ),
      label: t('payments.offer_mapping.source_offer_code'),
      baseWidth: '20%'
    },
    {
      key: 'notes',
      cellRenderer: (offerMapping) => offerMapping.notes,
      label: t('payments.offer_mapping.notes'),
      sortFunction: (a, b) => a.notes?.localeCompare(b.notes || '') || 0,
      baseWidth: '20%'
    },
    {
      key: 'mappings',
      cellRenderer: (offerMapping) => (
        <>
          {offerMapping.source.offer.subscriptionManagerCouponMappings.map((subCouponMapping, i) => (
            <div className={classes.mapping} key={i} data-testid={offerMappingManagerTestIds.offerMappings}>
              <Stack direction="column" gap={2}>
                <div>
                  <b>{t('payments.code')}:</b> <IdBadge id={subCouponMapping.code} />
                </div>
                {subCouponMapping.country && (
                  <div>
                    <b>{t('general.country')}:</b> <CountryBadge countryCode={subCouponMapping.country.toUpperCase()} />
                  </div>
                )}
              </Stack>
            </div>
          ))}
        </>
      ),
      label: t('payments.offer_mapping.source_subcription_manager_coupon_mappings'),
      baseWidth: '30%'
    },
    {
      key: 'actions',
      cellRenderer: (offerMapping) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            deleteOfferMappingHandler(offerMapping);
          }}
          title={t('general.delete')}
          size="large"
          disabled={!hasDeletePermission(PermissionsGroupId.MONETIZATION) && !canDelete}
        >
          <Delete />
        </IconButton>
      ),
      label: t('general.actions'),
      baseWidth: '10%',
      align: 'right'
    }
  ];

  const onOfferMappingClick = (offerMapping: OfferMappingResponse) => {
    if (!allLoaded) return;
    editOfferMapping(offerMapping.id);
  };

  const onTextFilterChanged: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    setFilters({
      ...filters,
      text: evt.target.value
    });
  };

  const updateFilteredOfferMappings = () => {
    if (!offerMappings) return;
    const newFilteredOfferMappings = offerMappings.filter((offerMapping) => {
      if (filters.text) {
        const textFilterRgx = textFilterToRegExp(filters.text);
        return (
          textFilterRgx.test(offerMapping.source.name) ||
          textFilterRgx.test(offerMapping.source.offer.code) ||
          (offerMapping.notes && textFilterRgx.test(offerMapping.notes)) ||
          offerMapping.source.offer.subscriptionManagerCouponMappings.some(
            (subCouponMapping) =>
              textFilterRgx.test(subCouponMapping.code) ||
              (subCouponMapping.country && textFilterRgx.test(subCouponMapping.country))
          )
        );
      }
      return true;
    });
    setFilteredOfferMappings(newFilteredOfferMappings);
  };

  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <div className={classes.textFilterContainer}>
          <TextField
            clearable
            label={t('payments.offer_mapping.filter_by_text')}
            className={classes.textFilter}
            value={filters.text}
            onChange={onTextFilterChanged}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            data-testid={offerMappingManagerTestIds.textFilter}
          />
        </div>
        <Button
          endIcon={<Add />}
          disabled={!allLoaded || (!hasUpsertPermission(PermissionsGroupId.MONETIZATION) && !canSave)}
          onClick={newOfferMapping}
        >
          {t('payments.new_offer_mapping')}
        </Button>
      </div>
      <div className={classes.gridContainer}>
        <DataGrid
          rows={filteredOfferMappings}
          columns={columns}
          onRowClick={(offerMapping) => onOfferMappingClick(offerMapping)}
          withColumnSizes={withPromotionsSplitPane}
          useVirtualization
          rowKey="id"
        />
      </div>
    </div>
  );
}
