import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Chip, Typography } from '@mui/material';
import { ScheduleTime } from '../../../../API';
import { useTheme } from '../../../../hooks';
import { ScheduledTimeDays } from '../scheduleTimeConstants';
import { parseValueToTime } from '../scheduleTimeHelpers';
import { scheduleTimePickerTestIds } from '../../TestsIds';

type ScheduleTimePreviewProps = {
  className?: string;
  value: ScheduleTime[] | undefined;
};

const useStyles = makeStyles()((theme) => ({
  schedulePreview: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    gap: theme.spacing(2)
  },
  scheduledRow: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    alignItems: 'left'
  },
  days: {
    display: 'flex',
    gap: theme.spacing(1),
    '& .MuiChip-root': {
      width: 20,
      height: 20
    },
    '& .MuiChip-label': {
      padding: 0,
      fontSize: 11
    },
    '& .MuiChip-filled': {
      border: '1px solid transparent'
    }
  }
}));

export function ScheduleTimePreview({ value }: ScheduleTimePreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const { formControlColor } = useTheme();

  return (
    <div className={classes.schedulePreview} data-testid={scheduleTimePickerTestIds.root}>
      {value?.map((schedule, i) => (
        <div className={classes.scheduledRow} data-testid={scheduleTimePickerTestIds.keyValueRow} key={i}>
          <Typography variant="body2" color="textSecondary">
            {parseValueToTime(schedule.startTime)} - {parseValueToTime(schedule.endTime)}
          </Typography>
          <div className={classes.days}>
            {Object.keys(ScheduledTimeDays)
              .slice(0, 7)
              .map((key) => {
                return (
                  <Chip
                    key={key}
                    label={ScheduledTimeDays[Number(key)].slice(0, 1).toUpperCase()}
                    color={formControlColor}
                    variant={schedule.daysOfWeek.includes(Number(key)) ? 'filled' : 'outlined'}
                  />
                );
              })}
          </div>
        </div>
      ))}
    </div>
  );
}
