import React from 'react';
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import useConfirm from './useConfirm';
import { useLocales } from './useLocales';
import { usePromptTestIds } from '../../components/shared/TestsIds';

function useBlocker(confirmExit: () => Promise<boolean>, when = true): void {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;

    navigator.push = async (...args: Parameters<typeof push>) => {
      const result = await confirmExit();
      if (result) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(when = true): void {
  const { confirm } = useConfirm();
  const { t } = useLocales();

  const confirmExit = useCallback(() => {
    return blockTransition();
  }, []);

  const blockTransition = async () => {
    const confirmed = await confirm({
      confirmText: t('nav_guard_dialog.leave_page'),
      cancelText: t('nav_guard_dialog.stay_here'),
      body: (
        <div>
          <div data-testid={usePromptTestIds.abandonMessage}>{t('unsaved_changes.title')}</div>
          <div>{t('unsaved_changes.subtitle')}</div>
        </div>
      )
    });

    return confirmed;
  };

  useBlocker(confirmExit, when);
}
