import { HomepageOptions } from '../state/theme';
import { PermissionsGroupId } from '../API';

export const getPermissionsGroupsHomepageOption = (homepageOption?: HomepageOptions): PermissionsGroupId[] => {
  switch (homepageOption) {
    case HomepageOptions.EPG:
      return [
        PermissionsGroupId.ADMIN,
        PermissionsGroupId.AVOD,
        PermissionsGroupId.SVOD,
        PermissionsGroupId.SPORTS,
        PermissionsGroupId.NEWS
      ];
    case HomepageOptions.COLLECTIONS:
      return [
        PermissionsGroupId.ADMIN,
        PermissionsGroupId.AVOD,
        PermissionsGroupId.SVOD,
        PermissionsGroupId.SPORTS,
        PermissionsGroupId.NEWS,
        PermissionsGroupId.CLIP
      ];
    case HomepageOptions.LAYOUTS:
    case HomepageOptions.HPC:
      return [
        PermissionsGroupId.ADMIN,
        PermissionsGroupId.AVOD,
        PermissionsGroupId.SVOD,
        PermissionsGroupId.SPORTS,
        PermissionsGroupId.NEWS,
        PermissionsGroupId.MARKETING
      ];
    case HomepageOptions.METADATA:
    case HomepageOptions.SPORTS:
      return [PermissionsGroupId.ADMIN, PermissionsGroupId.AVOD, PermissionsGroupId.SVOD, PermissionsGroupId.SPORTS];
    case HomepageOptions.MONETIZATION:
      return [PermissionsGroupId.ADMIN, PermissionsGroupId.MONETIZATION];
    default:
      return [];
  }
};
