import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useLocales } from '../../../../../hooks';
import { makeStyles } from 'tss-react/mui';
import IconButton from '../../../IconButton';
import { Delete, Edit } from '@mui/icons-material';
import { HeroThumbnail } from '../../../../Layouts/UIModuleListItem/ContentPreview/Thumbnails/HeroThumbnail';
import { ThumbnailOrientation } from '../../../../../utils/types/genericTypes';
import { NewHeroPresetTypes } from '../../../../../utils/uiModuleUtils';
import { useData } from '../../../../../data-layer';
import { useRecoilValue } from 'recoil';
import { useStyles as useHeroPresetStyles } from '../HeroPresets';
import { heroPresetItemTestIds } from '../../../TestsIds';

export interface HeroPresetItemProps {
  presetId: string;
  onRemove: () => unknown;
  'data-testid'?: string;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  thumbnail: {
    height: 160
  }
}));

export function HeroPresetItem({ presetId, onRemove, 'data-testid': dataTestId }: HeroPresetItemProps): JSX.Element {
  const { t } = useLocales();
  const { classes } = useStyles();
  const { classes: hpClasses } = useHeroPresetStyles();
  const {
    presets: {
      state: { withFormMetadata, withRecordById },
      hook: { edit }
    }
  } = useData();

  const preset = useRecoilValue(withRecordById(presetId));
  const formMetadata = useRecoilValue(withFormMetadata);

  const onEdit = () => edit(presetId);

  if (!preset) {
    return <Skeleton className={hpClasses.skeleton} animation="wave" />;
  }

  return (
    <Stack direction="row" alignItems="center" className={classes.root} gap={2} data-testid={dataTestId}>
      <Typography variant="body1" flexGrow={1}>
        {preset.name}
      </Typography>
      <Stack direction="row" gap={2}>
        <div className={classes.thumbnail}>
          <HeroThumbnail
            heroContentItem={preset.data as NewHeroPresetTypes}
            orientation={ThumbnailOrientation.LANDSCAPE}
          />
        </div>
        <div className={classes.thumbnail}>
          <HeroThumbnail
            heroContentItem={preset.data as NewHeroPresetTypes}
            orientation={ThumbnailOrientation.PORTRAIT}
          />
        </div>
      </Stack>
      <div>
        <IconButton
          disabled={formMetadata.isShowingForm}
          onClick={onEdit}
          title={t('presets.edit_preset')}
          size="large"
          data-testid={heroPresetItemTestIds.edit}
        >
          <Edit />
        </IconButton>
        <IconButton
          onClick={onRemove}
          title={t('presets.delete_preset')}
          size="large"
          data-testid={heroPresetItemTestIds.delete}
        >
          <Delete />
        </IconButton>
      </div>
    </Stack>
  );
}
