import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ExpandMore, Search } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
  List,
  ListItemButton,
  Skeleton,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useConfirm, useLocales } from '../../../hooks';
import {
  AssetType,
  AssetCategory,
  AssetTypes,
  AssetRecord,
  isValidType,
  INITIAL_ASSET_TYPE,
  AssetTypesEnum
} from '../../../utils/assetTypes';
import ShadowScroller from '../../shared/ShadowScroller';
import TextField from '../../shared/TextField';
import Button from '../../shared/Button';
import { debounce, startCase } from 'lodash-es';
import { AssetResponse } from '../../../API';
import { AssetCard } from './AssetCard';
import { AssetUploader } from '../AssetUploader';
import { useData } from '../../../data-layer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import { VirtuosoGrid } from 'react-virtuoso';
import { assetsManagerTestIds } from '../../shared/TestsIds';

export const ASSET_WIDTH = 200;

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  assetTypesHeader: {
    padding: theme.spacing(4)
  },
  assetGroupsPanel: {
    width: 300,
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column'
  },
  assetGroups: {
    flexGrow: 1
  },
  accordion: {
    borderRadius: '0 !important',
    borderTop: `1px solid ${theme.palette.divider}`,
    background: 'transparent',
    '&::before': {
      background: 'transparent'
    },
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  accordionSummary: {
    paddingLeft: theme.spacing(4)
  },
  accordionDetails: {
    padding: 0,
    '& ul': {
      padding: '0 !important'
    }
  },
  assetTypeListItem: {
    padding: theme.spacing(3, 2, 3, 6)
  },
  assetsRightPanel: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  assetsRightPanelTop: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  searchBar: {
    width: 500,
    margin: 0,
    marginBottom: '0 !important'
  },
  assetTilesContainer: {
    flexGrow: 1
  },
  scrollContainer: {
    padding: theme.spacing(4)
  },
  assetList: {
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: `repeat(auto-fill, minmax(${ASSET_WIDTH}px, 1fr))`,
    gridTemplateRows: 'repeat(auto-fit, minmax(0, max-content))'
  },
  skeleton: {
    aspectRatio: '1.1'
  }
}));

const assetTypeGroups = Object.keys(AssetTypes) as AssetCategory[];

function AssetsManagerComponent(): JSX.Element {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollEl, setScrollEl] = useState<HTMLDivElement>();

  useEffect(() => {
    if (scrollRef.current) {
      setScrollEl(scrollRef.current);
    }
  }, [scrollRef]);

  const {
    assets: {
      state: { withRecordBucket, withIsFetching, withSelectedAssetType },
      hook: { getBucket, remove }
    }
  } = useData();

  const { classes } = useStyles();
  const { t } = useLocales();
  const { confirm } = useConfirm();
  const navigate = useNavigate();

  const [selectedAssetType, setSelectedAssetType] = useRecoilState(withSelectedAssetType);

  const { assetType: assetTypeParam } = useParams<{ assetType: string }>();
  const assetType = assetTypeParam && decodeURIComponent(assetTypeParam);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<AssetCategory>(AssetRecord[selectedAssetType] as AssetCategory);
  const [filteredAssets, setFilteredAssets] = useState<AssetResponse[]>([]);

  const [isUploaderOpen, setIsUploaderOpen] = useState(false);
  const [editingAsset, setEditingAsset] = useState<AssetResponse>();

  const isLoading = useRecoilValue(withIsFetching);
  const assets = useRecoilValue(withRecordBucket(selectedAssetType));

  useEffect(() => {
    if (!assetType) {
      navigate(AppRoutes.assets(encodeURIComponent(selectedAssetType)), { replace: true });
    } else if (assetType !== selectedAssetType) {
      setSelectedAssetType(assetType as AssetTypesEnum);
      setCategory(AssetRecord[assetType]);
    }

    // for invalid routes
    if (assetType) {
      if (!isValidType(assetType)) {
        setSelectedAssetType(INITIAL_ASSET_TYPE);
        setCategory(AssetRecord[INITIAL_ASSET_TYPE]);
        navigate(AppRoutes.assets(), { replace: true });
      }
    }
  }, [assetType]);

  const changeAssetType = (type: AssetType) => {
    // some of the types have forward slashes in it
    if (selectedAssetType !== type) {
      navigate(AppRoutes.assets(encodeURIComponent(type)));
    }
  };

  const fetchAssets = (type: string) => {
    getBucket?.(type);
  };

  const filterAssets = (term: string) => {
    if (!term) {
      setFilteredAssets(assets || []);
      return;
    }
    const filtered = assets?.filter((asset) => asset.assetName.toLowerCase().includes(term.toLowerCase()));
    setFilteredAssets(filtered || []);
  };

  const debounceAssetType = useCallback(debounce(fetchAssets, 500), []);

  const openUploader = (editAsset?: AssetResponse | undefined) => {
    setEditingAsset(editAsset);
    setIsUploaderOpen(true);
  };

  const handleEditAsset = (edit: AssetResponse | undefined) => {
    openUploader(edit);
  };

  const handleOnClose = () => {
    setIsUploaderOpen(false);
  };

  const handleOnUpload = () => {
    handleOnClose();
  };

  const handleOnRemove = async (removed: AssetResponse) => {
    const confirmReply = await confirm({ body: t('assets.assets_manager.remove_message') });
    if (confirmReply) {
      remove(removed.id);
    }
  };

  useEffect(() => {
    debounceAssetType(selectedAssetType);
  }, [selectedAssetType]);

  useEffect(() => {
    filterAssets(search);
  }, [search, assets]);

  useEffect(() => {
    if (!isUploaderOpen) {
      setEditingAsset(undefined);
    }
  }, [isUploaderOpen]);

  return (
    <div data-testid={assetsManagerTestIds.root} className={classes.root}>
      <div className={classes.assetGroupsPanel}>
        <Typography variant="h5" className={classes.assetTypesHeader}>
          {t('assets.manager.types')}
        </Typography>
        <div className={classes.assetGroups}>
          <ShadowScroller paper>
            {assetTypeGroups.map((group) => (
              <Accordion
                disableGutters
                expanded={group === category}
                key={group}
                className={classes.accordion}
                onClick={() => setCategory(group)}
              >
                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMore />}>
                  {startCase(group)}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <List>
                    {Object.keys(AssetTypes[group] as unknown as AssetCategory).map((key) => {
                      const value = AssetTypes[group][key as AssetType<typeof group>];
                      return (
                        <ListItemButton
                          data-testid={assetsManagerTestIds.assetCategory}
                          key={key}
                          selected={selectedAssetType === value}
                          className={classes.assetTypeListItem}
                          onClick={() => changeAssetType(value)}
                        >
                          {startCase(key)}
                        </ListItemButton>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </ShadowScroller>
        </div>
      </div>
      <div className={classes.assetsRightPanel}>
        <div className={classes.assetsRightPanelTop}>
          <TextField
            data-testid={assetsManagerTestIds.filter}
            debounced
            debounceDelay={300}
            clearable
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={classes.searchBar}
            placeholder={t('assets.browse')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
          <Button data-testid={assetsManagerTestIds.uploadButton} onClick={() => handleEditAsset(undefined)}>
            {t('assets.new_asset')}
          </Button>
        </div>

        <div className={classes.assetTilesContainer}>
          <ShadowScroller ref={scrollRef} loading={isLoading}>
            <div className={classes.scrollContainer}>
              {!isLoading && (
                <VirtuosoGrid
                  useWindowScroll
                  customScrollParent={scrollEl}
                  totalCount={filteredAssets.length}
                  overscan={600}
                  listClassName={classes.assetList}
                  itemContent={(index) => {
                    const asset = filteredAssets[index];
                    if (asset) {
                      return (
                        <AssetCard
                          key={asset.id}
                          asset={asset}
                          width={ASSET_WIDTH}
                          onClick={() => handleEditAsset(asset)}
                          onRemove={() => handleOnRemove(asset)}
                        />
                      );
                    }
                    return <Skeleton animation="wave" className={classes.skeleton} />;
                  }}
                />
              )}
              {isLoading && (
                <div className={classes.assetList} data-testid={assetsManagerTestIds.loader}>
                  {Array(100)
                    .fill(1)
                    .map((x, i) => (
                      <Skeleton key={i} animation="wave" className={classes.skeleton} />
                    ))}
                </div>
              )}
            </div>
          </ShadowScroller>
        </div>
      </div>
      <AssetUploader
        open={isUploaderOpen}
        assetType={selectedAssetType}
        editingAsset={editingAsset}
        onAssetUpload={handleOnUpload}
        onClose={handleOnClose}
      />
    </div>
  );
}

export const AssetsManager = React.memo(AssetsManagerComponent);
