import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { LinkingAccountUiModuleBody } from '../../../../../API';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { useLocales } from '../../../../../hooks';
import { Controller, useFormContext } from 'react-hook-form';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { linkingAccountFormTestIds } from '../../../../shared/TestsIds';

type LinkingAccountFieldProps = {
  name: keyof LinkingAccountUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
};

const LinkingAccountForm = forwardRef<UIModuleTypeForm<LinkingAccountUiModuleBody>>(() => {
  const { control, watch } = useFormContext<LinkingAccountUiModuleBody>();
  const { t } = useLocales();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: LinkingAccountFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.linking_account.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={linkingAccountFormTestIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  return (
    <FormBody data-testid={linkingAccountFormTestIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.linking_account.headers.images')}</SectionHeader>
        <AssetPicker name="vixLogo" assetType={AssetTypes.linkingAccount.vixLogo} required />
        <AssetPicker name="partnerLogo" assetType={AssetTypes.linkingAccount.partnerLogo} required />
        <AssetPicker name="background" assetType={AssetTypes.linkingAccount.background} required />
      </div>
      <div>
        <SectionHeader>{`${t('layouts.linking_account.headers.linking_account')} - ${t(
          'layouts.linking_account.headers.registered_users'
        )}`}</SectionHeader>
        <LokaliseField
          name="avodHeader"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('avodHeader')}
        />
        <LokaliseField
          name="avodSubHeader"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('avodSubHeader')}
        />
        <LokaliseField
          name="createAccountCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('createAccountCta')}
        />
        <LokaliseField
          name="signInCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('signInCta')}
        />
        <LokaliseField
          name="linkExistingAccountCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('linkExistingAccountCta')}
        />
        <LokaliseField
          name="createNewAccountCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('createNewAccountCta')}
        />
      </div>
      <div>
        <SectionHeader>{`${t('layouts.linking_account.headers.linking_account')} - ${t(
          'layouts.linking_account.headers.logged_in'
        )}`}</SectionHeader>
        <LokaliseField
          name="loggedInHeader"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('loggedInHeader')}
        />
        <LokaliseField
          name="loggedInSubHeader"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('loggedInSubHeader')}
        />
        <LokaliseField
          name="loggedInLinkingCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('loggedInLinkingCta')}
        />
        <LokaliseField
          name="loggedInCreateNewAccountCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('loggedInCreateNewAccountCta')}
        />
      </div>
      <div>
        <SectionHeader>{`${t('layouts.linking_account.headers.linking_account')} - ${t(
          'layouts.linking_account.headers.svod_users'
        )}`}</SectionHeader>
        <LokaliseField
          name="svodHeader"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('svodHeader')}
        />
        <LokaliseField
          name="svodSubHeader"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('svodSubHeader')}
        />
        <LokaliseField
          name="svodCreateAccountCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('svodCreateAccountCta')}
        />
        <LokaliseField
          name="notNowCta"
          control={control}
          toTranslate="layouts.linking_account.fields"
          dataTestId={linkingAccountFormTestIds.formField('notNowCta')}
        />
      </div>
    </FormBody>
  );
});

LinkingAccountForm.displayName = 'LinkingAccountForm';

export default LinkingAccountForm;
