import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, useLocales } from '../../../../hooks';
import { IconButton, Popover, MenuItem, Link as MUILink, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';
import VersionLog from '../VersionLog';
import DebugMenu from '../DebugMenu';
import { LogoDev, Article, SettingsSuggestSharp, Group, HelpCenter, Logout, Public, Image } from '@mui/icons-material';
import AppSettings from '../../../AppSettings';
import { AppRoutes } from '../../../../Routes';
import { Environments, whichEnv } from '../../../../utils/whichEnv';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { navBarMenuTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  menuItem: {
    padding: theme.spacing(3)
  },
  menuItemIcon: {
    marginRight: theme.spacing(3)
  }
}));

function NavBarMenu(): JSX.Element {
  const anchorRef = useRef(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const [open, setOpen] = useState<boolean>(false);
  const [isVersionLogOpen, setIsVersionLogOpen] = useState<boolean>(false);
  const [isAppSettingsOpen, setIsAppSettingsOpen] = useState<boolean>(false);
  const [isDebugMenuOpen, setIsDebugMenuOpen] = useState<boolean>(false);
  const { canManageUsers } = usePermissionsGuard();
  const { logout } = useAuth();

  const enableDebugMenu = whichEnv() !== Environments.PRODUCTION;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showVersionLog = () => {
    handleClose();
    setIsVersionLogOpen(true);
  };

  const showDebugMenu = () => {
    handleClose();
    setIsDebugMenuOpen(true);
  };

  const showAppSettings = () => {
    handleClose();
    setIsAppSettingsOpen(true);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color="inherit"
        aria-label="menu"
        aria-haspopup="true"
        onClick={handleOpen}
        data-testid={navBarMenuTestIds.menuButton}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        {canManageUsers && (
          <>
            <MenuItem
              component={Link}
              to={AppRoutes.users}
              className={classes.menuItem}
              onClick={handleClose}
              data-testid={navBarMenuTestIds.usersLink}
            >
              <Group className={classes.menuItemIcon} />
              {t('topbar.manage_users')}
            </MenuItem>
          </>
        )}
        <MenuItem
          component={Link}
          to={AppRoutes.assets()}
          className={classes.menuItem}
          onClick={handleClose}
          data-testid={navBarMenuTestIds.assetsLink}
        >
          <Image className={classes.menuItemIcon} />
          {t('topbar.manage_assets')}
        </MenuItem>
        <MenuItem
          component={Link}
          to={AppRoutes.configSeo}
          className={classes.menuItem}
          onClick={handleClose}
          data-testid={navBarMenuTestIds.settings}
        >
          <Public className={classes.menuItemIcon} />
          {t('topbar.app_configuration')}
        </MenuItem>
        <MenuItem
          data-testid={navBarMenuTestIds.appSettingsButton}
          className={classes.menuItem}
          onClick={showAppSettings}
        >
          <SettingsSuggestSharp className={classes.menuItemIcon} />
          {t('topbar.settings')}
        </MenuItem>
        <MenuItem
          data-testid={navBarMenuTestIds.versionLogButton}
          className={classes.menuItem}
          onClick={showVersionLog}
        >
          <Article className={classes.menuItemIcon} />
          {t('topbar.version_log')}
        </MenuItem>
        {enableDebugMenu && (
          <MenuItem
            data-testid={navBarMenuTestIds.debugMenuButton}
            className={classes.menuItem}
            onClick={showDebugMenu}
          >
            <LogoDev className={classes.menuItemIcon} />
            {t('topbar.debug_menu')}
          </MenuItem>
        )}
        <MenuItem
          component={MUILink}
          href={process.env.REACT_APP_USER_GUIDE_PAGE}
          target="_blank"
          className={classes.menuItem}
        >
          <HelpCenter className={classes.menuItemIcon} />
          {t('topbar.user_guide')}
        </MenuItem>
        <Divider />
        <MenuItem data-testid={navBarMenuTestIds.logout} className={classes.menuItem} onClick={logout}>
          <Logout className={classes.menuItemIcon} />
          {t('topbar.logout')}
        </MenuItem>
      </Popover>
      <VersionLog {...{ isOpen: isVersionLogOpen, setIsOpen: setIsVersionLogOpen }} />
      <AppSettings {...{ isOpen: isAppSettingsOpen, setIsOpen: setIsAppSettingsOpen }} />
      <DebugMenu {...{ isOpen: isDebugMenuOpen, setIsOpen: setIsDebugMenuOpen }} />
    </>
  );
}

export default NavBarMenu;
