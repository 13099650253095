import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Box, InputLabel, MenuItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { makeStyles } from 'tss-react/mui';
import { useData } from '../../../../data-layer';
import { useLocales, useTheme } from '../../../../hooks';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import { IdBadge } from '../../../shared/IdBadge';
import { AppStoreResponse, OfferMappingBody, OfferMappingResponse, PermissionsGroupId } from '../../../../API';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import TextField from '../../../shared/TextField';
import { markAsRequired } from '../../../../utils/formHelpers';
import { SubscriptionManagerCouponMappingItem } from './SubscriptionManagerCouponMappingItem';
import { isArray } from 'lodash-es';
import FormControl from '../../../shared/FormControl';
import Select from '../../../shared/Select';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { offerMappingFormTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4)
  },
  footerButtons: {
    display: 'flex',
    gap: theme.spacing(4)
  },
  footerButton: {
    minWidth: 160
  },
  offerCode: {
    width: 420
  },
  notes: {
    width: 420
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(4)
  },
  formRowFullW: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(4)
  },
  subManagerCouponMappingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    paddingTop: theme.spacing(4),
    '&:not(:last-of-type)': {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px dotted ${theme.palette.divider}`
    }
  }
}));

export function OfferMappingForm(): React.ReactElement {
  const { classes } = useStyles();
  const { offerMappings } = useData();
  const { formControlColor } = useTheme();
  const { t } = useLocales();

  const formMetadata = useRecoilValue(offerMappings.state.withFormMetadata);
  const isSaving = useRecoilValue(offerMappings.state.withIsSaving);
  const offerMapping = formMetadata.record;
  const formRef = useRef<ValidatorForm>(null);
  const [appStores, setAppStores] = React.useState<AppStoreResponse[]>([]);

  const methods = useForm<OfferMappingBody | OfferMappingResponse>();
  const { control, handleSubmit, reset } = methods;
  const { fields, remove, append } = useFieldArray({ control, name: 'source.offer.subscriptionManagerCouponMappings' });

  const { canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.MONETIZATION
  });
  const { hasUpsertPermission } = usePermissions();

  useLayoutEffect(() => {
    reset(offerMapping);
  }, [offerMapping]);

  useEffect(() => {
    const initAppStores = async () => {
      const appStoresResponse = await offerMappings.hook.getAppStores();
      setAppStores(appStoresResponse?.appStores ?? []);
    };

    initAppStores();
  }, []);

  const onClose = () => {
    offerMappings.hook.closeForm();
  };

  const onSubmit = async (offerMapping: OfferMappingBody | OfferMappingResponse) => {
    const offerMappingToSave = {
      ...offerMapping,
      source: {
        ...offerMapping.source,
        offer: {
          ...offerMapping.source.offer,
          subscriptionManagerCouponMappings: offerMapping.source.offer.subscriptionManagerCouponMappings.map(
            (item) => ({
              code: typeof item.code === 'string' ? item.code : (item.code as unknown as string[])[0],
              country: isArray(item.country) ? item.country[0].toUpperCase() : item.country?.toUpperCase()
            })
          )
        }
      }
    };
    const savedOfferMapping = await offerMappings.hook.save(offerMappingToSave);

    if (savedOfferMapping) {
      onClose();
    }
  };

  const footerLeft = (
    <div className={classes.footerButtons}>
      <Button
        className={classes.footerButton}
        color="primary"
        loading={isSaving}
        onClick={handleSubmit(onSubmit)}
        disabled={!hasUpsertPermission(PermissionsGroupId.MONETIZATION) && !canSave}
        data-testid={offerMappingFormTestIds.saveButton}
      >
        {t('general.save')}
      </Button>
    </div>
  );

  const footerRight = (
    <Button className={classes.footerButton} color="grey" onClick={onClose}>
      {t('general.cancel')}
    </Button>
  );

  const blankSubscriptionManagerCoupn = () => ({
    code: '',
    country: ''
  });

  const addSubManagerCouponMappingItem = () => {
    append(blankSubscriptionManagerCoupn());
  };

  return (
    <Drawer
      open={formMetadata.isShowingForm}
      onClose={onClose}
      headerLeft={
        <Stack direction="row" gap={4}>
          <Typography variant="h6">{t(`payments.${formMetadata.isEditing ? 'edit' : 'new'}_offer_mapping`)}</Typography>
          {formMetadata.isEditing && offerMapping && (
            <IdBadge
              id={(offerMapping as OfferMappingResponse).id}
              data-testid={offerMappingFormTestIds.offerMappingId}
            />
          )}
        </Stack>
      }
      footerLeft={footerLeft}
      footerRight={footerRight}
      formRef={formRef}
    >
      {offerMapping && (
        <div className={classes.formBody} data-testid={offerMappingFormTestIds.formBody}>
          <FormProvider {...methods}>
            <div className={classes.formRow}>
              <Controller
                control={control}
                name="source.offer.code"
                rules={{ required: t('general.field_is_required') }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    required
                    className={classes.offerCode}
                    value={value}
                    onChange={(e) => onChange(e.target.value.trim())}
                    label={t('payments.offer_mapping.source_offer_code')}
                    fieldError={error}
                    data-testid={offerMappingFormTestIds.offerCode}
                  />
                )}
              />
              <Controller
                name="source.name"
                control={control}
                rules={{ required: t('general.field_is_required') }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <FormControl>
                      <InputLabel id="sourceNameSelector" error={!!error}>
                        {markAsRequired(t('payments.offer_mapping.source_name'))}
                      </InputLabel>
                      <Select
                        label={markAsRequired(t('payments.offer_mapping.source_name'))}
                        labelId="sourceNameSelector"
                        value={value}
                        onChange={onChange}
                        data-testid={offerMappingFormTestIds.nameInput}
                        required
                        color={formControlColor}
                        error={!!error}
                      >
                        {appStores.map((appStore) => (
                          <MenuItem key={appStore.key} value={appStore.key}>
                            {appStore.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
              />
              <Controller
                control={control}
                name="notes"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextField
                    className={classes.notes}
                    multiline
                    value={value}
                    onChange={onChange}
                    label={t('payments.offer_mapping.notes')}
                    fieldError={error}
                    data-testid={offerMappingFormTestIds.notes}
                  />
                )}
              />
            </div>
            <div className={classes.formRowFullW}>
              <div>
                <Box sx={{ mt: 3 }}>
                  <h3>{t('payments.offer_mapping.source_subcription_manager_coupon_mappings')}</h3>
                </Box>
                {fields.map((field, i) => (
                  <div key={field.id} className={classes.subManagerCouponMappingsContainer}>
                    <SubscriptionManagerCouponMappingItem index={i} onDelete={() => remove(i)} />
                  </div>
                ))}
                <Box sx={{ mt: 4 }}>
                  <Button
                    color={formControlColor}
                    variant="outlined"
                    onClick={addSubManagerCouponMappingItem}
                    data-testid={offerMappingFormTestIds.addSubManagerCouponMappingItem}
                  >
                    {t('payments.offer_mapping.add_sub_manager_coupon_mapping_item')}
                  </Button>
                </Box>
              </div>
            </div>
          </FormProvider>
        </div>
      )}
    </Drawer>
  );
}
