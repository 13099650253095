import React, { memo, useRef } from 'react';
import { Box } from '@mui/material';
import { useData } from '../../../../data-layer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MatchListItem } from '../../../Sports/MatchListItem';
import { FOUND_CLASS } from '../Media';
import { makeStyles } from 'tss-react/mui';
import { useSelectedCollectionContent } from '../../../../hooks/Collections/useSelectedCollectionContent';
import { MediaButtons, MediaButtonsProps } from '../Media/MediaButtons';
import { useRatings } from '../../../../hooks/Media/useRatings';
import { ensurePinnedElementsAtTheBeginning } from '../../../../utils/autoRotate';
import { CollectionAsset, CollectionAssetType, MediaResponse } from '../../../../API';
import { DisplayAsOptions } from '../../../../utils/types/genericTypes';
import { matchTestIds } from '../../../shared/TestsIds';

interface MatchProps {
  match: MediaResponse;
  inCollection?: boolean;
  previewMode?: boolean;
  collectionForKids?: boolean;
  'data-testid'?: string;
}

const useStyles = makeStyles()((theme) => ({
  matchListItem: {
    fontSize: '0.9rem',
    cursor: 'pointer'
  },
  mediaButtons: {
    position: 'absolute',
    top: 0,
    left: 0,
    flexDirection: 'column-reverse'
  },
  mediaListButtons: {
    position: 'absolute',
    top: '50',
    right: 0,
    flexDirection: 'column-reverse'
  },
  styleFound: {
    position: 'absolute',
    inset: 0,
    transition: 'box-shadow 0.5s ease',
    pointerEvents: 'none',
    [`&.${FOUND_CLASS}`]: {
      boxShadow: `inset 0 0 0 5px ${theme.palette.primary.main}`
    }
  }
}));

function Match({ match, inCollection, previewMode, collectionForKids, ...props }: MatchProps) {
  const {
    collections: {
      state: {
        withDisplayAs,
        withSelected,
        withSelectedCollectionContent,
        withSelectedCollectionAsset,
        withMixedCollectionDisplay
      }
    }
  } = useData();

  const { classes, cx } = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const displayAs = useRecoilValue(withDisplayAs);
  const selectedCollection = useRecoilValue(withSelected);
  const setSelectedCollectionContents = useSetRecoilState(withSelectedCollectionContent);
  const setSelectedCollectionAssets = useSetRecoilState(withSelectedCollectionAsset);
  const isMixedCollection = useRecoilValue(withMixedCollectionDisplay);

  const matchId = match.contentId;
  const { isSuitableForKids } = useRatings(matchId);
  const isList = displayAs === DisplayAsOptions.LIST;
  const collectionSelected = !!selectedCollection;

  const { isContentIdInActiveCollection } = useSelectedCollectionContent();

  const onAdd = (evt?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!selectedCollection) return;
    // Add to the end if the user is holding SHIFT, otherwise add to the beginning
    const addToEnd = !!evt?.nativeEvent.shiftKey;
    setSelectedCollectionContents(
      (contents) =>
        ensurePinnedElementsAtTheBeginning(
          selectedCollection.pinnedContents,
          contents ? (addToEnd ? [...contents, matchId] : [matchId, ...contents]) : [matchId]
        ) as string[]
    );

    if (isMixedCollection) {
      const asset = { assetId: matchId, assetType: CollectionAssetType.LIVE_EVENT };
      setSelectedCollectionAssets(
        (contents) =>
          ensurePinnedElementsAtTheBeginning(
            selectedCollection.pinnedContents,
            contents ? (addToEnd ? [...contents, asset] : [asset, ...contents]) : [asset]
          ) as CollectionAsset[]
      );
    }
  };

  // shared props for media buttons
  const mediaButtonsProps: MediaButtonsProps = {
    inCollection,
    aptForKids: isSuitableForKids(),
    collectionForKids,
    previewMode,
    collectionSelected,
    onAdd: onAdd
  };

  return (
    <Box data-testid={props['data-testid'] || matchTestIds.root} sx={{ cursor: 'pointer' }}>
      <MatchListItem
        match={match}
        data-testid={matchTestIds.matchListItem}
        className={cx({
          [classes.matchListItem]: true,
          sortableFiltered: isContentIdInActiveCollection(matchId)
        })}
      >
        {isList ? (
          <MediaButtons className={classes.mediaListButtons} {...mediaButtonsProps} />
        ) : (
          <MediaButtons className={classes.mediaButtons} backdrop size="small" {...mediaButtonsProps} />
        )}
        <div className={classes.styleFound} ref={ref} />
      </MatchListItem>
    </Box>
  );
}

export default memo(Match);
