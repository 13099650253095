import React from 'react';
import { Warning } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useLocales } from '../../../hooks';
export interface IDeletedBadgeProps {
  className?: string;
  'data-testid'?: string;
}

export function DeletedBadge(props: IDeletedBadgeProps): React.ReactElement {
  const { t } = useLocales();
  return (
    <Tooltip title={t('general.entity_is_deleted')} arrow data-testid={props['data-testid']}>
      <Warning color="warning" />
    </Tooltip>
  );
}
