import React, { useMemo } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { Page, PermissionsGroupId } from '../../../API';
import { useAuth, useLayoutsRouter, useLocales, useTheme } from '../../../hooks';
import { DUMMY_LAYOUT_ID } from '../../../utils/appDefaults';
import { withLayoutsType, withLayoutsTypeLowercase } from '../../../state/Layouts';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { useData } from '../../../data-layer';
import { pageToPathParam } from '../../../utils/layouts';
import { layoutPageSelectorTestIds } from '../../shared/TestsIds';

export const maxKeyValuePairs = 3;

export type LayoutsPageSelectorProps = {
  className?: string;
};

type GroupedPage = Page & { group: string };

export function LayoutsPageSelector({ className }: LayoutsPageSelectorProps): React.ReactElement {
  const { formControlColor } = useTheme();
  const { t } = useLocales();
  const layoutsTypeLowercase = useRecoilValue(withLayoutsTypeLowercase);
  const layoutsType = useRecoilValue(withLayoutsType);
  const { hasReadPermission } = usePermissions();
  const { updateLayoutsRoute } = useLayoutsRouter();
  const { currentUser } = useAuth();

  const {
    pages: {
      state: { withRecordBucket, withSelected }
    }
  } = useData();
  const pages = useRecoilValue(withRecordBucket(layoutsType));
  const selectedPage = useRecoilValue(withSelected);

  const isPageHidden = (page: Page) => currentUser?.hiddenLayouts?.includes(page.urlPath);

  const pagesGrouped = useMemo(() => {
    return pages?.map((page) => ({
      ...page,
      group: isPageHidden(page)
        ? 'HIDDEN'
        : page.ownerPermissionsGroup?.toString() || PermissionsGroupId.ADMIN.toString()
    })) as GroupedPage[];
  }, [pages, currentUser?.hiddenLayouts]);

  const onChange = (changedPage: Page) => {
    updateLayoutsRoute({
      pageUrl: pageToPathParam(changedPage),
      layoutId: DUMMY_LAYOUT_ID
    });
  };

  const noPageOption = {
    urlPath: t('layouts.no_page_selected'),
    ownerPermissionsGroup: PermissionsGroupId.ADMIN,
    group: PermissionsGroupId.ADMIN.toString()
  } as GroupedPage;
  const loadingOption = { ...noPageOption, urlPath: t('general.loading') } as GroupedPage;
  const dummyOptions: GroupedPage[] = [loadingOption];

  const selectedPageGrouped = {
    ...selectedPage,
    group:
      selectedPage && isPageHidden(selectedPage)
        ? 'HIDDEN'
        : selectedPage?.ownerPermissionsGroup?.toString() || PermissionsGroupId.ADMIN.toString()
  } as GroupedPage;

  return (
    <Autocomplete
      className={className}
      options={pagesGrouped?.sort((a, b) => a.group.localeCompare(b.group)) || dummyOptions}
      getOptionLabel={(option) => option.urlPath}
      getOptionDisabled={(option) => !hasReadPermission(option.ownerPermissionsGroup)}
      groupBy={(option) => option.group}
      onChange={(_, changedPage) => onChange(changedPage as GroupedPage)}
      isOptionEqualToValue={(option, value) => option.urlPath === value.urlPath}
      renderOption={(props, page) => (
        <MenuItem
          {...props}
          data-testid={layoutPageSelectorTestIds.autocompleteOption}
          data-page={page.urlPath}
          disabled={page.urlPath === selectedPage?.urlPath}
          style={{
            color: isPageHidden(page) && !props['aria-selected'] ? 'rgba(0, 0, 0, 0.3)' : 'inherit'
          }}
        >
          {page.urlPath}
        </MenuItem>
      )}
      disabled={!pagesGrouped}
      disableClearable
      blurOnSelect
      value={!pages ? loadingOption : selectedPageGrouped || noPageOption}
      data-testid={layoutPageSelectorTestIds.autocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(`layouts.${layoutsTypeLowercase}`)}
          color={formControlColor}
          data-testid={layoutPageSelectorTestIds.textField}
        />
      )}
    />
  );
}
