import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { DataPresetBody, DataPresetResponse } from '../../../../API';
import { useLocales, useNotifications, useTheme } from '../../../../hooks';
import Button from '../../Button';
import Modal from '../../Modal';
import { ObjectTreeView } from '../../ObjectTreeView';
import { useData } from '../../../../data-layer';
import { fieldErrorsToArray, isFormValid } from '../../../../utils/formHelpers';
import TextValidator from '../../TextValidator';
import { presetModalTestIds } from '../../TestsIds';

export interface IPresetModalProps {
  formPreset: DataPresetBody | DataPresetResponse | undefined;
  saveCallback: (preset: DataPresetResponse, isNew: boolean) => void;
  onClose: () => void;
}

const useStyles = makeStyles()((theme) => ({
  form: {
    padding: theme.spacing(4)
  },
  nameInput: {
    minWidth: 270
  },
  footerButton: {
    minWidth: 160
  },
  dataPreview: {
    marginTop: theme.spacing(4)
  }
}));

export function PresetModal({ formPreset, saveCallback, onClose: onCloseProp }: IPresetModalProps): React.ReactElement {
  const {
    presets: {
      hook: { createPreset, updatePreset }
    }
  } = useData();
  const { classes } = useStyles();
  const { t } = useLocales();
  const { notifyError } = useNotifications();
  const { formControlColor } = useTheme();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<DataPresetBody | DataPresetResponse>();
  const formRef = useRef<ValidatorForm>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (formPreset) {
      reset(formPreset);
      setIsOpen(true);
      setIsNew(formPreset.name === '');
    }
  }, [formPreset]);

  const onClose = () => {
    if (!isSaving) {
      setIsOpen(false);
      onCloseProp();
    }
  };

  const onSave = async () => {
    if (!formRef.current) return;
    const valid = await isFormValid(formRef);
    if (!valid) {
      notifyError(t('general.form_error'), fieldErrorsToArray(errors));
    }
    (formRef.current as ValidatorForm & { submit: () => void }).submit();
  };

  const onSubmit: SubmitHandler<DataPresetBody | DataPresetResponse> = (preset) => {
    if (formPreset) {
      doSubmit({ ...preset, type: formPreset.type, data: preset.data });
    }
  };

  const doSubmit = async (preset: DataPresetBody | DataPresetResponse) => {
    if (!formPreset) return;
    setIsSaving(true);
    let result: DataPresetResponse | undefined;
    if (isNew) {
      result = await createPreset(preset);
    } else {
      result = await updatePreset((formPreset as DataPresetResponse).id as string, preset as DataPresetResponse);
    }
    if (result) {
      saveCallback(result, isNew);
      setIsOpen(false);
    }
    setIsSaving(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      headerLeft={<Typography variant="h6">{t(`presets.${isNew ? 'new_preset' : 'edit_preset'}`)}</Typography>}
      footerLeft={
        <Button className={classes.footerButton} loading={isSaving} onClick={onSave}>
          {t('presets.save_preset')}
        </Button>
      }
      footerRight={
        <Button className={classes.footerButton} disabled={isSaving} color="grey" onClick={onClose}>
          {t('general.cancel')}
        </Button>
      }
    >
      <ValidatorForm
        className={classes.form}
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        data-testid={presetModalTestIds.validatorForm}
      >
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextValidator
              className={classes.nameInput}
              name="name"
              color={formControlColor}
              value={value}
              onChange={onChange}
              label={t('presets.name')}
              validators={['required']}
              errorMessages={[t('general.field_is_required')]}
              data-testid={presetModalTestIds.presetNameInput}
            />
          )}
        />
        <div className={classes.dataPreview}>
          {formPreset && <ObjectTreeView object={formPreset.data} title={t('presets.preset_data')} />}
        </div>
      </ValidatorForm>
    </Modal>
  );
}
