import React, { forwardRef } from 'react';
import { Controller, useFormContext, Path } from 'react-hook-form';
import { ProfileUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { FormControlLabel, Switch } from '@mui/material';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { profileFormTestIds } from '../../../../shared/TestsIds';

type ProfileFieldProps = {
  name: Path<ProfileUiModuleBody>;
  required?: boolean;
  'data-testid'?: string;
};

const ProfileForm = forwardRef<UIModuleTypeForm<ProfileUiModuleBody>>(() => {
  const { control, watch } = useFormContext<ProfileUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: ProfileFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            label={markAsRequired(t(`layouts.profile.fields.${name}`), required)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={profileFormTestIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const SwitchField = ({ name }: ProfileFieldProps): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={t(`layouts.profile.fields.${name}` as LocaleKeys)}
            control={
              <Switch
                color={formControlColor}
                checked={value as boolean}
                onChange={onChange}
                data-testid={profileFormTestIds.formField(name)}
              />
            }
          />
        )}
      />
    </FormField>
  );

  return (
    <FormBody data-testid={profileFormTestIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.profile.headers.images')}</SectionHeader>
        <AssetPicker name="landscapeFillImage" assetType={AssetTypes.profile.landscape} required />
        <AssetPicker name="portraitFillImage" assetType={AssetTypes.profile.portrait} required />
        <AssetPicker name="desktopFillImage" assetType={AssetTypes.profile.desktop} />
        <AssetPicker name="mobileFillImage" assetType={AssetTypes.profile.mobile} />
        <AssetPicker name="ctvFillImage" assetType={AssetTypes.profile.ctv} />
        <AssetPicker name="tabletFillImage" assetType={AssetTypes.profile.tablet} />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.profile')}</SectionHeader>
        <LokaliseField
          name="header"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('header')}
          required
        />
        <LokaliseField
          name="subheader"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('subheader')}
          required
        />
        <LokaliseField
          name="benefit"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('benefit')}
        />
        <LokaliseField
          name="adminMiCuenta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('adminMiCuenta')}
        />
        <LokaliseField
          name="subscribeCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('subscribeCta')}
        />
        <LokaliseField
          name="subscribeCtaDestination"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('subscribeCtaDestination')}
        />
        <LokaliseField
          name="myProfileAVODandAdTierUpgradeCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('myProfileAVODandAdTierUpgradeCta')}
        />
        <LokaliseField
          name="signUpCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('signUpCta')}
        />
        <LokaliseField
          name="signInCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('signInCta')}
        />
        <LokaliseField
          name="reactivateCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('reactivateCta')}
        />
        <LokaliseField
          name="reactivateCtaDestination"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('reactivateCtaDestination')}
        />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.reminder')}</SectionHeader>
        <LokaliseField
          name="bundleReminderPrimaryOpt"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('bundleReminderPrimaryOpt')}
        />
        <LokaliseField
          name="bundleReminderSecondaryOpt"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('bundleReminderSecondaryOpt')}
        />
        <LokaliseField
          name="bundleCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('bundleCta')}
        />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.my_account')}</SectionHeader>
        <LokaliseField
          name="planNameTag"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planNameTag')}
        />
        <LokaliseField
          name="planStatusTag"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planStatusTag')}
        />
        <LokaliseField
          name="paymentMethodTag"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('paymentMethodTag')}
        />
        <LokaliseField
          name="paymentStatus"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('paymentStatus')}
        />
        <LokaliseField
          name="paymentStatusAlert"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('paymentStatusAlert')}
        />
        <LokaliseField
          name="planSwitchingCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchingCta')}
        />
        <LokaliseField
          name="changePaymentMethodCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('changePaymentMethodCta')}
        />
        <LokaliseField
          name="cancelSubscriptionCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('cancelSubscriptionCta')}
        />
        <LokaliseField
          name="planSwitchingHeaderCard"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchingHeaderCard')}
        />
        <LokaliseField
          name="planSwitchingCardCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchingCardCta')}
        />
        <LokaliseField
          name="legalDisclosurePlanSwitchingCard"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('legalDisclosurePlanSwitchingCard')}
        />
        <LokaliseField
          name="changeEmailCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('changeEmailCta')}
        />
        <LokaliseField
          name="changePasswordCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('changePasswordCta')}
        />
        <LokaliseField
          name="planDetailsCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planDetailsCta')}
        />
        <LokaliseField
          name="cancelInstructionsIap"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('cancelInstructionsIap')}
        />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.confirmation')}</SectionHeader>
        <LokaliseField
          name="newPlanNameTag"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('newPlanNameTag')}
        />
        <LokaliseField
          name="confirmationPaymentStatusAlert"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('confirmationPaymentStatusAlert')}
        />
        <LokaliseField
          name="planSwitchingRefundTag"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchingRefundTag')}
        />
        <LokaliseField
          name="totalAmountDueTag"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('totalAmountDueTag')}
        />
        <LokaliseField
          name="planSwitchDetailSubheader"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchDetailSubheader')}
        />
        <LokaliseField
          name="activationDateDetail"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('activationDateDetail')}
        />
        <LokaliseField
          name="newBenefitsDetail"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('newBenefitsDetail')}
        />
        <LokaliseField
          name="planSwitchLegalDisclosure"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchLegalDisclosure')}
        />
        <LokaliseField
          name="planSwitchConfirmCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchConfirmCta')}
        />
        <LokaliseField
          name="planSwitchCancelCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('planSwitchCancelCta')}
        />
        <div></div>
        <SwitchField name="legalCheckbox" />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.spin_premia')}</SectionHeader>
        <LokaliseField
          name="spinPremia.headerBefore"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('spinPremia.headerBefore')}
        />
        <LokaliseField
          name="spinPremia.ctaBefore"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('spinPremia.ctaBefore')}
        />
        <LokaliseField
          name="spinPremia.headerAfter"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('spinPremia.headerAfter')}
        />
        <LokaliseField
          name="spinPremia.bodyAfter"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('spinPremia.bodyAfter')}
        />
        <LokaliseField
          name="spinPremia.ctaAfter"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('spinPremia.ctaAfter')}
        />
      </div>
      <div>
        <SectionHeader>{t('layouts.profile.headers.gift_cards')}</SectionHeader>
        <LokaliseField
          name="paymentMethodTitle"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('paymentMethodTitle')}
        />
        <LokaliseField
          name="paymentMethodDescription"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('paymentMethodDescription')}
        />
        <LokaliseField
          name="giftCardBalance"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('giftCardBalance')}
        />
        <LokaliseField
          name="topUpCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('topUpCta')}
        />
        <LokaliseField
          name="redeemTitle"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('redeemTitle')}
        />
        <LokaliseField
          name="redeemCta"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('redeemCta')}
        />
        <LokaliseField
          name="giftCardBalanceHeading"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('giftCardBalanceHeading')}
        />
        <LokaliseField
          name="giftCardBalanceDtc"
          control={control}
          toTranslate="layouts.profile.fields"
          dataTestId={profileFormTestIds.formField('giftCardBalanceDtc')}
        />
      </div>
    </FormBody>
  );
});

ProfileForm.displayName = 'ProfileForm';

export default ProfileForm;
