import React from 'react';
import Modal from '../../../../shared/Modal';
import { AccountCreationUiModuleBody } from '../../../../../API';
import { useLocales, useLokalise, useTheme } from '../../../../../hooks';
import { Box, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { Person, VisibilityOff } from '@mui/icons-material';
import { VixOrangeButton } from '../shared/SharedButtons';
import Button from '../../../../shared/Button';
import { previewDetailsDialog } from '../../../../../styles/previewDetails';
import { accountCreationPreviewTestIds } from '../../../../shared/TestsIds';

type AccountCreationPreviewProps = {
  module: AccountCreationUiModuleBody;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export function AccountCreationPreview({ module, isOpen, setIsOpen }: AccountCreationPreviewProps): React.ReactElement {
  const { classes } = previewDetailsDialog({ width: 420, padding: '0 16px' })();
  const { t } = useLocales();
  const { lt } = useLokalise();
  const { formControlColor } = useTheme();

  const onModalClose = () => setIsOpen(false);

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      headerLeft={t('layouts.account_creation.preview')}
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box data-testid={accountCreationPreviewTestIds.root} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Box data-testid={accountCreationPreviewTestIds.container} className={classes.container}>
          <Stack gap="24px" width="100%">
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Stack direction="row" gap="16px" alignItems="center">
                <img src={getAssetUrl(module.vixLogo)} className={classes.logo} />
                {module.partnerLogo && (
                  <>
                    <div className={classes.separator} />
                    <img src={getAssetUrl(module.partnerLogo)} className={classes.logo} />
                  </>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" gap="4px">
                <Person />
                <span>{lt(module.signInCta)}</span>
              </Stack>
            </Stack>
            <Stack gap="8px">
              <Typography variant="h5" alignSelf="start">
                <span dangerouslySetInnerHTML={{ __html: lt(module.header) }} />
              </Typography>
              <Typography>
                <span dangerouslySetInnerHTML={{ __html: lt(module.subHeader) }} />
              </Typography>
            </Stack>
            <Stack gap="8px">
              <TextField
                className={classes.textField}
                color={formControlColor}
                variant="outlined"
                placeholder={lt(module.emailText)}
                focused
              />
              <TextField
                className={classes.textField}
                color={formControlColor}
                variant="outlined"
                placeholder={lt(module.passwordText)}
                InputProps={{ endAdornment: <VisibilityOff /> }}
                focused
              />
            </Stack>
            <Stack direction="row" alignItems="start">
              {module.legalCheckbox && <Checkbox className={classes.checkbox} />}
              <Stack gap="8px">
                <Typography variant="subtitle2" color="gray">
                  <span dangerouslySetInnerHTML={{ __html: lt(module.vixDisclaimer) }} />
                </Typography>
                <Typography variant="subtitle2" color="gray">
                  <span dangerouslySetInnerHTML={{ __html: lt(module.partnerDisclaimer) }} />
                </Typography>
              </Stack>
            </Stack>
            <VixOrangeButton>{lt(module.signUpCta)}</VixOrangeButton>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
