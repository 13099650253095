/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * The CMS API!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum PermissionsGroupId {
    ADMIN = 'ADMIN',
    AVOD = 'AVOD',
    SVOD = 'SVOD',
    SPORTS = 'SPORTS',
    NEWS = 'NEWS',
    MARKETING = 'MARKETING',
    MONETIZATION = 'MONETIZATION',
    CLIP = 'CLIP'
}



