import React, { useRef, useState } from 'react';
import { Button, MenuItem, ListItemText, Popover, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Timezone } from '../../../../state/Timezone';
import { useTimezones } from '../../../../hooks';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { timezonePopoverTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()(() => ({
  button: {
    color: 'inherit'
  }
}));

function TimezonePopover(): JSX.Element {
  const { getTimezonesList, currentTimezone, onChangeTimezone } = useTimezones();
  const timezones = getTimezonesList();
  const { classes } = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onHandleChangeTimezone = (value: Timezone) => {
    onChangeTimezone(value);
    handleClose();
  };

  return (
    <>
      <Button
        className={classes.button}
        ref={anchorRef}
        onClick={handleOpen}
        data-testid={timezonePopoverTestIds.menuButton}
      >
        {<AccessTimeIcon />}
        <Typography mr={1} ml={1} variant="button" display="block">
          {currentTimezone.label}
        </Typography>
      </Button>

      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        data-testid={timezonePopoverTestIds.popover}
      >
        {timezones.map((Timezone) => (
          <MenuItem
            key={Timezone.label}
            selected={Timezone.label === currentTimezone.label}
            onClick={() => onHandleChangeTimezone(Timezone)}
            data-testid={timezonePopoverTestIds.TimezoneMenuItem}
          >
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{Timezone.description}</ListItemText>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

export default TimezonePopover;
