import React from 'react';
import { Chip, Typography } from '@mui/material';
import { RecommendationsUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { toTitleCase } from '../../../../../utils/textUtils';
import { getContentTreatmentIcon } from '../../../../../utils/uiModuleUtils';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { CollectionLink } from '../../../../Collections/CollectionLink';
import { recommendationsListItemTestIds } from '../../../../shared/TestsIds';

export type RecommendationsListItemProps = IBaseUIModuleListItemProps<RecommendationsUiModuleBody>;

const RecommendationsListItem = ({ uiModule, ...props }: RecommendationsListItemProps): JSX.Element => {
  const { t } = useLocales();

  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      topLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.treatment')}:
          </Typography>
          <Chip
            icon={getContentTreatmentIcon(uiModule.contentTreatment)}
            label={toTitleCase(uiModule.contentTreatment)}
            size="small"
            data-testid={recommendationsListItemTestIds.treatment}
          />
        </>
      ]}
      collectionLine={[
        <>
          <Typography variant="body2" color="textSecondary">
            {t('layouts.recommendations_type')}:
          </Typography>
          <Chip
            label={toTitleCase(uiModule.recommendationsType as string)}
            size="small"
            data-testid={recommendationsListItemTestIds.recommendationsType}
          />
        </>,
        <>
          {!!uiModule.contentTypes.length && (
            <>
              <Typography variant="body2" color="textSecondary">
                {t('layouts.content_type')}:
              </Typography>
              <Chip
                label={toTitleCase(uiModule.contentTypes.join(', '))}
                size="small"
                data-testid={recommendationsListItemTestIds.contentTypes}
              />
            </>
          )}
        </>,
        <>
          {uiModule.collectionId && (
            <>
              <Typography variant="body2" color="textSecondary">
                {t('layouts.fallback_collection')}:
              </Typography>
              {uiModule.collectionId && <CollectionLink collectionId={uiModule.collectionId} />}
            </>
          )}
        </>
      ]}
      {...props}
    />
  );
};

export default RecommendationsListItem;
