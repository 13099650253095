import React from 'react';
import { InlinePageUiModuleBody } from '../../../../../API';
import Localized from '../../../../shared/Localized';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import InlinePagePreview from '../../ContentPreview/InlinePagePreview';
import { inllinePageListItemTestIds } from '../../../../shared/TestsIds';

export type InlinePageListItemProps = IBaseUIModuleListItemProps<InlinePageUiModuleBody>;

const InlinePageListItem = ({ uiModule, ...props }: InlinePageListItemProps): JSX.Element => {
  return (
    <UIModuleListItem
      uiModule={uiModule}
      topLineTitle={<Localized prop={uiModule.title} />}
      renderPreview={(showPreview) => (
        <div data-testid={inllinePageListItemTestIds.preview}>
          <InlinePagePreview showing={showPreview} module={uiModule} />
        </div>
      )}
      {...props}
    />
  );
};

export default InlinePageListItem;
