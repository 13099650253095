import React from 'react';
import { Add, Delete } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useLocales, useTheme } from '../../../hooks';
import { AdTarget } from '../../../API';
import TextValidator from '../TextValidator';
import { markAsRequired } from '../../../utils/formHelpers';
import IconButton from '../IconButton';
import Button from '../Button';
import { AD_TARGETING_KEY_REGEX, AD_TARGETING_VALUE_REGEX } from '../../../utils/regex';
import { adTargetingTestIds } from '../TestsIds';

export const maxKeyValuePairs = 3;

export type AdTargetingProps = {
  value: AdTarget[] | undefined;
  onChange: (value: AdTarget[] | undefined) => void;
  className?: string;
};

const useStyles = makeStyles()((theme) => ({
  keyValuePair: {
    display: 'flex',
    width: '100%',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  inputColumn: {
    flexGrow: 1
  }
}));

export function AdTargeting({ value, onChange, className }: AdTargetingProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const handleAdd = () => {
    onChange([...(value || []), { key: '', value: '' }]);
  };

  const handleRemove = (index: number) => {
    if (!value) return;
    onChange(value.slice(0, index).concat(value.slice(index + 1)));
  };

  const onKeyChange = (index: number, key: string) => {
    if (!value) return;
    const newValue = [...value];
    newValue[index] = {
      value: value[index].value,
      key
    };
    onChange(newValue);
  };

  const onValueChange = (index: number, val: string) => {
    if (!value) return;
    const newValue = [...value];
    newValue[index] = {
      key: value[index].key,
      value: val
    };
    onChange(newValue);
  };

  return (
    <div className={className} data-testid={adTargetingTestIds.root}>
      {value?.map((adTarget, i) => (
        <div className={classes.keyValuePair} data-testid={adTargetingTestIds.keyValueRow} key={i}>
          <div className={classes.inputColumn}>
            <TextValidator
              fullWidth
              name="key"
              debounced={false}
              value={adTarget.key}
              onChange={({ target: { value: val } }) => onKeyChange(i, val)}
              label={markAsRequired(t('ad_targeting.key'))}
              validators={['required', `matchRegexp:${AD_TARGETING_KEY_REGEX}`]}
              errorMessages={[t('general.field_is_required'), t('ad_targeting.key_validation_text')]}
              data-testid={adTargetingTestIds.keyTextField}
            />
          </div>
          <div className={classes.inputColumn}>
            <TextValidator
              fullWidth
              name="value"
              debounced={false}
              value={adTarget.value}
              onChange={({ target: { value: val } }) => onValueChange(i, val)}
              label={markAsRequired(t('ad_targeting.value'))}
              validators={['required', `matchRegexp:${AD_TARGETING_VALUE_REGEX}`]}
              errorMessages={[t('general.field_is_required'), t('ad_targeting.value_validation_text')]}
              data-testid={adTargetingTestIds.valueTextField}
            />
          </div>
          <div>
            <IconButton
              onClick={() => handleRemove(i)}
              title={t('ad_targeting.remove')}
              size="large"
              data-testid={adTargetingTestIds.removeKeyValuePairButton}
            >
              <Delete />
            </IconButton>
          </div>
        </div>
      ))}
      {(!value || value.length < maxKeyValuePairs) && (
        <Button
          color={formControlColor}
          startIcon={<Add />}
          onClick={handleAdd}
          variant="text"
          data-testid={adTargetingTestIds.addKeyValuePairButton}
        >
          {t('ad_targeting.add')}
        </Button>
      )}
    </div>
  );
}
