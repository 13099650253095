import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import CollectionsPanel from './CollectionsPanel';
import CollectionsBrowser from './CollectionsPanel/CollectionsBrowser';
import CollectionForm from './CollectionForm';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { ContentCollectionResponse, SmartQueryFieldContentType } from '../../API';
import { useNavigate, useParams } from 'react-router-dom';
import { CollectionsPanelTabViewOptions, useData } from '../../data-layer';
import { AppRoutes } from '../../Routes';
import CollectionManager from './CollectionsPanel/CollectionManager';
import CollectionLayoutsModal from './CollectionsPanel/CollectionsBrowser/CollectionLayoutsModal';
import { Allotment } from 'allotment';
import { useSplitPane } from '../../hooks/General/useSplitPane';
import { CollectionPanelOptions } from './CollectionsPanel/CollectionsPanel';
import { collectionsTestIds } from '../shared/TestsIds';

interface CollectionsProps {
  asMixedType?: boolean;
}

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  wrapper: {
    display: 'flex',
    height: '100%'
  }
}));

interface CollectionsProps {
  asMixedType?: boolean;
}

function Collections({ asMixedType = false }: CollectionsProps): JSX.Element {
  const {
    collections: {
      state: {
        withCollectionsExpanded,
        withCollectionContentTypes,
        withCollectionQueryFields,
        withCollectionQueryOperators,
        withCollectionQuerySortings,
        withCollectionQueryOptions,
        withCollectionsPanelTabView,
        withSelected,
        withAllGenres,
        withSplitPaneSizes,
        withMixedCollectionDisplay
      },
      hook: { getById, getSmartQueryParameters, setSelected, getGenres }
    },
    channels: {
      state: { withAllRecords: withAllChannels },
      hook: { getAll: getAllChannels }
    },
    media: {
      state: { withContentTypesAllowed }
    }
  } = useData();

  const { classes } = useStyles();
  const setCollectionContentTypes = useSetRecoilState(withCollectionContentTypes);
  const expanded = useRecoilValue(withCollectionsExpanded);
  const setWhichView = useSetRecoilState(withCollectionsPanelTabView);
  const setQuerySortings = useSetRecoilState(withCollectionQuerySortings);
  const setQueryOptions = useSetRecoilState(withCollectionQueryOptions);
  const setQueryOperators = useSetRecoilState(withCollectionQueryOperators);
  const setQueryFields = useSetRecoilState(withCollectionQueryFields);
  const [allGenres, setAllGenres] = useRecoilState(withAllGenres);
  const setMixedCollectionDisplay = useSetRecoilState(withMixedCollectionDisplay);
  const allChannels = useRecoilValue(withAllChannels);
  const setContentTypesAllowed = useSetRecoilState(withContentTypesAllowed);
  const navigate = useNavigate();
  const { id: collectionId } = useParams<{ id: string | undefined }>();

  const { sizes, onChange: onSplitPaneChange } = useSplitPane(withSplitPaneSizes);

  const selectedCollection = useRecoilValue(withSelected);

  const setSelectedCollection = (collection: ContentCollectionResponse | undefined) => {
    setSelected(collection?.entityId);
  };

  const getAllGenres = async () => {
    if (!allGenres?.length) {
      const genres = await getGenres();
      setAllGenres(genres);
    }
  };

  useEffect(() => {
    const { MOVIE, SERIES, EPISODE } = SmartQueryFieldContentType;
    const usedCollectionContentTypes = [MOVIE, SERIES, EPISODE];
    setCollectionContentTypes(usedCollectionContentTypes);
    getCollectionParams(usedCollectionContentTypes);
    setSelected(undefined);
    getAllGenres();
    // In case the cms user have visited the MetadataManager page before, we need to reset the contentTypesAllowed
    setContentTypesAllowed([]);
    setMixedCollectionDisplay(asMixedType);

    if (asMixedType && !allChannels) {
      getAllChannels();
    }

    return () => {
      setMixedCollectionDisplay(false);
    };
  }, []);

  useEffect(() => {
    if (!collectionId) {
      setSelected(undefined);
      return;
    }
    fetchAndSetCollection(collectionId);
  }, [collectionId]);

  useEffect(() => {
    if (!expanded) {
      if (asMixedType) {
        setWhichView(CollectionsPanelTabViewOptions.VOD_SEARCH);
      } else {
        setWhichView(CollectionsPanelTabViewOptions.CONTENT_SEARCH);
      }
    }
    setMixedCollectionDisplay(asMixedType);
  }, [expanded, asMixedType]);

  const fetchAndSetCollection = async (collectionId: string) => {
    try {
      const foundCollection = await getById(collectionId, true);
      if (foundCollection) {
        setSelected(foundCollection.entityId);
        return;
      }
      navigate(asMixedType ? AppRoutes.collections : AppRoutes.vodCollections, { replace: true });
    } catch (err) {
      navigate(AppRoutes[404], { replace: true });
    }
  };

  const getCollectionParams = async (contentTypes: SmartQueryFieldContentType[]) => {
    const data = await getSmartQueryParameters(contentTypes);
    if (data) {
      setQuerySortings(data.sortings);
      setQueryOperators(data.operators);
      setQueryFields(data.fields);
      setQueryOptions(data.options);
    }
  };

  const handleGoBack = () => {
    navigate(asMixedType ? AppRoutes.collections : AppRoutes.vodCollections);
    setSelectedCollection(undefined);
  };

  const getCollectionPanelOptions = () => {
    if (asMixedType) {
      return {
        tabs: [
          CollectionsPanelTabViewOptions.VOD_SEARCH,
          CollectionsPanelTabViewOptions.LIVE_SEARCH,
          CollectionsPanelTabViewOptions.CHANNEL_SEARCH,
          CollectionsPanelTabViewOptions.BROWSE_COLLECTONS
        ]
      } as CollectionPanelOptions;
    }
    return undefined;
  };

  return (
    <>
      <div data-testid={collectionsTestIds.root} className={classes.root}>
        <Allotment minSize={580} defaultSizes={sizes} onChange={onSplitPaneChange}>
          <Allotment.Pane>
            <div className={classes.wrapper}>
              {!collectionId ? (
                <CollectionsBrowser
                  selectedCollection={selectedCollection}
                  setSelectedCollection={setSelectedCollection}
                  asMixedType={asMixedType}
                />
              ) : (
                <CollectionManager collection={selectedCollection} onGoBack={handleGoBack} useSelected />
              )}
            </div>
          </Allotment.Pane>
          <Allotment.Pane visible={!expanded}>
            <CollectionsPanel forKids={selectedCollection?.kids} options={getCollectionPanelOptions()} />
          </Allotment.Pane>
        </Allotment>
      </div>
      <CollectionForm asMixedCollectionForm={asMixedType} />
      <CollectionLayoutsModal />
    </>
  );
}

export default Collections;
