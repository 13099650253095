import React, { useState } from 'react';
import Modal from '../../../../shared/Modal';
import { AccountSuccessUiModuleBody } from '../../../../../API';
import { useLocales, useLokalise, useTheme } from '../../../../../hooks';
import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { TextButton, VixOrangeButton } from '../shared/SharedButtons';
import Button from '../../../../shared/Button';
import { previewDetailsDialog } from '../../../../../styles/previewDetails';
import { accountSuccessPreviewTestIds } from '../../../../shared/TestsIds';

type AccountSuccessPreviewProps = {
  module: AccountSuccessUiModuleBody;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function AccountSuccessPreview({ module, open, setOpen }: AccountSuccessPreviewProps): React.ReactElement {
  const { classes } = previewDetailsDialog({ width: 420 })();
  const { t } = useLocales();
  const { lt } = useLokalise();
  const { formControlColor } = useTheme();

  const [isSVOD, setIsSVOD] = useState(false);

  const onModalClose = () => setOpen(false);

  const headerRight = (
    <Stack>
      <FormControlLabel
        control={
          <Switch
            color={formControlColor}
            size="small"
            checked={isSVOD}
            onChange={({ target: { checked } }) => setIsSVOD(checked)}
          />
        }
        label={t('layouts.profile.svod_user')}
      />
    </Stack>
  );

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      headerLeft={t('layouts.account_success.preview')}
      headerRight={headerRight}
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box data-testid={accountSuccessPreviewTestIds.root} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Box data-testid={accountSuccessPreviewTestIds.container} className={classes.container}>
          {/* Background Image */}
          <Box
            component="img"
            src={getAssetUrl(module.portraitAccountSuccessImage)}
            sx={{
              width: '100%',
              position: 'absolute'
            }}
          />
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Box
              sx={{
                padding: '16px',
                background: 'linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.9) 20%)'
              }}
            >
              <Stack gap="24px" width="100%">
                <Stack direction="row" justifyContent="space-between" width="100%">
                  <Stack direction="row" gap="16px" alignItems="center">
                    {/* Vix Logo */}
                    <img src="/assets/vix-white.svg" className={classes.logo} />
                    {module.partnerLogo && (
                      <>
                        <div className={classes.separator} />
                        <img src={getAssetUrl(module.partnerLogo)} className={classes.logo} />
                      </>
                    )}
                  </Stack>
                </Stack>
                <Stack gap="24px" marginTop="180px">
                  <Typography variant="h5" alignSelf="start">
                    <span dangerouslySetInnerHTML={{ __html: lt(isSVOD ? module.svodHeader : module.avodHeader) }} />
                  </Typography>
                  <Typography>
                    <span
                      dangerouslySetInnerHTML={{ __html: lt(isSVOD ? module.svodSubHeader : module.avodSubHeader) }}
                    />
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="start">
                  <Typography variant="subtitle2" color="gray">
                    <span dangerouslySetInnerHTML={{ __html: lt(module.legalDisclaimer) }} />
                  </Typography>
                </Stack>

                <Stack gap="16px" marginTop="8px">
                  <VixOrangeButton>{lt(isSVOD ? module.downloadAndOpenAppCta : module.subscribeCta)}</VixOrangeButton>
                  <TextButton>{lt(isSVOD ? module.continueWebCta : module.notNowCta)}</TextButton>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
