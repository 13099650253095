import React, { useEffect, useRef } from 'react';
import { Delete } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRecoilValue } from 'recoil';
import { Role, UserBody, UserResponse } from '../../../API';
import { useData } from '../../../data-layer';
import { useAuth, useLocales, useNotifications, useTheme } from '../../../hooks';
import useConfirm from '../../../hooks/General/useConfirm';
import Button from '../../shared/Button';
import Drawer from '../../shared/Drawer';
import { withCurrentUser } from '../../../state/auth';
import { UserPermissionsGroups } from './UserPermissionsGroups';
import { fieldErrorsToArray, isFormValid as isFormValidHelper } from '../../../utils/formHelpers';
import TextValidator from '../../shared/TextValidator';
import Select from '../../shared/Select';
import { userFormTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  bodyContainer: {
    padding: theme.spacing(4)
  },
  formField: {
    maxWidth: 500,
    marginBottom: theme.spacing(4)
  },
  footerButton: {
    minWidth: 120
  },
  permissionsGroups: {
    paddingTop: theme.spacing(4)
  }
}));

const allRoles = Object.values(Role);

function UserForm(): JSX.Element {
  const formRef = useRef<ValidatorForm>(null);
  const { notifyError, notifyInfo } = useNotifications();
  const { formControlColor } = useTheme();
  const { classes } = useStyles();
  const { t } = useLocales();
  const { confirm } = useConfirm();

  const {
    users: { state: usersState, hook: useUsers }
  } = useData();
  const userFormMetadata = useRecoilValue(usersState.withFormMetadata);
  const isSavingUser = useRecoilValue(usersState.withIsSaving);
  const currentUser = useRecoilValue(withCurrentUser);
  const { logout } = useAuth();

  const user = userFormMetadata.record;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<UserBody | UserResponse>();

  useEffect(() => {
    reset(user);
  }, [user]);

  const isFormValid = async () => {
    if (formRef && formRef.current) {
      const valid = await isFormValidHelper(formRef);
      if (!valid) notifyError(t('general.form_error'), fieldErrorsToArray(errors));
      return valid;
    }
    return false;
  };

  const onSubmit: SubmitHandler<UserBody | UserResponse> = async (user) => {
    const valid = await isFormValid();
    if (valid) {
      let savedUser;
      if (userFormMetadata.isNew) {
        savedUser = await useUsers.create(user);
      } else {
        const oldUser = userFormMetadata.record as UserResponse;
        const updatedUser = user as UserResponse;

        savedUser = await useUsers.update(updatedUser.id, updatedUser);
        // remove from cachedUser if email was changed
        if (savedUser && oldUser.email !== savedUser.email) await useUsers.removeFromCache(oldUser.email);
      }
      if (!savedUser) return;

      useUsers.closeForm();
      if (savedUser.id === currentUser?.id) {
        notifyInfo(t('auth.user_modified'));
        logout();
      }
    }
  };

  const handleDelete = async () => {
    if (!user) return;

    const result = await confirm({
      confirmText: t('general.confirm_delete'),
      confirmColor: 'error',
      body: t('users.delete_confirm'),
      'data-testid': userFormTestIds.confirmDialog
    });

    if (result) {
      const successful = await useUsers.remove(user.email);
      if (successful) useUsers.closeForm();
    }
  };

  return (
    <>
      <Drawer
        open={userFormMetadata.isShowingForm}
        onClose={() => (!isSavingUser ? useUsers.closeForm() : undefined)}
        onSubmit={handleSubmit(onSubmit)}
        formRef={formRef}
        headerLeft={
          <Typography variant="h6">{userFormMetadata.isNew ? t('users.new_user') : t('users.edit_user')}</Typography>
        }
        headerRight={
          !userFormMetadata.isNew && (
            <Button
              color="error"
              endIcon={<Delete />}
              onClick={handleDelete}
              data-testid={userFormTestIds.deleteButton}
            >
              {t('general.delete')}
            </Button>
          )
        }
        footerLeft={
          <>
            <Button
              type="submit"
              loading={isSavingUser}
              className={classes.footerButton}
              data-testid={userFormTestIds.saveButton}
            >
              {t('general.save')}
            </Button>
          </>
        }
        footerRight={
          <Button
            color="grey"
            className={classes.footerButton}
            disabled={isSavingUser}
            onClick={useUsers.closeForm}
            data-testid={userFormTestIds.cancelButton}
          >
            {t('general.cancel')}
          </Button>
        }
      >
        {user && (
          <div className={classes.bodyContainer} data-testid={userFormTestIds.formBody}>
            <div className={classes.formField}>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextValidator
                    fullWidth
                    color={formControlColor}
                    name="firstName"
                    label={t('users.first_name')}
                    value={value}
                    validators={['required']}
                    errorMessages={[t('general.field_is_required')]}
                    onChange={onChange}
                    data-testid={userFormTestIds.firstNameInput}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextValidator
                    fullWidth
                    color={formControlColor}
                    name="lastName"
                    label={t('users.last_name')}
                    value={value}
                    validators={['required']}
                    errorMessages={[t('general.field_is_required')]}
                    onChange={onChange}
                    data-testid={userFormTestIds.lastNameInput}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextValidator
                    fullWidth
                    color={formControlColor}
                    name="email"
                    label={t('users.email')}
                    value={value}
                    validators={['required', 'isEmail']}
                    errorMessages={[t('general.field_is_required'), t('users.email_valid')]}
                    onChange={onChange}
                    data-testid={userFormTestIds.emailInput}
                  />
                )}
              />
            </div>
            <div className={classes.formField}>
              <Controller
                name="roles"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    color={formControlColor}
                    value={value[0]}
                    onChange={(evt) => {
                      onChange([evt.target.value as Role]);
                    }}
                  >
                    {allRoles.map((r) => (
                      <MenuItem key={r} value={r}>
                        {r}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div>
              <Controller
                name="permissionsGroups"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <UserPermissionsGroups className={classes.permissionsGroups} value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}

export default UserForm;
