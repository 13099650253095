import { useSetRecoilState } from 'recoil';
import { ChannelsApi, VllChannelResponse } from '../../../../API';
import { useLocales } from '../../../../hooks';
import {
  CachedRecord,
  DataManagerHelpers,
  DataManagerHook,
  DataManagerHookReturnType,
  IDataManagerHookProps,
  useGetRecoilState
} from '../../../DataManager';
import { saveAsFile } from '../../../../utils/saveAsFile';

export interface ChannelsHookReturnType extends DataManagerHookReturnType<VllChannelResponse, VllChannelResponse> {
  addCreatedChannelToBuckets: (record: VllChannelResponse) => Promise<void>;
  exportChannelsToCSV: (
    maxResults?: number | undefined,
    filteredData?: { linked: string[]; unlinked: string[] }
  ) => Promise<void>;
}

export function ChannelsHook(
  params: IDataManagerHookProps<VllChannelResponse, VllChannelResponse>
): ChannelsHookReturnType {
  const dataManagerHook = DataManagerHook<VllChannelResponse, VllChannelResponse>(params);
  const api = params.useApiHook() as ChannelsApi;
  const { handleApiError } = DataManagerHelpers({ name: params.name, toString });
  const { t } = useLocales();

  const getBuckets = useGetRecoilState(params.state.withRecordBucketsById);
  const setBuckets = useSetRecoilState(params.state.withRecordBucketsById);

  // Adds newly linked channel to all available buckets
  const addCreatedChannelToBuckets = async (record: VllChannelResponse) => {
    const buckets = { ...(await getBuckets()) };
    for (const bucketId in buckets) {
      if (buckets[bucketId] && buckets[bucketId].object) {
        buckets[bucketId] = {
          lastUpdated: buckets[bucketId].lastUpdated,
          object: await dataManagerHook.addRecordToIdsList(record, buckets[bucketId] as CachedRecord<string[]>)
        };
      }
    }
    setBuckets(buckets);
  };

  const exportChannelsToCSV = async (
    maxResults: number | undefined,
    channelIds?: { linked: string[]; unlinked: string[] }
  ) => {
    const allChannels = [
      ...(channelIds?.linked || []).map((id) => ({ id, status: 'linked' })),
      ...(channelIds?.unlinked || []).map((id) => ({ id, status: 'unlinked' }))
    ];
    if (allChannels.length === 0) return;

    try {
      const chunkSize = parseInt(process.env.REACT_APP_CHUNK_SIZE || '200');
      const chunks = [];
      let allData = '';

      for (let i = 0; i < allChannels.length; i += chunkSize) {
        chunks.push(allChannels.slice(i, i + chunkSize));
      }

      for (const chunk of chunks) {
        const response = await api.exportCsv(
          maxResults,
          chunk.map((channel) => channel.id)
        );
        const csvData = response.data as unknown as string;

        const lines = csvData.split('\n');

        if (!allData) {
          allData = lines[0] + ',Status\n';
        }

        const linkedIds = channelIds?.linked?.map((ch) => ch.trim().toLowerCase()) ?? [];

        const dataSet = new Set<string>();

        const dataLines = lines
          .slice(1)
          .map((line) => {
            const column = line.split(',');
            const columnId = column[2].trim().toLowerCase();
            const isLinked = linkedIds.includes(columnId);
            const newLine = isLinked ? `${line},Linked` : `${line},Unlinked`;

            if (!dataSet.has(newLine)) {
              dataSet.add(newLine);
              return newLine;
            }
          })
          .filter(Boolean)
          .join('\n');

        allData += dataLines + '\n';
      }

      saveAsFile({
        data: new Blob([allData.trim()], { type: 'text/csv' }),
        filename: `vix-channels-export_${new Date().toISOString().replace(/[\W]/gi, '')}`
      });
    } catch (err) {
      handleApiError(err, 'export_channels_to_csv', `${t('query_builder.max_results')}: ${maxResults}`);
    }
  };

  return {
    ...dataManagerHook,
    addCreatedChannelToBuckets,
    exportChannelsToCSV
  };
}
