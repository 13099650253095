import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { emphasize, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useLocales, useTheme } from '../../../hooks';
import InputController from '../../shared/InputController';
import { ArrayPath, Control, FieldArray, UnpackNestedValue, useFieldArray } from 'react-hook-form';
import { COUNTRIES } from '../../../utils/countryCodes';
import FoldableSection from '../FoldableSection';
import Button from '../Button';
import CountryBadgesMerged from '../CountryBadgesMerged';
import CountryPickerLite from '../CountryPickerLite';
import { intersection } from 'lodash-es';
import { countryExceptionsTestIds } from '../TestsIds';

const useStyles = makeStyles()((theme) => ({
  blankState: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(4),
    width: '100%'
  },
  exceptionContainer: {
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  actionsContainer: {
    background: emphasize(theme.palette.background.paper, 0.05),
    padding: theme.spacing(2),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  exceptionTopContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  countryFilter: {
    width: '50%'
  }
}));

export interface CountryExceptionsProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TEntity extends Record<string, any>,
  TException = UnpackNestedValue<FieldArray<TEntity, ArrayPath<TEntity>>>
> {
  control: Control<TEntity>;
  attribute: ArrayPath<TEntity>;
  countryAttribute?: string;
  countryIdsExtractor: (field: TException) => string[];
  renderField: (field: TException, index: number) => React.ReactNode;
  createNewException: () => TException;
}

const CountryExceptions = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TEntity extends Record<string, any>,
  TException = UnpackNestedValue<FieldArray<TEntity, ArrayPath<TEntity>>>
>({
  control,
  attribute,
  countryAttribute = 'countryCodes',
  countryIdsExtractor,
  renderField,
  createNewException
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
CountryExceptionsProps<TEntity | any, TException>): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const { fields, remove, append, update } = useFieldArray({ control, name: attribute });

  const usedCountyCodes = fields
    ?.map((field) => countryIdsExtractor(field as unknown as TException))
    .flat()
    .sort();
  const filteredCountryCodes = COUNTRIES.map(({ code }) => code).filter(
    (countryCode) => !usedCountyCodes?.includes(countryCode)
  );

  const handleAddException = () => {
    if (!filteredCountryCodes?.length) return;
    const newException = createNewException();
    if (newException) append(newException);
  };

  const handleRemoveException = (index: number) => {
    remove(index);
  };

  const renderAddCountryButton = () => (
    <Button
      variant="text"
      size="small"
      color={formControlColor}
      startIcon={<Add />}
      onClick={handleAddException}
      data-testid={countryExceptionsTestIds.addCountry}
    >
      {t('general.add_new_exception')}
    </Button>
  );

  return (
    <div data-testid={countryExceptionsTestIds.root}>
      <FoldableSection
        summary={
          <>
            {t('general.exceptions')}
            {!!fields.length && (
              <CountryBadgesMerged
                countryCodes={usedCountyCodes}
                data-testid={countryExceptionsTestIds.countriesBadge}
                truncateAfter={10}
              />
            )}
          </>
        }
        disableGutters
      >
        {!fields.length && (
          <div className={classes.blankState} data-testid={countryExceptionsTestIds.blankState}>
            <Typography variant="body1">{t('general.exceptions_blank_state')}</Typography>
            {renderAddCountryButton()}
          </div>
        )}
        {!!fields.length && (
          <div>
            {fields.map((field, index) => {
              const countryCodesUsedInField = countryIdsExtractor(field as unknown as TException);
              return (
                <div
                  className={classes.exceptionContainer}
                  key={field.id}
                  data-testid={countryExceptionsTestIds.exception(index.toString())}
                >
                  <div className={classes.exceptionTopContent}>
                    <InputController
                      name={`${attribute}[${index}].${countryAttribute}`}
                      control={control}
                      render={({ field: { value } }) => (
                        <CountryPickerLite
                          className={classes.countryFilter}
                          label={t('general.exception_country')}
                          value={value || []}
                          onChange={(newCountries) =>
                            update(index, {
                              ...(field as unknown as TException),
                              [countryAttribute]: newCountries
                            } as UnpackNestedValue<FieldArray<TEntity, ArrayPath<TEntity>>>)
                          }
                          disableCountry={(countryCode) =>
                            !countryCodesUsedInField.includes(countryCode) && usedCountyCodes.includes(countryCode)
                          }
                          disablePreset={(countryCodes) => !!intersection(usedCountyCodes, countryCodes).length}
                          textFieldTestId={countryExceptionsTestIds.countryInput(index.toString())}
                        />
                      )}
                    />
                    <Button
                      variant="text"
                      size="small"
                      endIcon={<Delete />}
                      color={formControlColor}
                      onClick={() => handleRemoveException(index)}
                      data-testid={countryExceptionsTestIds.removeCountry(index.toString())}
                    >
                      {t('general.remove_exception')}
                    </Button>
                  </div>
                  {renderField(field as unknown as TException, index)}
                </div>
              );
            })}
          </div>
        )}
        {!!filteredCountryCodes.length && !!fields.length && (
          <div className={classes.actionsContainer}>{renderAddCountryButton()}</div>
        )}
      </FoldableSection>
    </div>
  );
};

export default CountryExceptions;
