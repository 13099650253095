import React, { useEffect, useRef, useState } from 'react';
import { AddOutlined, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Skeleton,
  Switch,
  Typography
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { capitalize } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  CollectionGenreFilter,
  DocumentLocale,
  GetAllContentCollectionQuery,
  PaginationMeta,
  SortDirection,
  VersionedDocumentStatus,
  CollectionUsedFilter,
  ContentCollectionResponse,
  ContentCollectionType,
  CollectionAssetType
} from '../../../../API';
import { useData, CollectionsPanelTabViewOptions } from '../../../../data-layer';
import { useLocales, useTheme, BreakPoint, useResponsive } from '../../../../hooks';
import { LocaleKeys } from '../../../../locales/i18n';
import AdvancedSearch from '../../../shared/AdvancedSearch';
import Button from '../../../shared/Button';
import Pagination from '../../../shared/Pagination';
import PaginationResults from '../../../shared/Pagination/PaginationResults';
import Repeat from '../../../shared/Repeat';
import ShadowScroller from '../../../shared/ShadowScroller';
import CollectionExpand from '../../CollectionExpand';
import DisplayAs from '../../DisplayAs';
import CollectionListItem from './CollectionListItem';
import { useLifecycle } from '../../../../hooks/General/useLifecycle';
import { EntitlementsFilter } from '../../../shared/Entitlements';
import { AppRoutes } from '../../../../Routes';
import SearchFilters, { FilterBadge, WithFilters } from '../../../shared/SearchFilters';
import { collectionsHeaderHeight } from '../../../../utils/consts/collectionsConsts';
import TextField from '../../../shared/TextField';
import { ALL, IncludeAll, transformAll } from '../../../../utils/types/genericTypes';
import { useSearchFilters } from '../../../../hooks/General/useSearchFilters';
import { usePermissions } from '../../../../hooks/Permissions/usePermissions';
import { PermissionsLevel } from '../../../../utils/types/permissionsTypes';
import Select from '../../../shared/Select';
import { usePermissionsGuard } from '../../../../hooks/General/usePermissionsGuard';
import { HomepageOptions } from '../../../../state/theme';
import { collectionsBrowserTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  topSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  topSectionHeader: {
    padding: theme.spacing(0, 4),
    height: collectionsHeaderHeight,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(4),
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  topSectionSearchContainer: {
    padding: theme.spacing(4)
  },
  topSectionSearch: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4)
  },
  topSectionAdvanced: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  collectionsContainer: {
    flexGrow: 1,
    borderTop: `1px solid ${theme.palette.divider}`
  },
  collections: {
    padding: 0
  },
  addButton: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  skeletonCollection: {
    height: 40,
    margin: theme.spacing(2)
  },
  bottomSection: {
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 4)
  },
  advancedOption: {
    minWidth: 200,
    marginBottom: 0
  },
  sort: {
    minWidth: 180,
    marginBottom: theme.spacing(4)
  },
  advancedOptionMenuPaper: {
    maxHeight: 500
  },
  textField: {
    caretColor: 'transparent',
    '&, & input': {
      cursor: 'pointer'
    },
    '& .MuiInput-root.Mui-disabled:before': {
      borderBottomStyle: 'solid'
    }
  }
}));

export type ExtendedGetCollectionParams = GetAllContentCollectionQuery & { sort?: SortTypes };
export type CollectionSearchParams = WithFilters<ExtendedGetCollectionParams, CollectionFilterOptions>;

export enum CollectionFilterOptions {
  GENRE_FILTER = 'genre_filter',
  FOR_SIMILARS = 'for_similars',
  STATUS_FILTER = 'status_filter',
  USED_FILTER = 'used_filter',
  SHOW_DELETED = 'show_deleted',
  AUTOROTATION_FILTER = 'auto_rotation',
  HAS_SMART_QUERY = 'has_smart_query',
  HAS_ASSETS_THESE_TYPES = 'has_assets_these_types'
}

export enum SortTypes {
  SORT_RECENTLY_PUBLISHED = 'SORT_RECENTLY_PUBLISHED',
  SORT_TITLE_ALPHABETICAL = 'SORT_TITLE_ALPHABETICAL'
}

export enum ForSimilars {
  YES = 'true',
  NO = 'false'
}

export enum HasSmartQuery {
  YES = 'true',
  NO = 'false'
}

export enum AutoRotate {
  YES = 'true',
  NO = 'false'
}

type Dependencies = {
  handleGoBack?: () => void;
  handleCollectionClick?: (entityId: string) => void;
  initialCollections?: string[];
};

interface CollectionsBrowserProps {
  useSelected?: boolean;
  deps?: Dependencies;
  selectedCollection: ContentCollectionResponse | undefined;
  setSelectedCollection: (collection: ContentCollectionResponse | undefined) => void;
  asMixedType?: boolean;
}

const DEFAULT_SORT_TYPE = SortTypes.SORT_RECENTLY_PUBLISHED;

type SortField = 'createdDate' | 'title';
type SortOptionType = {
  field: SortField;
  direction: SortDirection;
  localizationKey: LocaleKeys;
  isLocalizedField?: boolean;
  localize?: (lang: DocumentLocale) => string;
};
const SortOptions: Record<string, SortOptionType> = {
  [SortTypes.SORT_RECENTLY_PUBLISHED]: {
    field: 'createdDate',
    direction: SortDirection.DESC,
    localizationKey: 'general.sort.recently_published'
  },
  [SortTypes.SORT_TITLE_ALPHABETICAL]: {
    field: 'title',
    direction: SortDirection.ASC,
    localizationKey: 'general.sort.title',
    isLocalizedField: true,
    localize: (lang: DocumentLocale) => `title.${lang}`
  }
};

const filterParams: Record<CollectionFilterOptions, (keyof ExtendedGetCollectionParams)[]> = {
  [CollectionFilterOptions.FOR_SIMILARS]: ['forSimilars'],
  [CollectionFilterOptions.HAS_SMART_QUERY]: ['hasSmartQuery'],
  [CollectionFilterOptions.USED_FILTER]: ['usedFilter'],
  [CollectionFilterOptions.GENRE_FILTER]: ['genreFilter'],
  [CollectionFilterOptions.STATUS_FILTER]: ['status'],
  [CollectionFilterOptions.SHOW_DELETED]: ['includeDeleted'],
  [CollectionFilterOptions.AUTOROTATION_FILTER]: ['hasAutoRotate'],
  [CollectionFilterOptions.HAS_ASSETS_THESE_TYPES]: ['hasAssetsTheseTypes']
};

const autoRotationFilterOptions = [ALL, ...Object.values(AutoRotate)] as [IncludeAll<AutoRotate>];
const statusFilterOptions = [ALL, ...Object.values(VersionedDocumentStatus)] as [IncludeAll<VersionedDocumentStatus>];
const usedFilterOptions = [ALL, ...Object.values(CollectionUsedFilter)] as [IncludeAll<CollectionUsedFilter>];
const forSimilarsOptions = [ALL, ...Object.values(ForSimilars)] as [IncludeAll<ForSimilars>];
const hasSmartQueryOptions = [ALL, ...Object.values(HasSmartQuery)] as [IncludeAll<HasSmartQuery>];

function CollectionsBrowser({
  useSelected = true,
  deps,
  selectedCollection,
  setSelectedCollection,
  asMixedType = false
}: CollectionsBrowserProps): JSX.Element {
  const {
    collections: {
      state: {
        withSearchParams,
        withCollectionsPanelTabView,
        withCollectionsExpanded,
        withAllGenres,
        withEntitlementsFilter,
        withDisplayAs,
        withLastSavedSearchParams
      },
      hook: { getPaginated, edit, new: newCollection, clone }
    }
  } = useData();

  const scrollRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const { willUnmount } = useLifecycle();
  const { t, currentLang } = useLocales();
  const { formControlColor } = useTheme();
  const { hasAnyPermission } = usePermissions();
  const { minWidth: screenMinWidth } = useResponsive(document.body);
  const expanded = useRecoilValue(withCollectionsExpanded);
  const [lastSavedSearchParams, setLastSavedSearchParams] = useRecoilState(withLastSavedSearchParams);
  const expandedAddButton = expanded || screenMinWidth(BreakPoint.MEDIUM);
  const collectionGenres = useRecoilValue(withAllGenres);
  const navigate = useNavigate();
  const setWhichView = useSetRecoilState(withCollectionsPanelTabView);

  const formMethods = useForm<CollectionSearchParams>();
  const { handleSubmit, control, reset, setValue } = formMethods;
  const [meta, setMeta] = useState<PaginationMeta>();

  const [isLoading, setIsLoading] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [collectionList, setCollectionList] = useState<ContentCollectionResponse[]>([]);

  const [recoilSearchParams, setRecoilSearchParams] = useSearchFilters(withSearchParams, filterParams, [
    'search',
    'sort',
    'entitlements'
  ]);
  const [entitlementsFilter, setEntitlementsFilter] = useRecoilState(withEntitlementsFilter);

  // the filters and their string names for the add filter dropdown button
  const actions: Record<string, string> = Object.values(CollectionFilterOptions)
    .filter((v) => (asMixedType ? v !== CollectionFilterOptions.HAS_SMART_QUERY : true))
    .filter((v) => (!asMixedType ? v !== CollectionFilterOptions.HAS_ASSETS_THESE_TYPES : true))
    .filter((v) => (asMixedType ? v !== CollectionFilterOptions.FOR_SIMILARS : true))
    .reduce((r, v) => ({ ...r, [v]: t(`collections.advanced_search.${v}.name`) }), {});

  useEffect(() => {
    // change if fetched already
    if (!selectedCollection) {
      reset(recoilSearchParams);
      handleSubmit(handleSearch)();
    }
  }, [selectedCollection]);

  useEffect(() => {
    const searchParams = asMixedType ? lastSavedSearchParams.mixedCollection : lastSavedSearchParams.vodCollection;
    reset(searchParams);
    handleNewSearch(searchParams as CollectionSearchParams);
  }, [asMixedType]);

  const handleNew = () => {
    newCollection();
  };

  const handleEdit = (id: string) => {
    edit(id);
  };

  const handleClone = (id: string) => {
    clone(id);
  };

  const handleCollectionClick = (entity: ContentCollectionResponse, whichView?: CollectionsPanelTabViewOptions) => {
    if (!entity) return;
    deps?.handleCollectionClick?.(entity.entityId);
    if (useSelected) {
      setWhichView(whichView || CollectionsPanelTabViewOptions.CONTENT_SEARCH);
      navigate(asMixedType ? AppRoutes.collection(entity.entityId) : AppRoutes.vodCollection(entity.entityId));
    } else {
      setSelectedCollection(entity);
    }
  };

  const handleFilterRemove = (filter: CollectionFilterOptions) => {
    for (const param of filterParams[filter]) {
      setValue(param, undefined);
    }
  };

  const handleSearch = async (data: CollectionSearchParams) => {
    const sort = SortOptions[data.sort || DEFAULT_SORT_TYPE];
    if (!willUnmount.current) setIsLoading(true);
    if (!getPaginated) return;
    const { _filters, ...searchData } = data;
    const response = await getPaginated({
      ...searchData,
      collectionTypes: asMixedType
        ? [ContentCollectionType.MIXED_LIST]
        : Object.values(ContentCollectionType).filter((t) => t !== ContentCollectionType.MIXED_LIST),
      sortBy: sort.localize
        ? sort.localize(currentLang as DocumentLocale)
        : sort.field || SortOptions[DEFAULT_SORT_TYPE].field,
      sortDirection: sort.direction,
      status: transformAll(data.status),
      genreFilter: transformAll(data.genreFilter),
      usedFilter: transformAll(data.usedFilter),
      hasAutoRotate: transformAll(data.hasAutoRotate),
      entitlements: entitlementsFilter,
      hasSmartQuery: transformAll(data.hasSmartQuery),
      hasAssetsTheseTypes: transformAll(data.hasAssetsTheseTypes)
    });
    if (!willUnmount.current) setIsLoading(false);
    if (response && !willUnmount.current) {
      setCollectionList(response.results);
      const params = {
        ...searchData,
        limit: response.meta.limit,
        page: response.meta.page,
        _filters
      };
      setRecoilSearchParams(params);
      setLastSavedSearchParams({
        ...lastSavedSearchParams,
        ...(asMixedType ? { mixedCollection: params } : { vodCollection: params })
      });
      setMeta(response.meta);
      reset(params);
    }
  };

  const handleNewSearch = async (data: CollectionSearchParams) => handleSearch({ ...data, page: 1 });

  const Footer = () => {
    const { canSave } = usePermissionsGuard({
      homepageOption: HomepageOptions.COLLECTIONS
    });
    return (
      <div className={classes.bottomSection}>
        {useSelected && (
          <Button
            className={classes.addButton}
            data-testid={collectionsBrowserTestIds.newButton}
            endIcon={expandedAddButton && <AddOutlined />}
            onClick={handleNew}
            disabled={!canSave || !hasAnyPermission(PermissionsLevel.UPSERT)}
            size={expandedAddButton ? 'medium' : 'small'}
            style={{ minWidth: expandedAddButton ? undefined : 0 }}
          >
            {expandedAddButton ? t('collections.new_collection') : <AddOutlined />}
          </Button>
        )}
        <Pagination
          queryMetaData={meta}
          onPageChange={(newPage: number) => {
            reset();
            setValue('page', newPage);
            scrollRef.current?.scrollTo(0, 0);
            handleSubmit(handleSearch)();
          }}
          onResultsChange={(newLimit: number) => {
            reset();
            setValue('limit', newLimit);
            handleSubmit(handleNewSearch)();
          }}
        />
      </div>
    );
  };

  return (
    <FormProvider {...formMethods}>
      <div data-testid={collectionsBrowserTestIds.root} className={classes.root}>
        {/* All of the collections */}
        <form className={classes.topSection} onSubmit={handleSubmit(handleNewSearch)}>
          {useSelected && (
            <div className={classes.topSectionHeader}>
              <Typography variant="h5" sx={{ flexGrow: 1 }}>
                {t('collections.manage_collections')}
              </Typography>
              <CollectionExpand />
              {expanded && <DisplayAs state={withDisplayAs} />}
            </div>
          )}
          <div className={classes.topSectionSearchContainer}>
            <div className={classes.topSectionSearch}>
              <Controller
                name="search"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label={t('collections.filter_by_keywords')}
                    value={value}
                    clearable={true}
                    onClear={() => {
                      setValue('search', '');
                      handleSubmit(handleSearch)();
                    }}
                    onChange={onChange}
                    data-testid={collectionsBrowserTestIds.filterInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              <Box sx={{ marginBottom: 4 }}>
                <EntitlementsFilter entitlements={entitlementsFilter} setEntitlements={setEntitlementsFilter} />
              </Box>
              <Controller
                name="sort"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl color={formControlColor} className={classes.sort}>
                    <InputLabel id="contentSortBy">{t('pagination.sort_by')}</InputLabel>
                    <Select
                      label={t('pagination.sort_by')}
                      data-testid={collectionsBrowserTestIds.sortBySelector}
                      labelId="contentSortBy"
                      value={value ? SortTypes[value as SortTypes] : DEFAULT_SORT_TYPE}
                      onChange={onChange}
                    >
                      {Object.keys(SortOptions).map((option) => (
                        <MenuItem key={option} value={option}>
                          {t(SortOptions[option].localizationKey)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Button data-testid={collectionsBrowserTestIds.searchButton} type="submit" size="small">
                {t('general.search')}
              </Button>
            </div>
            <Collapse in={isAdvancedSearchOpen}>
              <SearchFilters
                actions={actions}
                onRemove={handleFilterRemove}
                render={(FilterWrap) => (
                  <>
                    {asMixedType && (
                      <FilterWrap
                        name={CollectionFilterOptions.HAS_ASSETS_THESE_TYPES}
                        data-testid={collectionsBrowserTestIds.removeFilter(
                          CollectionFilterOptions.HAS_ASSETS_THESE_TYPES
                        )}
                      >
                        <Controller
                          name="hasAssetsTheseTypes"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <FormControl color={formControlColor} className={classes.advancedOption}>
                              <Autocomplete
                                data-testid={collectionsBrowserTestIds.filter(
                                  CollectionFilterOptions.HAS_ASSETS_THESE_TYPES
                                )}
                                multiple={true}
                                options={Object.values(CollectionAssetType)}
                                getOptionLabel={(option) =>
                                  t(
                                    ('collections.advanced_search.has_assets_these_types' +
                                      `.options.${option.toLowerCase()}`) as LocaleKeys
                                  )
                                }
                                disableClearable
                                disableCloseOnSelect
                                renderOption={(props, option, { selected }) => (
                                  <li
                                    {...props}
                                    data-testid={collectionsBrowserTestIds.hasAssetsTheseTypesOption}
                                    data-option={option}
                                  >
                                    <Checkbox color={formControlColor} checked={selected} />
                                    <ListItemText
                                      primary={t(
                                        ('collections.advanced_search.has_assets_these_types' +
                                          `.options.${option.toLowerCase()}`) as LocaleKeys
                                      )}
                                    />
                                  </li>
                                )}
                                renderInput={(params) => {
                                  const inputProps = { ...params.InputProps, disabled: true };
                                  return (
                                    <TextField
                                      className={classes.textField}
                                      color={formControlColor}
                                      {...params}
                                      InputProps={inputProps}
                                      label={t('collections.advanced_search.has_assets_these_types.name')}
                                    />
                                  );
                                }}
                                value={value ?? Object.values(CollectionAssetType)}
                                onChange={(_, changedValues) => {
                                  if (changedValues.length) {
                                    onChange(changedValues as CollectionAssetType[]);
                                  }
                                }}
                              />
                            </FormControl>
                          )}
                        />
                      </FilterWrap>
                    )}
                    <FilterWrap
                      name={CollectionFilterOptions.GENRE_FILTER}
                      data-testid={collectionsBrowserTestIds.removeFilter(CollectionFilterOptions.GENRE_FILTER)}
                    >
                      <Controller
                        name="genreFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl color={formControlColor} className={classes.advancedOption}>
                            <InputLabel id="genreFilter">
                              {t('collections.advanced_search.genre_filter.name')}
                            </InputLabel>
                            <Select
                              data-testid={collectionsBrowserTestIds.filter(CollectionFilterOptions.GENRE_FILTER)}
                              labelId="genreFilter"
                              label={t('collections.advanced_search.genre_filter.name')}
                              value={value ?? ALL}
                              onChange={onChange}
                              MenuProps={{ classes: { paper: classes.advancedOptionMenuPaper } }}
                            >
                              {[ALL, ...Object.values(CollectionGenreFilter)].map((op) => (
                                <MenuItem key={op} value={op}>
                                  {t(
                                    ('collections.advanced_search.genre_filter' +
                                      `.options.${op.toLowerCase()}`) as LocaleKeys
                                  )}
                                </MenuItem>
                              ))}
                              {collectionGenres.map((genre) => (
                                <MenuItem key={genre} value={genre}>
                                  {capitalize(genre)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </FilterWrap>
                    <FilterWrap
                      name={CollectionFilterOptions.STATUS_FILTER}
                      data-testid={collectionsBrowserTestIds.removeFilter(CollectionFilterOptions.STATUS_FILTER)}
                    >
                      <Controller
                        name="status"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl color={formControlColor} className={classes.advancedOption}>
                            <InputLabel id="statusFilter">
                              {t('collections.advanced_search.status_filter.name')}
                            </InputLabel>
                            <Select
                              data-testid={collectionsBrowserTestIds.filter(CollectionFilterOptions.STATUS_FILTER)}
                              labelId="statusFilter"
                              label={t('collections.advanced_search.status_filter.name')}
                              value={value ?? ALL}
                              onChange={onChange}
                            >
                              {statusFilterOptions.map((op) => (
                                <MenuItem
                                  data-testid={collectionsBrowserTestIds.filterOptions(
                                    CollectionFilterOptions.STATUS_FILTER,
                                    op
                                  )}
                                  key={op}
                                  value={op}
                                >
                                  {t(
                                    // eslint-disable-next-line max-len
                                    `collections.advanced_search.status_filter.options.${op.toLowerCase()}` as LocaleKeys
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </FilterWrap>
                    <FilterWrap
                      name={CollectionFilterOptions.USED_FILTER}
                      data-testid={collectionsBrowserTestIds.removeFilter(CollectionFilterOptions.USED_FILTER)}
                    >
                      <Controller
                        name="usedFilter"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl color={formControlColor} className={classes.advancedOption}>
                            <InputLabel id="usedFilter">{t('collections.advanced_search.used_filter.name')}</InputLabel>
                            <Select
                              data-testid={collectionsBrowserTestIds.filter(CollectionFilterOptions.USED_FILTER)}
                              labelId="genreFilter"
                              label={t('collections.advanced_search.used_filter.name')}
                              value={value ?? ALL}
                              onChange={onChange}
                            >
                              {usedFilterOptions.map((op) => (
                                <MenuItem
                                  data-testid={collectionsBrowserTestIds.filterOptions(
                                    CollectionFilterOptions.USED_FILTER,
                                    op
                                  )}
                                  key={op}
                                  value={op}
                                >
                                  {t(
                                    ('collections.advanced_search.used_filter' +
                                      `.options.${op.toLowerCase()}`) as LocaleKeys
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </FilterWrap>
                    {!asMixedType && (
                      <>
                        <FilterWrap
                          name={CollectionFilterOptions.FOR_SIMILARS}
                          data-testid={collectionsBrowserTestIds.removeFilter(CollectionFilterOptions.FOR_SIMILARS)}
                        >
                          <Controller
                            name="forSimilars"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <FormControl color={formControlColor} className={classes.advancedOption}>
                                <InputLabel id="usedFilter">
                                  {t('collections.advanced_search.for_similars.name')}
                                </InputLabel>
                                <Select
                                  data-testid={collectionsBrowserTestIds.filter(CollectionFilterOptions.FOR_SIMILARS)}
                                  labelId="forSimilars"
                                  label={t('collections.advanced_search.for_similars.name')}
                                  value={value ?? ALL}
                                  onChange={onChange}
                                >
                                  {forSimilarsOptions.map((op) => (
                                    <MenuItem
                                      data-testid={collectionsBrowserTestIds.filterOptions(
                                        CollectionFilterOptions.FOR_SIMILARS,
                                        op
                                      )}
                                      key={op}
                                      value={op}
                                    >
                                      {t(
                                        ('collections.advanced_search.for_similars' +
                                          `.options.${op.toLowerCase()}`) as LocaleKeys
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </FilterWrap>
                        <FilterWrap
                          name={CollectionFilterOptions.HAS_SMART_QUERY}
                          data-testid={collectionsBrowserTestIds.removeFilter(CollectionFilterOptions.HAS_SMART_QUERY)}
                        >
                          <Controller
                            name="hasSmartQuery"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <FormControl color={formControlColor} className={classes.advancedOption}>
                                <InputLabel id="usedFilter">
                                  {t('collections.advanced_search.has_smart_query.name')}
                                </InputLabel>
                                <Select
                                  data-testid={collectionsBrowserTestIds.filter(
                                    CollectionFilterOptions.HAS_SMART_QUERY
                                  )}
                                  labelId="hasSmartQuery"
                                  label={t('collections.advanced_search.has_smart_query.name')}
                                  value={value ?? ALL}
                                  onChange={onChange}
                                >
                                  {hasSmartQueryOptions.map((op) => (
                                    <MenuItem
                                      data-testid={collectionsBrowserTestIds.filterOptions(
                                        CollectionFilterOptions.HAS_SMART_QUERY,
                                        op
                                      )}
                                      key={op}
                                      value={op}
                                    >
                                      {t(
                                        ('collections.advanced_search.has_smart_query' +
                                          `.options.${op.toLowerCase()}`) as LocaleKeys
                                      )}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </FilterWrap>
                      </>
                    )}
                    <FilterWrap
                      name={CollectionFilterOptions.AUTOROTATION_FILTER}
                      data-testid={collectionsBrowserTestIds.removeFilter(CollectionFilterOptions.AUTOROTATION_FILTER)}
                    >
                      <Controller
                        name="hasAutoRotate"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <FormControl color={formControlColor} className={classes.advancedOption}>
                            <InputLabel id="autoRotation">
                              {t('collections.advanced_search.auto_rotation.name')}
                            </InputLabel>
                            <Select
                              data-testid={collectionsBrowserTestIds.filter(
                                CollectionFilterOptions.AUTOROTATION_FILTER
                              )}
                              labelId="hasAutoRotation"
                              label={t('collections.advanced_search.auto_rotation.name')}
                              value={value ?? ALL}
                              onChange={onChange}
                            >
                              {autoRotationFilterOptions.map((op) => (
                                <MenuItem
                                  data-testid={collectionsBrowserTestIds.filterOptions(
                                    CollectionFilterOptions.AUTOROTATION_FILTER,
                                    op
                                  )}
                                  key={op}
                                  value={op}
                                >
                                  {t(
                                    ('collections.advanced_search.auto_rotation' +
                                      `.options.${op.toLowerCase()}`) as LocaleKeys
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </FilterWrap>
                    <FilterWrap
                      name={CollectionFilterOptions.SHOW_DELETED}
                      data-testid={collectionsBrowserTestIds.removeFilter(CollectionFilterOptions.SHOW_DELETED)}
                    >
                      <Controller
                        name="includeDeleted"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <FormControl color={formControlColor} className={classes.advancedOption}>
                            <FormControlLabel
                              label={t('collections.advanced_search.show_deleted.name')}
                              control={<Switch color={formControlColor} checked={value || false} onChange={onChange} />}
                            />
                          </FormControl>
                        )}
                      />
                    </FilterWrap>
                  </>
                )}
              />
            </Collapse>
            <div className={classes.topSectionAdvanced}>
              <PaginationResults list={collectionList} meta={meta} isLoading={isLoading} />
              <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                <FilterBadge filters={recoilSearchParams._filters} />
                <AdvancedSearch open={isAdvancedSearchOpen} onChange={setIsAdvancedSearchOpen} />
              </Box>
            </div>
          </div>
        </form>
        <div className={classes.collectionsContainer}>
          <ShadowScroller ref={scrollRef} loading={isLoading}>
            <List className={classes.collections}>
              {/* Loading state */}
              {isLoading ? (
                <Repeat n={15}>
                  <Skeleton
                    data-testid={collectionsBrowserTestIds.loader}
                    className={classes.skeletonCollection}
                    animation="wave"
                  />
                </Repeat>
              ) : (
                collectionList?.map((collection) => (
                  <CollectionListItem
                    key={collection.entityId}
                    collectionId={collection.entityId}
                    onClick={handleCollectionClick}
                    onEdit={handleEdit}
                    onClone={handleClone}
                  />
                ))
              )}
            </List>
          </ShadowScroller>
        </div>
        <Footer />
      </div>
    </FormProvider>
  );
}

export default CollectionsBrowser;
