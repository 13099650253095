import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { CategoryOverrideBody, EntitlementType } from '../../../../../API';
import { FormMetadata } from '../../../../../data-layer';
import { useConfirm, useLocales, useNotifications, useTheme } from '../../../../../hooks';
import { useEntitlements } from '../../../../../hooks/General/useEntitlements';
import Button from '../../../../shared/Button';
import Drawer from '../../../../shared/Drawer';
import { EntitlementsIcon } from '../../../../shared/Entitlements';
import { EPGCategoryOrderingFormTestIds } from '../../../../shared/TestsIds';

export const allEntitlementTypes = Object.keys(EntitlementType) as EntitlementType[];

const useStyles = makeStyles()((theme) => ({
  formBody: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  checkboxContainer: {
    paddingLeft: theme.spacing(4)
  },
  checkboxLabel: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  iconContainer: {
    width: 24
  },
  footerButton: {
    minWidth: 120
  },
  formHelpText: {
    margin: theme.spacing(4, 0)
  }
}));

export interface EPGCategoryOrderingFormMetadata extends FormMetadata<CategoryOverrideBody> {
  index?: number;
  allowedEntitlements: EntitlementType[];
}

export type EPGCategoryOrderingFormProps = {
  formMetadata: EPGCategoryOrderingFormMetadata | undefined;
  onSubmit: (formMetadata: EPGCategoryOrderingFormMetadata) => void;
  onDelete: (formMetadata: EPGCategoryOrderingFormMetadata) => void;
};

export function EPGCategoryOrderingForm({
  formMetadata,
  onSubmit,
  onDelete
}: EPGCategoryOrderingFormProps): React.ReactElement {
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { localizeEntitlement } = useEntitlements();
  const { notifyError } = useNotifications();
  const { formControlColor } = useTheme();
  const { confirm } = useConfirm();
  const { handleSubmit, control, reset } = useForm<CategoryOverrideBody>();

  const [isDrawerShowing, setIsDrawerShowing] = useState(false);

  useEffect(() => {
    if (!formMetadata?.record) return;
    reset(formMetadata.record);
    setIsDrawerShowing(true);
  }, [formMetadata]);

  const onFormSubmit: SubmitHandler<CategoryOverrideBody> = async (submittedCategoryOverride) => {
    if (!submittedCategoryOverride.entitlements.length) {
      return notifyError(t('errors.category_bundles.order_override_empty'));
    }
    if (!formMetadata) return;
    onSubmit({
      ...formMetadata,
      record: submittedCategoryOverride
    });
    handleClose();
  };

  const handleClose = () => {
    setIsDrawerShowing(false);
  };

  const handleDeleteClick = async () => {
    if (!formMetadata) return;
    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: t('epg.confirm_override_delete'),
      'data-testid': EPGCategoryOrderingFormTestIds.confirmDialog
    });

    if (result) {
      onDelete(formMetadata);
      handleClose();
    }
  };

  return (
    <Drawer
      open={isDrawerShowing}
      formRef={formRef}
      onSubmit={handleSubmit(onFormSubmit)}
      onClose={handleClose}
      headerLeft={
        <Typography variant="h6">{t(`epg.${formMetadata?.isNew ? 'new' : 'edit'}_category_override`)}</Typography>
      }
      headerRight={
        formMetadata?.isEditing && (
          <Button color="error" onClick={handleDeleteClick} data-testid={EPGCategoryOrderingFormTestIds.deleteButton}>
            {t('general.delete')}
          </Button>
        )
      }
      footerLeft={
        <Button
          type="submit"
          className={classes.footerButton}
          data-testid={EPGCategoryOrderingFormTestIds.submitButton}
        >
          {t('general.save')}
        </Button>
      }
      footerRight={
        <Button color="grey" className={classes.footerButton} onClick={handleClose}>
          {t('general.cancel')}
        </Button>
      }
    >
      <div className={classes.formBody} data-testid={EPGCategoryOrderingFormTestIds.formBody}>
        <div className={classes.formHelpText}>Choose access levels for which this override will apply.</div>
        <Controller
          name="entitlements"
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              {allEntitlementTypes.map((entitlement) => (
                <FormControlLabel
                  key={entitlement}
                  className={classes.checkboxContainer}
                  control={
                    <Checkbox
                      color={formControlColor}
                      checked={value.includes(entitlement)}
                      disabled={!formMetadata?.allowedEntitlements.includes(entitlement)}
                      data-testid={EPGCategoryOrderingFormTestIds.entitlementCheckbox}
                      data-entitlement={entitlement}
                      onChange={({ target: { checked } }) => {
                        if (!checked) {
                          onChange(value.filter((e: EntitlementType) => e !== entitlement));
                        } else {
                          onChange([...value, entitlement].sort());
                        }
                      }}
                    />
                  }
                  label={
                    <div className={classes.checkboxLabel}>
                      <div className={classes.iconContainer}>
                        <EntitlementsIcon entitlement={entitlement} />
                      </div>
                      {localizeEntitlement(entitlement)}
                    </div>
                  }
                />
              ))}
            </>
          )}
        />
      </div>
    </Drawer>
  );
}
