import React, { useState } from 'react';
import { StayPrimaryPortrait } from '@mui/icons-material';
import { PlanSummaryUiModuleBody } from '../../../../../API';
import { useLocales, useTheme } from '../../../../../hooks';
import Button from '../../../../shared/Button';
import { PlanSummaryPreview } from '../../ContentPreview/PlanSummaryPreview';
import UIModuleListItem, { IBaseUIModuleListItemProps } from '../../UIModuleListItem';
import { planSummaryListItemTestIds } from '../../../../shared/TestsIds';

export interface PlanSummaryListItemProps extends IBaseUIModuleListItemProps {
  uiModule: PlanSummaryUiModuleBody;
}

export function PlanSummaryListItem({ uiModule, ...props }: PlanSummaryListItemProps): JSX.Element {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const [open, setOpen] = useState(false);

  const onPreviewButtonClick = () => setOpen(true);

  return (
    <>
      <UIModuleListItem
        uiModule={uiModule}
        topLineTitle={t('layouts.ui_module_plan_summary')}
        collectionLine={[
          <Button
            key="preview"
            variant="text"
            size="small"
            startIcon={<StayPrimaryPortrait />}
            color={formControlColor}
            onClick={onPreviewButtonClick}
            data-testid={planSummaryListItemTestIds.button}
          >
            {t('layouts.preview_contents')}
          </Button>
        ]}
        {...props}
      />
      <PlanSummaryPreview module={uiModule} open={open} setOpen={setOpen} />
    </>
  );
}
