import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { AssetResponse } from '../../../../API';
import Image from '../../../shared/Image';
import { getAssetUrl } from '../../../../utils/assetHelpers';
import { makeStyles } from 'tss-react/mui';
import { UserBadge } from '../../../shared/UserBadge';
import IconButton from '../../../shared/IconButton';
import { useLocales } from '../../../../hooks';
import { Delete } from '@mui/icons-material';
import { ASSET_WIDTH } from '../AssetsManager';
import { assetCardManagerTestIds } from '../../../shared/TestsIds';

export const ASPECT_RATIO = '3 / 2';

interface AssetCardProps {
  asset: AssetResponse;
  width: number;
  className?: string;
  onClick?: () => unknown;
  onRemove?: () => unknown;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: ASPECT_RATIO,
    borderRadius: theme.shape.borderRadius,
    background: '#000',
    boxShadow: theme.shadows[2],
    cursor: 'pointer'
  },
  image: {
    width: '100%',
    maxHeight: ASSET_WIDTH,
    objectFit: 'contain',
    aspectRatio: ASPECT_RATIO
  },
  info: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    alignSelf: 'stretch',
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
  },
  assetName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  details: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  }
}));

function AssetCardComponent({ asset, className, onClick, onRemove }: AssetCardProps): JSX.Element {
  const { classes, cx } = useStyles();
  const { t } = useLocales();

  return (
    <Box
      className={cx(className, classes.root)}
      data-testid={assetCardManagerTestIds.card}
      onClick={onClick}
      data-asset-id={asset.id}
    >
      <Image className={classes.image} src={`${getAssetUrl(asset.filePath)}?tr=w-${ASSET_WIDTH * 2}`} />
      <div className={classes.info}>
        <Tooltip title={asset.assetName}>
          <Typography variant="body1" className={classes.assetName}>
            {asset.assetName}
          </Typography>
        </Tooltip>
        <div className={classes.details}>
          <UserBadge record={{ ...asset, createdBy: asset.authorId }} />
          <div style={{ flexGrow: 1 }} />
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onRemove?.();
            }}
            size="small"
            data-testid={assetCardManagerTestIds.remove}
            title={t('general.delete')}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    </Box>
  );
}

export const AssetCard = React.memo(AssetCardComponent);
