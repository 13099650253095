import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PlaybackPaywallUiModuleBody } from '../../../../../API';
import { useFormContext } from 'react-hook-form';
import { FormBody, SectionHeader } from '../../styles';
import { useLocales } from '../../../../../hooks';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { playbackPaywallFormTestIds } from '../../../../shared/TestsIds';

const PlaybackPaywallForm = forwardRef<UIModuleTypeForm<PlaybackPaywallUiModuleBody>>(() => {
  const { control } = useFormContext<PlaybackPaywallUiModuleBody>();
  const { t } = useLocales();

  return (
    <FormBody data-testid={playbackPaywallFormTestIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.playback_paywall.headers.trailer')}</SectionHeader>
        <LokaliseField
          name="postTrailerPrimaryText"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={playbackPaywallFormTestIds.formField('postTrailerPrimaryText')}
          required
        />
        <LokaliseField
          name="postTrailerPrimaryCta"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={playbackPaywallFormTestIds.formField('postTrailerPrimaryCta')}
          required
        />
        <LokaliseField
          name="postTrailerSecondaryCtaAuth"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={playbackPaywallFormTestIds.formField('postTrailerSecondaryCtaAuth')}
        />
        <LokaliseField
          name="postTrailerSecondaryCtaAnon"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={playbackPaywallFormTestIds.formField('postTrailerSecondaryCtaAnon')}
        />
      </div>
      <div>
        <SectionHeader>{t('layouts.playback_paywall.headers.up_next')}</SectionHeader>
        <LokaliseField
          name="postEpisodePrimaryCta"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={playbackPaywallFormTestIds.formField('postEpisodePrimaryCta')}
          required
        />
        <LokaliseField
          name="postEpisodeSecondaryCtaAuth"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={playbackPaywallFormTestIds.formField('postEpisodeSecondaryCtaAuth')}
        />
        <LokaliseField
          name="postEpisodeSecondaryCtaAnon"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={playbackPaywallFormTestIds.formField('postEpisodeSecondaryCtaAnon')}
        />
      </div>
    </FormBody>
  );
});

PlaybackPaywallForm.displayName = 'PlaybackPaywallForm';

export default PlaybackPaywallForm;
