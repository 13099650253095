import React, { useEffect, useRef, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { alpha, Chip, InputLabel, MenuItem, Typography, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { omit } from 'lodash-es';
import { Controller, SubmitErrorHandler, useForm, useWatch } from 'react-hook-form';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  ContentCollectionResponse,
  ContentCollectionSortingType,
  ContentCollectionType,
  DocumentLocale,
  EntitlementType,
  VersionedDocumentStatus
} from '../../../API';
import { useData } from '../../../data-layer';
import { useLocales, useNotifications, useTheme } from '../../../hooks';
import {
  collectionTypeCaptions,
  sortingTypeCaptions,
  sortingTypeDescriptions
} from '../../../utils/consts/translationKeys';
import { CustomValidators } from '../../../utils/customValidators';
import Button from '../../shared/Button';
import Code from '../../shared/Code';
import DraftBadge from '../../shared/DraftBadge';
import Drawer from '../../shared/Drawer';
import FormControl from '../../shared/FormControl';
import InputController from '../../shared/InputController';
import LocalizedInputCollection from '../../shared/LocalizedInputCollection';
import Localized from '../../shared/Localized';
import useConfirm from '../../../hooks/General/useConfirm';
import { EntitlementsMultiSelect } from '../../shared/Entitlements';
import { AppRoutes } from '../../../Routes';
import { LocaleKeys } from '../../../locales/i18n';
import { PermissionsGroupSelector } from '../../shared/PermissionsGroupSelector';
import { usePermissions } from '../../../hooks/Permissions/usePermissions';
import { CollectionDeleteConfirm } from '../CollectionDeleteConfirm';
import AssetBrowser from '../../Assets/AssetBrowser';
import { AssetTypes } from '../../../utils/assetTypes';
import { Slug } from '../../shared/Slug';
import { handleSaveDraft, handleSavePublish, isFormValid } from '../../../utils/formHelpers';
import { defaultAutoRotateParams } from '../../../utils/autoRotate';
import TextValidator from '../../shared/TextValidator';
import Select from '../../shared/Select';
import { usePermissionsGuard } from '../../../hooks/General/usePermissionsGuard';
import ClearableDateTimePicker from '../../shared/ClearableDateTimePicker';
import { renderFormLabel } from '../../../utils/formUtils';
import { DateTime } from 'luxon';
import { HomepageOptions } from '../../../state/theme';
import { collectionFormTestIds } from '../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  headerLeft: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center'
  },
  sortingMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  sortingDescription: {
    whiteSpace: 'normal',
    color: alpha(theme.palette.text.primary, 0.6),
    fontSize: theme.typography.body2.fontSize
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(4)
  },
  formField: {
    maxWidth: 500,
    marginBottom: 4
  },
  licContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    '& > div': {
      flexGrow: 1
    }
  },
  footerButton: {
    minWidth: 190,
    marginRight: theme.spacing(4)
  },
  selectControl: {
    minWidth: 500,
    marginBottom: theme.spacing(4)
  },
  selectOption: {
    textTransform: 'capitalize'
  },
  metaGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(4)
  },
  startDate: {
    display: 'inline-flex',
    flexDirection: 'column'
  }
}));

type CollectionsFormProps = {
  asMixedCollectionForm?: boolean;
};

function CollectionForm({ asMixedCollectionForm = false }: CollectionsFormProps): JSX.Element {
  const { canDelete, canPublish, canSave } = usePermissionsGuard({
    homepageOption: HomepageOptions.COLLECTIONS
  });
  const formRef = useRef<ValidatorForm>(null);
  const { classes } = useStyles();
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { notifyError } = useNotifications();
  const { hasPermissions } = usePermissions();
  const navigate = useNavigate();
  const collectionTypes = Object.keys(collectionTypeCaptions).filter(
    (key) => !['SPORTS_EVENTS', 'SPORTS'].includes(key)
  ) as Array<keyof typeof ContentCollectionType>;

  const {
    collections: {
      state: collectionsState,
      hook: { remove, closeForm, saveAndPublish, save, publish }
    }
  } = useData();

  const genresList = useRecoilValue(collectionsState.withAllGenres);
  const formMetadata = useRecoilValue(collectionsState.withFormMetadata);
  const isSaving = useRecoilValue(collectionsState.withIsSaving);
  const isPublishing = useRecoilValue(collectionsState.withIsPublishing);
  const collection = formMetadata.record;
  const cloningCollectionTitle = formMetadata.cloningRecord?.title;
  const [isForSimilarsDisabled, setIsForSimilarsDisabled] = useState(!collection?.genre?.key);
  const isMixedCollection = asMixedCollectionForm || collection?.collectionType === ContentCollectionType.MIXED_LIST;

  const { handleSubmit, control, reset, getValues, setValue, watch } = useForm<ContentCollectionResponse>({
    defaultValues: collection
  });
  const { confirm } = useConfirm();
  const {
    UPSERT: hasUpsertPermission,
    PUBLISH: hasPublishPermission,
    DELETE: hasDeletePermission
  } = hasPermissions(collection?.ownerPermissionsGroup);

  const title = useWatch({ control, name: 'title' });
  const isAutoRotateEnabled = !!useWatch({ control, name: 'autoRotate.numberOfItems' });

  const [splashLandscapeImage, splashPortraitImage] = watch(['splashLandscapeImage', 'splashPortraitImage']);

  useEffect(() => {
    reset(collection);
    setIsForSimilarsDisabled(!collection?.genre?.key);

    if (isMixedCollection) {
      setValue('collectionType', ContentCollectionType.MIXED_LIST);
      setValue('forSimilars', false);
    }
  }, [collection]);

  const onSubmit = async (collection: ContentCollectionResponse, shouldPublish = false) => {
    if (!(await isFormValid(formRef))) return;
    if (collection?.genre?.key === '') {
      collection = omit(collection, 'genre');
    }

    if (
      !collection?.autoRotate?.numberOfItems &&
      !collection?.autoRotate?.numberOfRotationDays &&
      !collection?.autoRotate?.startDate
    ) {
      collection = omit(collection, 'autoRotate');
    }

    if (collection?.autoRotate && !collection?.autoRotate?.startDate) {
      collection = {
        ...collection,
        autoRotate: {
          ...collection.autoRotate,
          startDate: DateTime.now().toISO()
        }
      };
    }

    let savedCollection: ContentCollectionResponse | undefined;

    if (shouldPublish) {
      if (hasUpsertPermission) {
        // save and publish
        savedCollection = await saveAndPublish(collection);
      } else {
        savedCollection = await publish(collection.entityId);
      }
    } else {
      // save as draft
      savedCollection = await save(collection);
    }

    if (!savedCollection) return;
    if (formMetadata.isNew) {
      if (isMixedCollection) {
        navigate(AppRoutes.collection(savedCollection.entityId));
      } else {
        navigate(AppRoutes.vodCollection(savedCollection.entityId));
      }
    }
    closeForm();
  };

  const onError: SubmitErrorHandler<ContentCollectionResponse> = async (errors) => {
    if (errors.splashLandscapeImage || errors.splashPortraitImage) {
      notifyError(t('errors.collections.splash_images'));
      return;
    }

    notifyError(t('general.form_error'));
  };

  const handleDeleteConfirm = async () => {
    if (!collection) return;

    const result = await confirm({
      confirmColor: 'error',
      confirmText: t('general.confirm_delete'),
      body: <CollectionDeleteConfirm collection={collection} />,
      'data-testid': collectionFormTestIds.confirmDialog
    });

    if (result) {
      const successful = await remove((collection as ContentCollectionResponse).entityId);
      if (successful) {
        closeForm();
        navigate(AppRoutes.vodCollections);
      }
    }
  };

  const handleOnClose = () => {
    if (!isSaving && !isPublishing) {
      closeForm();
    }
  };

  const renderCollectionForm = () => (
    <Drawer
      data-testid={collectionFormTestIds.root}
      open={formMetadata.isShowingForm}
      formRef={formRef}
      onClose={handleOnClose}
      headerLeft={
        <div className={classes.headerLeft} data-testid={collectionFormTestIds.headerLeft}>
          <Typography variant="h6">
            {!isMixedCollection
              ? t(`collections.${formMetadata.isNew ? 'new_collection' : 'edit_collection_metadata'}`)
              : t(`collections.${formMetadata.isNew ? 'new_mixed_collection' : 'edit_mixed_collection_metadata'}`)}
          </Typography>
          {(collection as ContentCollectionResponse)?.status === VersionedDocumentStatus.DRAFT &&
            !formMetadata.isNew && <DraftBadge />}
          {formMetadata.isCloning && cloningCollectionTitle && (
            <Chip
              size="small"
              color="secondary"
              label={
                <>
                  {t('general.clone_of')} <Localized prop={cloningCollectionTitle} />
                </>
              }
              data-testid={collectionFormTestIds.cloneBadge}
            />
          )}
        </div>
      }
      headerRight={
        collection ? (
          <Button
            color="error"
            endIcon={<Delete />}
            onClick={handleDeleteConfirm}
            disabled={!canDelete || formMetadata.isNew || collection.locked || !hasDeletePermission}
            data-testid={collectionFormTestIds.deleteButton}
            sx={{ ml: 4 }}
          >
            {t('general.delete')}
          </Button>
        ) : null
      }
      footerLeft={
        <>
          <Button
            onClick={handleSaveDraft(handleSubmit, onSubmit, onError)}
            color="secondary"
            loading={isSaving}
            disabled={!canSave || !hasUpsertPermission || isPublishing || isSaving}
            className={classes.footerButton}
            data-testid={collectionFormTestIds.saveButton}
          >
            {t('general.saveAsDraft')}
          </Button>
          <Button
            onClick={handleSavePublish(handleSubmit, onSubmit, onError)}
            loading={isPublishing}
            disabled={!canSave || !canPublish || !hasPublishPermission || isSaving || isPublishing}
            className={classes.footerButton}
            data-testid={collectionFormTestIds.publishButton}
          >
            {t(hasUpsertPermission ? 'general.saveAndPublish' : 'general.publish')}
          </Button>
        </>
      }
      footerRight={
        <Button
          color="grey"
          disabled={isSaving || isPublishing}
          className={classes.footerButton}
          onClick={closeForm}
          data-testid={collectionFormTestIds.cancelButton}
        >
          {t('general.cancel')}
        </Button>
      }
    >
      {collection && (
        <div className={classes.formBody} data-testid={collectionFormTestIds.formBody}>
          <Code obj={getValues()} />
          <LocalizedInputCollection
            containerClassName={classes.licContainer}
            reset={reset}
            getValues={getValues}
            fields={[
              {
                component: (
                  <InputController
                    name="title"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextValidator
                        fullWidth
                        name="title"
                        color={formControlColor}
                        value={value}
                        onChange={onChange}
                        label={t('general.title')}
                        validators={[CustomValidators.requiredIfDefined]}
                        errorMessages={[t('general.field_is_required')]}
                      />
                    )}
                  />
                )
              },
              {
                component: (
                  <InputController
                    name="description"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextValidator
                        fullWidth
                        name="description"
                        color={formControlColor}
                        value={value}
                        onChange={onChange}
                        label={t('general.description')}
                        validators={[CustomValidators.requiredIfDefined]}
                        errorMessages={[t('general.field_is_required')]}
                      />
                    )}
                  />
                )
              }
            ]}
          />

          <Controller
            name="editorNotes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextValidator
                fullWidth
                multiline
                color={formControlColor}
                name="editorNotes"
                label={t('general.editor_notes')}
                value={value}
                onChange={onChange}
                data-testid={collectionFormTestIds.editorNotes}
              />
            )}
          />

          <div className={classes.metaGrid}>
            <Controller
              control={control}
              name="splashLandscapeImage"
              rules={{ required: splashPortraitImage }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <AssetBrowser
                  label={t('collections.landscape_splash')}
                  value={value}
                  onChange={onChange}
                  assetType={AssetTypes.collections.landscapeSplash}
                  hasError={!!error}
                  data-testid={collectionFormTestIds.splashLandscape}
                />
              )}
            />
            <Controller
              control={control}
              name="splashPortraitImage"
              rules={{ required: splashLandscapeImage }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <AssetBrowser
                  label={t('collections.portrait_splash')}
                  value={value}
                  onChange={onChange}
                  assetType={AssetTypes.collections.portraitSplash}
                  hasError={!!error}
                  data-testid={collectionFormTestIds.splashPortrait}
                />
              )}
            />
            <Controller
              control={control}
              name="cardLandscapeImage"
              render={({ field: { value, onChange } }) => (
                <AssetBrowser
                  label={t('collections.card_image_landscape')}
                  value={value}
                  onChange={onChange}
                  assetType={AssetTypes.collections.cardImageLandsape}
                  data-testid={collectionFormTestIds.cardImageLandscape}
                />
              )}
            />
            <Controller
              control={control}
              name="slug"
              render={({ field: { value, onChange } }) => (
                <Slug value={value} onChange={onChange} slugify={title?.[DocumentLocale.ES]} />
              )}
            />
          </div>

          <div className={classes.formField}>
            {collection.entitlements && (
              <Controller
                name="entitlements"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <EntitlementsMultiSelect
                    label={t('general.user_access_level')}
                    value={value as EntitlementType[]}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </div>
          {!isMixedCollection && (
            <div className={classes.formField}>
              <FormControl className={classes.selectControl}>
                <InputLabel>{t('collections.collection_type')}</InputLabel>
                <Controller
                  name="collectionType"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={t('collections.collection_type')}
                      value={value || ''}
                      onChange={onChange}
                      SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
                      data-testid={collectionFormTestIds.contentTypeSelect}
                    >
                      {collectionTypes.map((collectionType) => (
                        <MenuItem
                          key={collectionType}
                          value={collectionType}
                          data-testid={''}
                          className={classes.selectOption}
                        >
                          {t(collectionTypeCaptions[collectionType as ContentCollectionType] as LocaleKeys)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          )}
          <div className={classes.formField}>
            <FormControl variant="standard" className={classes.selectControl}>
              <InputLabel>{t('general.genre')}</InputLabel>
              {genresList && (
                <Controller
                  name="genre"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label={t('general.genre')}
                      value={value?.key || ''}
                      onChange={({ target: { value } }) => {
                        setIsForSimilarsDisabled(!value);
                        if (!value) {
                          setValue('forSimilars', false);
                        }
                        onChange({ key: value });
                      }}
                      SelectDisplayProps={{ style: { textTransform: 'capitalize' } }}
                      data-testid={collectionFormTestIds.genresSelect}
                    >
                      <MenuItem value="">{t('general.select_a_genre')}</MenuItem>
                      {genresList.map((genre) => (
                        <MenuItem key={genre} value={genre} data-testid={''} className={classes.selectOption}>
                          {genre}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            </FormControl>
          </div>
          {!isMixedCollection && (
            <div className={classes.formField}>
              <Controller
                name="forSimilars"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label={t('collections.for_similars')}
                    control={
                      <Checkbox
                        name="forSimilars"
                        data-testid={collectionFormTestIds.forSimilars}
                        checked={value}
                        color={formControlColor}
                        disabled={isForSimilarsDisabled}
                        onChange={({ target: { checked } }) => {
                          onChange(checked);
                        }}
                      />
                    }
                  />
                )}
              />
            </div>
          )}
          <div className={classes.formField}>
            <Controller
              name="kids"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label={t('collections.kids.enable')}
                  control={
                    <Checkbox
                      name="kids"
                      data-testid={collectionFormTestIds.kids}
                      checked={value}
                      color={formControlColor}
                      onChange={({ target: { checked } }) => {
                        onChange(checked);
                      }}
                    />
                  }
                />
              )}
            />
          </div>

          <div className={classes.formField}>
            <FormControl className={classes.selectControl}>
              <InputLabel id="sortingType">{t('collections.sorting.sorting_type')}</InputLabel>
              <Controller
                name="sortingType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="sortingType"
                    label={t('collections.sorting.sorting_type')}
                    value={value || ContentCollectionSortingType.CURATED_FIRST}
                    onChange={onChange}
                    data-testid={collectionFormTestIds.sortingType}
                  >
                    {Object.keys(ContentCollectionSortingType).map((sortingType) => (
                      <MenuItem
                        className={classes.sortingMenuItem}
                        key={sortingType}
                        value={sortingType}
                        disabled={
                          asMixedCollectionForm && sortingType === ContentCollectionSortingType.FALLBACK_CURATED
                        }
                      >
                        <div>{t(sortingTypeCaptions[sortingType as ContentCollectionSortingType])}</div>
                        <div className={classes.sortingDescription}>
                          {t(sortingTypeDescriptions[sortingType as ContentCollectionSortingType])}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className={classes.formField}>
            <FormControl
              className={classes.selectControl}
              data-testid={collectionFormTestIds.ownerPermissionsGroupSelect}
            >
              <InputLabel>{t('permissions.permissions_group')}</InputLabel>
              <Controller
                name="ownerPermissionsGroup"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PermissionsGroupSelector value={value} onChange={onChange} />
                )}
              />
            </FormControl>
          </div>

          <div>
            <Controller
              name="autoRotate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label={t('collections.auto_rotate.enable')}
                  control={
                    <Checkbox
                      data-testid={collectionFormTestIds.autoRotateCheckbox}
                      checked={!!value}
                      color={formControlColor}
                      onChange={({ target: { checked } }) => {
                        if (checked) {
                          onChange(defaultAutoRotateParams());
                        } else {
                          onChange(null);
                        }
                      }}
                    />
                  }
                />
              )}
            />
          </div>
          {isAutoRotateEnabled && (
            <Stack direction="row" gap={6} alignItems="center">
              <Controller
                control={control}
                name="autoRotate.numberOfItems"
                render={({ field: { value, onChange } }) => (
                  <TextValidator
                    type="number"
                    name="autoRotate.numberOfItems"
                    value={value}
                    label={t('collections.auto_rotate.number_of_items')}
                    validators={['minNumber:1', 'matchRegexp:^[0-9]+$']}
                    color={formControlColor}
                    onChange={({ target }) => onChange(Number(target.value))}
                    data-testid={collectionFormTestIds.autoRotateNumItemsInput}
                  />
                )}
              />
              <Controller
                control={control}
                name="autoRotate.numberOfRotationDays"
                render={({ field: { value, onChange } }) => (
                  <TextValidator
                    type="number"
                    name="autoRotate.numberOfRotationDays"
                    value={value}
                    label={t('collections.auto_rotate.number_of_days')}
                    onChange={({ target }) => onChange(Number(target.value))}
                    validators={['minNumber:1', 'matchRegexp:^[0-9]+$']}
                    color={formControlColor}
                    data-testid={collectionFormTestIds.autoRotateNumDaysInput}
                  />
                )}
              />
              <Controller
                control={control}
                name="autoRotate.startDate"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <div className={classes.startDate}>
                    {renderFormLabel(t('collections.auto_rotate.started_on'))}
                    <ClearableDateTimePicker
                      value={value}
                      onChange={onChange}
                      clearable={false}
                      fullWidth
                      showTimezones
                      error={!!error}
                      helperText={error ? error?.message : t('collections.auto_rotate.start_time_help_text')}
                    />
                  </div>
                )}
              />
            </Stack>
          )}
        </div>
      )}
    </Drawer>
  );

  return renderCollectionForm();
}

export default CollectionForm;
