import React from 'react';
import { useLocales } from '../../../../../hooks';
import { RegistrationWallUiModuleBody } from '../../../../../API';
import Modal from '../../../../shared/Modal';
import { Box, Typography } from '@mui/material';
import Button from '../../../../shared/Button';
import { themeColors } from '../../../../../theme';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import { makeStyles } from 'tss-react/mui';
import { Stack } from '@mui/system';
import { TextButton, VixOrangeButton } from '../shared/SharedButtons';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { regWallPreviewTestIds } from '../../../../shared/TestsIds';

export interface RegWallPreviewProps {
  module: RegistrationWallUiModuleBody;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const useStyles = makeStyles()({
  container: {
    background: 'black',
    display: 'flex',
    position: 'relative',
    width: 450,
    color: 'white',
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    position: 'absolute',
    pointerEvents: 'none'
  },
  bar: {
    width: 50,
    height: 3,
    background: 'white',
    borderRadius: 5,
    margin: 'auto'
  },
  body: {
    position: 'relative',
    marginTop: '50%',
    background: '#0B0D17',
    width: '100%',
    borderRadius: '10px 10px 0 0',
    padding: '20px 40px'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    textAlign: 'left',
    li: {
      '&::marker': {
        content: '"✓ "',
        color: themeColors.orange
      }
    }
  }
});

export function RegWallPreview({ module, isOpen, setIsOpen }: RegWallPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();
  const { lt } = useLokalise();

  const onModalClose = () => setIsOpen(false);

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      headerLeft={t('layouts.reg_wall.preview')}
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box data-testid={regWallPreviewTestIds.root} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Box data-testid={regWallPreviewTestIds.container} className={classes.container}>
          {/* Background Image */}
          {module.portraitImage && <img className={classes.image} src={getAssetUrl(module.portraitImage)} />}
          <Box className={classes.body}>
            <div className={classes.bar} />
            <Stack gap="24px" marginTop="50px">
              <Typography variant="h4" fontWeight="bold" alignSelf="start">
                <span dangerouslySetInnerHTML={{ __html: lt(module.header) }} />
              </Typography>
              <Typography className={classes.listContainer} sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                <span dangerouslySetInnerHTML={{ __html: lt(module.valuePropositionsList) }} />
              </Typography>
              <Stack gap="24px" marginTop="40px">
                <VixOrangeButton fullWidth>{lt(module.registerCta)}</VixOrangeButton>
                <TextButton fullWidth>{lt(module.signInCta)}</TextButton>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
