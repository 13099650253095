import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../data-layer';
import Localized from '../Localized';
import DraftBadge from '../DraftBadge';
import GenreBadge from '../GenreBadge';
import { VersionedDocumentStatus } from '../../../API';
import { EntitlementsVisibilityBadge } from '../Entitlements';
import { PermissionsGroupBadge } from '../PermissionsGroupBadge';
import { DeletedBadge } from '../DeletedBadge';
import { isEntityDeleted } from '../../../utils/generalUtils';
import { AutoRotateBadge } from '../../Collections/CollectionsPanel/CollectionManager/AutoRotateBadge';
import { SortingType } from '../../Collections/CollectionsPanel/CollectionManager/SortingType';
import { KidsBadge } from '../../Collections/CollectionsPanel/CollectionManager/KidsBadge';
import { collectionLabelTestIds } from '../TestsIds';

export const collectionLabelClasses = {
  root: 'collection-label-root',
  title: 'collection-label-title'
};

export interface CollectionLabelProps {
  collectionId: string;
  className?: string;
  onlyName?: boolean;
}

export default function CollectionLabel({
  collectionId,
  className,
  onlyName: nameOnly
}: CollectionLabelProps): JSX.Element {
  const {
    collections: {
      state: { withRecordById },
      hook: { queueIdToFetch }
    }
  } = useData();

  const collection = useRecoilValue(withRecordById(collectionId));
  const isDeleted = isEntityDeleted(collection);

  useEffect(() => {
    if (!collection) {
      queueIdToFetch(collectionId);
    }
  }, [collectionId]);

  if (!collection) return <></>;

  if (nameOnly) {
    return (
      <Localized
        prop={collection.title}
        data-testid={collectionLabelTestIds.title}
        className={collectionLabelClasses.title}
      />
    );
  }

  return (
    <Stack gap={2} direction="row" alignItems="center" className={className}>
      {isDeleted && <DeletedBadge />}
      <Localized
        prop={collection.title}
        data-testid={collectionLabelTestIds.title}
        className={collectionLabelClasses.title}
      />
      {collection.status === VersionedDocumentStatus.DRAFT && (
        <DraftBadge data-testid={collectionLabelTestIds.draftBadge} />
      )}
      {collection.genre && (
        <GenreBadge
          label={collection.genre.key}
          data-testid={collectionLabelTestIds.genreBadge}
          forSimilars={collection.forSimilars}
        />
      )}
      {collection.ownerPermissionsGroup && <PermissionsGroupBadge value={collection.ownerPermissionsGroup} />}
      {collection.entitlements && <EntitlementsVisibilityBadge entitlements={collection.entitlements} />}
      {collection.autoRotate && <AutoRotateBadge collection={collection} />}
      {collection.sortingType && <SortingType sortingType={collection.sortingType} />}
      {collection.kids && <KidsBadge collection={collection} />}
    </Stack>
  );
}
