import React from 'react';
import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { capitalize } from 'lodash-es';

interface ContentTypeSelectProps {
  formControlColor: 'primary' | 'secondary';
  classes: Record<string, string>;
  value: string[] | [];
  setValue: (arg0: string, arg1: string[]) => void;
  contentTypes: string[];
  contentTypesAllowed: string[];
  t: (key: string) => string;
  contentSearchFiltersTestIds: string;
}

const contentTypeOrganizer = {
  select_All: 'all',
  clear_All: 'all_clear'
};

const getContentTypeValue = (value: string[], contentTypes: string[], contentTypesAllowed: string[]) => {
  if (value.includes(contentTypeOrganizer.select_All)) {
    return contentTypes;
  }

  if (value.includes(contentTypeOrganizer.clear_All)) {
    return [];
  }

  if (!value || !contentTypes.length) {
    const [firstContentTypeAllowed] = contentTypesAllowed || [];
    return [firstContentTypeAllowed?.toLowerCase() || []];
  }

  return value;
};

const ContentTypeSelect: React.FC<ContentTypeSelectProps> = ({
  formControlColor,
  classes,
  value,
  setValue,
  contentTypes,
  contentTypesAllowed,
  t,
  contentSearchFiltersTestIds
}) => (
  <FormControl color={formControlColor} className={classes.contentTypeFilter}>
    <InputLabel id="filter" htmlFor="content-type">
      {t('layouts.content_type')}
    </InputLabel>

    <Select
      id="content-type"
      multiple
      label={t('layouts.content_type')}
      data-testid={contentSearchFiltersTestIds}
      labelId="filter"
      value={getContentTypeValue(value, contentTypes, contentTypesAllowed)}
      onChange={(evt) => {
        const selectedValues = evt.target.value as string[];
        if (selectedValues.includes(contentTypeOrganizer.select_All)) {
          setValue('contentType', contentTypes);
          return;
        }

        if (selectedValues.includes(contentTypeOrganizer.clear_All)) {
          setValue('contentType', []);
          return;
        }

        setValue('contentType', evt.target.value as unknown as string[]);
      }}
    >
      <MenuItem value={contentTypeOrganizer.select_All}>{t('collections.content_type.all')}</MenuItem>
      <MenuItem value={contentTypeOrganizer.clear_All}>{t('collections.content_type.all_clear')}</MenuItem>
      <Divider />
      {Object.values(contentTypes).map((type) => (
        <MenuItem key={type} value={type}>
          {capitalize(type.replace(/_/g, ' '))}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default ContentTypeSelect;
