import React, { useEffect, useState } from 'react';
import { Button, List, ListItem, ListItemText, Typography, Drawer, Badge, Chip } from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Notifications as NotificationsIcon
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useRecoilState } from 'recoil';
import { withShowNotificationDrawer } from '../../../../state/notifications';
import { times } from 'lodash';

import { useNotifications, useLocales, useTimezones } from '../../../../hooks';
import ShadowScroller from '../../../shared/ShadowScroller';
import IconButton from '../../../shared/IconButton';
import { ObjectTreeView } from '../../../shared/ObjectTreeView';
import { notificationDrawerTestIds } from '../../../shared/TestsIds';

const useStyles = makeStyles()((theme) => ({
  notificationDrawer: {
    width: 420,
    height: '100%',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  },
  noNotifications: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  notificationActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  notificationList: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  notification: {
    borderLeftWidth: theme.spacing(1),
    borderLeftStyle: 'solid',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 1, 1, 2),
    margin: theme.spacing(1, 0),
    background: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  notificationBody: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    overflow: 'hidden'
  },
  successClass: {
    borderColor: theme.palette.success.main
  },
  errorClass: {
    borderColor: theme.palette.error.main
  },
  defaultClass: {
    borderColor: 'transparent'
  },
  chipFilters: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  debugInfo: {
    wordBreak: 'break-word'
  }
}));

enum NotificationFilterTypes {
  SUCCESS = 'success',
  ERROR = 'error'
}

type NotificationFilters = {
  success: boolean;
  error: boolean;
};

export const NotificationDrawer = (): JSX.Element => {
  const { classes, cx } = useStyles();
  const { t } = useLocales();
  const { notificationHistory, clearNotification, clearNotificationHistory, closeNotificationsDrawer, markAllAsRead } =
    useNotifications();
  const [showNotificationDrawer, setShowNotificationDrawer] = useRecoilState(withShowNotificationDrawer);
  const [badgeCount, setBadgeCount] = useState(
    notificationHistory.filter((notification) => notification.options?.variant !== 'success' && !notification.isRead)
      .length
  );
  const [filters, setFilters] = useState<NotificationFilters>({
    success: true,
    error: true
  });
  const [expanded, setExpanded] = useState<boolean[]>([]);
  const { timestamp } = useTimezones();

  const onClickFilter = (filter: NotificationFilterTypes) => {
    setFilters({ ...filters, [filter]: !filters[filter] });
  };

  const toggleNotification = (index: number) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  useEffect(() => {
    setBadgeCount(notificationHistory.filter((notif) => notif.options?.variant !== 'success' && !notif.isRead).length);
    setExpanded(times(notificationHistory.length, () => false));
  }, [notificationHistory]);

  useEffect(() => {
    if (showNotificationDrawer) markAllAsRead();
  }, [showNotificationDrawer]);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={() => setShowNotificationDrawer(true)}
        data-testid={notificationDrawerTestIds.menuButton}
        size="large"
      >
        <Badge badgeContent={badgeCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Drawer open={showNotificationDrawer} onClose={() => setShowNotificationDrawer(false)} anchor={'right'}>
        <div className={classes.notificationDrawer} data-testid={notificationDrawerTestIds.drawerBody}>
          <div className={classes.notificationActions}>
            <Button
              variant="text"
              color="inherit"
              size="small"
              onClick={clearNotificationHistory}
              startIcon={<DeleteIcon />}
              data-testid={notificationDrawerTestIds.clearAll}
            >
              {t('notifications.clear_all')}
            </Button>
            <Button
              color="inherit"
              variant="text"
              size="small"
              onClick={closeNotificationsDrawer}
              endIcon={<CloseIcon />}
            >
              {t('general.close')}
            </Button>
          </div>
          <div className={classes.chipFilters}>
            <Chip
              label={t('filters.success')}
              size="small"
              color={'success'}
              variant={filters.success ? 'filled' : 'outlined'}
              onClick={() => onClickFilter(NotificationFilterTypes.SUCCESS)}
              data-testid={notificationDrawerTestIds.successFilter}
            />
            <Chip
              label={t('filters.error')}
              size="small"
              color={'error'}
              variant={filters.error ? 'filled' : 'outlined'}
              onClick={() => onClickFilter(NotificationFilterTypes.ERROR)}
              data-testid={notificationDrawerTestIds.errorFilter}
            />
          </div>
          {notificationHistory.length === 0 && (
            <Typography
              className={classes.noNotifications}
              variant={'h5'}
              data-testid={notificationDrawerTestIds.noNotifications}
            >
              {t('notifications.none')}
            </Typography>
          )}
          {notificationHistory.length > 0 && (
            <ShadowScroller>
              <List className={classes.notificationList}>
                {notificationHistory
                  .filter((notification) => filters[notification.options?.variant as NotificationFilterTypes])
                  .map((notification, index) => (
                    <ListItem
                      key={notification.key}
                      className={cx({
                        [classes.notification]: true,
                        [classes.successClass]: notification.options?.variant === 'success',
                        [classes.errorClass]: notification.options?.variant === 'error',
                        [classes.defaultClass]: notification.options?.variant === 'default'
                      })}
                    >
                      {(notification.details || notification.debugInfo) && (
                        <IconButton
                          onClick={() => toggleNotification(index)}
                          data-testid={notificationDrawerTestIds.toggleDetails}
                        >
                          {expanded[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      )}
                      <div className={classes.notificationBody}>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                          {notification.message}
                        </ListItemText>
                        {expanded[index] && notification.details && (
                          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                            {Array.isArray(notification.details)
                              ? notification.details.map((detail, i) => <div key={i}>{detail}</div>)
                              : notification.details}
                          </ListItemText>
                        )}
                        {expanded[index] && notification.debugInfo && (
                          <ListItemText className={classes.debugInfo} primaryTypographyProps={{ variant: 'body2' }}>
                            {typeof notification.debugInfo === 'object' ? (
                              <ObjectTreeView title={t('general.request')} object={notification.debugInfo} />
                            ) : (
                              notification.debugInfo
                            )}
                          </ListItemText>
                        )}
                        <ListItemText primaryTypographyProps={{ variant: 'caption' }}>
                          {timestamp(notification.timestamp.toISOString())}
                        </ListItemText>
                      </div>
                      <IconButton
                        onClick={() => clearNotification(notification.key)}
                        data-testid={notificationDrawerTestIds.clearNotification}
                        title={t('notifications.clear')}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
              </List>
            </ShadowScroller>
          )}
        </div>
      </Drawer>
    </>
  );
};
