import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ContentPreview, useStyles as useContentPreviewStyles } from '..';
import { useData } from '../../../../../data-layer';
import { HeroUiModuleItem, withHeroPreviewOrientation } from '../../../../../state/Layouts';
import { DisplayAsOptions, ThumbnailOrientation } from '../../../../../utils/types/genericTypes';
import { getContentIdFromHeroItem } from '../../../../../utils/uiModuleUtils';
import { PresetListItem } from '../../../../HPC/PresetListItem';
import { HeroThumbnail } from '../Thumbnails/HeroThumbnail';
import { ButtonGroup, Stack, Tooltip, Typography } from '@mui/material';
import { useLocales } from '../../../../../hooks';
import { Button } from '@mui/material';
import { PhoneAndroid, Tv } from '@mui/icons-material';
import { useStyles } from '../../../../Collections/DisplayAs/DisplayAs';
import { heroPreviewTestIds } from '../../../../shared/TestsIds';

export interface IHeroPreviewProps {
  heroPresetCollection: string | undefined;
  heroContents: HeroUiModuleItem[];
  showing: boolean;
}

export function HeroPreview({ heroPresetCollection, heroContents, showing }: IHeroPreviewProps): React.ReactElement {
  const { classes } = useStyles();
  const {
    heroPresetCollections: {
      state: { withLatestPublishedRecordById },
      hook: { queueLatestPublishedIdToFetch }
    }
  } = useData();
  const hpc = useRecoilValue(withLatestPublishedRecordById(heroPresetCollection));
  const { classes: contentPreviewClasses } = useContentPreviewStyles({});
  const { t } = useLocales();
  const [orientation, setOrientation] = useRecoilState(withHeroPreviewOrientation);

  const getButtonClass = (which: ThumbnailOrientation) =>
    orientation === which ? classes.displayAsButtonSelected : classes.displayAsButton;

  useEffect(() => {
    if (!hpc && heroPresetCollection) {
      queueLatestPublishedIdToFetch(heroPresetCollection, true);
    }
  }, [hpc, heroPresetCollection]);

  const thumbnailClass = orientation === ThumbnailOrientation.LANDSCAPE ? 'heroLandscape' : 'heroPortrait';

  return (
    <>
      <ContentPreview
        showing={showing}
        displayAs={thumbnailClass}
        header={
          <Stack direction="row" ml={4} mb={2} alignItems="center" gap={2}>
            <Typography variant="body2" color="textSecondary">
              {t('layouts.hero_preview.preview_for')}
            </Typography>
            <div className={classes.displayAs}>
              <ButtonGroup disableElevation variant="contained">
                <Tooltip title={t('layouts.hero_preview.web_ctv')} arrow placement="top">
                  <Button
                    size="small"
                    className={getButtonClass(ThumbnailOrientation.LANDSCAPE)}
                    onClick={() => setOrientation(ThumbnailOrientation.LANDSCAPE)}
                  >
                    <Tv />
                  </Button>
                </Tooltip>
                <Tooltip title={t('layouts.hero_preview.mobile')} arrow placement="top">
                  <Button
                    size="small"
                    className={getButtonClass(ThumbnailOrientation.PORTRAIT)}
                    onClick={() => setOrientation(ThumbnailOrientation.PORTRAIT)}
                  >
                    <PhoneAndroid />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </div>
          </Stack>
        }
      >
        <>
          {hpc?.presets.map((preset) => (
            <div key={preset} className={contentPreviewClasses.thumbnailContainer}>
              <div
                className={`${contentPreviewClasses.thumbnail} ${thumbnailClass}`}
                data-testid={heroPreviewTestIds.heroContentItem}
              >
                <PresetListItem
                  canEdit={false}
                  presetId={preset}
                  displayAs={orientation as unknown as DisplayAsOptions}
                  previewMode
                />
              </div>
            </div>
          ))}
          {heroContents.map((heroContentItem) => (
            <div key={getContentIdFromHeroItem(heroContentItem)} className={contentPreviewClasses.thumbnailContainer}>
              <div
                className={`${contentPreviewClasses.thumbnail} ${thumbnailClass}`}
                data-testid={heroPreviewTestIds.heroContentItem}
              >
                <HeroThumbnail heroContentItem={heroContentItem} orientation={orientation} />
              </div>
            </div>
          ))}
        </>
      </ContentPreview>
    </>
  );
}
