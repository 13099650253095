import React, { forwardRef } from 'react';
import { GeoBlockUiModuleBody } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { useFormContext } from 'react-hook-form';
import { FormBody } from '../../styles';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { geoBlockFormTestIds } from '../../../../shared/TestsIds';

const GeoBlockForm = forwardRef<UIModuleTypeForm<GeoBlockUiModuleBody>>(() => {
  const { control } = useFormContext<GeoBlockUiModuleBody>();

  return (
    <FormBody data-testid={geoBlockFormTestIds.formBody}>
      <div>
        <LokaliseField
          name="body"
          control={control}
          toTranslate="layouts.geo_block.fields"
          dataTestId={geoBlockFormTestIds.formField('body')}
          required
        />
      </div>
      <div>
        <LokaliseField
          name="legal"
          control={control}
          toTranslate="layouts.geo_block.fields"
          dataTestId={geoBlockFormTestIds.formField('legal')}
          required
        />
      </div>
    </FormBody>
  );
});

GeoBlockForm.displayName = 'GeoBlockForm';

export default GeoBlockForm;
