import React, { useState } from 'react';
import { useLocales, useTheme } from '../../../../../hooks';
import { LivePlusUiModuleBody } from '../../../../../API';
import Modal from '../../../../shared/Modal';
import { Box, Checkbox, FormControlLabel, Grid, Tab } from '@mui/material';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../../../shared/Button';
import { getAssetUrl } from '../../../../../utils/assetHelpers';
import { themeColors } from '../../../../../theme';
import TextField from '../../../../shared/TextField';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import { useLokalise } from '../../../../../hooks/General/useLokalise';
import { VixOrangeButton } from '../shared/SharedButtons';
import { livePlusPreviewTestIds } from '../../../../shared/TestsIds';

export interface ILivePlusPreviewProps {
  livePlusModule: LivePlusUiModuleBody;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export function LivePlusPreview({ livePlusModule, isOpen, setIsOpen }: ILivePlusPreviewProps): React.ReactElement {
  const { t } = useLocales();
  const { formControlColor } = useTheme();
  const { lt } = useLokalise();
  const [tabValue, setTabValue] = useState('0');
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [email, setEmail] = useState('');

  const takeoverPortraitImage = livePlusModule.portraitTakeoverImage;
  const endGamePortraitImage = livePlusModule.portraitEndGameImage;

  const onModalClose = () => setIsOpen(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleForm = () => setIsOpenForm(!isOpenForm);

  return (
    <Modal
      open={isOpen}
      onClose={onModalClose}
      headerLeft={t('layouts.live_plus.preview')}
      footerLeft={
        <Button color="grey" onClick={onModalClose} sx={{ width: 140 }}>
          {t('general.close')}
        </Button>
      }
    >
      <Box data-testid={livePlusPreviewTestIds.root} sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Box data-testid={livePlusPreviewTestIds.livePlusContainer}>
          <TabContext value={tabValue}>
            <TabList indicatorColor={formControlColor} textColor={formControlColor} onChange={handleTabChange} centered>
              <Tab label={t('layouts.live_plus.headers.home')} value="0" />
              <Tab label={t('layouts.live_plus.headers.end_game')} value="1" />
            </TabList>

            <TabPanel value="0" data-testid={livePlusPreviewTestIds.homeContainer}>
              <Box
                sx={{
                  width: 600,
                  backgroundColor: '#7D0631',
                  position: 'relative',
                  color: 'white',
                  strong: {
                    color: themeColors.orange
                  }
                }}
              >
                {/* Background Image */}
                <Box
                  component="img"
                  src={getAssetUrl(takeoverPortraitImage)}
                  sx={{
                    width: '100%',
                    position: 'absolute'
                  }}
                />
                <Box sx={{ position: 'relative' }}>
                  {/* Vix Logo */}
                  <Box component="img" src="/assets/vix-plus-white.svg" sx={{ margin: '40px 30px' }} />
                  <Box
                    sx={{
                      marginTop: '90px',
                      padding: '0 5%',
                      background: 'linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.7) 20%)'
                    }}
                  >
                    {/* Takeover Header */}
                    <Box sx={{ paddingTop: '25px', fontSize: '30px', fontWeight: 'bold', maxWidth: '50%' }}>
                      <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.takeoverHeader) }} />
                    </Box>
                    {/* Takeover Lead */}
                    <Box>
                      <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.takeoverLead) }} />
                    </Box>
                    {/* Teams vs */}
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: 300,
                            height: 150,
                            marginTop: '20px',
                            backgroundColor: 'black'
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            width: 300,
                            height: 150,
                            marginTop: '20px',
                            backgroundColor: 'black'
                          }}
                        />
                      </Grid>
                    </Grid>
                    {/* Takeover Exit CTA */}
                    <Box
                      sx={{
                        marginTop: '20px',
                        color: 'rgba(255,255,255,0.8)',
                        textAlign: 'center',
                        paddingBottom: '25px',
                        cursor: 'pointer'
                      }}
                      onClick={handleForm}
                    >
                      <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.takeoverExitCta) }} />
                    </Box>
                  </Box>
                </Box>
                <Dialog
                  open={isOpenForm}
                  onClose={handleForm}
                  PaperProps={{
                    style: {
                      background: 'linear-gradient(to right, #040E30, rgba(0,0,0,1) 90%)'
                    }
                  }}
                >
                  <DialogTitle>
                    <IconButton
                      onClick={handleForm}
                      sx={{
                        position: 'absolute',
                        right: 4,
                        top: 4,
                        color: (theme) => theme.palette.grey[500]
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <Box>
                      <Box
                        sx={{
                          width: 500,
                          position: 'relative',
                          color: 'white',
                          strong: {
                            color: themeColors.orange
                          }
                        }}
                      >
                        <Box sx={{ position: 'relative' }}>
                          {/* Vix Logo */}
                          <Box component="img" src="/assets/vix-plus-white.svg" />
                          <Box
                            sx={{
                              marginTop: '15px'
                            }}
                          >
                            {/* Form Header */}
                            <Box sx={{ paddingTop: '5px', fontSize: '30px', fontWeight: 'bold' }}>
                              <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.formHeader) }} />
                            </Box>
                            {/* Form Lead */}
                            <Box sx={{ marginTop: '10px' }}>
                              <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.formLead) }} />
                            </Box>
                            {/* Form Email */}
                            <Box sx={{ marginTop: '10px' }}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                label={lt('formEmailPlaceholder')}
                                value={email}
                                onChange={({ target }) => setEmail(target.value)}
                                error={email.length !== 0}
                                helperText={email.length !== 0 ? lt(livePlusModule.formEmailValidationError) : ''}
                              />
                            </Box>
                            {/* Form Opt in Label */}
                            <Box sx={{ fontSize: '12px' }}>
                              <FormControlLabel
                                value="formOptInLabel"
                                control={<Checkbox />}
                                label={<span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.formOptInLabel) }} />}
                                labelPlacement="end"
                              />
                            </Box>
                            <Box sx={{ marginTop: '20px' }}>
                              {/* Form Submit CTA */}
                              <Box>
                                <VixOrangeButton>{lt(livePlusModule.formSubmitCta)}</VixOrangeButton>
                              </Box>
                              {/* Form Cancel CTA */}
                              <Box
                                sx={{
                                  fontSize: '14px',
                                  color: 'rgba(255,255,255,0.5)',
                                  padding: '20px 0 60px',
                                  textAlign: 'center'
                                }}
                              >
                                <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.formCancelCta) }} />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
            </TabPanel>
            <TabPanel value="1" data-testid={livePlusPreviewTestIds.endGameContainer}>
              <Box
                sx={{
                  width: 600,
                  backgroundColor: '#7D0631',
                  position: 'relative',
                  color: 'white',
                  strong: {
                    color: themeColors.orange
                  }
                }}
              >
                {/* Background Image */}
                <Box
                  component="img"
                  src={getAssetUrl(endGamePortraitImage)}
                  sx={{
                    width: '100%',
                    position: 'absolute'
                  }}
                />
                <Box sx={{ position: 'relative' }}>
                  {/* Vix Logo */}
                  <Box component="img" src="/assets/vix-plus-white.svg" sx={{ margin: '40px 30px' }} />
                  <Box
                    sx={{
                      marginTop: '350px',
                      padding: '0 18%',
                      background: 'linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.7) 20%, rgba(0,0,0,1) 30%)'
                    }}
                  >
                    {/* End Game Header */}
                    <Box sx={{ paddingTop: '25px', fontSize: '30px', fontWeight: 'bold', maxWidth: '50%' }}>
                      <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.endGameHeader) }} />
                    </Box>
                    {/* End Game Lead */}
                    <Box sx={{ marginTop: '20px', color: 'rgba(255,255,255,0.8)', maxWidth: '75%' }}>
                      <span dangerouslySetInnerHTML={{ __html: lt(livePlusModule.endGameLead) }} />
                    </Box>
                    {/* End Game Continue Vix CTA */}
                    <Box sx={{ marginTop: '20px', paddingBottom: '45%' }}>
                      <VixOrangeButton>{lt(livePlusModule.endGameContinueVixCta)}</VixOrangeButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Modal>
  );
}
