import React, { useMemo, useState } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  IconButtonProps,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
  createFilterOptions,
  inputClasses
} from '@mui/material';
import TextValidator from '../TextValidator';
import { markAsRequired } from '../../../utils/formHelpers';
import { useLocales, useTheme } from '../../../hooks';
import { makeStyles } from 'tss-react/mui';
import { useLokalise } from '../../../hooks/General/useLokalise';
import IconButton from '../IconButton';
import { Preview } from '@mui/icons-material';
import Button from '../Button';
import Modal from '../Modal';
import { useRecoilValue } from 'recoil';
import { useData } from '../../../data-layer';
import { IdBadge } from '../IdBadge';
import FormControl from '../FormControl';
import { lokaliseAutocompleteTestIds } from '../TestsIds';

type LokaliseAutocompleteProps = {
  name: string;
  label: string;
  value: string;
  keyPrefix?: string;
  'data-testid'?: string;
  onChange: (value: string) => unknown;
  required?: boolean;
  validateLokaliseKey?: boolean;
};

const useStyles = makeStyles()(() => ({
  autocomplete: {
    overflow: 'hidden'
  },
  modal: {
    outline: 'none !important'
  },
  input: {
    [`& .${inputClasses.root}`]: {
      display: 'grid',
      gridTemplateColumns: '1fr auto'
    },
    [`& .${inputClasses.input}`]: {
      width: 'unset !important',
      paddingBottom: '0 !important'
    }
  },
  previewButton: {
    gridRow: '1 / 3',
    gridColumn: 2
  },
  translationText: {
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    order: 2
  }
}));

type LACProps = AutocompleteProps<string | undefined, false, false, false>;

const AUTOCOMPLETE_OPTIONS_LIMIT = 50;

export const LokaliseAutocomplete = ({
  name,
  label,
  value,
  keyPrefix,
  'data-testid': dataTestId,
  onChange,
  required = true,
  validateLokaliseKey = false
}: LokaliseAutocompleteProps): JSX.Element => {
  const {
    lokalise: {
      state: { withAllRecords, withIsFetching }
    }
  } = useData();
  const { classes, cx } = useStyles();
  const { lt, searchTranslations, getTranslations } = useLokalise();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const isLoading = useRecoilValue(withIsFetching);
  const keys = useRecoilValue(withAllRecords);
  const options = useMemo(() => Object.keys(getTranslations(keyPrefix)), [keys]);
  const [inputValue, setInputValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [modalOption, setModalOption] = useState<string>();

  const validators = validateLokaliseKey ? ['isLokaliseKeyValid'] : [];
  const errorMessages = validateLokaliseKey ? [t('errors.layouts.invalid_lokalise_key', { key: value })] : [];

  const findTranslations = (search: string): string[] => Object.keys(searchTranslations(search, keyPrefix));

  const isLokaliseKeyValid = (value: string | undefined) =>
    !!value && (!!lt(value) || !!findTranslations(value).find((key) => lt(key) === value));

  const onModalClose = () => {
    setModalOption(undefined);
  };

  const filterOptions = createFilterOptions<string | undefined>({
    limit: AUTOCOMPLETE_OPTIONS_LIMIT,
    matchFrom: 'any',
    // Allow to filter by translation and key
    stringify: (option) => (option ? lt(option) + ' ' + option : '')
  });

  const extendedFilterOptions: LACProps['filterOptions'] = (options, state) => {
    // If the inputValue here is blank, it means we've opened the popover without entering in a new value
    if (!state.inputValue) {
      // Look for an existing key that matches our current value
      const found = options.findIndex((option) => option === value);
      if (~found) {
        // Take a section of the options before/after the found value to slice away
        const half = AUTOCOMPLETE_OPTIONS_LIMIT / 2;

        // Get the start and end indexes
        const start = Math.max(found - half, 0);
        const end = Math.min(found + half, options.length);

        // Add padding to allow for having the limit values in size
        const startPad = Math.max(half - (end - found), 0);
        const endPad = Math.min(half - (found - start), options.length);

        // Slice off this difference
        options = options.slice(start - startPad, end + endPad);
      }
    }
    // Run through our filter options
    return filterOptions(options, state);
  };

  const onOpen = () => setOpen(true);

  const onClose: LACProps['onClose'] = () => {
    if (modalOption) return;
    setOpen(false);
  };

  const onAutoCompleteChange: LACProps['onChange'] = (event, newValue) => {
    handleChange(newValue || '');
  };

  const handleChange = (newValue: string | undefined = '') => {
    if (!newValue || options.find((option) => option === newValue)) {
      onChange(newValue);
      setInputValue(newValue);
    }
  };

  const onInputChange: LACProps['onInputChange'] = (event, newValue) => setInputValue(newValue);

  const onUse = () => {
    if (modalOption) {
      onChange(modalOption);
      setInputValue(modalOption);
      setModalOption(undefined);
    }
  };

  const TranslationType: React.FC<TypographyProps> = (props) => (
    <Typography variant="body2" color="textSecondary" className={classes.translationText} {...props} />
  );

  const headerLeft = (
    <Stack direction="row" gap={2}>
      <Typography>{t('general.lokalise_key')}</Typography>
      <Typography>
        <IdBadge id={modalOption as string} />
      </Typography>
    </Stack>
  );

  const PreviewButton: React.FC<{ option: string | undefined; size?: IconButtonProps['size']; className?: string }> = ({
    option,
    size,
    className
  }) => (
    <Tooltip title={t('general.lokalise_view_key_info')} arrow>
      <div className={className}>
        <IconButton
          size={size}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setModalOption(option);
          }}
        >
          <Preview />
        </IconButton>
      </div>
    </Tooltip>
  );

  return (
    <FormControl color={formControlColor} required fullWidth>
      <Autocomplete
        className={classes.autocomplete}
        data-testid={dataTestId}
        value={value || null}
        options={options}
        openOnFocus
        autoComplete
        filterOptions={extendedFilterOptions}
        onChange={onAutoCompleteChange}
        onInputChange={onInputChange}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        loading={isLoading}
        loadingText={t('general.loading')}
        inputValue={inputValue}
        isOptionEqualToValue={(_, value) => value === value}
        renderInput={(params) => (
          <TextValidator
            {...params}
            className={classes.input}
            fullWidth
            name={name}
            color={formControlColor}
            label={required ? markAsRequired(label) : label}
            value={value || null}
            InputProps={{
              ...params.InputProps,
              className: cx(params.InputProps.className, classes.input),
              startAdornment: (
                <TranslationType sx={{ gridRow: 2, mb: 1 }}>
                  {/* The space is to keep the element visible to preserve height */}
                  <span>{inputValue === value && lt(value)}&nbsp;</span>
                </TranslationType>
              ),
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {!!lt(inputValue) && <PreviewButton option={value} size="small" className={classes.previewButton} />}
                </>
              )
            }}
            onChange={({ target: { value } }) => handleChange(value)}
            data-testid={lokaliseAutocompleteTestIds.autoCompleteSearchInput}
            validators={required ? ['required', ...validators] : validators}
            errorMessages={required ? [t('general.field_is_required'), ...errorMessages] : errorMessages}
            customValidationFunction={isLokaliseKeyValid}
            customValidationName="isLokaliseKeyValid"
          />
        )}
        renderOption={(props, option) => (
          <Stack
            {...props}
            component="li"
            direction="row"
            overflow="hidden"
            alignItems="center"
            gap={2}
            data-testid={lokaliseAutocompleteTestIds.autoCompleteOption}
            data-option={option}
          >
            <Stack overflow="hidden" sx={{ flexGrow: 1 }}>
              <Typography>{option}</Typography>
              <TranslationType>{lt(option)}</TranslationType>
            </Stack>
            <PreviewButton option={option} />
          </Stack>
        )}
      />
      {modalOption && (
        <Modal
          open
          bodyClassName={classes.modal}
          headerLeft={headerLeft}
          onClose={onModalClose}
          footerLeft={
            <Button sx={{ minWidth: 120 }} onClick={onUse}>
              {t('general.use_lokalise_key')}
            </Button>
          }
          footerRight={
            <Button sx={{ minWidth: 120 }} color="grey" onClick={onModalClose}>
              {t('general.close')}
            </Button>
          }
        >
          <Stack p={4} gap={2}>
            <Typography variant="body2" color="textSecondary" sx={{ overflowX: 'hidden' }}>
              <span dangerouslySetInnerHTML={{ __html: lt(modalOption) }} />
            </Typography>
          </Stack>
        </Modal>
      )}
    </FormControl>
  );
};
