import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { Controller, useFormContext } from 'react-hook-form';
import { InlinePageUiModuleBody } from '../../../../../API';
import { FormBody, FormField } from '../../styles';
import { useLocales, useTheme } from '../../../../../hooks';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { Stack } from '@mui/material';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { PagesAutocomplete } from '../PagesAutocomplete';
import TextValidator from '../../../../shared/TextValidator';
import { inlinePageFormTestIds } from '../../../../shared/TestsIds';

const InlinePageForm = forwardRef<UIModuleTypeForm<InlinePageUiModuleBody>>(() => {
  const { control } = useFormContext<InlinePageUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const InlinePageAssetBrowser: React.FC<{
    name: keyof InlinePageUiModuleBody;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }> = ({ name, label, assetType, required = false }): React.ReactElement => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <FormField>
              <AssetBrowser
                label={isRequired(label)}
                assetId={assetType}
                assetType={assetType}
                value={value as string}
                data-testid={inlinePageFormTestIds.assetField(name)}
                hasError={!!error}
                onChange={onChange}
              />
            </FormField>
          );
        }}
      />
    );
  };

  return (
    <FormBody>
      <Stack>
        <Stack direction="row">
          <InlinePageAssetBrowser
            required
            name="landscapeImage"
            label={t('image_labels.landscape')}
            assetType={AssetTypes.inlinePage.landscape}
          />
          <InlinePageAssetBrowser
            required
            name="portraitImage"
            label={t('image_labels.portrait')}
            assetType={AssetTypes.inlinePage.portrait}
          />
        </Stack>
        <Stack direction="row">
          <InlinePageAssetBrowser
            name="mobileImage"
            label={t('image_labels.mobile')}
            assetType={AssetTypes.inlinePage.mobile}
          />
          <InlinePageAssetBrowser name="ctvImage" label={t('image_labels.ctv')} assetType={AssetTypes.inlinePage.ctv} />
        </Stack>
      </Stack>

      <Stack>
        <Stack direction="row">
          <FormField>
            <Controller
              control={control}
              name="ctaName"
              render={({ field: { value, onChange } }) => (
                <LokaliseAutocomplete
                  label={t('layouts.inline_page.fields.ctaName')}
                  name="ctaName"
                  value={value as string}
                  onChange={onChange}
                  required
                  data-testid={inlinePageFormTestIds.ctaName}
                />
              )}
            />
          </FormField>
          <FormField>
            <Controller
              control={control}
              name="ctaUrlPath"
              render={({ field: { value, onChange } }) => (
                <PagesAutocomplete
                  value={value}
                  onChange={onChange}
                  useOnlyPages
                  renderInput={(params) => (
                    <TextValidator
                      value={value}
                      {...params}
                      name="ctaUrlPath"
                      label={markAsRequired(t('layouts.inline_page.fields.ctaUrlPath'))}
                      color={formControlColor}
                      validators={['required']}
                      errorMessages={[t('general.field_is_required')]}
                    />
                  )}
                  data-testid={inlinePageFormTestIds.ctaUrlPath}
                />
              )}
            />
          </FormField>
        </Stack>
        <FormField>
          <Controller
            control={control}
            name="header"
            render={({ field: { value, onChange } }) => (
              <LokaliseAutocomplete
                label={t('layouts.inline_page.fields.header')}
                name="header"
                value={value as string}
                required={false}
                onChange={onChange}
                data-testid={inlinePageFormTestIds.header}
              />
            )}
          />
        </FormField>
      </Stack>
    </FormBody>
  );
});

InlinePageForm.displayName = 'InlinePageForm';

export default InlinePageForm;
