import React from 'react';
import { InputLabel, Chip, Select, MenuItem, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../hooks';
import { RecommendationContentType } from '../../../../API';
import FormControl from '../../../shared/FormControl';
import { markAsRequired } from '../../../../utils/formHelpers';
import { recommendationsContentTypePickerTestIds } from '../../../shared/TestsIds';

interface IRecommendationsContentTypePickerProps {
  value: RecommendationContentType[] | undefined;
  onChange: (changedValues: RecommendationContentType[] | undefined) => unknown;
  error: boolean;
}

const useStyles = makeStyles()(() => ({
  selector: {
    minWidth: 260
  }
}));

export function RecommendationsContentTypePicker({
  value,
  onChange,
  error
}: IRecommendationsContentTypePickerProps): React.ReactElement {
  const options: RecommendationContentType[] = Object.values(RecommendationContentType);
  const { classes } = useStyles();
  const { t } = useLocales();
  const stringValues = value?.map((v) => v.toString()) || [];

  return (
    <FormControl>
      <InputLabel id="recommendationSelector" shrink variant="standard">
        {markAsRequired(t('layouts.content_type'))}
      </InputLabel>
      <Select
        multiple
        label={t('layouts.content_type')}
        className={classes.selector}
        value={stringValues}
        onChange={({ target: { value } }) => onChange(value as RecommendationContentType[])}
        displayEmpty
        error={error}
        renderValue={(value: string[]) => {
          if (value.length === 0) {
            return <>{t('layouts.content_type_placeholder')}</>;
          }
          return (
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              {value.map((option: string) => (
                <Chip
                  key={option}
                  size="small"
                  label={option}
                  data-testid={recommendationsContentTypePickerTestIds.chip}
                  data-option={option}
                />
              ))}
            </Box>
          );
        }}
        data-testid={recommendationsContentTypePickerTestIds.contentTypeSelector}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name} data-testid={recommendationsContentTypePickerTestIds.option}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
