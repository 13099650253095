import React from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useLocales } from '../../../../hooks';
import { SetterOrUpdater } from 'recoil';
import FormControl from '../../../shared/FormControl';
import { continueWatchingContentTypePickerTestIds } from '../../../shared/TestsIds';

export interface ITreatmentPickerProps<T> {
  options: T;
  value: keyof T;
  onChange: SetterOrUpdater<keyof T>;
}

const useStyles = makeStyles()(() => ({
  selector: {
    minWidth: 260
  }
}));

export function ContinueWatchingContentTypePicker<T extends Record<string, string>>({
  options,
  value,
  onChange
}: ITreatmentPickerProps<T>): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useLocales();

  return (
    <FormControl>
      <InputLabel id="contentTypeTreatmentSelector">{t('layouts.content_type')}</InputLabel>
      <Select
        label={t('layouts.content_type')}
        className={classes.selector}
        labelId="contentTypeTreatmentSelector"
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        data-testid={continueWatchingContentTypePickerTestIds.treatmentSelector}
      >
        {Object.values(options).map((value) => (
          <MenuItem
            key={value}
            value={value}
            data-testid={continueWatchingContentTypePickerTestIds.treatmentSelectorItem}
            data-treatment-id={value}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
